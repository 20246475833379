@charset "UTF-8";
/* Regular Font */
@import '@angular/cdk/overlay-prebuilt.css';
@import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Regular-v1.0/WEB/PPMori-Regular.woff2") format("woff2"), url("./PP-Mori-Regular-v1.0/WEB/PPMori-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* semibold Font */
/* Medium Font */
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Medium/PPMori-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
/* Bold Font */
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Bold-v1.0//WEB/PPMori-Bold.woff2") format("woff2"), url("./PP-Mori-Bold-v1.0//WEB/PPMori-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
/* semibold Font */
@font-face {
  font-family: "PP Mori"; /* Nome da fonte */
  src: url("./PP-Mori-SemiBold/PPMori-SemiBold.otf") format("opentype"); /* URL do arquivo de fonte OTF */
  font-weight: 600; /* Peso da fonte (normal, bold, etc.) */
  font-style: normal; /* Estilo da fonte (normal, italic, etc.) */
}
.no-margin {
  margin: 0 !important;
}

.mzic-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

:root {
  --bs-gutter-x: 3.5rem;
  --primary-color: #1d192d;
  --label-primary-color: #e6e6ea;
  --button-primary-color: #7c73f3;
  --placeholder-color: #a3a5c3;
  --link-primary-color: #9976ff;
  --success-color: #09d793;
  --error-color: #ca2029;
  --background-color-primary: #211b36;
  --id-main-purple: #9655ff;
  --id-main-black: #111010;
  --id-white: #ededed;
  --id-subtitles: #878787;
  --navigation-width: 236px;
  --navigation-width-collapsed: 68px;
  --navigation-inner-padding: 12px;
  --dashboard-inner-padding: 38px;
  --table-fixed-footer-height: 72px;
  --font-extra-light: 100;
  --font-light: 200;
  --font-book: 300;
  --font-regular: 400;
  --font-medium: 475;
  --font--lg-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-book: 800;
  --neutral-100: #ededed;
  --neutral-200: #c5c5c5;
  --neutral-200-50: rgba(197, 197, 197, 0.5019607843);
  --neutral-300: #878787;
  --neutral-400: #545454;
  --neutral-500: #27262d;
  --neutral-500-50: rgba(39, 38, 45, 0.5019607843);
  --neutral-600: #1a1a1d;
  --neutral-600-50: rgba(26, 26, 29, 0.5019607843);
  --neutral-700: #111010;
  --neutral-700-70: rgba(17, 16, 16, 0.7019607843);
  --auxiliary-200-24: rgba(153, 154, 186, 0.2392156863);
  --black: #000;
  --white: #fff;
  --purple-700: #552ab7;
  --purple-600: #743edb;
  --purple-500: #9655ff;
  --purple-400: #b57fff;
  --purple-300: #c799ff;
  --purple-200: #dcbbff;
  --purple-100: #efddff;
  --red-700: #91102e;
  --red-600: #ad172c;
  --red-500: #ca2029;
  --red-400: #ea3933;
  --red-300: #ff7272;
  --red-200: #ff9999;
  --red-100: #ffd2d2;
  --yellow-700: #b7801f;
  --yellow-600: #dba12e;
  --yellow-500: #ffc53f;
  --yellow-400: #ffd76f;
  --yellow-300: #ffe38b;
  --yellow-200: #ffeeb2;
  --yellow-100: #fff8d8;
  --green-700: #049a86;
  --green-600: #06b88f;
  --green-500: #09d793;
  --green-400: #41e79f;
  --green-300: #67f3a9;
  --green-200: #9afbbe;
  --green-100: #ccfdd9;
  --blue-700: #2063b3;
  --blue-600: #2e84d6;
  --blue-500: #40a9f9;
  --blue-400: #6fc6fb;
  --blue-300: #8cd9fd;
  --blue-200: #b2eafe;
  --blue-100: #d8f6fe;
}

:root {
  --bs-gutter-x: 3.5rem;
  --primary-color: #1d192d;
  --label-primary-color: #e6e6ea;
  --button-primary-color: #7c73f3;
  --placeholder-color: #a3a5c3;
  --link-primary-color: #9976ff;
  --success-color: #09d793;
  --error-color: #ca2029;
  --background-color-primary: #211b36;
  --id-main-purple: #9655ff;
  --id-main-black: #111010;
  --id-white: #ededed;
  --id-subtitles: #878787;
  --navigation-width: 236px;
  --navigation-width-collapsed: 68px;
  --navigation-inner-padding: 12px;
  --dashboard-inner-padding: 38px;
  --table-fixed-footer-height: 72px;
  --font-extra-light: 100;
  --font-light: 200;
  --font-book: 300;
  --font-regular: 400;
  --font-medium: 475;
  --font--lg-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-book: 800;
  --neutral-100: #ededed;
  --neutral-200: #c5c5c5;
  --neutral-200-50: rgba(197, 197, 197, 0.5019607843);
  --neutral-300: #878787;
  --neutral-400: #545454;
  --neutral-500: #27262d;
  --neutral-500-50: rgba(39, 38, 45, 0.5019607843);
  --neutral-600: #1a1a1d;
  --neutral-600-50: rgba(26, 26, 29, 0.5019607843);
  --neutral-700: #111010;
  --neutral-700-70: rgba(17, 16, 16, 0.7019607843);
  --auxiliary-200-24: rgba(153, 154, 186, 0.2392156863);
  --black: #000;
  --white: #fff;
  --purple-700: #552ab7;
  --purple-600: #743edb;
  --purple-500: #9655ff;
  --purple-400: #b57fff;
  --purple-300: #c799ff;
  --purple-200: #dcbbff;
  --purple-100: #efddff;
  --red-700: #91102e;
  --red-600: #ad172c;
  --red-500: #ca2029;
  --red-400: #ea3933;
  --red-300: #ff7272;
  --red-200: #ff9999;
  --red-100: #ffd2d2;
  --yellow-700: #b7801f;
  --yellow-600: #dba12e;
  --yellow-500: #ffc53f;
  --yellow-400: #ffd76f;
  --yellow-300: #ffe38b;
  --yellow-200: #ffeeb2;
  --yellow-100: #fff8d8;
  --green-700: #049a86;
  --green-600: #06b88f;
  --green-500: #09d793;
  --green-400: #41e79f;
  --green-300: #67f3a9;
  --green-200: #9afbbe;
  --green-100: #ccfdd9;
  --blue-700: #2063b3;
  --blue-600: #2e84d6;
  --blue-500: #40a9f9;
  --blue-400: #6fc6fb;
  --blue-300: #8cd9fd;
  --blue-200: #b2eafe;
  --blue-100: #d8f6fe;
}

.text-heading-xxxl,
.text-heading-xxl,
.text-heading-xl,
.text-heading-lg,
.text-heading-md,
.text-heading-sm,
.text-body-lg,
.text-body-lg-medium,
.text-body-md,
.mz-item-option > .mid strong,
.text-body-xxsm,
.side-floating-menu ul li,
.tracks-release-section-text,
.split-details-payees-list-component .col-percent,
.rule-resume-card-component .rule-card .content,
.mzic-tip-on-hover .mzic-tip .item,
.mzic-tip-on-hover .mzic-tip strong,
.mzic-tip-on-hover .mzic-tip p,
.mzic-popover.modal-popover .main p,
.select-option__main-title,
.mz-form-row.for-paragraph p,
.mz-item-option > .mid small,
.mz-form-row > .mz-label > p,
.text-body-xsm,
.split-details-resume-component .text-container .dates > div span,
.split-details-resume-component .text-container .resume span,
.mzic-bank-account .text p,
.select-option__main-description,
.mzic-alert > p,
.form-hint-error,
.form-hint-success,
.mz-form-row .mz-input .input-prefix,
.mz-form-row.for-image .mz-input .image-text .secondary,
.mz-form-row .mz-input .input-tip,
.text-body-sm,
.text-heading-xxxl-semibold,
.text-heading-xxl-semibold,
.text-heading-xl-semibold,
.text-heading-lg-semibold,
.text-heading-md-semibold,
.tracks-release-input-section-title,
.text-heading-sm-semibold,
.split-details-resume-component .text-container .title .text,
.text-body-lg-semibold,
.text-body-md-semibold,
.toggle-content-link-component,
.mzic-bank-account .text .title,
.mzic-popover.modal-popover .main .title,
.mzic-popover.modal-popover .header .title,
.mzic-dialog .header .title,
.mzic-select > label,
.mz-form-row.for-image .mz-input .image-text .primary,
.mz-label > label,
.text-body-xxsm-semibold,
.split-details-payees-list-component .col-icon-msg > div label,
.rule-resume-card-component .rule-card .content .strong,
.text-body-xsm-semibold,
.text-body-sm-semibold,
.text-heading-xxxl-bold,
.text-heading-xxl-bold,
.text-heading-xl-bold,
.text-heading-lg-bold,
.text-heading-md-bold,
.text-heading-sm-bold,
.text-body-lg-bold,
.text-body-md-bold,
.rule-resume-card-component .rule-card .card-title,
.text-body-xxsm-bold,
.mz-button-tag .label,
.mz-button-tag .number,
.text-body-xsm-bold,
.track-status-tag,
.split-details-resume-component .text-container .dates > div strong,
.split-details-resume-component .text-container .resume strong,
.sort-number,
.select-option__main-version,
.text-body-sm-bold,
.split-details-payees-list-component .col-status .status,
.split-details-payees-list-component .col-tag .tag,
.split-details-resume-component .text-container .tag,
.mzic-bank-account .text .tag {
  padding: 0;
  color: var(--neutral-300);
}
.text-heading-xxxl.primary,
.text-heading-xxl.primary,
.text-heading-xl.primary,
.text-heading-lg.primary,
.text-heading-md.primary,
.text-heading-sm.primary,
.text-body-lg.primary,
.text-body-lg-medium.primary,
.text-body-md.primary,
.mz-item-option > .mid strong.primary,
.text-body-xxsm.primary,
.side-floating-menu ul li.primary,
.primary.tracks-release-section-text,
.split-details-payees-list-component .primary.col-percent,
.rule-resume-card-component .rule-card .primary.content,
.mzic-tip-on-hover .mzic-tip .primary.item,
.mzic-tip-on-hover .mzic-tip strong.primary,
.mzic-tip-on-hover .mzic-tip p.primary,
.mzic-popover.modal-popover .main p.primary,
.primary.select-option__main-title,
.mz-form-row.for-paragraph p.primary,
.mz-item-option > .mid small.primary,
.mz-form-row > .mz-label > p.primary,
.text-body-xsm.primary,
.split-details-resume-component .text-container .dates > div span.primary,
.split-details-resume-component .text-container .resume span.primary,
.mzic-bank-account .text p.primary,
.primary.select-option__main-description,
.mzic-alert > p.primary,
.primary.form-hint-error,
.primary.form-hint-success,
.mz-form-row .mz-input .primary.input-prefix,
.mz-form-row .mz-input .primary.input-tip,
.text-body-sm.primary,
.text-heading-xxxl-semibold.primary,
.text-heading-xxl-semibold.primary,
.text-heading-xl-semibold.primary,
.text-heading-lg-semibold.primary,
.text-heading-md-semibold.primary,
.primary.tracks-release-input-section-title,
.text-heading-sm-semibold.primary,
.split-details-resume-component .text-container .title .primary.text,
.text-body-lg-semibold.primary,
.text-body-md-semibold.primary,
.primary.toggle-content-link-component,
.mzic-bank-account .text .primary.title,
.mzic-popover.modal-popover .main .primary.title,
.mzic-popover.modal-popover .header .primary.title,
.mzic-dialog .header .primary.title,
.mzic-select > label.primary,
.mz-form-row.for-image .mz-input .image-text .primary,
.mz-label > label.primary,
.text-body-xxsm-semibold.primary,
.split-details-payees-list-component .col-icon-msg > div label.primary,
.rule-resume-card-component .rule-card .content .primary.strong,
.text-body-xsm-semibold.primary,
.text-body-sm-semibold.primary,
.text-heading-xxxl-bold.primary,
.text-heading-xxl-bold.primary,
.text-heading-xl-bold.primary,
.text-heading-lg-bold.primary,
.text-heading-md-bold.primary,
.text-heading-sm-bold.primary,
.text-body-lg-bold.primary,
.text-body-md-bold.primary,
.rule-resume-card-component .rule-card .primary.card-title,
.text-body-xxsm-bold.primary,
.mz-button-tag .primary.label,
.mz-button-tag .primary.number,
.text-body-xsm-bold.primary,
.primary.track-status-tag,
.split-details-resume-component .text-container .dates > div strong.primary,
.split-details-resume-component .text-container .resume strong.primary,
.primary.sort-number,
.primary.select-option__main-version,
.text-body-sm-bold.primary,
.split-details-payees-list-component .col-status .primary.status,
.split-details-payees-list-component .col-tag .primary.tag,
.split-details-resume-component .text-container .primary.tag,
.mzic-bank-account .text .primary.tag {
  color: var(--purple-500);
}
.text-heading-xxxl.secondary,
.text-heading-xxl.secondary,
.text-heading-xl.secondary,
.text-heading-lg.secondary,
.text-heading-md.secondary,
.text-heading-sm.secondary,
.text-body-lg.secondary,
.text-body-lg-medium.secondary,
.text-body-md.secondary,
.mz-item-option > .mid strong.secondary,
.text-body-xxsm.secondary,
.side-floating-menu ul li.secondary,
.secondary.tracks-release-section-text,
.split-details-payees-list-component .secondary.col-percent,
.rule-resume-card-component .rule-card .secondary.content,
.mzic-tip-on-hover .mzic-tip .secondary.item,
.mzic-tip-on-hover .mzic-tip strong.secondary,
.mzic-tip-on-hover .mzic-tip p.secondary,
.mzic-popover.modal-popover .main p.secondary,
.secondary.select-option__main-title,
.mz-form-row.for-paragraph p.secondary,
.mz-item-option > .mid small.secondary,
.mz-form-row > .mz-label > p.secondary,
.text-body-xsm.secondary,
.split-details-resume-component .text-container .dates > div span.secondary,
.split-details-resume-component .text-container .resume span.secondary,
.mzic-bank-account .text p.secondary,
.secondary.select-option__main-description,
.mzic-alert > p.secondary,
.secondary.form-hint-error,
.secondary.form-hint-success,
.mz-form-row .mz-input .secondary.input-prefix,
.mz-form-row.for-image .mz-input .image-text .secondary,
.mz-form-row .mz-input .secondary.input-tip,
.text-body-sm.secondary,
.text-heading-xxxl-semibold.secondary,
.text-heading-xxl-semibold.secondary,
.text-heading-xl-semibold.secondary,
.text-heading-lg-semibold.secondary,
.text-heading-md-semibold.secondary,
.secondary.tracks-release-input-section-title,
.text-heading-sm-semibold.secondary,
.split-details-resume-component .text-container .title .secondary.text,
.text-body-lg-semibold.secondary,
.text-body-md-semibold.secondary,
.secondary.toggle-content-link-component,
.mzic-bank-account .text .secondary.title,
.mzic-popover.modal-popover .main .secondary.title,
.mzic-popover.modal-popover .header .secondary.title,
.mzic-dialog .header .secondary.title,
.mzic-select > label.secondary,
.mz-label > label.secondary,
.text-body-xxsm-semibold.secondary,
.split-details-payees-list-component .col-icon-msg > div label.secondary,
.rule-resume-card-component .rule-card .content .secondary.strong,
.text-body-xsm-semibold.secondary,
.text-body-sm-semibold.secondary,
.text-heading-xxxl-bold.secondary,
.text-heading-xxl-bold.secondary,
.text-heading-xl-bold.secondary,
.text-heading-lg-bold.secondary,
.text-heading-md-bold.secondary,
.text-heading-sm-bold.secondary,
.text-body-lg-bold.secondary,
.text-body-md-bold.secondary,
.rule-resume-card-component .rule-card .secondary.card-title,
.text-body-xxsm-bold.secondary,
.mz-button-tag .secondary.label,
.mz-button-tag .secondary.number,
.text-body-xsm-bold.secondary,
.secondary.track-status-tag,
.split-details-resume-component .text-container .dates > div strong.secondary,
.split-details-resume-component .text-container .resume strong.secondary,
.secondary.sort-number,
.secondary.select-option__main-version,
.text-body-sm-bold.secondary,
.split-details-payees-list-component .col-status .secondary.status,
.split-details-payees-list-component .col-tag .secondary.tag,
.split-details-resume-component .text-container .secondary.tag,
.mzic-bank-account .text .secondary.tag {
  color: var(--neutral-300);
}
.text-heading-xxxl.disabled,
.text-heading-xxl.disabled,
.text-heading-xl.disabled,
.text-heading-lg.disabled,
.text-heading-md.disabled,
.text-heading-sm.disabled,
.text-body-lg.disabled,
.text-body-lg-medium.disabled,
.text-body-md.disabled,
.mz-item-option > .mid strong.disabled,
.text-body-xxsm.disabled,
.side-floating-menu ul li.disabled,
.disabled.tracks-release-section-text,
.split-details-payees-list-component .disabled.col-percent,
.rule-resume-card-component .rule-card .disabled.content,
.mzic-tip-on-hover .mzic-tip .disabled.item,
.mzic-tip-on-hover .mzic-tip strong.disabled,
.mzic-tip-on-hover .mzic-tip p.disabled,
.mzic-popover.modal-popover .main p.disabled,
.disabled.select-option__main-title,
.mz-form-row.for-paragraph p.disabled,
.mz-item-option > .mid small.disabled,
.mz-form-row > .mz-label > p.disabled,
.text-body-xsm.disabled,
.split-details-resume-component .text-container .dates > div span.disabled,
.split-details-resume-component .text-container .resume span.disabled,
.mzic-bank-account .text p.disabled,
.disabled.select-option__main-description,
.mzic-alert > p.disabled,
.disabled.form-hint-error,
.disabled.form-hint-success,
.mz-form-row .mz-input .disabled.input-prefix,
.mz-form-row.for-image .mz-input .image-text .disabled.secondary,
.mz-form-row .mz-input .disabled.input-tip,
.text-body-sm.disabled,
.text-heading-xxxl-semibold.disabled,
.text-heading-xxl-semibold.disabled,
.text-heading-xl-semibold.disabled,
.text-heading-lg-semibold.disabled,
.text-heading-md-semibold.disabled,
.disabled.tracks-release-input-section-title,
.text-heading-sm-semibold.disabled,
.split-details-resume-component .text-container .title .disabled.text,
.text-body-lg-semibold.disabled,
.text-body-md-semibold.disabled,
.disabled.toggle-content-link-component,
.mzic-bank-account .text .disabled.title,
.mzic-popover.modal-popover .main .disabled.title,
.mzic-popover.modal-popover .header .disabled.title,
.mzic-dialog .header .disabled.title,
.mzic-select > label.disabled,
.mz-form-row.for-image .mz-input .image-text .disabled.primary,
.mz-label > label.disabled,
.text-body-xxsm-semibold.disabled,
.split-details-payees-list-component .col-icon-msg > div label.disabled,
.rule-resume-card-component .rule-card .content .disabled.strong,
.text-body-xsm-semibold.disabled,
.text-body-sm-semibold.disabled,
.text-heading-xxxl-bold.disabled,
.text-heading-xxl-bold.disabled,
.text-heading-xl-bold.disabled,
.text-heading-lg-bold.disabled,
.text-heading-md-bold.disabled,
.text-heading-sm-bold.disabled,
.text-body-lg-bold.disabled,
.text-body-md-bold.disabled,
.rule-resume-card-component .rule-card .disabled.card-title,
.text-body-xxsm-bold.disabled,
.mz-button-tag .disabled.label,
.mz-button-tag .disabled.number,
.text-body-xsm-bold.disabled,
.disabled.track-status-tag,
.split-details-resume-component .text-container .dates > div strong.disabled,
.split-details-resume-component .text-container .resume strong.disabled,
.disabled.sort-number,
.disabled.select-option__main-version,
.text-body-sm-bold.disabled,
.split-details-payees-list-component .col-status .disabled.status,
.split-details-payees-list-component .col-tag .disabled.tag,
.split-details-resume-component .text-container .disabled.tag,
.mzic-bank-account .text .disabled.tag {
  color: var(--neutral-400);
}
.text-heading-xxxl.black,
.text-heading-xxl.black,
.text-heading-xl.black,
.text-heading-lg.black,
.text-heading-md.black,
.text-heading-sm.black,
.text-body-lg.black,
.text-body-lg-medium.black,
.text-body-md.black,
.mz-item-option > .mid strong.black,
.text-body-xxsm.black,
.side-floating-menu ul li.black,
.black.tracks-release-section-text,
.split-details-payees-list-component .black.col-percent,
.rule-resume-card-component .rule-card .black.content,
.mzic-tip-on-hover .mzic-tip .black.item,
.mzic-tip-on-hover .mzic-tip strong.black,
.mzic-tip-on-hover .mzic-tip p.black,
.mzic-popover.modal-popover .main p.black,
.black.select-option__main-title,
.mz-form-row.for-paragraph p.black,
.mz-item-option > .mid small.black,
.mz-form-row > .mz-label > p.black,
.text-body-xsm.black,
.split-details-resume-component .text-container .dates > div span.black,
.split-details-resume-component .text-container .resume span.black,
.mzic-bank-account .text p.black,
.black.select-option__main-description,
.mzic-alert > p.black,
.black.form-hint-error,
.black.form-hint-success,
.mz-form-row .mz-input .black.input-prefix,
.mz-form-row.for-image .mz-input .image-text .black.secondary,
.mz-form-row .mz-input .black.input-tip,
.text-body-sm.black,
.text-heading-xxxl-semibold.black,
.text-heading-xxl-semibold.black,
.text-heading-xl-semibold.black,
.text-heading-lg-semibold.black,
.text-heading-md-semibold.black,
.black.tracks-release-input-section-title,
.text-heading-sm-semibold.black,
.split-details-resume-component .text-container .title .black.text,
.text-body-lg-semibold.black,
.text-body-md-semibold.black,
.black.toggle-content-link-component,
.mzic-bank-account .text .black.title,
.mzic-popover.modal-popover .main .black.title,
.mzic-popover.modal-popover .header .black.title,
.mzic-dialog .header .black.title,
.mzic-select > label.black,
.mz-form-row.for-image .mz-input .image-text .black.primary,
.mz-label > label.black,
.text-body-xxsm-semibold.black,
.split-details-payees-list-component .col-icon-msg > div label.black,
.rule-resume-card-component .rule-card .content .black.strong,
.text-body-xsm-semibold.black,
.text-body-sm-semibold.black,
.text-heading-xxxl-bold.black,
.text-heading-xxl-bold.black,
.text-heading-xl-bold.black,
.text-heading-lg-bold.black,
.text-heading-md-bold.black,
.text-heading-sm-bold.black,
.text-body-lg-bold.black,
.text-body-md-bold.black,
.rule-resume-card-component .rule-card .black.card-title,
.text-body-xxsm-bold.black,
.mz-button-tag .black.label,
.mz-button-tag .black.number,
.text-body-xsm-bold.black,
.black.track-status-tag,
.split-details-resume-component .text-container .dates > div strong.black,
.split-details-resume-component .text-container .resume strong.black,
.black.sort-number,
.black.select-option__main-version,
.text-body-sm-bold.black,
.split-details-payees-list-component .col-status .black.status,
.split-details-payees-list-component .col-tag .black.tag,
.split-details-resume-component .text-container .black.tag,
.mzic-bank-account .text .black.tag {
  color: var(--neutral-700);
}
.text-heading-xxxl.success,
.text-heading-xxl.success,
.text-heading-xl.success,
.text-heading-lg.success,
.text-heading-md.success,
.text-heading-sm.success,
.text-body-lg.success,
.text-body-lg-medium.success,
.text-body-md.success,
.mz-item-option > .mid strong.success,
.text-body-xxsm.success,
.side-floating-menu ul li.success,
.success.tracks-release-section-text,
.split-details-payees-list-component .success.col-percent,
.rule-resume-card-component .rule-card .success.content,
.mzic-tip-on-hover .mzic-tip .success.item,
.mzic-tip-on-hover .mzic-tip strong.success,
.mzic-tip-on-hover .mzic-tip p.success,
.mzic-popover.modal-popover .main p.success,
.success.select-option__main-title,
.mz-form-row.for-paragraph p.success,
.mz-item-option > .mid small.success,
.mz-form-row > .mz-label > p.success,
.text-body-xsm.success,
.split-details-resume-component .text-container .dates > div span.success,
.split-details-resume-component .text-container .resume span.success,
.mzic-bank-account .text p.success,
.success.select-option__main-description,
.mzic-alert > p.success,
.success.form-hint-error,
.success.form-hint-success,
.mz-form-row .mz-input .success.input-prefix,
.mz-form-row.for-image .mz-input .image-text .success.secondary,
.mz-form-row .mz-input .success.input-tip,
.text-body-sm.success,
.text-heading-xxxl-semibold.success,
.text-heading-xxl-semibold.success,
.text-heading-xl-semibold.success,
.text-heading-lg-semibold.success,
.text-heading-md-semibold.success,
.success.tracks-release-input-section-title,
.text-heading-sm-semibold.success,
.split-details-resume-component .text-container .title .success.text,
.text-body-lg-semibold.success,
.text-body-md-semibold.success,
.success.toggle-content-link-component,
.mzic-bank-account .text .success.title,
.mzic-popover.modal-popover .main .success.title,
.mzic-popover.modal-popover .header .success.title,
.mzic-dialog .header .success.title,
.mzic-select > label.success,
.mz-form-row.for-image .mz-input .image-text .success.primary,
.mz-label > label.success,
.text-body-xxsm-semibold.success,
.split-details-payees-list-component .col-icon-msg > div label.success,
.rule-resume-card-component .rule-card .content .success.strong,
.text-body-xsm-semibold.success,
.text-body-sm-semibold.success,
.text-heading-xxxl-bold.success,
.text-heading-xxl-bold.success,
.text-heading-xl-bold.success,
.text-heading-lg-bold.success,
.text-heading-md-bold.success,
.text-heading-sm-bold.success,
.text-body-lg-bold.success,
.text-body-md-bold.success,
.rule-resume-card-component .rule-card .success.card-title,
.text-body-xxsm-bold.success,
.mz-button-tag .success.label,
.mz-button-tag .success.number,
.text-body-xsm-bold.success,
.success.track-status-tag,
.split-details-resume-component .text-container .dates > div strong.success,
.split-details-resume-component .text-container .resume strong.success,
.success.sort-number,
.success.select-option__main-version,
.text-body-sm-bold.success,
.split-details-payees-list-component .col-status .success.status,
.split-details-payees-list-component .col-tag .success.tag,
.split-details-resume-component .text-container .success.tag,
.mzic-bank-account .text .success.tag {
  color: var(--green-500);
}
.text-heading-xxxl.danger,
.text-heading-xxl.danger,
.text-heading-xl.danger,
.text-heading-lg.danger,
.text-heading-md.danger,
.text-heading-sm.danger,
.text-body-lg.danger,
.text-body-lg-medium.danger,
.text-body-md.danger,
.mz-item-option > .mid strong.danger,
.text-body-xxsm.danger,
.side-floating-menu ul li.danger,
.danger.tracks-release-section-text,
.split-details-payees-list-component .danger.col-percent,
.rule-resume-card-component .rule-card .danger.content,
.mzic-tip-on-hover .mzic-tip .danger.item,
.mzic-tip-on-hover .mzic-tip strong.danger,
.mzic-tip-on-hover .mzic-tip p.danger,
.mzic-popover.modal-popover .main p.danger,
.danger.select-option__main-title,
.mz-form-row.for-paragraph p.danger,
.mz-item-option > .mid small.danger,
.mz-form-row > .mz-label > p.danger,
.text-body-xsm.danger,
.split-details-resume-component .text-container .dates > div span.danger,
.split-details-resume-component .text-container .resume span.danger,
.mzic-bank-account .text p.danger,
.danger.select-option__main-description,
.mzic-alert > p.danger,
.danger.form-hint-error,
.danger.form-hint-success,
.mz-form-row .mz-input .danger.input-prefix,
.mz-form-row.for-image .mz-input .image-text .danger.secondary,
.mz-form-row .mz-input .danger.input-tip,
.text-body-sm.danger,
.text-heading-xxxl-semibold.danger,
.text-heading-xxl-semibold.danger,
.text-heading-xl-semibold.danger,
.text-heading-lg-semibold.danger,
.text-heading-md-semibold.danger,
.danger.tracks-release-input-section-title,
.text-heading-sm-semibold.danger,
.split-details-resume-component .text-container .title .danger.text,
.text-body-lg-semibold.danger,
.text-body-md-semibold.danger,
.danger.toggle-content-link-component,
.mzic-bank-account .text .danger.title,
.mzic-popover.modal-popover .main .danger.title,
.mzic-popover.modal-popover .header .danger.title,
.mzic-dialog .header .danger.title,
.mzic-select > label.danger,
.mz-form-row.for-image .mz-input .image-text .danger.primary,
.mz-label > label.danger,
.text-body-xxsm-semibold.danger,
.split-details-payees-list-component .col-icon-msg > div label.danger,
.rule-resume-card-component .rule-card .content .danger.strong,
.text-body-xsm-semibold.danger,
.text-body-sm-semibold.danger,
.text-heading-xxxl-bold.danger,
.text-heading-xxl-bold.danger,
.text-heading-xl-bold.danger,
.text-heading-lg-bold.danger,
.text-heading-md-bold.danger,
.text-heading-sm-bold.danger,
.text-body-lg-bold.danger,
.text-body-md-bold.danger,
.rule-resume-card-component .rule-card .danger.card-title,
.text-body-xxsm-bold.danger,
.mz-button-tag .danger.label,
.mz-button-tag .danger.number,
.text-body-xsm-bold.danger,
.danger.track-status-tag,
.split-details-resume-component .text-container .dates > div strong.danger,
.split-details-resume-component .text-container .resume strong.danger,
.danger.sort-number,
.danger.select-option__main-version,
.text-body-sm-bold.danger,
.split-details-payees-list-component .col-status .danger.status,
.split-details-payees-list-component .col-tag .danger.tag,
.split-details-resume-component .text-container .danger.tag,
.mzic-bank-account .text .danger.tag {
  color: var(--red-500);
}
.text-heading-xxxl.alert,
.text-heading-xxl.alert,
.text-heading-xl.alert,
.text-heading-lg.alert,
.text-heading-md.alert,
.text-heading-sm.alert,
.text-body-lg.alert,
.text-body-lg-medium.alert,
.text-body-md.alert,
.mz-item-option > .mid strong.alert,
.text-body-xxsm.alert,
.side-floating-menu ul li.alert,
.alert.tracks-release-section-text,
.split-details-payees-list-component .alert.col-percent,
.rule-resume-card-component .rule-card .alert.content,
.mzic-tip-on-hover .mzic-tip .alert.item,
.mzic-tip-on-hover .mzic-tip strong.alert,
.mzic-tip-on-hover .mzic-tip p.alert,
.mzic-popover.modal-popover .main p.alert,
.alert.select-option__main-title,
.mz-form-row.for-paragraph p.alert,
.mz-item-option > .mid small.alert,
.mz-form-row > .mz-label > p.alert,
.text-body-xsm.alert,
.split-details-resume-component .text-container .dates > div span.alert,
.split-details-resume-component .text-container .resume span.alert,
.mzic-bank-account .text p.alert,
.alert.select-option__main-description,
.mzic-alert > p.alert,
.alert.form-hint-error,
.alert.form-hint-success,
.mz-form-row .mz-input .alert.input-prefix,
.mz-form-row.for-image .mz-input .image-text .alert.secondary,
.mz-form-row .mz-input .alert.input-tip,
.text-body-sm.alert,
.text-heading-xxxl-semibold.alert,
.text-heading-xxl-semibold.alert,
.text-heading-xl-semibold.alert,
.text-heading-lg-semibold.alert,
.text-heading-md-semibold.alert,
.alert.tracks-release-input-section-title,
.text-heading-sm-semibold.alert,
.split-details-resume-component .text-container .title .alert.text,
.text-body-lg-semibold.alert,
.text-body-md-semibold.alert,
.alert.toggle-content-link-component,
.mzic-bank-account .text .alert.title,
.mzic-popover.modal-popover .main .alert.title,
.mzic-popover.modal-popover .header .alert.title,
.mzic-dialog .header .alert.title,
.mzic-select > label.alert,
.mz-form-row.for-image .mz-input .image-text .alert.primary,
.mz-label > label.alert,
.text-body-xxsm-semibold.alert,
.split-details-payees-list-component .col-icon-msg > div label.alert,
.rule-resume-card-component .rule-card .content .alert.strong,
.text-body-xsm-semibold.alert,
.text-body-sm-semibold.alert,
.text-heading-xxxl-bold.alert,
.text-heading-xxl-bold.alert,
.text-heading-xl-bold.alert,
.text-heading-lg-bold.alert,
.text-heading-md-bold.alert,
.text-heading-sm-bold.alert,
.text-body-lg-bold.alert,
.text-body-md-bold.alert,
.rule-resume-card-component .rule-card .alert.card-title,
.text-body-xxsm-bold.alert,
.mz-button-tag .alert.label,
.mz-button-tag .alert.number,
.text-body-xsm-bold.alert,
.alert.track-status-tag,
.split-details-resume-component .text-container .dates > div strong.alert,
.split-details-resume-component .text-container .resume strong.alert,
.alert.sort-number,
.alert.select-option__main-version,
.text-body-sm-bold.alert,
.split-details-payees-list-component .col-status .alert.status,
.split-details-payees-list-component .col-tag .alert.tag,
.split-details-resume-component .text-container .alert.tag,
.mzic-bank-account .text .alert.tag {
  color: var(--yellow-500);
}
.text-heading-xxxl.info,
.text-heading-xxl.info,
.text-heading-xl.info,
.text-heading-lg.info,
.text-heading-md.info,
.text-heading-sm.info,
.text-body-lg.info,
.text-body-lg-medium.info,
.text-body-md.info,
.mz-item-option > .mid strong.info,
.text-body-xxsm.info,
.side-floating-menu ul li.info,
.info.tracks-release-section-text,
.split-details-payees-list-component .info.col-percent,
.rule-resume-card-component .rule-card .info.content,
.mzic-tip-on-hover .mzic-tip .info.item,
.mzic-tip-on-hover .mzic-tip strong.info,
.mzic-tip-on-hover .mzic-tip p.info,
.mzic-popover.modal-popover .main p.info,
.info.select-option__main-title,
.mz-form-row.for-paragraph p.info,
.mz-item-option > .mid small.info,
.mz-form-row > .mz-label > p.info,
.text-body-xsm.info,
.split-details-resume-component .text-container .dates > div span.info,
.split-details-resume-component .text-container .resume span.info,
.mzic-bank-account .text p.info,
.info.select-option__main-description,
.mzic-alert > p.info,
.info.form-hint-error,
.info.form-hint-success,
.mz-form-row .mz-input .info.input-prefix,
.mz-form-row.for-image .mz-input .image-text .info.secondary,
.mz-form-row .mz-input .info.input-tip,
.text-body-sm.info,
.text-heading-xxxl-semibold.info,
.text-heading-xxl-semibold.info,
.text-heading-xl-semibold.info,
.text-heading-lg-semibold.info,
.text-heading-md-semibold.info,
.info.tracks-release-input-section-title,
.text-heading-sm-semibold.info,
.split-details-resume-component .text-container .title .info.text,
.text-body-lg-semibold.info,
.text-body-md-semibold.info,
.info.toggle-content-link-component,
.mzic-bank-account .text .info.title,
.mzic-popover.modal-popover .main .info.title,
.mzic-popover.modal-popover .header .info.title,
.mzic-dialog .header .info.title,
.mzic-select > label.info,
.mz-form-row.for-image .mz-input .image-text .info.primary,
.mz-label > label.info,
.text-body-xxsm-semibold.info,
.split-details-payees-list-component .col-icon-msg > div label.info,
.rule-resume-card-component .rule-card .content .info.strong,
.text-body-xsm-semibold.info,
.text-body-sm-semibold.info,
.text-heading-xxxl-bold.info,
.text-heading-xxl-bold.info,
.text-heading-xl-bold.info,
.text-heading-lg-bold.info,
.text-heading-md-bold.info,
.text-heading-sm-bold.info,
.text-body-lg-bold.info,
.text-body-md-bold.info,
.rule-resume-card-component .rule-card .info.card-title,
.text-body-xxsm-bold.info,
.mz-button-tag .info.label,
.mz-button-tag .info.number,
.text-body-xsm-bold.info,
.info.track-status-tag,
.split-details-resume-component .text-container .dates > div strong.info,
.split-details-resume-component .text-container .resume strong.info,
.info.sort-number,
.info.select-option__main-version,
.text-body-sm-bold.info,
.split-details-payees-list-component .col-status .info.status,
.split-details-payees-list-component .col-tag .info.tag,
.split-details-resume-component .text-container .info.tag,
.mzic-bank-account .text .info.tag {
  color: var(--blue-500);
}

.text-heading-xxxl {
  font-weight: var(--font-regular);
  font-size: 64px;
  line-height: 72px;
}

.text-heading-xxl {
  font-weight: var(--font-regular);
  font-size: 48px;
  line-height: 56px;
}

.text-heading-xl {
  font-weight: var(--font-regular);
  font-size: 40px;
  line-height: 48px;
}

.text-heading-lg {
  font-weight: var(--font-regular);
  font-size: 32px;
  line-height: 40px;
}

.text-heading-md {
  font-weight: var(--font-regular);
  font-size: 24px;
  line-height: 32px;
}

.text-heading-sm {
  font-weight: var(--font-regular);
  font-size: 20px;
  line-height: 28px;
}

.text-body-lg {
  font-weight: var(--font-regular);
  font-size: 18px;
  line-height: 26px;
}

.text-body-md, .mz-item-option > .mid strong {
  font-weight: var(--font-regular);
  font-size: 16px;
  line-height: 24px;
}

.text-body-xxsm, .side-floating-menu ul li, .tracks-release-section-text, .split-details-payees-list-component .col-percent, .rule-resume-card-component .rule-card .content, .mzic-tip-on-hover .mzic-tip .item, .mzic-tip-on-hover .mzic-tip strong, .mzic-tip-on-hover .mzic-tip p, .mzic-popover.modal-popover .main p, .select-option__main-title, .mz-form-row.for-paragraph p, .mz-item-option > .mid small, .mz-form-row > .mz-label > p {
  font-weight: var(--font-regular);
  font-size: 14px;
  line-height: 20px;
}

.text-body-xsm, .split-details-resume-component .text-container .dates > div span, .split-details-resume-component .text-container .resume span, .mzic-bank-account .text p, .select-option__main-description, .mzic-alert > p, .form-hint-error, .form-hint-success, .mz-form-row .mz-input .input-prefix, .mz-form-row.for-image .mz-input .image-text .secondary, .mz-form-row .mz-input .input-tip {
  font-weight: var(--font-regular);
  font-size: 12px;
  line-height: 16px;
}

.text-body-sm {
  font-weight: var(--font-regular);
  font-size: 10px;
  line-height: 14px;
}

.text-body-lg-medium {
  font-weight: var(--font--lg-medium);
  font-size: 18px;
  line-height: 26px;
}

.text-heading-xxxl-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 64px;
  line-height: 72px;
}

.text-heading-xxl-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 48px;
  line-height: 56px;
}

.text-heading-xl-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 40px;
  line-height: 48px;
}

.text-heading-lg-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 32px;
  line-height: 40px;
}

.text-heading-md-semibold, .tracks-release-input-section-title {
  font-weight: var(--font-semi-bold);
  font-size: 24px;
  line-height: 32px;
}

.text-heading-sm-semibold, .split-details-resume-component .text-container .title .text {
  font-weight: var(--font-semi-bold);
  font-size: 20px;
  line-height: 28px;
}

.text-body-lg-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 18px;
  line-height: 26px;
}

.text-body-md-semibold, .toggle-content-link-component, .mzic-bank-account .text .title, .mzic-popover.modal-popover .main .title, .mzic-popover.modal-popover .header .title, .mzic-dialog .header .title, .mzic-select > label, .mz-form-row.for-image .mz-input .image-text .primary, .mz-label > label {
  font-weight: var(--font-semi-bold);
  font-size: 16px;
  line-height: 24px;
}

.text-body-xxsm-semibold, .split-details-payees-list-component .col-icon-msg > div label, .rule-resume-card-component .rule-card .content .strong {
  font-weight: var(--font-semi-bold);
  font-size: 14px;
  line-height: 20px;
}

.text-body-xsm-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 12px;
  line-height: 16px;
}

.text-body-sm-semibold {
  font-weight: var(--font-semi-bold);
  font-size: 10px;
  line-height: 14px;
}

.text-heading-xxxl-bold {
  font-weight: var(--font-bold);
  font-size: 64px;
  line-height: 72px;
}

.text-heading-xxl-bold {
  font-weight: var(--font-bold);
  font-size: 48px;
  line-height: 56px;
}

.text-heading-xl-bold {
  font-weight: var(--font-bold);
  font-size: 40px;
  line-height: 48px;
}

.text-heading-lg-bold {
  font-weight: var(--font-bold);
  font-size: 32px;
  line-height: 40px;
}

.text-heading-md-bold {
  font-weight: var(--font-bold);
  font-size: 24px;
  line-height: 32px;
}

.text-heading-sm-bold {
  font-weight: var(--font-bold);
  font-size: 20px;
  line-height: 28px;
}

.text-body-lg-bold {
  font-weight: var(--font-bold);
  font-size: 18px;
  line-height: 26px;
}

.text-body-md-bold, .rule-resume-card-component .rule-card .card-title {
  font-weight: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
}

.text-body-xxsm-bold, .mz-button-tag .label,
.mz-button-tag .number {
  font-weight: var(--font-bold);
  font-size: 14px;
  line-height: 20px;
}

.text-body-xsm-bold, .track-status-tag, .split-details-resume-component .text-container .dates > div strong, .split-details-resume-component .text-container .resume strong, .sort-number, .select-option__main-version {
  font-weight: var(--font-bold);
  font-size: 12px;
  line-height: 16px;
}

.text-body-sm-bold, .split-details-payees-list-component .col-status .status, .split-details-payees-list-component .col-tag .tag, .split-details-resume-component .text-container .tag, .mzic-bank-account .text .tag {
  font-weight: var(--font-bold);
  font-size: 10px;
  line-height: 14px;
}

.neutral-100 {
  color: var(--neutral-100) !important;
}
.neutral-100:not(.stroke) > svg > path {
  fill: var(--neutral-100) !important;
}
.neutral-100.stroke > svg > path {
  stroke: var(--neutral-100) !important;
}
.neutral-100.circle > svg > circle {
  stroke: var(--neutral-100) !important;
}

.bg-neutral-100 {
  background-color: var(--neutral-100) !important;
}

.neutral-200, .split-details-payees-list-component .col-icon-msg > div:hover mzic-svg {
  color: var(--neutral-200) !important;
}
.neutral-200:not(.stroke) > svg > path, .split-details-payees-list-component .col-icon-msg > div:hover mzic-svg:not(.stroke) > svg > path {
  fill: var(--neutral-200) !important;
}
.neutral-200.stroke > svg > path, .split-details-payees-list-component .col-icon-msg > div:hover mzic-svg.stroke > svg > path {
  stroke: var(--neutral-200) !important;
}
.neutral-200.circle > svg > circle, .split-details-payees-list-component .col-icon-msg > div:hover mzic-svg.circle > svg > circle {
  stroke: var(--neutral-200) !important;
}

.bg-neutral-200 {
  background-color: var(--neutral-200) !important;
}

.neutral-200-50 {
  color: var(--neutral-200-50) !important;
}
.neutral-200-50:not(.stroke) > svg > path {
  fill: var(--neutral-200-50) !important;
}
.neutral-200-50.stroke > svg > path {
  stroke: var(--neutral-200-50) !important;
}
.neutral-200-50.circle > svg > circle {
  stroke: var(--neutral-200-50) !important;
}

.bg-neutral-200-50 {
  background-color: var(--neutral-200-50) !important;
}

.neutral-300, .split-details-payees-list-component .col-icon-msg > div mzic-svg {
  color: var(--neutral-300) !important;
}
.neutral-300:not(.stroke) > svg > path, .split-details-payees-list-component .col-icon-msg > div mzic-svg:not(.stroke) > svg > path {
  fill: var(--neutral-300) !important;
}
.neutral-300.stroke > svg > path, .split-details-payees-list-component .col-icon-msg > div mzic-svg.stroke > svg > path {
  stroke: var(--neutral-300) !important;
}
.neutral-300.circle > svg > circle, .split-details-payees-list-component .col-icon-msg > div mzic-svg.circle > svg > circle {
  stroke: var(--neutral-300) !important;
}

.bg-neutral-300 {
  background-color: var(--neutral-300) !important;
}

.neutral-400 {
  color: var(--neutral-400) !important;
}
.neutral-400:not(.stroke) > svg > path {
  fill: var(--neutral-400) !important;
}
.neutral-400.stroke > svg > path {
  stroke: var(--neutral-400) !important;
}
.neutral-400.circle > svg > circle {
  stroke: var(--neutral-400) !important;
}

.bg-neutral-400 {
  background-color: var(--neutral-400) !important;
}

.neutral-500 {
  color: var(--neutral-500) !important;
}
.neutral-500:not(.stroke) > svg > path {
  fill: var(--neutral-500) !important;
}
.neutral-500.stroke > svg > path {
  stroke: var(--neutral-500) !important;
}
.neutral-500.circle > svg > circle {
  stroke: var(--neutral-500) !important;
}

.bg-neutral-500 {
  background-color: var(--neutral-500) !important;
}

.neutral-500-50 {
  color: var(--neutral-500-50) !important;
}
.neutral-500-50:not(.stroke) > svg > path {
  fill: var(--neutral-500-50) !important;
}
.neutral-500-50.stroke > svg > path {
  stroke: var(--neutral-500-50) !important;
}
.neutral-500-50.circle > svg > circle {
  stroke: var(--neutral-500-50) !important;
}

.bg-neutral-500-50 {
  background-color: var(--neutral-500-50) !important;
}

.neutral-600 {
  color: var(--neutral-600) !important;
}
.neutral-600:not(.stroke) > svg > path {
  fill: var(--neutral-600) !important;
}
.neutral-600.stroke > svg > path {
  stroke: var(--neutral-600) !important;
}
.neutral-600.circle > svg > circle {
  stroke: var(--neutral-600) !important;
}

.bg-neutral-600 {
  background-color: var(--neutral-600) !important;
}

.neutral-600-50 {
  color: var(--neutral-600-50) !important;
}
.neutral-600-50:not(.stroke) > svg > path {
  fill: var(--neutral-600-50) !important;
}
.neutral-600-50.stroke > svg > path {
  stroke: var(--neutral-600-50) !important;
}
.neutral-600-50.circle > svg > circle {
  stroke: var(--neutral-600-50) !important;
}

.bg-neutral-600-50 {
  background-color: var(--neutral-600-50) !important;
}

.neutral-700 {
  color: var(--neutral-700) !important;
}
.neutral-700:not(.stroke) > svg > path {
  fill: var(--neutral-700) !important;
}
.neutral-700.stroke > svg > path {
  stroke: var(--neutral-700) !important;
}
.neutral-700.circle > svg > circle {
  stroke: var(--neutral-700) !important;
}

.bg-neutral-700 {
  background-color: var(--neutral-700) !important;
}

.neutral-700-70 {
  color: var(--neutral-700-70) !important;
}
.neutral-700-70:not(.stroke) > svg > path {
  fill: var(--neutral-700-70) !important;
}
.neutral-700-70.stroke > svg > path {
  stroke: var(--neutral-700-70) !important;
}
.neutral-700-70.circle > svg > circle {
  stroke: var(--neutral-700-70) !important;
}

.bg-neutral-700-70 {
  background-color: var(--neutral-700-70) !important;
}

.black,
button:not(.stroke).black,
button:not(.stroke).black > svg > path,
input[type=button]:not(.stroke).black > svg > path {
  color: var(--black) !important;
  fill: var(--black) !important;
}

.mz-button.black.stroke > svg > path {
  stroke: var(--black) !important;
}

.bg-black {
  background-color: var(--black) !important;
}

.white,
button:not(.stroke).white,
button:not(.stroke).white > svg > path,
input[type=button]:not(.stroke).white > svg > path {
  color: var(--white) !important;
  fill: var(--white) !important;
}

.mz-button.white.stroke > svg > path {
  stroke: var(--white) !important;
}

.bg-white {
  background-color: var(--white) !important;
}

.purple-700 {
  color: var(--purple-700) !important;
}
.purple-700:not(.stroke) > svg > path {
  fill: var(--purple-700) !important;
}
.purple-700.stroke > svg > path {
  stroke: var(--purple-700) !important;
}
.purple-700.circle > svg > circle {
  stroke: var(--purple-700) !important;
}

.bg-purple-700 {
  background-color: var(--purple-700) !important;
}

.purple-600 {
  color: var(--purple-600) !important;
}
.purple-600:not(.stroke) > svg > path {
  fill: var(--purple-600) !important;
}
.purple-600.stroke > svg > path {
  stroke: var(--purple-600) !important;
}
.purple-600.circle > svg > circle {
  stroke: var(--purple-600) !important;
}

.bg-purple-600 {
  background-color: var(--purple-600) !important;
}

.purple-500,
.purple {
  color: var(--purple-500) !important;
}
.purple-500:not(.stroke) > svg > path,
.purple:not(.stroke) > svg > path {
  fill: var(--purple-500) !important;
}
.purple-500.stroke > svg > path,
.purple.stroke > svg > path {
  stroke: var(--purple-500) !important;
}
.purple-500.circle > svg > circle,
.purple.circle > svg > circle {
  stroke: var(--purple-500) !important;
}

.bg-purple-500,
.bg-purple {
  background-color: var(--purple-500) !important;
}

.purple-400 {
  color: var(--purple-400) !important;
}
.purple-400:not(.stroke) > svg > path {
  fill: var(--purple-400) !important;
}
.purple-400.stroke > svg > path {
  stroke: var(--purple-400) !important;
}
.purple-400.circle > svg > circle {
  stroke: var(--purple-400) !important;
}

.bg-purple-400 {
  background-color: var(--purple-400) !important;
}

.purple-300 {
  color: var(--purple-300) !important;
}
.purple-300:not(.stroke) > svg > path {
  fill: var(--purple-300) !important;
}
.purple-300.stroke > svg > path {
  stroke: var(--purple-300) !important;
}
.purple-300.circle > svg > circle {
  stroke: var(--purple-300) !important;
}

.bg-purple-300 {
  background-color: var(--purple-300) !important;
}

.purple-200 {
  color: var(--purple-200) !important;
}
.purple-200:not(.stroke) > svg > path {
  fill: var(--purple-200) !important;
}
.purple-200.stroke > svg > path {
  stroke: var(--purple-200) !important;
}
.purple-200.circle > svg > circle {
  stroke: var(--purple-200) !important;
}

.bg-purple-200 {
  background-color: var(--purple-200) !important;
}

.purple-100 {
  color: var(--purple-100) !important;
}
.purple-100:not(.stroke) > svg > path {
  fill: var(--purple-100) !important;
}
.purple-100.stroke > svg > path {
  stroke: var(--purple-100) !important;
}
.purple-100.circle > svg > circle {
  stroke: var(--purple-100) !important;
}

.bg-purple-100 {
  background-color: var(--purple-100) !important;
}

.red-700 {
  color: var(--red-700) !important;
}
.red-700:not(.stroke) > svg > path {
  fill: var(--red-700) !important;
}
.red-700.stroke > svg > path {
  stroke: var(--red-700) !important;
}
.red-700.circle > svg > circle {
  stroke: var(--red-700) !important;
}

.bg-red-700 {
  background-color: var(--red-700) !important;
}

.red-600 {
  color: var(--red-600) !important;
}
.red-600:not(.stroke) > svg > path {
  fill: var(--red-600) !important;
}
.red-600.stroke > svg > path {
  stroke: var(--red-600) !important;
}
.red-600.circle > svg > circle {
  stroke: var(--red-600) !important;
}

.bg-red-600 {
  background-color: var(--red-600) !important;
}

.red-500,
.red {
  color: var(--red-500) !important;
}
.red-500:not(.stroke) > svg > path,
.red:not(.stroke) > svg > path {
  fill: var(--red-500) !important;
}
.red-500.stroke > svg > path,
.red.stroke > svg > path {
  stroke: var(--red-500) !important;
}
.red-500.circle > svg > circle,
.red.circle > svg > circle {
  stroke: var(--red-500) !important;
}

.bg-red-500,
.bg-red {
  background-color: var(--red-500) !important;
}

.red-400 {
  color: var(--red-400) !important;
}
.red-400:not(.stroke) > svg > path {
  fill: var(--red-400) !important;
}
.red-400.stroke > svg > path {
  stroke: var(--red-400) !important;
}
.red-400.circle > svg > circle {
  stroke: var(--red-400) !important;
}

.bg-red-400 {
  background-color: var(--red-400) !important;
}

.red-300 {
  color: var(--red-300) !important;
}
.red-300:not(.stroke) > svg > path {
  fill: var(--red-300) !important;
}
.red-300.stroke > svg > path {
  stroke: var(--red-300) !important;
}
.red-300.circle > svg > circle {
  stroke: var(--red-300) !important;
}

.bg-red-300 {
  background-color: var(--red-300) !important;
}

.red-200 {
  color: var(--red-200) !important;
}
.red-200:not(.stroke) > svg > path {
  fill: var(--red-200) !important;
}
.red-200.stroke > svg > path {
  stroke: var(--red-200) !important;
}
.red-200.circle > svg > circle {
  stroke: var(--red-200) !important;
}

.bg-red-200 {
  background-color: var(--red-200) !important;
}

.red-100 {
  color: var(--red-100) !important;
}
.red-100:not(.stroke) > svg > path {
  fill: var(--red-100) !important;
}
.red-100.stroke > svg > path {
  stroke: var(--red-100) !important;
}
.red-100.circle > svg > circle {
  stroke: var(--red-100) !important;
}

.bg-red-100 {
  background-color: var(--red-100) !important;
}

.yellow-700 {
  color: var(--yellow-700) !important;
}
.yellow-700:not(.stroke) > svg > path {
  fill: var(--yellow-700) !important;
}
.yellow-700.stroke > svg > path {
  stroke: var(--yellow-700) !important;
}
.yellow-700.circle > svg > circle {
  stroke: var(--yellow-700) !important;
}

.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}

.yellow-600 {
  color: var(--yellow-600) !important;
}
.yellow-600:not(.stroke) > svg > path {
  fill: var(--yellow-600) !important;
}
.yellow-600.stroke > svg > path {
  stroke: var(--yellow-600) !important;
}
.yellow-600.circle > svg > circle {
  stroke: var(--yellow-600) !important;
}

.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}

.yellow-500,
.yellow {
  color: var(--yellow-500) !important;
}
.yellow-500:not(.stroke) > svg > path,
.yellow:not(.stroke) > svg > path {
  fill: var(--yellow-500) !important;
}
.yellow-500.stroke > svg > path,
.yellow.stroke > svg > path {
  stroke: var(--yellow-500) !important;
}
.yellow-500.circle > svg > circle,
.yellow.circle > svg > circle {
  stroke: var(--yellow-500) !important;
}

.bg-yellow-500,
.bg-yellow {
  background-color: var(--yellow-500) !important;
}

.yellow-400 {
  color: var(--yellow-400) !important;
}
.yellow-400:not(.stroke) > svg > path {
  fill: var(--yellow-400) !important;
}
.yellow-400.stroke > svg > path {
  stroke: var(--yellow-400) !important;
}
.yellow-400.circle > svg > circle {
  stroke: var(--yellow-400) !important;
}

.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}

.yellow-300 {
  color: var(--yellow-300) !important;
}
.yellow-300:not(.stroke) > svg > path {
  fill: var(--yellow-300) !important;
}
.yellow-300.stroke > svg > path {
  stroke: var(--yellow-300) !important;
}
.yellow-300.circle > svg > circle {
  stroke: var(--yellow-300) !important;
}

.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}

.yellow-200 {
  color: var(--yellow-200) !important;
}
.yellow-200:not(.stroke) > svg > path {
  fill: var(--yellow-200) !important;
}
.yellow-200.stroke > svg > path {
  stroke: var(--yellow-200) !important;
}
.yellow-200.circle > svg > circle {
  stroke: var(--yellow-200) !important;
}

.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}

.yellow-100 {
  color: var(--yellow-100) !important;
}
.yellow-100:not(.stroke) > svg > path {
  fill: var(--yellow-100) !important;
}
.yellow-100.stroke > svg > path {
  stroke: var(--yellow-100) !important;
}
.yellow-100.circle > svg > circle {
  stroke: var(--yellow-100) !important;
}

.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}

.green-700 {
  color: var(--green-700) !important;
}
.green-700:not(.stroke) > svg > path {
  fill: var(--green-700) !important;
}
.green-700.stroke > svg > path {
  stroke: var(--green-700) !important;
}
.green-700.circle > svg > circle {
  stroke: var(--green-700) !important;
}

.bg-green-700 {
  background-color: var(--green-700) !important;
}

.green-600 {
  color: var(--green-600) !important;
}
.green-600:not(.stroke) > svg > path {
  fill: var(--green-600) !important;
}
.green-600.stroke > svg > path {
  stroke: var(--green-600) !important;
}
.green-600.circle > svg > circle {
  stroke: var(--green-600) !important;
}

.bg-green-600 {
  background-color: var(--green-600) !important;
}

.green-500,
.green {
  color: var(--green-500) !important;
}
.green-500:not(.stroke) > svg > path,
.green:not(.stroke) > svg > path {
  fill: var(--green-500) !important;
}
.green-500.stroke > svg > path,
.green.stroke > svg > path {
  stroke: var(--green-500) !important;
}
.green-500.circle > svg > circle,
.green.circle > svg > circle {
  stroke: var(--green-500) !important;
}

.bg-green-500,
.bg-green {
  background-color: var(--green-500) !important;
}

.green-400 {
  color: var(--green-400) !important;
}
.green-400:not(.stroke) > svg > path {
  fill: var(--green-400) !important;
}
.green-400.stroke > svg > path {
  stroke: var(--green-400) !important;
}
.green-400.circle > svg > circle {
  stroke: var(--green-400) !important;
}

.bg-green-400 {
  background-color: var(--green-400) !important;
}

.green-300 {
  color: var(--green-300) !important;
}
.green-300:not(.stroke) > svg > path {
  fill: var(--green-300) !important;
}
.green-300.stroke > svg > path {
  stroke: var(--green-300) !important;
}
.green-300.circle > svg > circle {
  stroke: var(--green-300) !important;
}

.bg-green-300 {
  background-color: var(--green-300) !important;
}

.green-200 {
  color: var(--green-200) !important;
}
.green-200:not(.stroke) > svg > path {
  fill: var(--green-200) !important;
}
.green-200.stroke > svg > path {
  stroke: var(--green-200) !important;
}
.green-200.circle > svg > circle {
  stroke: var(--green-200) !important;
}

.bg-green-200 {
  background-color: var(--green-200) !important;
}

.green-100 {
  color: var(--green-100) !important;
}
.green-100:not(.stroke) > svg > path {
  fill: var(--green-100) !important;
}
.green-100.stroke > svg > path {
  stroke: var(--green-100) !important;
}
.green-100.circle > svg > circle {
  stroke: var(--green-100) !important;
}

.bg-green-100 {
  background-color: var(--green-100) !important;
}

.blue-700 {
  color: var(--blue-700) !important;
}
.blue-700:not(.stroke) > svg > path {
  fill: var(--blue-700) !important;
}
.blue-700.stroke > svg > path {
  stroke: var(--blue-700) !important;
}
.blue-700.circle > svg > circle {
  stroke: var(--blue-700) !important;
}

.bg-blue-700 {
  background-color: var(--blue-700) !important;
}

.blue-600 {
  color: var(--blue-600) !important;
}
.blue-600:not(.stroke) > svg > path {
  fill: var(--blue-600) !important;
}
.blue-600.stroke > svg > path {
  stroke: var(--blue-600) !important;
}
.blue-600.circle > svg > circle {
  stroke: var(--blue-600) !important;
}

.bg-blue-600 {
  background-color: var(--blue-600) !important;
}

.blue-500,
.blue {
  color: var(--blue-500) !important;
}
.blue-500:not(.stroke) > svg > path,
.blue:not(.stroke) > svg > path {
  fill: var(--blue-500) !important;
}
.blue-500.stroke > svg > path,
.blue.stroke > svg > path {
  stroke: var(--blue-500) !important;
}
.blue-500.circle > svg > circle,
.blue.circle > svg > circle {
  stroke: var(--blue-500) !important;
}

.bg-blue-500,
.bg-blue {
  background-color: var(--blue-500) !important;
}

.blue-400 {
  color: var(--blue-400) !important;
}
.blue-400:not(.stroke) > svg > path {
  fill: var(--blue-400) !important;
}
.blue-400.stroke > svg > path {
  stroke: var(--blue-400) !important;
}
.blue-400.circle > svg > circle {
  stroke: var(--blue-400) !important;
}

.bg-blue-400 {
  background-color: var(--blue-400) !important;
}

.blue-300 {
  color: var(--blue-300) !important;
}
.blue-300:not(.stroke) > svg > path {
  fill: var(--blue-300) !important;
}
.blue-300.stroke > svg > path {
  stroke: var(--blue-300) !important;
}
.blue-300.circle > svg > circle {
  stroke: var(--blue-300) !important;
}

.bg-blue-300 {
  background-color: var(--blue-300) !important;
}

.blue-200 {
  color: var(--blue-200) !important;
}
.blue-200:not(.stroke) > svg > path {
  fill: var(--blue-200) !important;
}
.blue-200.stroke > svg > path {
  stroke: var(--blue-200) !important;
}
.blue-200.circle > svg > circle {
  stroke: var(--blue-200) !important;
}

.bg-blue-200 {
  background-color: var(--blue-200) !important;
}

.blue-100 {
  color: var(--blue-100) !important;
}
.blue-100:not(.stroke) > svg > path {
  fill: var(--blue-100) !important;
}
.blue-100.stroke > svg > path {
  stroke: var(--blue-100) !important;
}
.blue-100.circle > svg > circle {
  stroke: var(--blue-100) !important;
}

.bg-blue-100 {
  background-color: var(--blue-100) !important;
}

body {
  /* Estilizando a barra de rolagem */
  /* Estilizando o indicador (alça) da barra de rolagem */
  /* Estilizando o indicador (alça) da barra de rolagem quando hover */
  /* Estilizando a pista (fundo) da barra de rolagem */
  /* Estilizando a pista (fundo) da barra de rolagem quando hover */
}
body ::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
}
body ::-webkit-scrollbar-thumb {
  background-color: var(--primary-color); /* Cor do indicador */
  border-radius: 5px; /* Raio de borda do indicador */
}
body ::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color); /* Cor do indicador ao passar o mouse */
}
body ::-webkit-scrollbar-track {
  background-color: transparent; /* Cor da pista */
}
body ::-webkit-scrollbar-track:hover {
  background-color: transparent; /* Cor da pista ao passar o mouse */
}
body .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--id-main-purple);
}

.mzic-scrollbar, .mzic-popover.modal-popover .main, .mz-input textarea {
  scrollbar-color: var(--purple-500) var(--neutral-600);
  scrollbar-width: thin;
}

.mz-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 18px 24px 14px;
  border-radius: 100px;
  border: 0;
  background-color: var(--purple-500);
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--font-semi-bold);
  transition: background-color 0.3s;
  gap: 8px;
  border: 1px solid transparent;
  color: var(--neutral-600);
  cursor: pointer;
}
.mz-button:not(.stroke) > mzic-svg > svg > path {
  fill: var(--neutral-600);
}
.mz-button.stroke > mzic-svg > svg > path {
  stroke: var(--neutral-600);
}
.mz-button.full {
  width: 100%;
}
.mz-button:hover {
  background-color: var(--purple-400);
}
.mz-button.error {
  border-color: var(--red-500);
}
.mz-button mzic-spinner {
  display: inline-flex;
  align-items: center;
  margin-top: -2px;
}
.mz-button mzic-spinner .spinner {
  border-color: var(--neutral-400);
  border-bottom-color: var(--neutral-500);
}
.mz-button.only-icon mzic-spinner {
  margin-top: 0;
}
.mz-button mzic-svg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  max-height: 18px;
  margin-top: -2px;
}
.mz-button mzic-svg > svg {
  width: 18px;
  max-height: 18px;
  transition: 0.3s;
}
.mz-button mzic-svg > svg > path {
  transition: 0.3s;
}
.mz-button.only-icon {
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  padding: 0;
}
.mz-button.only-icon > mzic-svg {
  width: 24px;
  max-height: 24px;
  margin-top: 0;
}
.mz-button.only-icon > mzic-svg > svg {
  width: 24px;
  max-height: 24px;
}
.mz-button.medium {
  padding: 12px 24px 10px;
  font-size: 14px;
  line-height: 20px;
}
.mz-button.medium > mzic-svg {
  width: 20px;
  max-height: 20px;
}
.mz-button.medium > mzic-svg > svg {
  width: 20px;
  max-height: 20px;
}
.mz-button.medium.only-icon {
  width: 44px;
  height: 44px;
  padding: 0;
}
.mz-button.medium.only-icon > mzic-svg {
  width: 20px;
  max-height: 20px;
}
.mz-button.medium.only-icon > mzic-svg > svg {
  width: 20px;
  max-height: 20px;
}
.mz-button.small {
  padding: 10px 16px 7px;
  font-size: 14px;
  line-height: 20px;
}
.mz-button.small > mzic-svg {
  width: 16px;
  max-height: 16px;
}
.mz-button.small > mzic-svg > svg {
  width: 16px;
  max-height: 16px;
}
.mz-button.small.only-icon {
  width: 36px;
  height: 36px;
  padding: 0;
}
.mz-button.small.only-icon > mzic-svg {
  width: 14px;
  max-height: 14px;
}
.mz-button.small.only-icon > mzic-svg > svg {
  width: 14px;
  max-height: 14px;
}
.mz-button.mini {
  padding: 6px 18px 3px;
  font-size: 12px;
  line-height: 16px;
}
.mz-button.mini > mzic-svg {
  width: 12px;
  max-height: 12px;
}
.mz-button.mini > mzic-svg > svg {
  width: 12px;
  max-height: 12px;
}
.mz-button.mini.only-icon {
  width: 24px;
  height: 24px;
  padding: 0;
}
.mz-button.mini.only-icon > mzic-svg {
  width: 12px;
  max-height: 12px;
}
.mz-button.mini.only-icon > mzic-svg > svg {
  width: 12px;
  max-height: 12px;
}

.mz-button.white mzic-spinner .spinner {
  border-color: var(--neutral-100);
  border-bottom-color: var(--neutral-200);
}

.mz-button.white:not(.stroke) > mzic-svg > svg > path {
  fill: var(--neutral-100);
}

.mz-button:disabled,
.mz-button.white:disabled {
  background-color: var(--neutral-300) !important;
  color: var(--neutral-400) !important;
  border-color: transparent !important;
  cursor: not-allowed;
}
.mz-button:disabled.stroke > mzic-svg > svg > path,
.mz-button.white:disabled.stroke > mzic-svg > svg > path {
  stroke: var(--neutral-400);
}
.mz-button:disabled:not(.stroke) > mzic-svg > svg > path,
.mz-button.white:disabled:not(.stroke) > mzic-svg > svg > path {
  fill: var(--neutral-400);
}

button.secondary,
input[type=button].secondary {
  background-color: var(--neutral-500-50);
  color: var(--neutral-100);
}
button.secondary.stroke > mzic-svg > svg > path,
input[type=button].secondary.stroke > mzic-svg > svg > path {
  stroke: var(--neutral-100);
}
button.secondary:not(.stroke) > mzic-svg > svg > path,
input[type=button].secondary:not(.stroke) > mzic-svg > svg > path {
  fill: var(--neutral-100);
}
button.secondary:hover,
input[type=button].secondary:hover {
  background-color: var(--neutral-600);
}

button.tertiary,
input[type=button].tertiary {
  background-color: var(--neutral-500-50);
  color: var(--purple-500);
  border: 1px solid var(--neutral-500-50);
}
button.tertiary.stroke > mzic-svg > svg > path,
input[type=button].tertiary.stroke > mzic-svg > svg > path {
  stroke: var(--purple-500);
}
button.tertiary:not(.stroke) > mzic-svg > svg > path,
input[type=button].tertiary:not(.stroke) > mzic-svg > svg > path {
  fill: var(--purple-500);
}
button.tertiary:hover,
input[type=button].tertiary:hover {
  background-color: var(--neutral-500);
}

button.outline,
input[type=button].outline {
  background-color: transparent;
  color: var(--purple-500);
  border-color: var(--purple-500);
}
button.outline.stroke > mzic-svg > svg > path,
input[type=button].outline.stroke > mzic-svg > svg > path {
  stroke: var(--purple-500);
}
button.outline:not(.stroke) > mzic-svg > svg > path,
input[type=button].outline:not(.stroke) > mzic-svg > svg > path {
  fill: var(--purple-500);
}
button.outline:hover,
input[type=button].outline:hover {
  background-color: var(--neutral-500-50);
}

button.outline.white,
input[type=button].outline.white {
  background-color: transparent;
  color: var(--neutral-100);
  border-color: var(--neutral-100);
}
button.outline.white.stroke > mzic-svg > svg > path,
input[type=button].outline.white.stroke > mzic-svg > svg > path {
  stroke: var(--neutral-100);
}
button.outline.white:not(.stroke) > mzic-svg > svg > path,
input[type=button].outline.white:not(.stroke) > mzic-svg > svg > path {
  fill: var(--neutral-100);
}
button.outline.white:hover,
input[type=button].outline.white:hover {
  background-color: var(--neutral-500-50);
}

button.outline.weak,
input[type=button].outline.weak {
  background-color: transparent;
  color: var(--neutral-100);
  border-color: var(--neutral-500);
}
button.outline.weak.stroke > mzic-svg > svg > path,
input[type=button].outline.weak.stroke > mzic-svg > svg > path {
  stroke: var(--neutral-100);
}
button.outline.weak:not(.stroke) > mzic-svg > svg > path,
input[type=button].outline.weak:not(.stroke) > mzic-svg > svg > path {
  fill: var(--neutral-100);
}
button.outline.weak:hover,
input[type=button].outline.weak:hover {
  background-color: var(--neutral-500-50);
}

button.ghost,
input[type=button].ghost {
  background-color: transparent;
  color: var(--purple-500);
}
button.ghost.stroke > mzic-svg > svg > path,
input[type=button].ghost.stroke > mzic-svg > svg > path {
  stroke: var(--purple-500);
}
button.ghost:not(.stroke) > mzic-svg > svg > path,
input[type=button].ghost:not(.stroke) > mzic-svg > svg > path {
  fill: var(--purple-500);
}
button.ghost:hover,
input[type=button].ghost:hover {
  background-color: var(--neutral-600);
}
button.ghost:disabled,
input[type=button].ghost:disabled {
  background-color: transparent !important;
  color: var(--neutral-400);
}

button.link,
input[type=button].link {
  padding: 0;
  background-color: transparent;
  color: var(--purple-500);
}
button.link mzic-spinner .spinner,
input[type=button].link mzic-spinner .spinner {
  border-color: var(--purple-500);
  border-bottom-color: var(--purple-600);
}
button.link.stroke > mzic-svg > svg > path,
input[type=button].link.stroke > mzic-svg > svg > path {
  stroke: var(--purple-500);
}
button.link:not(.stroke) > mzic-svg > svg > path,
input[type=button].link:not(.stroke) > mzic-svg > svg > path {
  fill: var(--purple-500);
}
button.link:hover,
input[type=button].link:hover {
  background-color: transparent;
  color: var(--purple-400);
}
button.link:hover.stroke > mzic-svg > svg > path,
input[type=button].link:hover.stroke > mzic-svg > svg > path {
  stroke: var(--purple-400);
}
button.link:hover:not(.stroke) > mzic-svg > svg > path,
input[type=button].link:hover:not(.stroke) > mzic-svg > svg > path {
  fill: var(--purple-400);
}
button.link:disabled,
input[type=button].link:disabled {
  background-color: transparent !important;
  color: var(--neutral-400);
}

button.danger,
input[type=button].danger {
  background-color: var(--red-500);
}
button.danger:hover,
input[type=button].danger:hover {
  background-color: var(--red-300);
}

button.success,
input[type=button].success {
  background-color: var(--green-500);
}
button.success:hover,
input[type=button].success:hover {
  background-color: var(--green-300);
}

.mz-button.stroke-neutral-700 > mzic-svg > svg > path {
  stroke: var(--neutral-700) !important;
}

.mz-button.stroke-white-hover:hover > mzic-svg > svg > path {
  stroke: #fff !important;
}

.mz-button.fill-white-hover:hover > mzic-svg > svg > path {
  fill: #fff !important;
}

.mz-button-tag {
  -webkit-appearance: none;
  display: inline-flex;
  gap: 4px;
  padding: 12px 16px;
  border-radius: 100px;
  background-color: var(--neutral-500-50);
  border: 0;
  transition: background-color 0.3s;
}
.mz-button-tag:hover {
  background-color: var(--neutral-500);
}
.mz-button-tag.active .number {
  color: var(--purple-500);
}
.mz-button-tag .label,
.mz-button-tag .number {
  font-weight: var(--font-medium);
  margin-top: 2px;
}
.mz-button-tag .label {
  color: var(--neutral-100);
}
.mz-button-tag .number {
  color: var(--neutral-400);
}

.mzic-input-style, .mzic-select .selected, .mz-form-row.small .mz-input > :is(input, textarea, select), .mz-form-row.medium .mz-input :is(input, textarea, select), .mz-input > :is(input, textarea, select),
.mz-input > .mz-input-container > :is(input, textarea, select) {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--font-regular);
  padding: 12px 24px;
  color: var(--neutral-100);
  background-color: var(--neutral-500-50);
  border: 2px solid transparent;
  border-radius: 100px;
  outline: none;
  transition: 0.3s;
  -webkit-appearance: none;
}
.mzic-input-style ::placeholder, .mzic-select .selected ::placeholder, .mz-form-row.small .mz-input > :is(input, textarea, select) ::placeholder, .mz-form-row.medium .mz-input :is(input, textarea, select) ::placeholder, .mz-input > :is(input, textarea, select) ::placeholder,
.mz-input > .mz-input-container > :is(input, textarea, select) ::placeholder {
  color: var(--neutral-400);
}
.mzic-input-style:hover, .mzic-select .selected:hover, .mz-form-row.small .mz-input > :hover:is(input, textarea, select), .mz-form-row.medium .mz-input :hover:is(input, textarea, select), .mz-input > :hover:is(input, textarea, select),
.mz-input > .mz-input-container > :hover:is(input, textarea, select) {
  background-color: var(--neutral-500);
}
.mzic-input-style.error, .mzic-select .error.selected, .mz-form-row.small .mz-input > .error:is(input, textarea, select), .mz-form-row.medium .mz-input .error:is(input, textarea, select), .mz-input > .error:is(input, textarea, select),
.mz-input > .mz-input-container > .error:is(input, textarea, select), .mzic-input-style.success, .mzic-select .success.selected, .mz-form-row.small .mz-input > .success:is(input, textarea, select), .mz-form-row.medium .mz-input .success:is(input, textarea, select), .mz-input > .success:is(input, textarea, select),
.mz-input > .mz-input-container > .success:is(input, textarea, select) {
  background-repeat: no-repeat;
  background-position: right 17px center;
}
.mzic-input-style.error, .mzic-select .error.selected, .mz-form-row.small .mz-input > .error:is(input, textarea, select), .mz-form-row.medium .mz-input .error:is(input, textarea, select), .mz-input > .error:is(input, textarea, select),
.mz-input > .mz-input-container > .error:is(input, textarea, select) {
  border-color: var(--red-500);
}
.mzic-input-style.success, .mzic-select .success.selected, .mz-form-row.small .mz-input > .success:is(input, textarea, select), .mz-form-row.medium .mz-input .success:is(input, textarea, select), .mz-input > .success:is(input, textarea, select),
.mz-input > .mz-input-container > .success:is(input, textarea, select) {
  border-color: var(--green-500);
}

.mz-form-row {
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
}
.mz-form-row + .mz-form-row {
  margin-top: 24px;
}
.mz-form-row.vertical + .mz-form-row {
  margin-top: 24px;
}

.mz-label {
  display: inline-flex;
  flex-direction: column;
  padding-right: 36px;
  width: 40%;
  align-items: flex-start;
  justify-content: flex-start;
}
.mz-label.center {
  justify-content: center;
}
.mz-label--full {
  width: 100%;
}

.mz-input {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
}
.mz-input--full {
  width: 100%;
}

.mz-input > .slot-start,
.mz-input > .slot-end {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 40px;
}

.mz-input > .slot-start {
  left: 24px;
  top: 50%;
  transform: translate(0, -50%);
}
.mz-input > .slot-start.password {
  width: 20px;
}
.mz-input > .slot-start.password button {
  width: 20px;
  padding: 0;
  margin: 0;
}
.mz-input > .slot-start.password button > svg {
  width: 20px;
  max-height: 20px;
}

.mz-input input:is(.success, .error) + .slot-end {
  right: 54px;
}

.mz-input > .slot-end {
  right: 24px;
  top: 5px;
}
.mz-input > .slot-end.password {
  width: 20px;
}
.mz-input > .slot-end.password button {
  width: 20px;
  padding: 0;
  margin: 0;
}
.mz-input > .slot-end.password button > svg {
  width: 20px;
  max-height: 20px;
}

.mz-input > .slot-start + input {
  padding-left: 52px !important;
}

.mz-input > .slot-start.slot-search > mzic-svg {
  width: 18px;
}
.mz-input > .slot-start.slot-search + input {
  padding-left: 52px;
}

.mz-input > .slot-start.password + input {
  padding-left: 56px;
}

.mz-input > input.with-slot-end {
  padding-right: 92px;
}

.mz-input > input.with-slot-end.password {
  padding-right: 56px;
}

.mz-input > input:is(.success, .error).with-slot-end.password {
  padding-right: 87px;
}

.mz-label > label .required {
  color: var(--red-500);
  font-size: 15px;
}

.mz-form-row > .mz-label > p {
  color: var(--neutral-300) !important;
}

.mz-input-container {
  position: relative;
  width: 100%;
}

.mz-input--white > :is(input, textarea, select),
.mz-input--white > .mz-input-container > :is(input, textarea, select) {
  background-color: var(--white);
  color: var(--neutral-700);
}
.mz-input--white > :is(input, textarea, select)::placeholder,
.mz-input--white > .mz-input-container > :is(input, textarea, select)::placeholder {
  color: var(--neutral-200);
}
.mz-input--white > :is(input, textarea, select):hover,
.mz-input--white > .mz-input-container > :is(input, textarea, select):hover {
  background-color: var(--white);
}

.mz-form-row.auto .mz-input {
  width: auto;
}

.mz-form-row.auto .mz-input > select {
  padding-right: 62px;
}

.mz-input textarea {
  padding: 16px 24px;
  border-radius: 24px;
  min-height: 242px;
}

.mz-input select {
  background-image: url("/assets/icons/mzic-arrow-down-neutral-100.svg");
  background-repeat: no-repeat;
  background-position: right 28px center;
  background-size: 12px;
}

.mz-form-row .message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: var(--neutral-300);
}
.mz-form-row .message:not(.error):not(.success) {
  background-image: url("/assets/icons/mzic-input-tooltip.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: left top;
  padding-left: 20px !important;
}
.mz-form-row .message.error {
  color: var(--red-500);
}
.mz-form-row .message.success {
  color: var(--green-500);
}

.mz-form-row.for-radio .mz-input {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.mz-form-row.for-radio .mz-input.full div,
.mz-form-row.for-radio .mz-input.full div div {
  max-width: 100% !important;
}
.mz-form-row.for-radio .mz-input > div:first-child {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 38px;
}
.mz-form-row.for-radio .mz-input > div:first-child > div {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 50%;
}

.mz-form-row.for-radio .mz-input label {
  font-size: 14px;
  line-height: 20px;
  padding-top: 2px;
  color: var(--neutral-100);
}

.mzic-radio-containers {
  display: inline-flex;
  align-items: center;
  gap: 34px;
}

.mzic-radio-container {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.mzic-radio-container label {
  font-size: 14px;
  margin-bottom: -3px;
}

.mz-form-row.for-radio .mz-input input[type=radio],
.mzic-radio {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid var(--neutral-300);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
.mz-form-row.for-radio .mz-input input[type=radio]:disabled,
.mzic-radio:disabled {
  cursor: not-allowed;
}
.mz-form-row.for-radio .mz-input input[type=radio]:disabled + label,
.mzic-radio:disabled + label {
  cursor: not-allowed;
}
.mz-form-row.for-radio .mz-input input[type=radio].error,
.mzic-radio.error {
  border: 2px solid var(--red-500);
}
.mz-form-row.for-radio .mz-input input[type=radio]::before,
.mzic-radio::before {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: transparent;
  content: "";
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mz-form-row.for-radio .mz-input input[type=radio]:checked::before,
.mzic-radio:checked::before {
  background-color: var(--purple-500);
}
.mz-form-row.for-radio .mz-input input[type=radio]:checked:disabled::before,
.mzic-radio:checked:disabled::before {
  background-color: var(--neutral-400);
}

.mz-form-row.for-text .mz-input input + .mz-item-option,
.mz-form-row.for-text .mz-input .mz-item-option + .mz-item-option,
.mz-form-row.for-text .mz-input .mz-item-option + button,
.mz-form-row.for-button .mz-input .mz-item-option + button,
.mz-form-row.for-text .mz-input input + button,
.mz-form-row.for-textarea .mz-input textarea + button,
.mz-form-row.for-select .mz-input select + button {
  margin-top: 16px;
}

.mz-form-row.for-text .mz-input .message {
  padding-left: 10px;
}

.mz-form-row.for-image:not(.vertical) .mz-label {
  padding-top: 26px;
}

.mz-form-row.for-text:not(.vertical) .mz-label,
.mz-form-row.for-select:not(.vertical) .mz-label {
  padding-top: 16px;
}

.mz-form-row.for-select-label .mz-label {
  padding-top: 29px;
}

.mz-form-row.for-textarea .mz-label {
  padding-top: 20px;
}

.mz-form-row.for-toggle .mz-label {
  padding-top: 5px;
}

.mz-form-row.for-empty {
  flex-direction: column;
}

.mz-form-row.for-select-label .mz-input > label {
  font-size: 12px;
  line-height: 16px;
  color: var(--neutral-300);
  margin-bottom: 10px;
}

.mz-form-row.for-button {
  min-height: 50px;
}
.mz-form-row.for-button .mz-label {
  padding-top: 12px;
}

.mz-item-option {
  position: relative;
  display: inline-flex;
  width: 100%;
  border: 1px solid var(--neutral-300);
  border-radius: 100px;
  padding: 6px 60px 6px 24px;
  min-height: 56px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.mz-item-option + .mz-item-option {
  margin-top: 16px;
}
.mz-item-option > div {
  display: inline-flex;
  align-items: center;
}
.mz-item-option > div > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.mz-item-option > div > img.large {
  width: 34px;
  height: 34px;
}
.mz-item-option > .mid {
  flex-direction: column;
  align-items: flex-start;
}
.mz-item-option > .mid strong {
  color: var(--neutral-100);
}
.mz-item-option > .mid small {
  color: var(--neutral-300);
}
.mz-item-option > .end {
  display: inline-flex;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  gap: 8px;
}
.mz-item-option > .end > .mz-button {
  width: 36px;
  height: 36px;
}
.mz-item-option > .end > .mz-button.delete:hover {
  background-color: var(--red-500);
}
.mz-item-option > .end > .mz-button > svg {
  width: 18px;
  max-height: 18px;
}
.mz-item-option.locked {
  background-color: var(--neutral-500-50);
  border-color: transparent;
  background-image: url("/assets/icons/mzic-lock.svg");
  background-repeat: no-repeat;
  background-position: right 18px center;
  background-size: 17px;
}
.mz-item-option.locked > .end {
  right: 52px;
}

.mz-item-option.small {
  padding: 4px 24px;
  min-height: 44px;
}

.mz-item-option.secondary {
  background-color: var(--neutral-500-50);
  border-color: transparent;
}
.mz-item-option.secondary strong {
  color: var(--neutral-300);
}

.mz-form-row.inputs-2 .mz-inputs-container {
  display: inline-flex;
  width: 60%;
  justify-content: space-between;
}
.mz-form-row.inputs-2 .mz-inputs-container .mz-input {
  width: calc(50% - 12px);
}

.mz-form-row .mz-input .input-tip {
  display: inline-block;
  color: var(--neutral-300);
  margin-top: 6px;
}

.mz-form-row.vertical {
  flex-direction: column;
}
.mz-form-row.vertical .mz-label {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
}
.mz-form-row.vertical .mz-input {
  width: 100%;
}
.mz-form-row.vertical .mz-input + .mz-input {
  margin-top: 12px;
}

.mz-form-row.disabled .mz-label label {
  color: var(--neutral-400) !important;
}
.mz-form-row.disabled .mz-input label {
  color: var(--neutral-400) !important;
}
.mz-form-row.disabled .mz-input :is(input, textarea, select),
.mz-form-row.disabled .mz-input :is(input, textarea, select):hover {
  cursor: not-allowed;
  background-color: var(--neutral-500-50);
  color: var(--neutral-400);
}
.mz-form-row.disabled .mz-input :is(input, textarea, select)::placeholder,
.mz-form-row.disabled .mz-input :is(input, textarea, select):hover::placeholder {
  color: var(--neutral-400);
  opacity: 1; /* Firefox */
}
.mz-form-row.disabled .mz-input :is(input, textarea, select)::-ms-input-placeholder,
.mz-form-row.disabled .mz-input :is(input, textarea, select):hover::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--neutral-400);
}

.mz-form-row.locked :is(input, textarea, select) {
  background-image: url("/assets/icons/mzic-lock.svg");
  background-repeat: no-repeat;
  background-position: right 18px center;
  background-size: 17px;
  background-color: var(--neutral-500-50);
  border-color: transparent;
}

.mz-form-row.for-select .mz-input mzic-select-basic {
  width: 100%;
}

.mz-form-row.for-image .mz-input {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}
.mz-form-row.for-image .mz-input:hover .image-text .primary {
  text-decoration: underline;
}
.mz-form-row.for-image .mz-input:hover .camera-icon {
  border-color: var(--purple-500);
}
.mz-form-row.for-image .mz-input .camera-icon {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: var(--neutral-500-50);
  background-image: url("/assets/icons/mzic-camera.svg");
  background-repeat: no-repeat;
  background-size: 29px;
  background-position: center center;
  border: 2px solid transparent;
  overflow: hidden;
}
.mz-form-row.for-image .mz-input .camera-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.mz-form-row.for-image .mz-input .image-text {
  display: inline-flex;
  max-width: calc(100% - 72px);
  padding-left: 16px;
  flex-direction: column;
  cursor: pointer;
}
.mz-form-row.for-image .mz-input .image-text .primary {
  color: var(--purple-500);
}
.mz-form-row.for-image .mz-input .image-text .secondary {
  color: var(--neutral-300);
}

.mz-form-row.medium .mz-input :is(input, textarea, select) {
  padding: 12px 16px 12px;
  font-size: 14px;
  line-height: 16px;
}
.mz-form-row.medium .mz-input select {
  padding-right: 44px;
  background-position: right 16px center;
}

.mz-form-row.small .mz-input > :is(input, textarea, select) {
  padding: 7px 16px 5px;
  font-size: 14px;
  line-height: 16px;
}
.mz-form-row.small .mz-input select {
  padding-right: 44px;
  background-position: right 16px center;
}

.mz-form-row .mz-input mzic-tooltip {
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translate(100%, -50%);
}

.mz-form-row.sub-row {
  margin-top: 0;
}
.mz-form-row.sub-row .mz-label > label {
  color: var(--neutral-300);
  font-size: 14px;
  line-height: 20px;
}
.mz-form-row.sub-row .mz-label > label .required {
  font-size: 14px;
}

.mz-form-row.icon-left-1-small .mz-input :is(input, textarea) {
  padding-left: calc(18px + 15px + 4px + 4px);
}
.mz-form-row.icon-right-1-small .mz-input :is(input, textarea) {
  padding-right: calc(18px + 15px + 4px + 4px);
}
.mz-form-row.icon-left-2-small .mz-input :is(input, textarea) {
  padding-left: calc(18px + 15px + 15px + 4px + 4px + 4px + 4px);
}
.mz-form-row.icon-right-2-small .mz-input :is(input, textarea) {
  padding-right: calc(18px + 15px + 15px + 4px + 4px + 4px + 4px);
}
.mz-form-row.icon-left-1 .mz-input :is(input, textarea) {
  padding-left: calc(18px + 18px + 8px);
}
.mz-form-row.icon-right-1 .mz-input :is(input, textarea) {
  padding-right: calc(18px + 18px + 8px);
}
.mz-form-row.icon-left-2 .mz-input :is(input, textarea) {
  padding-left: calc(18px + 18px + 18px + 8px + 8px + 8px);
}
.mz-form-row.icon-right-2 .mz-input :is(input, textarea) {
  padding-right: calc(18px + 18px + 18px + 8px + 8px + 8px);
}
.mz-form-row.icon-left-2-large .mz-input :is(input, textarea) {
  padding-left: calc(18px + 28px + 28px + 12px + 12px);
}
.mz-form-row.icon-right-2-large .mz-input :is(input, textarea) {
  padding-right: calc(18px + 28px + 28px + 12px + 12px);
}
.mz-form-row.icon-left-2-small .icon.left + .icon.left {
  left: 44px;
}
.mz-form-row.icon-right-2-small .icon.right + .icon.right {
  right: 44px;
}
.mz-form-row.icon-left-2 .icon.left + .icon.left {
  left: 52px;
}
.mz-form-row.icon-right-2 .icon.right + .icon.right {
  right: 52px;
}
.mz-form-row.icon-left-2-large .icon.left + .icon.left {
  left: 60px;
}
.mz-form-row.icon-right-2-large .icon.right + .icon.right {
  right: 60px;
}
.mz-form-row.icon-left-1-large .icon.left, .mz-form-row.icon-left-2-large .icon.left, .mz-form-row.icon-right-1-large .icon.right, .mz-form-row.icon-right-2-large .icon.right {
  width: 28px;
}
.mz-form-row.icon-left-1-small .icon.left, .mz-form-row.icon-left-2-small .icon.left, .mz-form-row.icon-right-1-small .icon.right, .mz-form-row.icon-right-2-small .icon.right {
  width: 15px;
}
.mz-form-row.icon-left-1-large .mz-input :is(input, textarea) {
  padding-left: calc(18px + 28px + 12px);
}
.mz-form-row.icon-right-1-large .mz-input :is(input, textarea) {
  padding-right: calc(18px + 28px + 12px);
}
.mz-form-row.for-select.icon-left-1-small .select .selected-item {
  padding-left: 41px;
}
.mz-form-row.for-select.icon-left-1 .select .selected-item {
  padding-left: 44px;
}
.mz-form-row.for-select.icon-left-1-large .select .selected-item {
  padding-left: 58px;
}
.mz-form-row .mz-input .icon {
  position: absolute;
  width: 18px;
  max-height: 24px;
  top: 50%;
  transform: translate(0, -50%);
  display: inline-flex;
  justify-content: center;
}
.mz-form-row .mz-input .icon.left {
  left: 18px;
}
.mz-form-row .mz-input .icon.right {
  right: 18px;
}
.mz-form-row .mz-input .icon > mzic-svg {
  display: block;
}
.mz-form-row .mz-input .icon > img {
  max-width: 100%;
  max-height: 100%;
}
.mz-form-row .mz-input .icon > mzic-svg,
.mz-form-row .mz-input .icon > mzic-svg > svg,
.mz-form-row .mz-input .icon > mzic-svg > svg > path {
  max-width: 100%;
  max-height: 100%;
}
.mz-form-row .mz-input .input-prefix {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translate(0, -50%);
  color: var(--neutral-400);
}

.form-hint-success {
  color: var(--green-500);
}

.form-hint-error {
  color: var(--red-500);
}

.form-hint--with-space {
  margin-top: 6px;
  padding: 0 10px;
}

.mzic-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}
.mzic-table.min-height {
  min-height: 50vh;
}
.mzic-table.bordered tbody tr {
  border-bottom: 1px solid var(--neutral-500);
}
.mzic-table table {
  color: #fff;
  width: 100%;
  height: fit-content;
}
.mzic-table table thead {
  border-bottom: 1px solid var(--neutral-500);
}
.mzic-table table thead,
.mzic-table table tbody {
  width: 100%;
}
.mzic-table table thead th {
  padding: 12px 12px 10px;
}
.mzic-table table thead th > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.mzic-table table thead th > div mzic-table-sort {
  margin-left: 4px;
}
.mzic-table table thead th label {
  height: 18px;
  font-size: 14px;
  color: var(--neutral-300);
}
.mzic-table table tbody td {
  padding: 20px 12px 18px;
  font-size: 14px;
  height: 94px;
}
.mzic-table table tbody tr {
  transition: 0.3s;
  position: relative;
}
.mzic-table table tbody tr::after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  border: 2px solid transparent;
  border-radius: 16px;
  z-index: -1;
}
.mzic-table table tbody tr.sortable-chosen:hover::after {
  border-color: var(--purple-500);
  background-color: var(--neutral-500);
}
.mzic-table table tbody tr.opened {
  background: var(--neutral-500);
}
.mzic-table table tbody tr.hoverable:hover {
  background: var(--neutral-500);
}
.mzic-table table tbody tr.checked {
  background: var(--neutral-500-50);
}
.mzic-table table tbody.empty td > div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
.mzic-table table .mzic-table-column-date-time {
  width: 120px;
}
.mzic-table table .mzic-table-column-date-time .date {
  display: block;
  font-size: 14px;
  line-height: 21px;
  color: var(--neutral-100);
  font-weight: var(--font-medium);
}
.mzic-table table .mzic-table-column-date-time .time {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: var(--neutral-300);
  font-weight: var(--font-medium);
}
.mzic-table table .mzic-table-column-transaction-type .icon {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 100%;
  background-color: var(--neutral-200);
  text-align: center;
  vertical-align: middle;
  margin-right: 8px;
}
.mzic-table table .mzic-table-column-transaction-type .icon > mzic-svg {
  display: inline;
}
.mzic-table table .mzic-table-column-release-date {
  min-width: 156px;
}
.mzic-table table .mzic-table-column-w30 {
  width: 30px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}
.mzic-table table .mzic-table-column-w20 {
  width: 20px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}
.mzic-table table .mzic-table-column-w20 > *:not(.sort-number) {
  display: inline;
}
.mzic-table table .mzic-table-column-w40 {
  width: 40px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}
.mzic-table table .mzic-table-column-w40 > *:not(.sort-number) {
  display: inline;
}
.mzic-table table .mzic-table-column-small {
  width: 30px;
  vertical-align: middle;
}
.mzic-table table .mzic-table-column-small > *:not(mzic-skeleton) {
  display: inline;
}
.mzic-table table .mzic-table-column-dsp-service img {
  margin-right: 3px;
}
.mzic-table table .mzic-table-match-check {
  width: 62px;
  text-align: center;
}
.mzic-table table .mzic-table-main-genre-check {
  min-width: 130px;
}
.mzic-table table .mzic-table-column-check {
  width: 20px;
  padding-right: 0;
  text-align: center;
  vertical-align: middle;
}
.mzic-table table .mzic-table-column-check .mzic-checkbox,
.mzic-table table .mzic-table-column-check .mzic-checkbox .fake-checkbox {
  display: block;
}
.mzic-table table .mzic-table-column-actions-button {
  width: 50px;
  text-align: center;
  justify-content: center;
  display: inline-flex;
  height: 100%;
}
.mzic-table table .mzic-table-column-dsp-status {
  width: 43px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.mzic-table table .mzic-table-column-dsp-status > * {
  display: inline;
}
.mzic-table table .mzic-table-column-dsp-status > mzic-svg,
.mzic-table table .mzic-table-column-dsp-status > mzic-svg > svg {
  width: 18px;
  max-height: 18px;
}
.mzic-table table .mzic-table-column-bullets {
  width: 22px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.mzic-table table .mzic-table-column-sort {
  text-align: center;
  width: 24px;
  padding-left: 0;
  padding-right: 0;
}
.mzic-table table .mzic-table-column-sort > mzic-svg {
  display: inline;
  margin-top: -2px;
}
.mzic-table table tbody .mzic-table-column-sort {
  cursor: move;
}
.mzic-table table .mzic-column-date-time {
  width: 150px;
}
.mzic-table table .mzic-column-date-time > div {
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
}
.mzic-table table .mzic-column-date-time > div span {
  font-size: 14px;
  line-height: 21px;
  color: var(--neutral-100);
}
.mzic-table table .mzic-column-date-time > div span.time {
  color: var(--neutral-300);
}
.mzic-table table .mzic-table-column-play {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 32px;
}
.mzic-table table .mzic-table-column-play > mzic-svg {
  display: inline;
}
.mzic-table .empty-container {
  padding-top: 26px;
  padding-bottom: 26px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.mzic-table footer {
  display: flex;
  width: 100%;
  border-top: 1px solid var(--neutral-500);
  justify-content: space-between;
  padding: 10px 12px;
  color: #fff;
}
.mzic-table footer > div {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.mzic-table footer .items-total {
  font-size: 14px;
  color: var(--neutral-300);
}
.mzic-table footer .items-total span {
  color: #fff;
}

.mzic-table.medium tbody td {
  padding-top: 2px;
  padding-bottom: 0;
  height: 76px;
}

.mzic-table.small tbody td {
  padding-top: 0;
  padding-bottom: 0;
  height: 48px;
}

.mzic-table.fixed-footer {
  margin-bottom: var(--table-fixed-footer-height);
}

.mzic-table.fixed-footer footer {
  position: fixed;
  width: calc(100% - 236px - 2 * 38px);
  z-index: 1;
  padding-top: 8px;
  left: calc(236px + 38px);
  bottom: 0;
  height: var(--table-fixed-footer-height);
  align-items: flex-start;
  background-color: var(--id-main-black);
  transition: width 0.3s, left 0.3s;
}

body.navigation-collapsed .mzic-table.fixed-footer footer {
  width: calc(100% - 68px - 2 * 38px);
  left: calc(68px + 38px);
}

mzic-svg {
  position: relative;
  display: inline-flex;
  width: 24px;
  max-height: 24px;
}
mzic-svg > svg {
  width: 24px;
  max-height: 24px;
}

.mz-button.stroke mzic-svg > svg > path {
  fill: transparent !important;
}

mzic-svg:not(.stroke) > svg > path {
  fill: var(--neutral-100);
}

mzic-svg.stroke > svg > path {
  stroke: var(--neutral-100);
}

mzic-svg.w1 {
  width: 1px;
  max-height: 1px;
}
mzic-svg.w1 > svg {
  width: 1px;
  max-height: 1px;
}
mzic-svg.w2 {
  width: 2px;
  max-height: 2px;
}
mzic-svg.w2 > svg {
  width: 2px;
  max-height: 2px;
}
mzic-svg.w3 {
  width: 3px;
  max-height: 3px;
}
mzic-svg.w3 > svg {
  width: 3px;
  max-height: 3px;
}
mzic-svg.w4 {
  width: 4px;
  max-height: 4px;
}
mzic-svg.w4 > svg {
  width: 4px;
  max-height: 4px;
}
mzic-svg.w5 {
  width: 5px;
  max-height: 5px;
}
mzic-svg.w5 > svg {
  width: 5px;
  max-height: 5px;
}
mzic-svg.w6 {
  width: 6px;
  max-height: 6px;
}
mzic-svg.w6 > svg {
  width: 6px;
  max-height: 6px;
}
mzic-svg.w7 {
  width: 7px;
  max-height: 7px;
}
mzic-svg.w7 > svg {
  width: 7px;
  max-height: 7px;
}
mzic-svg.w8 {
  width: 8px;
  max-height: 8px;
}
mzic-svg.w8 > svg {
  width: 8px;
  max-height: 8px;
}
mzic-svg.w9 {
  width: 9px;
  max-height: 9px;
}
mzic-svg.w9 > svg {
  width: 9px;
  max-height: 9px;
}
mzic-svg.w10 {
  width: 10px;
  max-height: 10px;
}
mzic-svg.w10 > svg {
  width: 10px;
  max-height: 10px;
}
mzic-svg.w11 {
  width: 11px;
  max-height: 11px;
}
mzic-svg.w11 > svg {
  width: 11px;
  max-height: 11px;
}
mzic-svg.w12 {
  width: 12px;
  max-height: 12px;
}
mzic-svg.w12 > svg {
  width: 12px;
  max-height: 12px;
}
mzic-svg.w13 {
  width: 13px;
  max-height: 13px;
}
mzic-svg.w13 > svg {
  width: 13px;
  max-height: 13px;
}
mzic-svg.w14 {
  width: 14px;
  max-height: 14px;
}
mzic-svg.w14 > svg {
  width: 14px;
  max-height: 14px;
}
mzic-svg.w15 {
  width: 15px;
  max-height: 15px;
}
mzic-svg.w15 > svg {
  width: 15px;
  max-height: 15px;
}
mzic-svg.w16 {
  width: 16px;
  max-height: 16px;
}
mzic-svg.w16 > svg {
  width: 16px;
  max-height: 16px;
}
mzic-svg.w17 {
  width: 17px;
  max-height: 17px;
}
mzic-svg.w17 > svg {
  width: 17px;
  max-height: 17px;
}
mzic-svg.w18 {
  width: 18px;
  max-height: 18px;
}
mzic-svg.w18 > svg {
  width: 18px;
  max-height: 18px;
}
mzic-svg.w19 {
  width: 19px;
  max-height: 19px;
}
mzic-svg.w19 > svg {
  width: 19px;
  max-height: 19px;
}
mzic-svg.w20 {
  width: 20px;
  max-height: 20px;
}
mzic-svg.w20 > svg {
  width: 20px;
  max-height: 20px;
}
mzic-svg.w21 {
  width: 21px;
  max-height: 21px;
}
mzic-svg.w21 > svg {
  width: 21px;
  max-height: 21px;
}
mzic-svg.w22 {
  width: 22px;
  max-height: 22px;
}
mzic-svg.w22 > svg {
  width: 22px;
  max-height: 22px;
}
mzic-svg.w23 {
  width: 23px;
  max-height: 23px;
}
mzic-svg.w23 > svg {
  width: 23px;
  max-height: 23px;
}
mzic-svg.w24 {
  width: 24px;
  max-height: 24px;
}
mzic-svg.w24 > svg {
  width: 24px;
  max-height: 24px;
}
mzic-svg.w25 {
  width: 25px;
  max-height: 25px;
}
mzic-svg.w25 > svg {
  width: 25px;
  max-height: 25px;
}
mzic-svg.w26 {
  width: 26px;
  max-height: 26px;
}
mzic-svg.w26 > svg {
  width: 26px;
  max-height: 26px;
}
mzic-svg.w27 {
  width: 27px;
  max-height: 27px;
}
mzic-svg.w27 > svg {
  width: 27px;
  max-height: 27px;
}
mzic-svg.w28 {
  width: 28px;
  max-height: 28px;
}
mzic-svg.w28 > svg {
  width: 28px;
  max-height: 28px;
}
mzic-svg.w29 {
  width: 29px;
  max-height: 29px;
}
mzic-svg.w29 > svg {
  width: 29px;
  max-height: 29px;
}
mzic-svg.w30 {
  width: 30px;
  max-height: 30px;
}
mzic-svg.w30 > svg {
  width: 30px;
  max-height: 30px;
}
mzic-svg.w31 {
  width: 31px;
  max-height: 31px;
}
mzic-svg.w31 > svg {
  width: 31px;
  max-height: 31px;
}
mzic-svg.w32 {
  width: 32px;
  max-height: 32px;
}
mzic-svg.w32 > svg {
  width: 32px;
  max-height: 32px;
}
mzic-svg.w33 {
  width: 33px;
  max-height: 33px;
}
mzic-svg.w33 > svg {
  width: 33px;
  max-height: 33px;
}
mzic-svg.w34 {
  width: 34px;
  max-height: 34px;
}
mzic-svg.w34 > svg {
  width: 34px;
  max-height: 34px;
}
mzic-svg.w35 {
  width: 35px;
  max-height: 35px;
}
mzic-svg.w35 > svg {
  width: 35px;
  max-height: 35px;
}
mzic-svg.w36 {
  width: 36px;
  max-height: 36px;
}
mzic-svg.w36 > svg {
  width: 36px;
  max-height: 36px;
}
mzic-svg.w37 {
  width: 37px;
  max-height: 37px;
}
mzic-svg.w37 > svg {
  width: 37px;
  max-height: 37px;
}
mzic-svg.w38 {
  width: 38px;
  max-height: 38px;
}
mzic-svg.w38 > svg {
  width: 38px;
  max-height: 38px;
}
mzic-svg.w39 {
  width: 39px;
  max-height: 39px;
}
mzic-svg.w39 > svg {
  width: 39px;
  max-height: 39px;
}
mzic-svg.w40 {
  width: 40px;
  max-height: 40px;
}
mzic-svg.w40 > svg {
  width: 40px;
  max-height: 40px;
}
mzic-svg.w41 {
  width: 41px;
  max-height: 41px;
}
mzic-svg.w41 > svg {
  width: 41px;
  max-height: 41px;
}
mzic-svg.w42 {
  width: 42px;
  max-height: 42px;
}
mzic-svg.w42 > svg {
  width: 42px;
  max-height: 42px;
}
mzic-svg.w43 {
  width: 43px;
  max-height: 43px;
}
mzic-svg.w43 > svg {
  width: 43px;
  max-height: 43px;
}
mzic-svg.w44 {
  width: 44px;
  max-height: 44px;
}
mzic-svg.w44 > svg {
  width: 44px;
  max-height: 44px;
}
mzic-svg.w45 {
  width: 45px;
  max-height: 45px;
}
mzic-svg.w45 > svg {
  width: 45px;
  max-height: 45px;
}
mzic-svg.w46 {
  width: 46px;
  max-height: 46px;
}
mzic-svg.w46 > svg {
  width: 46px;
  max-height: 46px;
}
mzic-svg.w47 {
  width: 47px;
  max-height: 47px;
}
mzic-svg.w47 > svg {
  width: 47px;
  max-height: 47px;
}
mzic-svg.w48 {
  width: 48px;
  max-height: 48px;
}
mzic-svg.w48 > svg {
  width: 48px;
  max-height: 48px;
}
mzic-svg.w49 {
  width: 49px;
  max-height: 49px;
}
mzic-svg.w49 > svg {
  width: 49px;
  max-height: 49px;
}
mzic-svg.w50 {
  width: 50px;
  max-height: 50px;
}
mzic-svg.w50 > svg {
  width: 50px;
  max-height: 50px;
}
mzic-svg.w51 {
  width: 51px;
  max-height: 51px;
}
mzic-svg.w51 > svg {
  width: 51px;
  max-height: 51px;
}
mzic-svg.w52 {
  width: 52px;
  max-height: 52px;
}
mzic-svg.w52 > svg {
  width: 52px;
  max-height: 52px;
}
mzic-svg.w53 {
  width: 53px;
  max-height: 53px;
}
mzic-svg.w53 > svg {
  width: 53px;
  max-height: 53px;
}
mzic-svg.w54 {
  width: 54px;
  max-height: 54px;
}
mzic-svg.w54 > svg {
  width: 54px;
  max-height: 54px;
}
mzic-svg.w55 {
  width: 55px;
  max-height: 55px;
}
mzic-svg.w55 > svg {
  width: 55px;
  max-height: 55px;
}
mzic-svg.w56 {
  width: 56px;
  max-height: 56px;
}
mzic-svg.w56 > svg {
  width: 56px;
  max-height: 56px;
}
mzic-svg.w57 {
  width: 57px;
  max-height: 57px;
}
mzic-svg.w57 > svg {
  width: 57px;
  max-height: 57px;
}
mzic-svg.w58 {
  width: 58px;
  max-height: 58px;
}
mzic-svg.w58 > svg {
  width: 58px;
  max-height: 58px;
}
mzic-svg.w59 {
  width: 59px;
  max-height: 59px;
}
mzic-svg.w59 > svg {
  width: 59px;
  max-height: 59px;
}
mzic-svg.w60 {
  width: 60px;
  max-height: 60px;
}
mzic-svg.w60 > svg {
  width: 60px;
  max-height: 60px;
}
mzic-svg.w61 {
  width: 61px;
  max-height: 61px;
}
mzic-svg.w61 > svg {
  width: 61px;
  max-height: 61px;
}
mzic-svg.w62 {
  width: 62px;
  max-height: 62px;
}
mzic-svg.w62 > svg {
  width: 62px;
  max-height: 62px;
}
mzic-svg.w63 {
  width: 63px;
  max-height: 63px;
}
mzic-svg.w63 > svg {
  width: 63px;
  max-height: 63px;
}
mzic-svg.w64 {
  width: 64px;
  max-height: 64px;
}
mzic-svg.w64 > svg {
  width: 64px;
  max-height: 64px;
}
mzic-svg.w65 {
  width: 65px;
  max-height: 65px;
}
mzic-svg.w65 > svg {
  width: 65px;
  max-height: 65px;
}
mzic-svg.w66 {
  width: 66px;
  max-height: 66px;
}
mzic-svg.w66 > svg {
  width: 66px;
  max-height: 66px;
}
mzic-svg.w67 {
  width: 67px;
  max-height: 67px;
}
mzic-svg.w67 > svg {
  width: 67px;
  max-height: 67px;
}
mzic-svg.w68 {
  width: 68px;
  max-height: 68px;
}
mzic-svg.w68 > svg {
  width: 68px;
  max-height: 68px;
}
mzic-svg.w69 {
  width: 69px;
  max-height: 69px;
}
mzic-svg.w69 > svg {
  width: 69px;
  max-height: 69px;
}
mzic-svg.w70 {
  width: 70px;
  max-height: 70px;
}
mzic-svg.w70 > svg {
  width: 70px;
  max-height: 70px;
}
mzic-svg.w71 {
  width: 71px;
  max-height: 71px;
}
mzic-svg.w71 > svg {
  width: 71px;
  max-height: 71px;
}
mzic-svg.w72 {
  width: 72px;
  max-height: 72px;
}
mzic-svg.w72 > svg {
  width: 72px;
  max-height: 72px;
}
mzic-svg.w73 {
  width: 73px;
  max-height: 73px;
}
mzic-svg.w73 > svg {
  width: 73px;
  max-height: 73px;
}
mzic-svg.w74 {
  width: 74px;
  max-height: 74px;
}
mzic-svg.w74 > svg {
  width: 74px;
  max-height: 74px;
}
mzic-svg.w75 {
  width: 75px;
  max-height: 75px;
}
mzic-svg.w75 > svg {
  width: 75px;
  max-height: 75px;
}
mzic-svg.w76 {
  width: 76px;
  max-height: 76px;
}
mzic-svg.w76 > svg {
  width: 76px;
  max-height: 76px;
}
mzic-svg.w77 {
  width: 77px;
  max-height: 77px;
}
mzic-svg.w77 > svg {
  width: 77px;
  max-height: 77px;
}
mzic-svg.w78 {
  width: 78px;
  max-height: 78px;
}
mzic-svg.w78 > svg {
  width: 78px;
  max-height: 78px;
}
mzic-svg.w79 {
  width: 79px;
  max-height: 79px;
}
mzic-svg.w79 > svg {
  width: 79px;
  max-height: 79px;
}
mzic-svg.w80 {
  width: 80px;
  max-height: 80px;
}
mzic-svg.w80 > svg {
  width: 80px;
  max-height: 80px;
}
mzic-svg.w81 {
  width: 81px;
  max-height: 81px;
}
mzic-svg.w81 > svg {
  width: 81px;
  max-height: 81px;
}
mzic-svg.w82 {
  width: 82px;
  max-height: 82px;
}
mzic-svg.w82 > svg {
  width: 82px;
  max-height: 82px;
}
mzic-svg.w83 {
  width: 83px;
  max-height: 83px;
}
mzic-svg.w83 > svg {
  width: 83px;
  max-height: 83px;
}
mzic-svg.w84 {
  width: 84px;
  max-height: 84px;
}
mzic-svg.w84 > svg {
  width: 84px;
  max-height: 84px;
}
mzic-svg.w85 {
  width: 85px;
  max-height: 85px;
}
mzic-svg.w85 > svg {
  width: 85px;
  max-height: 85px;
}
mzic-svg.w86 {
  width: 86px;
  max-height: 86px;
}
mzic-svg.w86 > svg {
  width: 86px;
  max-height: 86px;
}
mzic-svg.w87 {
  width: 87px;
  max-height: 87px;
}
mzic-svg.w87 > svg {
  width: 87px;
  max-height: 87px;
}
mzic-svg.w88 {
  width: 88px;
  max-height: 88px;
}
mzic-svg.w88 > svg {
  width: 88px;
  max-height: 88px;
}
mzic-svg.w89 {
  width: 89px;
  max-height: 89px;
}
mzic-svg.w89 > svg {
  width: 89px;
  max-height: 89px;
}
mzic-svg.w90 {
  width: 90px;
  max-height: 90px;
}
mzic-svg.w90 > svg {
  width: 90px;
  max-height: 90px;
}
mzic-svg.w91 {
  width: 91px;
  max-height: 91px;
}
mzic-svg.w91 > svg {
  width: 91px;
  max-height: 91px;
}
mzic-svg.w92 {
  width: 92px;
  max-height: 92px;
}
mzic-svg.w92 > svg {
  width: 92px;
  max-height: 92px;
}
mzic-svg.w93 {
  width: 93px;
  max-height: 93px;
}
mzic-svg.w93 > svg {
  width: 93px;
  max-height: 93px;
}
mzic-svg.w94 {
  width: 94px;
  max-height: 94px;
}
mzic-svg.w94 > svg {
  width: 94px;
  max-height: 94px;
}
mzic-svg.w95 {
  width: 95px;
  max-height: 95px;
}
mzic-svg.w95 > svg {
  width: 95px;
  max-height: 95px;
}
mzic-svg.w96 {
  width: 96px;
  max-height: 96px;
}
mzic-svg.w96 > svg {
  width: 96px;
  max-height: 96px;
}

.mzic-alert-container {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
}
.mzic-alert-container.no-gap {
  gap: 0;
}

.mzic-alert {
  display: inline-flex;
  align-items: center;
  gap: 13px !important;
  padding: 10px 24px 7px !important;
  border: 1px solid transparent;
  border-radius: 16px;
}
.mzic-alert > mzic-svg {
  width: 21px;
  max-height: 21px;
  margin-top: -5px;
}
.mzic-alert > p {
  margin: 0 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}
.mzic-alert.error {
  border-color: var(--red-500);
}
.mzic-alert.error p {
  color: var(--red-300) !important;
}
.mzic-alert.error > mzic-svg > svg > path {
  fill: var(--red-500);
}
.mzic-alert.error.filled {
  background-color: var(--red-500);
}
.mzic-alert.error.filled p {
  color: var(--neutral-700) !important;
}
.mzic-alert.error.filled > mzic-svg > svg > path {
  fill: var(--neutral-700);
}
.mzic-alert.warning {
  border-color: var(--yellow-500);
}
.mzic-alert.warning p {
  color: var(--yellow-500) !important;
}
.mzic-alert.warning > mzic-svg > svg > path {
  fill: var(--yellow-500);
}
.mzic-alert.warning.filled {
  background-color: var(--yellow-500);
}
.mzic-alert.warning.filled p {
  color: var(--neutral-700) !important;
}
.mzic-alert.warning.filled > mzic-svg > svg > path {
  fill: var(--neutral-700);
}

.mzic-select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mzic-select > label .required {
  color: var(--red-500);
  font-size: 15px;
}
.select-option-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-option {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  width: 90%;
}
.select-option__image {
  display: flex;
  flex-shrink: 0;
  width: 34px;
  height: 34px;
}
.select-option__image img {
  width: 100%;
  border-radius: 100%;
}
.select-option__main {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.select-option__main > header {
  display: flex;
  align-items: center;
  gap: 3px;
}
.select-option__main-title {
  color: var(--neutral-100);
}
.select-option__main-version {
  color: var(--neutral-300);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-option__main-description {
  color: var(--neutral-300);
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mzic-tabs {
  display: inline-flex;
}
.mzic-tabs > ul {
  display: inline-flex;
  margin: 0;
  gap: 24px;
  padding: 0;
}
.mzic-tabs > ul > li {
  display: inline-flex;
  list-style: none;
}
.mzic-tabs > ul > li > a {
  position: relative;
  display: inline-flex;
  height: 44px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: var(--font-regular);
  color: var(--neutral-300) !important;
}
.mzic-tabs > ul > li > a::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: transparent;
  content: "";
  left: 0;
  bottom: 0;
}
.mzic-tabs > ul > li > a.active {
  color: var(--purple-500) !important;
  font-weight: var(--font-bold) !important;
}
.mzic-tabs > ul > li > a.active::after {
  background: var(--purple-500);
}

.mzic-popover-outside {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  cursor: default;
}
.mzic-popover-outside.show-background-true {
  background-color: rgba(17, 16, 16, 0.8);
}

.mzic-popover {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 110;
  max-width: none;
  width: fit-content;
  overflow: visible;
  opacity: 0;
}
.mzic-popover.fixed-true {
  position: fixed;
}
.mzic-popover.active {
  opacity: 1;
}

.mzic-dialog {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 16px;
  background-color: var(--neutral-600);
  padding: 16px 24px 24px 24px;
  width: 350px;
  gap: 0;
}
.mzic-dialog.mzic-dialog--overlay .mat-mdc-dialog-container {
  overflow-y: visible !important;
}
.mzic-dialog .mat-mdc-dialog-container {
  max-height: 86vh;
  overflow-y: hidden;
}
.mzic-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  background: transparent;
  box-shadow: none;
  overflow: visible;
}
.mzic-dialog .header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0;
}
.mzic-dialog .header.flex-end {
  justify-content: flex-end;
}
.mzic-dialog .header.no-margin {
  margin: 0 !important;
}
.mzic-dialog .header .title {
  display: inline-flex;
  padding-right: 27px;
  align-items: center;
  gap: 8px;
}
.mzic-dialog .header .mz-button.close {
  margin-top: 4px;
  padding: 0;
  height: 16px;
  width: 16px;
}
.mzic-dialog .footer-sticky {
  position: sticky;
  bottom: 0px;
  background: var(--neutral-600);
  z-index: 10;
  padding: 12px 0 !important;
}
.mzic-dialog .footer-sticky .footer {
  margin-top: inherit;
}
.mzic-dialog .footer {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  padding: 0;
  width: 100%;
}
.mzic-dialog .footer.flex-end {
  justify-content: flex-end;
}
.mzic-dialog .footer button:not(.full) {
  width: auto;
}
.mzic-dialog .footer.padding {
  padding-left: 24px;
  padding-right: 24px;
}

.mzic-popover.modal-popover {
  border-radius: 16px;
  background-color: var(--neutral-600);
  padding: 16px 24px 24px 24px;
  width: 350px;
  gap: 0;
  max-height: 86vh;
}
.mzic-popover.modal-popover > div {
  padding: inherit;
  font-size: inherit;
  line-height: inherit;
  height: inherit;
  gap: inherit;
  align-items: inherit;
}
.mzic-popover.modal-popover.medium {
  width: 596px;
}
.mzic-popover.modal-popover .header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0;
}
.mzic-popover.modal-popover .header.flex-end {
  justify-content: flex-end;
}
.mzic-popover.modal-popover .header.no-margin {
  margin: 0;
}
.mzic-popover.modal-popover .header .title {
  display: inline-flex;
  padding-right: 27px;
  align-items: center;
  gap: 8px;
  margin-top: -2px !important;
}
.mzic-popover.modal-popover .header .mz-button.close {
  margin-top: 4px;
  padding: 0;
  height: 16px;
  width: 16px;
}
.mzic-popover.modal-popover .main {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  max-height: 70vh;
  gap: 12px;
}
@media screen and (max-height: 950px) {
  .mzic-popover.modal-popover .main {
    max-height: 64vh;
  }
}
@media screen and (max-height: 640px) {
  .mzic-popover.modal-popover .main {
    max-height: 58vh;
  }
}
.mzic-popover.modal-popover .main.center {
  align-items: center;
}
.mzic-popover.modal-popover .main.center p {
  justify-content: center;
  text-align: center;
}
.mzic-popover.modal-popover .main.padding {
  padding: 24px 24px 4px 24px;
}
.mzic-popover.modal-popover .main.no-gap {
  gap: 0;
}
.mzic-popover.modal-popover .main .title {
  font-size: 16px;
  color: var(--neutral-100);
  margin-bottom: -3px;
}
.mzic-popover.modal-popover .footer {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  padding: 0;
  width: 100%;
}
.mzic-popover.modal-popover .footer.flex-end {
  justify-content: flex-end;
}
.mzic-popover.modal-popover .footer button:not(.full) {
  width: auto;
}
.mzic-popover.modal-popover .footer.padding {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
}

.mzic-popover.style-dark-small {
  background-color: var(--neutral-500);
  border-radius: 24px;
  padding: 9px 14px;
  gap: 6px;
}
.mzic-popover.style-dark-small > div {
  color: #fff;
  font-size: 14px;
  line-height: 160%;
  font-weight: var(--font-regular);
  padding: 0;
}
.mzic-popover.style-dark-small > div a,
.mzic-popover.style-dark-small > div button,
.mzic-popover.style-dark-small > div .clickable {
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  border-radius: 100px;
  padding: 1px 11px;
}
.mzic-popover.style-dark-small > div a:hover,
.mzic-popover.style-dark-small > div button:hover,
.mzic-popover.style-dark-small > div .clickable:hover {
  background: var(--neutral-700);
}

.mzic-popover.style-white-small {
  background-color: var(--neutral-100);
  border-radius: 24px;
  padding: 9px 16px;
  gap: 6px;
}
.mzic-popover.style-white-small > div {
  display: inline-flex;
  padding: 6px 8px 3px;
  font-size: 12px;
  line-height: 12px;
  height: 24px;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--font-medium);
  width: 100%;
  color: var(--neutral-700);
}
.mzic-popover.style-white-small > div > mzic-svg {
  max-width: 14px;
  margin-top: -3px;
}
.mzic-popover.style-white-small > div > mzic-svg:not(.stroke) > svg > path {
  fill: var(--neutral-700);
}
.mzic-popover.style-white-small > div > mzic-svg.stroke > svg > path {
  stroke: var(--neutral-700);
}
.mzic-popover.style-white-small > div a,
.mzic-popover.style-white-small > div button,
.mzic-popover.style-white-small > div .clickable, .mzic-popover.style-white-small > div.clickable {
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  border-radius: 100px;
}
.mzic-popover.style-white-small > div a:hover,
.mzic-popover.style-white-small > div button:hover,
.mzic-popover.style-white-small > div .clickable:hover, .mzic-popover.style-white-small > div.clickable:hover {
  background-color: var(--neutral-200-50);
}

.common-style, .mzic-popover.style-toast > ng-component, .mzic-popover.style-toast {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

#mzicToastContainer {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 101;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

@keyframes toast-enter-animation {
  from {
    opacity: 0;
    right: -400px;
  }
  to {
    opacity: 1;
    right: 0;
  }
}
.mzic-popover.style-toast {
  background-color: var(--neutral-100);
  padding: 24px 24px 20px;
  border-radius: 16px;
  min-width: 375px;
  max-width: 935px;
  width: fit-content;
  overflow: hidden;
  position: relative;
  opacity: 1;
  animation-name: toast-enter-animation;
  animation-duration: 0.3s;
}
@media (max-width: 430px) {
  .mzic-popover.style-toast {
    max-width: 98vw;
  }
}
.mzic-popover.style-toast mzic-svg {
  margin-top: -3px;
}
.mzic-popover.style-toast > ng-component {
  width: 100%;
}
.mzic-popover.style-toast.medium {
  padding: 19px 24px 16px;
}
.mzic-popover.style-toast > div,
.mzic-popover.style-toast > ng-component > div {
  display: inline-flex;
  align-items: center;
  gap: 13px;
}
.mzic-popover.style-toast .message-container {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
}
.mzic-popover.style-toast .message-container .main {
  font-size: 16px;
  line-height: 18px;
  color: var(--neutral-700);
  font-weight: var(--font-bold);
}
.mzic-popover.style-toast .message-container .secondary {
  font-size: 16px;
  line-height: 18px;
  color: var(--neutral-700);
  font-weight: var(--font-regular);
}
.mzic-popover.style-toast .link-btn {
  color: var(--purple-500);
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--font-semi-bold);
  padding: 2px 24px;
}
.mzic-popover.style-toast .progress {
  position: absolute;
  height: 4px;
  background-color: var(--purple-500);
  bottom: 0;
  left: 0;
}

.mzic-popover.style-tooltip {
  background-color: var(--neutral-500);
  border-radius: 8px;
  padding: 8px;
  display: inline-flex;
  color: #fff;
  font-size: 10px;
  line-height: 14px;
  max-width: 182px;
  position: absolute;
}
.mzic-popover.style-tooltip > div {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
}
.mzic-popover.style-tooltip::after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--neutral-500);
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}
.mzic-popover.style-tooltip strong {
  font-size: 10px;
  line-height: 14px;
  font-weight: var(--font-bold);
  color: #fff;
  margin: 0;
  padding: 0;
}
.mzic-popover.style-tooltip p {
  font-size: 10px;
  line-height: 14px;
  font-weight: var(--font-regular);
  color: #fff;
  margin: 0;
  padding: 0;
}

.mzic-bank-account {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--neutral-500-50);
}
.mzic-bank-account.is-checked-true {
  border-color: var(--purple-500);
}
.mzic-bank-account > div {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}
.mzic-bank-account > div:last-child {
  gap: 16px;
}
.mzic-bank-account .icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background-color: var(--neutral-500-50);
  border-radius: 50%;
}
.mzic-bank-account .icon .flag {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 0px;
  right: -8px;
}
.mzic-bank-account .text {
  display: inline-flex;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
  padding-left: 10px;
}
.mzic-bank-account .text .tag {
  font-size: 10px;
  line-height: 14px;
  color: var(--neutral-700);
  background-color: var(--purple-500);
  border-radius: 4px;
  padding: 3px 4px 1px;
  margin-bottom: 4px;
}
.mzic-bank-account .text .title {
  color: var(--neutral-100);
}
.mzic-bank-account .text p {
  color: var(--neutral-300);
}
.mzic-bank-account input[type=checkbox] {
  margin-right: 16px;
}
.mzic-bank-account .remove-btn:hover {
  background-color: var(--red-500);
}

.mzic-bank-account.dark {
  border-color: transparent;
  background-color: var(--neutral-600-50);
}

.mzic-checkbox {
  position: relative;
  display: inline-flex;
}
.mzic-checkbox input[type=checkbox] {
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 2;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
}
.mzic-checkbox input[type=checkbox].error + .fake-checkbox {
  border-color: var(--red-500);
}
.mzic-checkbox .fake-checkbox {
  position: relative;
  display: inline-flex;
  width: 16px;
  height: 16px;
  border: 1px solid var(--neutral-300);
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;
  background-size: 13px;
  background-position: center center;
  background-repeat: no-repeat;
}
.mzic-checkbox .fake-checkbox.checked {
  background-image: url("/assets/icons/mzic-check-mark-white.svg");
  background-color: var(--purple-500);
  border-color: var(--purple-500);
}

@media (max-width: 320px) {
  .hide-in-320 {
    display: none;
  }
}
@media (max-width: 321px) {
  .hide-in-321 {
    display: none;
  }
}
@media (max-width: 322px) {
  .hide-in-322 {
    display: none;
  }
}
@media (max-width: 323px) {
  .hide-in-323 {
    display: none;
  }
}
@media (max-width: 324px) {
  .hide-in-324 {
    display: none;
  }
}
@media (max-width: 325px) {
  .hide-in-325 {
    display: none;
  }
}
@media (max-width: 326px) {
  .hide-in-326 {
    display: none;
  }
}
@media (max-width: 327px) {
  .hide-in-327 {
    display: none;
  }
}
@media (max-width: 328px) {
  .hide-in-328 {
    display: none;
  }
}
@media (max-width: 329px) {
  .hide-in-329 {
    display: none;
  }
}
@media (max-width: 330px) {
  .hide-in-330 {
    display: none;
  }
}
@media (max-width: 331px) {
  .hide-in-331 {
    display: none;
  }
}
@media (max-width: 332px) {
  .hide-in-332 {
    display: none;
  }
}
@media (max-width: 333px) {
  .hide-in-333 {
    display: none;
  }
}
@media (max-width: 334px) {
  .hide-in-334 {
    display: none;
  }
}
@media (max-width: 335px) {
  .hide-in-335 {
    display: none;
  }
}
@media (max-width: 336px) {
  .hide-in-336 {
    display: none;
  }
}
@media (max-width: 337px) {
  .hide-in-337 {
    display: none;
  }
}
@media (max-width: 338px) {
  .hide-in-338 {
    display: none;
  }
}
@media (max-width: 339px) {
  .hide-in-339 {
    display: none;
  }
}
@media (max-width: 340px) {
  .hide-in-340 {
    display: none;
  }
}
@media (max-width: 341px) {
  .hide-in-341 {
    display: none;
  }
}
@media (max-width: 342px) {
  .hide-in-342 {
    display: none;
  }
}
@media (max-width: 343px) {
  .hide-in-343 {
    display: none;
  }
}
@media (max-width: 344px) {
  .hide-in-344 {
    display: none;
  }
}
@media (max-width: 345px) {
  .hide-in-345 {
    display: none;
  }
}
@media (max-width: 346px) {
  .hide-in-346 {
    display: none;
  }
}
@media (max-width: 347px) {
  .hide-in-347 {
    display: none;
  }
}
@media (max-width: 348px) {
  .hide-in-348 {
    display: none;
  }
}
@media (max-width: 349px) {
  .hide-in-349 {
    display: none;
  }
}
@media (max-width: 350px) {
  .hide-in-350 {
    display: none;
  }
}
@media (max-width: 351px) {
  .hide-in-351 {
    display: none;
  }
}
@media (max-width: 352px) {
  .hide-in-352 {
    display: none;
  }
}
@media (max-width: 353px) {
  .hide-in-353 {
    display: none;
  }
}
@media (max-width: 354px) {
  .hide-in-354 {
    display: none;
  }
}
@media (max-width: 355px) {
  .hide-in-355 {
    display: none;
  }
}
@media (max-width: 356px) {
  .hide-in-356 {
    display: none;
  }
}
@media (max-width: 357px) {
  .hide-in-357 {
    display: none;
  }
}
@media (max-width: 358px) {
  .hide-in-358 {
    display: none;
  }
}
@media (max-width: 359px) {
  .hide-in-359 {
    display: none;
  }
}
@media (max-width: 360px) {
  .hide-in-360 {
    display: none;
  }
}
@media (max-width: 361px) {
  .hide-in-361 {
    display: none;
  }
}
@media (max-width: 362px) {
  .hide-in-362 {
    display: none;
  }
}
@media (max-width: 363px) {
  .hide-in-363 {
    display: none;
  }
}
@media (max-width: 364px) {
  .hide-in-364 {
    display: none;
  }
}
@media (max-width: 365px) {
  .hide-in-365 {
    display: none;
  }
}
@media (max-width: 366px) {
  .hide-in-366 {
    display: none;
  }
}
@media (max-width: 367px) {
  .hide-in-367 {
    display: none;
  }
}
@media (max-width: 368px) {
  .hide-in-368 {
    display: none;
  }
}
@media (max-width: 369px) {
  .hide-in-369 {
    display: none;
  }
}
@media (max-width: 370px) {
  .hide-in-370 {
    display: none;
  }
}
@media (max-width: 371px) {
  .hide-in-371 {
    display: none;
  }
}
@media (max-width: 372px) {
  .hide-in-372 {
    display: none;
  }
}
@media (max-width: 373px) {
  .hide-in-373 {
    display: none;
  }
}
@media (max-width: 374px) {
  .hide-in-374 {
    display: none;
  }
}
@media (max-width: 375px) {
  .hide-in-375 {
    display: none;
  }
}
@media (max-width: 376px) {
  .hide-in-376 {
    display: none;
  }
}
@media (max-width: 377px) {
  .hide-in-377 {
    display: none;
  }
}
@media (max-width: 378px) {
  .hide-in-378 {
    display: none;
  }
}
@media (max-width: 379px) {
  .hide-in-379 {
    display: none;
  }
}
@media (max-width: 380px) {
  .hide-in-380 {
    display: none;
  }
}
@media (max-width: 381px) {
  .hide-in-381 {
    display: none;
  }
}
@media (max-width: 382px) {
  .hide-in-382 {
    display: none;
  }
}
@media (max-width: 383px) {
  .hide-in-383 {
    display: none;
  }
}
@media (max-width: 384px) {
  .hide-in-384 {
    display: none;
  }
}
@media (max-width: 385px) {
  .hide-in-385 {
    display: none;
  }
}
@media (max-width: 386px) {
  .hide-in-386 {
    display: none;
  }
}
@media (max-width: 387px) {
  .hide-in-387 {
    display: none;
  }
}
@media (max-width: 388px) {
  .hide-in-388 {
    display: none;
  }
}
@media (max-width: 389px) {
  .hide-in-389 {
    display: none;
  }
}
@media (max-width: 390px) {
  .hide-in-390 {
    display: none;
  }
}
@media (max-width: 391px) {
  .hide-in-391 {
    display: none;
  }
}
@media (max-width: 392px) {
  .hide-in-392 {
    display: none;
  }
}
@media (max-width: 393px) {
  .hide-in-393 {
    display: none;
  }
}
@media (max-width: 394px) {
  .hide-in-394 {
    display: none;
  }
}
@media (max-width: 395px) {
  .hide-in-395 {
    display: none;
  }
}
@media (max-width: 396px) {
  .hide-in-396 {
    display: none;
  }
}
@media (max-width: 397px) {
  .hide-in-397 {
    display: none;
  }
}
@media (max-width: 398px) {
  .hide-in-398 {
    display: none;
  }
}
@media (max-width: 399px) {
  .hide-in-399 {
    display: none;
  }
}
@media (max-width: 400px) {
  .hide-in-400 {
    display: none;
  }
}
@media (max-width: 401px) {
  .hide-in-401 {
    display: none;
  }
}
@media (max-width: 402px) {
  .hide-in-402 {
    display: none;
  }
}
@media (max-width: 403px) {
  .hide-in-403 {
    display: none;
  }
}
@media (max-width: 404px) {
  .hide-in-404 {
    display: none;
  }
}
@media (max-width: 405px) {
  .hide-in-405 {
    display: none;
  }
}
@media (max-width: 406px) {
  .hide-in-406 {
    display: none;
  }
}
@media (max-width: 407px) {
  .hide-in-407 {
    display: none;
  }
}
@media (max-width: 408px) {
  .hide-in-408 {
    display: none;
  }
}
@media (max-width: 409px) {
  .hide-in-409 {
    display: none;
  }
}
@media (max-width: 410px) {
  .hide-in-410 {
    display: none;
  }
}
@media (max-width: 411px) {
  .hide-in-411 {
    display: none;
  }
}
@media (max-width: 412px) {
  .hide-in-412 {
    display: none;
  }
}
@media (max-width: 413px) {
  .hide-in-413 {
    display: none;
  }
}
@media (max-width: 414px) {
  .hide-in-414 {
    display: none;
  }
}
@media (max-width: 415px) {
  .hide-in-415 {
    display: none;
  }
}
@media (max-width: 416px) {
  .hide-in-416 {
    display: none;
  }
}
@media (max-width: 417px) {
  .hide-in-417 {
    display: none;
  }
}
@media (max-width: 418px) {
  .hide-in-418 {
    display: none;
  }
}
@media (max-width: 419px) {
  .hide-in-419 {
    display: none;
  }
}
@media (max-width: 420px) {
  .hide-in-420 {
    display: none;
  }
}
@media (max-width: 421px) {
  .hide-in-421 {
    display: none;
  }
}
@media (max-width: 422px) {
  .hide-in-422 {
    display: none;
  }
}
@media (max-width: 423px) {
  .hide-in-423 {
    display: none;
  }
}
@media (max-width: 424px) {
  .hide-in-424 {
    display: none;
  }
}
@media (max-width: 425px) {
  .hide-in-425 {
    display: none;
  }
}
@media (max-width: 426px) {
  .hide-in-426 {
    display: none;
  }
}
@media (max-width: 427px) {
  .hide-in-427 {
    display: none;
  }
}
@media (max-width: 428px) {
  .hide-in-428 {
    display: none;
  }
}
@media (max-width: 429px) {
  .hide-in-429 {
    display: none;
  }
}
@media (max-width: 430px) {
  .hide-in-430 {
    display: none;
  }
}
@media (max-width: 431px) {
  .hide-in-431 {
    display: none;
  }
}
@media (max-width: 432px) {
  .hide-in-432 {
    display: none;
  }
}
@media (max-width: 433px) {
  .hide-in-433 {
    display: none;
  }
}
@media (max-width: 434px) {
  .hide-in-434 {
    display: none;
  }
}
@media (max-width: 435px) {
  .hide-in-435 {
    display: none;
  }
}
@media (max-width: 436px) {
  .hide-in-436 {
    display: none;
  }
}
@media (max-width: 437px) {
  .hide-in-437 {
    display: none;
  }
}
@media (max-width: 438px) {
  .hide-in-438 {
    display: none;
  }
}
@media (max-width: 439px) {
  .hide-in-439 {
    display: none;
  }
}
@media (max-width: 440px) {
  .hide-in-440 {
    display: none;
  }
}
@media (max-width: 441px) {
  .hide-in-441 {
    display: none;
  }
}
@media (max-width: 442px) {
  .hide-in-442 {
    display: none;
  }
}
@media (max-width: 443px) {
  .hide-in-443 {
    display: none;
  }
}
@media (max-width: 444px) {
  .hide-in-444 {
    display: none;
  }
}
@media (max-width: 445px) {
  .hide-in-445 {
    display: none;
  }
}
@media (max-width: 446px) {
  .hide-in-446 {
    display: none;
  }
}
@media (max-width: 447px) {
  .hide-in-447 {
    display: none;
  }
}
@media (max-width: 448px) {
  .hide-in-448 {
    display: none;
  }
}
@media (max-width: 449px) {
  .hide-in-449 {
    display: none;
  }
}
@media (max-width: 450px) {
  .hide-in-450 {
    display: none;
  }
}
@media (max-width: 451px) {
  .hide-in-451 {
    display: none;
  }
}
@media (max-width: 452px) {
  .hide-in-452 {
    display: none;
  }
}
@media (max-width: 453px) {
  .hide-in-453 {
    display: none;
  }
}
@media (max-width: 454px) {
  .hide-in-454 {
    display: none;
  }
}
@media (max-width: 455px) {
  .hide-in-455 {
    display: none;
  }
}
@media (max-width: 456px) {
  .hide-in-456 {
    display: none;
  }
}
@media (max-width: 457px) {
  .hide-in-457 {
    display: none;
  }
}
@media (max-width: 458px) {
  .hide-in-458 {
    display: none;
  }
}
@media (max-width: 459px) {
  .hide-in-459 {
    display: none;
  }
}
@media (max-width: 460px) {
  .hide-in-460 {
    display: none;
  }
}
@media (max-width: 461px) {
  .hide-in-461 {
    display: none;
  }
}
@media (max-width: 462px) {
  .hide-in-462 {
    display: none;
  }
}
@media (max-width: 463px) {
  .hide-in-463 {
    display: none;
  }
}
@media (max-width: 464px) {
  .hide-in-464 {
    display: none;
  }
}
@media (max-width: 465px) {
  .hide-in-465 {
    display: none;
  }
}
@media (max-width: 466px) {
  .hide-in-466 {
    display: none;
  }
}
@media (max-width: 467px) {
  .hide-in-467 {
    display: none;
  }
}
@media (max-width: 468px) {
  .hide-in-468 {
    display: none;
  }
}
@media (max-width: 469px) {
  .hide-in-469 {
    display: none;
  }
}
@media (max-width: 470px) {
  .hide-in-470 {
    display: none;
  }
}
@media (max-width: 471px) {
  .hide-in-471 {
    display: none;
  }
}
@media (max-width: 472px) {
  .hide-in-472 {
    display: none;
  }
}
@media (max-width: 473px) {
  .hide-in-473 {
    display: none;
  }
}
@media (max-width: 474px) {
  .hide-in-474 {
    display: none;
  }
}
@media (max-width: 475px) {
  .hide-in-475 {
    display: none;
  }
}
@media (max-width: 476px) {
  .hide-in-476 {
    display: none;
  }
}
@media (max-width: 477px) {
  .hide-in-477 {
    display: none;
  }
}
@media (max-width: 478px) {
  .hide-in-478 {
    display: none;
  }
}
@media (max-width: 479px) {
  .hide-in-479 {
    display: none;
  }
}
@media (max-width: 480px) {
  .hide-in-480 {
    display: none;
  }
}
@media (max-width: 481px) {
  .hide-in-481 {
    display: none;
  }
}
@media (max-width: 482px) {
  .hide-in-482 {
    display: none;
  }
}
@media (max-width: 483px) {
  .hide-in-483 {
    display: none;
  }
}
@media (max-width: 484px) {
  .hide-in-484 {
    display: none;
  }
}
@media (max-width: 485px) {
  .hide-in-485 {
    display: none;
  }
}
@media (max-width: 486px) {
  .hide-in-486 {
    display: none;
  }
}
@media (max-width: 487px) {
  .hide-in-487 {
    display: none;
  }
}
@media (max-width: 488px) {
  .hide-in-488 {
    display: none;
  }
}
@media (max-width: 489px) {
  .hide-in-489 {
    display: none;
  }
}
@media (max-width: 490px) {
  .hide-in-490 {
    display: none;
  }
}
@media (max-width: 491px) {
  .hide-in-491 {
    display: none;
  }
}
@media (max-width: 492px) {
  .hide-in-492 {
    display: none;
  }
}
@media (max-width: 493px) {
  .hide-in-493 {
    display: none;
  }
}
@media (max-width: 494px) {
  .hide-in-494 {
    display: none;
  }
}
@media (max-width: 495px) {
  .hide-in-495 {
    display: none;
  }
}
@media (max-width: 496px) {
  .hide-in-496 {
    display: none;
  }
}
@media (max-width: 497px) {
  .hide-in-497 {
    display: none;
  }
}
@media (max-width: 498px) {
  .hide-in-498 {
    display: none;
  }
}
@media (max-width: 499px) {
  .hide-in-499 {
    display: none;
  }
}
@media (max-width: 500px) {
  .hide-in-500 {
    display: none;
  }
}
@media (max-width: 501px) {
  .hide-in-501 {
    display: none;
  }
}
@media (max-width: 502px) {
  .hide-in-502 {
    display: none;
  }
}
@media (max-width: 503px) {
  .hide-in-503 {
    display: none;
  }
}
@media (max-width: 504px) {
  .hide-in-504 {
    display: none;
  }
}
@media (max-width: 505px) {
  .hide-in-505 {
    display: none;
  }
}
@media (max-width: 506px) {
  .hide-in-506 {
    display: none;
  }
}
@media (max-width: 507px) {
  .hide-in-507 {
    display: none;
  }
}
@media (max-width: 508px) {
  .hide-in-508 {
    display: none;
  }
}
@media (max-width: 509px) {
  .hide-in-509 {
    display: none;
  }
}
@media (max-width: 510px) {
  .hide-in-510 {
    display: none;
  }
}
@media (max-width: 511px) {
  .hide-in-511 {
    display: none;
  }
}
@media (max-width: 512px) {
  .hide-in-512 {
    display: none;
  }
}
@media (max-width: 513px) {
  .hide-in-513 {
    display: none;
  }
}
@media (max-width: 514px) {
  .hide-in-514 {
    display: none;
  }
}
@media (max-width: 515px) {
  .hide-in-515 {
    display: none;
  }
}
@media (max-width: 516px) {
  .hide-in-516 {
    display: none;
  }
}
@media (max-width: 517px) {
  .hide-in-517 {
    display: none;
  }
}
@media (max-width: 518px) {
  .hide-in-518 {
    display: none;
  }
}
@media (max-width: 519px) {
  .hide-in-519 {
    display: none;
  }
}
@media (max-width: 520px) {
  .hide-in-520 {
    display: none;
  }
}
@media (max-width: 521px) {
  .hide-in-521 {
    display: none;
  }
}
@media (max-width: 522px) {
  .hide-in-522 {
    display: none;
  }
}
@media (max-width: 523px) {
  .hide-in-523 {
    display: none;
  }
}
@media (max-width: 524px) {
  .hide-in-524 {
    display: none;
  }
}
@media (max-width: 525px) {
  .hide-in-525 {
    display: none;
  }
}
@media (max-width: 526px) {
  .hide-in-526 {
    display: none;
  }
}
@media (max-width: 527px) {
  .hide-in-527 {
    display: none;
  }
}
@media (max-width: 528px) {
  .hide-in-528 {
    display: none;
  }
}
@media (max-width: 529px) {
  .hide-in-529 {
    display: none;
  }
}
@media (max-width: 530px) {
  .hide-in-530 {
    display: none;
  }
}
@media (max-width: 531px) {
  .hide-in-531 {
    display: none;
  }
}
@media (max-width: 532px) {
  .hide-in-532 {
    display: none;
  }
}
@media (max-width: 533px) {
  .hide-in-533 {
    display: none;
  }
}
@media (max-width: 534px) {
  .hide-in-534 {
    display: none;
  }
}
@media (max-width: 535px) {
  .hide-in-535 {
    display: none;
  }
}
@media (max-width: 536px) {
  .hide-in-536 {
    display: none;
  }
}
@media (max-width: 537px) {
  .hide-in-537 {
    display: none;
  }
}
@media (max-width: 538px) {
  .hide-in-538 {
    display: none;
  }
}
@media (max-width: 539px) {
  .hide-in-539 {
    display: none;
  }
}
@media (max-width: 540px) {
  .hide-in-540 {
    display: none;
  }
}
@media (max-width: 541px) {
  .hide-in-541 {
    display: none;
  }
}
@media (max-width: 542px) {
  .hide-in-542 {
    display: none;
  }
}
@media (max-width: 543px) {
  .hide-in-543 {
    display: none;
  }
}
@media (max-width: 544px) {
  .hide-in-544 {
    display: none;
  }
}
@media (max-width: 545px) {
  .hide-in-545 {
    display: none;
  }
}
@media (max-width: 546px) {
  .hide-in-546 {
    display: none;
  }
}
@media (max-width: 547px) {
  .hide-in-547 {
    display: none;
  }
}
@media (max-width: 548px) {
  .hide-in-548 {
    display: none;
  }
}
@media (max-width: 549px) {
  .hide-in-549 {
    display: none;
  }
}
@media (max-width: 550px) {
  .hide-in-550 {
    display: none;
  }
}
@media (max-width: 551px) {
  .hide-in-551 {
    display: none;
  }
}
@media (max-width: 552px) {
  .hide-in-552 {
    display: none;
  }
}
@media (max-width: 553px) {
  .hide-in-553 {
    display: none;
  }
}
@media (max-width: 554px) {
  .hide-in-554 {
    display: none;
  }
}
@media (max-width: 555px) {
  .hide-in-555 {
    display: none;
  }
}
@media (max-width: 556px) {
  .hide-in-556 {
    display: none;
  }
}
@media (max-width: 557px) {
  .hide-in-557 {
    display: none;
  }
}
@media (max-width: 558px) {
  .hide-in-558 {
    display: none;
  }
}
@media (max-width: 559px) {
  .hide-in-559 {
    display: none;
  }
}
@media (max-width: 560px) {
  .hide-in-560 {
    display: none;
  }
}
@media (max-width: 561px) {
  .hide-in-561 {
    display: none;
  }
}
@media (max-width: 562px) {
  .hide-in-562 {
    display: none;
  }
}
@media (max-width: 563px) {
  .hide-in-563 {
    display: none;
  }
}
@media (max-width: 564px) {
  .hide-in-564 {
    display: none;
  }
}
@media (max-width: 565px) {
  .hide-in-565 {
    display: none;
  }
}
@media (max-width: 566px) {
  .hide-in-566 {
    display: none;
  }
}
@media (max-width: 567px) {
  .hide-in-567 {
    display: none;
  }
}
@media (max-width: 568px) {
  .hide-in-568 {
    display: none;
  }
}
@media (max-width: 569px) {
  .hide-in-569 {
    display: none;
  }
}
@media (max-width: 570px) {
  .hide-in-570 {
    display: none;
  }
}
@media (max-width: 571px) {
  .hide-in-571 {
    display: none;
  }
}
@media (max-width: 572px) {
  .hide-in-572 {
    display: none;
  }
}
@media (max-width: 573px) {
  .hide-in-573 {
    display: none;
  }
}
@media (max-width: 574px) {
  .hide-in-574 {
    display: none;
  }
}
@media (max-width: 575px) {
  .hide-in-575 {
    display: none;
  }
}
@media (max-width: 576px) {
  .hide-in-576 {
    display: none;
  }
}
@media (max-width: 577px) {
  .hide-in-577 {
    display: none;
  }
}
@media (max-width: 578px) {
  .hide-in-578 {
    display: none;
  }
}
@media (max-width: 579px) {
  .hide-in-579 {
    display: none;
  }
}
@media (max-width: 580px) {
  .hide-in-580 {
    display: none;
  }
}
@media (max-width: 581px) {
  .hide-in-581 {
    display: none;
  }
}
@media (max-width: 582px) {
  .hide-in-582 {
    display: none;
  }
}
@media (max-width: 583px) {
  .hide-in-583 {
    display: none;
  }
}
@media (max-width: 584px) {
  .hide-in-584 {
    display: none;
  }
}
@media (max-width: 585px) {
  .hide-in-585 {
    display: none;
  }
}
@media (max-width: 586px) {
  .hide-in-586 {
    display: none;
  }
}
@media (max-width: 587px) {
  .hide-in-587 {
    display: none;
  }
}
@media (max-width: 588px) {
  .hide-in-588 {
    display: none;
  }
}
@media (max-width: 589px) {
  .hide-in-589 {
    display: none;
  }
}
@media (max-width: 590px) {
  .hide-in-590 {
    display: none;
  }
}
@media (max-width: 591px) {
  .hide-in-591 {
    display: none;
  }
}
@media (max-width: 592px) {
  .hide-in-592 {
    display: none;
  }
}
@media (max-width: 593px) {
  .hide-in-593 {
    display: none;
  }
}
@media (max-width: 594px) {
  .hide-in-594 {
    display: none;
  }
}
@media (max-width: 595px) {
  .hide-in-595 {
    display: none;
  }
}
@media (max-width: 596px) {
  .hide-in-596 {
    display: none;
  }
}
@media (max-width: 597px) {
  .hide-in-597 {
    display: none;
  }
}
@media (max-width: 598px) {
  .hide-in-598 {
    display: none;
  }
}
@media (max-width: 599px) {
  .hide-in-599 {
    display: none;
  }
}
@media (max-width: 600px) {
  .hide-in-600 {
    display: none;
  }
}
@media (max-width: 601px) {
  .hide-in-601 {
    display: none;
  }
}
@media (max-width: 602px) {
  .hide-in-602 {
    display: none;
  }
}
@media (max-width: 603px) {
  .hide-in-603 {
    display: none;
  }
}
@media (max-width: 604px) {
  .hide-in-604 {
    display: none;
  }
}
@media (max-width: 605px) {
  .hide-in-605 {
    display: none;
  }
}
@media (max-width: 606px) {
  .hide-in-606 {
    display: none;
  }
}
@media (max-width: 607px) {
  .hide-in-607 {
    display: none;
  }
}
@media (max-width: 608px) {
  .hide-in-608 {
    display: none;
  }
}
@media (max-width: 609px) {
  .hide-in-609 {
    display: none;
  }
}
@media (max-width: 610px) {
  .hide-in-610 {
    display: none;
  }
}
@media (max-width: 611px) {
  .hide-in-611 {
    display: none;
  }
}
@media (max-width: 612px) {
  .hide-in-612 {
    display: none;
  }
}
@media (max-width: 613px) {
  .hide-in-613 {
    display: none;
  }
}
@media (max-width: 614px) {
  .hide-in-614 {
    display: none;
  }
}
@media (max-width: 615px) {
  .hide-in-615 {
    display: none;
  }
}
@media (max-width: 616px) {
  .hide-in-616 {
    display: none;
  }
}
@media (max-width: 617px) {
  .hide-in-617 {
    display: none;
  }
}
@media (max-width: 618px) {
  .hide-in-618 {
    display: none;
  }
}
@media (max-width: 619px) {
  .hide-in-619 {
    display: none;
  }
}
@media (max-width: 620px) {
  .hide-in-620 {
    display: none;
  }
}
@media (max-width: 621px) {
  .hide-in-621 {
    display: none;
  }
}
@media (max-width: 622px) {
  .hide-in-622 {
    display: none;
  }
}
@media (max-width: 623px) {
  .hide-in-623 {
    display: none;
  }
}
@media (max-width: 624px) {
  .hide-in-624 {
    display: none;
  }
}
@media (max-width: 625px) {
  .hide-in-625 {
    display: none;
  }
}
@media (max-width: 626px) {
  .hide-in-626 {
    display: none;
  }
}
@media (max-width: 627px) {
  .hide-in-627 {
    display: none;
  }
}
@media (max-width: 628px) {
  .hide-in-628 {
    display: none;
  }
}
@media (max-width: 629px) {
  .hide-in-629 {
    display: none;
  }
}
@media (max-width: 630px) {
  .hide-in-630 {
    display: none;
  }
}
@media (max-width: 631px) {
  .hide-in-631 {
    display: none;
  }
}
@media (max-width: 632px) {
  .hide-in-632 {
    display: none;
  }
}
@media (max-width: 633px) {
  .hide-in-633 {
    display: none;
  }
}
@media (max-width: 634px) {
  .hide-in-634 {
    display: none;
  }
}
@media (max-width: 635px) {
  .hide-in-635 {
    display: none;
  }
}
@media (max-width: 636px) {
  .hide-in-636 {
    display: none;
  }
}
@media (max-width: 637px) {
  .hide-in-637 {
    display: none;
  }
}
@media (max-width: 638px) {
  .hide-in-638 {
    display: none;
  }
}
@media (max-width: 639px) {
  .hide-in-639 {
    display: none;
  }
}
@media (max-width: 640px) {
  .hide-in-640 {
    display: none;
  }
}
@media (max-width: 641px) {
  .hide-in-641 {
    display: none;
  }
}
@media (max-width: 642px) {
  .hide-in-642 {
    display: none;
  }
}
@media (max-width: 643px) {
  .hide-in-643 {
    display: none;
  }
}
@media (max-width: 644px) {
  .hide-in-644 {
    display: none;
  }
}
@media (max-width: 645px) {
  .hide-in-645 {
    display: none;
  }
}
@media (max-width: 646px) {
  .hide-in-646 {
    display: none;
  }
}
@media (max-width: 647px) {
  .hide-in-647 {
    display: none;
  }
}
@media (max-width: 648px) {
  .hide-in-648 {
    display: none;
  }
}
@media (max-width: 649px) {
  .hide-in-649 {
    display: none;
  }
}
@media (max-width: 650px) {
  .hide-in-650 {
    display: none;
  }
}
@media (max-width: 651px) {
  .hide-in-651 {
    display: none;
  }
}
@media (max-width: 652px) {
  .hide-in-652 {
    display: none;
  }
}
@media (max-width: 653px) {
  .hide-in-653 {
    display: none;
  }
}
@media (max-width: 654px) {
  .hide-in-654 {
    display: none;
  }
}
@media (max-width: 655px) {
  .hide-in-655 {
    display: none;
  }
}
@media (max-width: 656px) {
  .hide-in-656 {
    display: none;
  }
}
@media (max-width: 657px) {
  .hide-in-657 {
    display: none;
  }
}
@media (max-width: 658px) {
  .hide-in-658 {
    display: none;
  }
}
@media (max-width: 659px) {
  .hide-in-659 {
    display: none;
  }
}
@media (max-width: 660px) {
  .hide-in-660 {
    display: none;
  }
}
@media (max-width: 661px) {
  .hide-in-661 {
    display: none;
  }
}
@media (max-width: 662px) {
  .hide-in-662 {
    display: none;
  }
}
@media (max-width: 663px) {
  .hide-in-663 {
    display: none;
  }
}
@media (max-width: 664px) {
  .hide-in-664 {
    display: none;
  }
}
@media (max-width: 665px) {
  .hide-in-665 {
    display: none;
  }
}
@media (max-width: 666px) {
  .hide-in-666 {
    display: none;
  }
}
@media (max-width: 667px) {
  .hide-in-667 {
    display: none;
  }
}
@media (max-width: 668px) {
  .hide-in-668 {
    display: none;
  }
}
@media (max-width: 669px) {
  .hide-in-669 {
    display: none;
  }
}
@media (max-width: 670px) {
  .hide-in-670 {
    display: none;
  }
}
@media (max-width: 671px) {
  .hide-in-671 {
    display: none;
  }
}
@media (max-width: 672px) {
  .hide-in-672 {
    display: none;
  }
}
@media (max-width: 673px) {
  .hide-in-673 {
    display: none;
  }
}
@media (max-width: 674px) {
  .hide-in-674 {
    display: none;
  }
}
@media (max-width: 675px) {
  .hide-in-675 {
    display: none;
  }
}
@media (max-width: 676px) {
  .hide-in-676 {
    display: none;
  }
}
@media (max-width: 677px) {
  .hide-in-677 {
    display: none;
  }
}
@media (max-width: 678px) {
  .hide-in-678 {
    display: none;
  }
}
@media (max-width: 679px) {
  .hide-in-679 {
    display: none;
  }
}
@media (max-width: 680px) {
  .hide-in-680 {
    display: none;
  }
}
@media (max-width: 681px) {
  .hide-in-681 {
    display: none;
  }
}
@media (max-width: 682px) {
  .hide-in-682 {
    display: none;
  }
}
@media (max-width: 683px) {
  .hide-in-683 {
    display: none;
  }
}
@media (max-width: 684px) {
  .hide-in-684 {
    display: none;
  }
}
@media (max-width: 685px) {
  .hide-in-685 {
    display: none;
  }
}
@media (max-width: 686px) {
  .hide-in-686 {
    display: none;
  }
}
@media (max-width: 687px) {
  .hide-in-687 {
    display: none;
  }
}
@media (max-width: 688px) {
  .hide-in-688 {
    display: none;
  }
}
@media (max-width: 689px) {
  .hide-in-689 {
    display: none;
  }
}
@media (max-width: 690px) {
  .hide-in-690 {
    display: none;
  }
}
@media (max-width: 691px) {
  .hide-in-691 {
    display: none;
  }
}
@media (max-width: 692px) {
  .hide-in-692 {
    display: none;
  }
}
@media (max-width: 693px) {
  .hide-in-693 {
    display: none;
  }
}
@media (max-width: 694px) {
  .hide-in-694 {
    display: none;
  }
}
@media (max-width: 695px) {
  .hide-in-695 {
    display: none;
  }
}
@media (max-width: 696px) {
  .hide-in-696 {
    display: none;
  }
}
@media (max-width: 697px) {
  .hide-in-697 {
    display: none;
  }
}
@media (max-width: 698px) {
  .hide-in-698 {
    display: none;
  }
}
@media (max-width: 699px) {
  .hide-in-699 {
    display: none;
  }
}
@media (max-width: 700px) {
  .hide-in-700 {
    display: none;
  }
}
@media (max-width: 701px) {
  .hide-in-701 {
    display: none;
  }
}
@media (max-width: 702px) {
  .hide-in-702 {
    display: none;
  }
}
@media (max-width: 703px) {
  .hide-in-703 {
    display: none;
  }
}
@media (max-width: 704px) {
  .hide-in-704 {
    display: none;
  }
}
@media (max-width: 705px) {
  .hide-in-705 {
    display: none;
  }
}
@media (max-width: 706px) {
  .hide-in-706 {
    display: none;
  }
}
@media (max-width: 707px) {
  .hide-in-707 {
    display: none;
  }
}
@media (max-width: 708px) {
  .hide-in-708 {
    display: none;
  }
}
@media (max-width: 709px) {
  .hide-in-709 {
    display: none;
  }
}
@media (max-width: 710px) {
  .hide-in-710 {
    display: none;
  }
}
@media (max-width: 711px) {
  .hide-in-711 {
    display: none;
  }
}
@media (max-width: 712px) {
  .hide-in-712 {
    display: none;
  }
}
@media (max-width: 713px) {
  .hide-in-713 {
    display: none;
  }
}
@media (max-width: 714px) {
  .hide-in-714 {
    display: none;
  }
}
@media (max-width: 715px) {
  .hide-in-715 {
    display: none;
  }
}
@media (max-width: 716px) {
  .hide-in-716 {
    display: none;
  }
}
@media (max-width: 717px) {
  .hide-in-717 {
    display: none;
  }
}
@media (max-width: 718px) {
  .hide-in-718 {
    display: none;
  }
}
@media (max-width: 719px) {
  .hide-in-719 {
    display: none;
  }
}
@media (max-width: 720px) {
  .hide-in-720 {
    display: none;
  }
}
@media (max-width: 721px) {
  .hide-in-721 {
    display: none;
  }
}
@media (max-width: 722px) {
  .hide-in-722 {
    display: none;
  }
}
@media (max-width: 723px) {
  .hide-in-723 {
    display: none;
  }
}
@media (max-width: 724px) {
  .hide-in-724 {
    display: none;
  }
}
@media (max-width: 725px) {
  .hide-in-725 {
    display: none;
  }
}
@media (max-width: 726px) {
  .hide-in-726 {
    display: none;
  }
}
@media (max-width: 727px) {
  .hide-in-727 {
    display: none;
  }
}
@media (max-width: 728px) {
  .hide-in-728 {
    display: none;
  }
}
@media (max-width: 729px) {
  .hide-in-729 {
    display: none;
  }
}
@media (max-width: 730px) {
  .hide-in-730 {
    display: none;
  }
}
@media (max-width: 731px) {
  .hide-in-731 {
    display: none;
  }
}
@media (max-width: 732px) {
  .hide-in-732 {
    display: none;
  }
}
@media (max-width: 733px) {
  .hide-in-733 {
    display: none;
  }
}
@media (max-width: 734px) {
  .hide-in-734 {
    display: none;
  }
}
@media (max-width: 735px) {
  .hide-in-735 {
    display: none;
  }
}
@media (max-width: 736px) {
  .hide-in-736 {
    display: none;
  }
}
@media (max-width: 737px) {
  .hide-in-737 {
    display: none;
  }
}
@media (max-width: 738px) {
  .hide-in-738 {
    display: none;
  }
}
@media (max-width: 739px) {
  .hide-in-739 {
    display: none;
  }
}
@media (max-width: 740px) {
  .hide-in-740 {
    display: none;
  }
}
@media (max-width: 741px) {
  .hide-in-741 {
    display: none;
  }
}
@media (max-width: 742px) {
  .hide-in-742 {
    display: none;
  }
}
@media (max-width: 743px) {
  .hide-in-743 {
    display: none;
  }
}
@media (max-width: 744px) {
  .hide-in-744 {
    display: none;
  }
}
@media (max-width: 745px) {
  .hide-in-745 {
    display: none;
  }
}
@media (max-width: 746px) {
  .hide-in-746 {
    display: none;
  }
}
@media (max-width: 747px) {
  .hide-in-747 {
    display: none;
  }
}
@media (max-width: 748px) {
  .hide-in-748 {
    display: none;
  }
}
@media (max-width: 749px) {
  .hide-in-749 {
    display: none;
  }
}
@media (max-width: 750px) {
  .hide-in-750 {
    display: none;
  }
}
@media (max-width: 751px) {
  .hide-in-751 {
    display: none;
  }
}
@media (max-width: 752px) {
  .hide-in-752 {
    display: none;
  }
}
@media (max-width: 753px) {
  .hide-in-753 {
    display: none;
  }
}
@media (max-width: 754px) {
  .hide-in-754 {
    display: none;
  }
}
@media (max-width: 755px) {
  .hide-in-755 {
    display: none;
  }
}
@media (max-width: 756px) {
  .hide-in-756 {
    display: none;
  }
}
@media (max-width: 757px) {
  .hide-in-757 {
    display: none;
  }
}
@media (max-width: 758px) {
  .hide-in-758 {
    display: none;
  }
}
@media (max-width: 759px) {
  .hide-in-759 {
    display: none;
  }
}
@media (max-width: 760px) {
  .hide-in-760 {
    display: none;
  }
}
@media (max-width: 761px) {
  .hide-in-761 {
    display: none;
  }
}
@media (max-width: 762px) {
  .hide-in-762 {
    display: none;
  }
}
@media (max-width: 763px) {
  .hide-in-763 {
    display: none;
  }
}
@media (max-width: 764px) {
  .hide-in-764 {
    display: none;
  }
}
@media (max-width: 765px) {
  .hide-in-765 {
    display: none;
  }
}
@media (max-width: 766px) {
  .hide-in-766 {
    display: none;
  }
}
@media (max-width: 767px) {
  .hide-in-767 {
    display: none;
  }
}
@media (max-width: 768px) {
  .hide-in-768 {
    display: none;
  }
}
@media (max-width: 769px) {
  .hide-in-769 {
    display: none;
  }
}
@media (max-width: 770px) {
  .hide-in-770 {
    display: none;
  }
}
@media (max-width: 771px) {
  .hide-in-771 {
    display: none;
  }
}
@media (max-width: 772px) {
  .hide-in-772 {
    display: none;
  }
}
@media (max-width: 773px) {
  .hide-in-773 {
    display: none;
  }
}
@media (max-width: 774px) {
  .hide-in-774 {
    display: none;
  }
}
@media (max-width: 775px) {
  .hide-in-775 {
    display: none;
  }
}
@media (max-width: 776px) {
  .hide-in-776 {
    display: none;
  }
}
@media (max-width: 777px) {
  .hide-in-777 {
    display: none;
  }
}
@media (max-width: 778px) {
  .hide-in-778 {
    display: none;
  }
}
@media (max-width: 779px) {
  .hide-in-779 {
    display: none;
  }
}
@media (max-width: 780px) {
  .hide-in-780 {
    display: none;
  }
}
@media (max-width: 781px) {
  .hide-in-781 {
    display: none;
  }
}
@media (max-width: 782px) {
  .hide-in-782 {
    display: none;
  }
}
@media (max-width: 783px) {
  .hide-in-783 {
    display: none;
  }
}
@media (max-width: 784px) {
  .hide-in-784 {
    display: none;
  }
}
@media (max-width: 785px) {
  .hide-in-785 {
    display: none;
  }
}
@media (max-width: 786px) {
  .hide-in-786 {
    display: none;
  }
}
@media (max-width: 787px) {
  .hide-in-787 {
    display: none;
  }
}
@media (max-width: 788px) {
  .hide-in-788 {
    display: none;
  }
}
@media (max-width: 789px) {
  .hide-in-789 {
    display: none;
  }
}
@media (max-width: 790px) {
  .hide-in-790 {
    display: none;
  }
}
@media (max-width: 791px) {
  .hide-in-791 {
    display: none;
  }
}
@media (max-width: 792px) {
  .hide-in-792 {
    display: none;
  }
}
@media (max-width: 793px) {
  .hide-in-793 {
    display: none;
  }
}
@media (max-width: 794px) {
  .hide-in-794 {
    display: none;
  }
}
@media (max-width: 795px) {
  .hide-in-795 {
    display: none;
  }
}
@media (max-width: 796px) {
  .hide-in-796 {
    display: none;
  }
}
@media (max-width: 797px) {
  .hide-in-797 {
    display: none;
  }
}
@media (max-width: 798px) {
  .hide-in-798 {
    display: none;
  }
}
@media (max-width: 799px) {
  .hide-in-799 {
    display: none;
  }
}
@media (max-width: 800px) {
  .hide-in-800 {
    display: none;
  }
}
@media (max-width: 801px) {
  .hide-in-801 {
    display: none;
  }
}
@media (max-width: 802px) {
  .hide-in-802 {
    display: none;
  }
}
@media (max-width: 803px) {
  .hide-in-803 {
    display: none;
  }
}
@media (max-width: 804px) {
  .hide-in-804 {
    display: none;
  }
}
@media (max-width: 805px) {
  .hide-in-805 {
    display: none;
  }
}
@media (max-width: 806px) {
  .hide-in-806 {
    display: none;
  }
}
@media (max-width: 807px) {
  .hide-in-807 {
    display: none;
  }
}
@media (max-width: 808px) {
  .hide-in-808 {
    display: none;
  }
}
@media (max-width: 809px) {
  .hide-in-809 {
    display: none;
  }
}
@media (max-width: 810px) {
  .hide-in-810 {
    display: none;
  }
}
@media (max-width: 811px) {
  .hide-in-811 {
    display: none;
  }
}
@media (max-width: 812px) {
  .hide-in-812 {
    display: none;
  }
}
@media (max-width: 813px) {
  .hide-in-813 {
    display: none;
  }
}
@media (max-width: 814px) {
  .hide-in-814 {
    display: none;
  }
}
@media (max-width: 815px) {
  .hide-in-815 {
    display: none;
  }
}
@media (max-width: 816px) {
  .hide-in-816 {
    display: none;
  }
}
@media (max-width: 817px) {
  .hide-in-817 {
    display: none;
  }
}
@media (max-width: 818px) {
  .hide-in-818 {
    display: none;
  }
}
@media (max-width: 819px) {
  .hide-in-819 {
    display: none;
  }
}
@media (max-width: 820px) {
  .hide-in-820 {
    display: none;
  }
}
@media (max-width: 821px) {
  .hide-in-821 {
    display: none;
  }
}
@media (max-width: 822px) {
  .hide-in-822 {
    display: none;
  }
}
@media (max-width: 823px) {
  .hide-in-823 {
    display: none;
  }
}
@media (max-width: 824px) {
  .hide-in-824 {
    display: none;
  }
}
@media (max-width: 825px) {
  .hide-in-825 {
    display: none;
  }
}
@media (max-width: 826px) {
  .hide-in-826 {
    display: none;
  }
}
@media (max-width: 827px) {
  .hide-in-827 {
    display: none;
  }
}
@media (max-width: 828px) {
  .hide-in-828 {
    display: none;
  }
}
@media (max-width: 829px) {
  .hide-in-829 {
    display: none;
  }
}
@media (max-width: 830px) {
  .hide-in-830 {
    display: none;
  }
}
@media (max-width: 831px) {
  .hide-in-831 {
    display: none;
  }
}
@media (max-width: 832px) {
  .hide-in-832 {
    display: none;
  }
}
@media (max-width: 833px) {
  .hide-in-833 {
    display: none;
  }
}
@media (max-width: 834px) {
  .hide-in-834 {
    display: none;
  }
}
@media (max-width: 835px) {
  .hide-in-835 {
    display: none;
  }
}
@media (max-width: 836px) {
  .hide-in-836 {
    display: none;
  }
}
@media (max-width: 837px) {
  .hide-in-837 {
    display: none;
  }
}
@media (max-width: 838px) {
  .hide-in-838 {
    display: none;
  }
}
@media (max-width: 839px) {
  .hide-in-839 {
    display: none;
  }
}
@media (max-width: 840px) {
  .hide-in-840 {
    display: none;
  }
}
@media (max-width: 841px) {
  .hide-in-841 {
    display: none;
  }
}
@media (max-width: 842px) {
  .hide-in-842 {
    display: none;
  }
}
@media (max-width: 843px) {
  .hide-in-843 {
    display: none;
  }
}
@media (max-width: 844px) {
  .hide-in-844 {
    display: none;
  }
}
@media (max-width: 845px) {
  .hide-in-845 {
    display: none;
  }
}
@media (max-width: 846px) {
  .hide-in-846 {
    display: none;
  }
}
@media (max-width: 847px) {
  .hide-in-847 {
    display: none;
  }
}
@media (max-width: 848px) {
  .hide-in-848 {
    display: none;
  }
}
@media (max-width: 849px) {
  .hide-in-849 {
    display: none;
  }
}
@media (max-width: 850px) {
  .hide-in-850 {
    display: none;
  }
}
@media (max-width: 851px) {
  .hide-in-851 {
    display: none;
  }
}
@media (max-width: 852px) {
  .hide-in-852 {
    display: none;
  }
}
@media (max-width: 853px) {
  .hide-in-853 {
    display: none;
  }
}
@media (max-width: 854px) {
  .hide-in-854 {
    display: none;
  }
}
@media (max-width: 855px) {
  .hide-in-855 {
    display: none;
  }
}
@media (max-width: 856px) {
  .hide-in-856 {
    display: none;
  }
}
@media (max-width: 857px) {
  .hide-in-857 {
    display: none;
  }
}
@media (max-width: 858px) {
  .hide-in-858 {
    display: none;
  }
}
@media (max-width: 859px) {
  .hide-in-859 {
    display: none;
  }
}
@media (max-width: 860px) {
  .hide-in-860 {
    display: none;
  }
}
@media (max-width: 861px) {
  .hide-in-861 {
    display: none;
  }
}
@media (max-width: 862px) {
  .hide-in-862 {
    display: none;
  }
}
@media (max-width: 863px) {
  .hide-in-863 {
    display: none;
  }
}
@media (max-width: 864px) {
  .hide-in-864 {
    display: none;
  }
}
@media (max-width: 865px) {
  .hide-in-865 {
    display: none;
  }
}
@media (max-width: 866px) {
  .hide-in-866 {
    display: none;
  }
}
@media (max-width: 867px) {
  .hide-in-867 {
    display: none;
  }
}
@media (max-width: 868px) {
  .hide-in-868 {
    display: none;
  }
}
@media (max-width: 869px) {
  .hide-in-869 {
    display: none;
  }
}
@media (max-width: 870px) {
  .hide-in-870 {
    display: none;
  }
}
@media (max-width: 871px) {
  .hide-in-871 {
    display: none;
  }
}
@media (max-width: 872px) {
  .hide-in-872 {
    display: none;
  }
}
@media (max-width: 873px) {
  .hide-in-873 {
    display: none;
  }
}
@media (max-width: 874px) {
  .hide-in-874 {
    display: none;
  }
}
@media (max-width: 875px) {
  .hide-in-875 {
    display: none;
  }
}
@media (max-width: 876px) {
  .hide-in-876 {
    display: none;
  }
}
@media (max-width: 877px) {
  .hide-in-877 {
    display: none;
  }
}
@media (max-width: 878px) {
  .hide-in-878 {
    display: none;
  }
}
@media (max-width: 879px) {
  .hide-in-879 {
    display: none;
  }
}
@media (max-width: 880px) {
  .hide-in-880 {
    display: none;
  }
}
@media (max-width: 881px) {
  .hide-in-881 {
    display: none;
  }
}
@media (max-width: 882px) {
  .hide-in-882 {
    display: none;
  }
}
@media (max-width: 883px) {
  .hide-in-883 {
    display: none;
  }
}
@media (max-width: 884px) {
  .hide-in-884 {
    display: none;
  }
}
@media (max-width: 885px) {
  .hide-in-885 {
    display: none;
  }
}
@media (max-width: 886px) {
  .hide-in-886 {
    display: none;
  }
}
@media (max-width: 887px) {
  .hide-in-887 {
    display: none;
  }
}
@media (max-width: 888px) {
  .hide-in-888 {
    display: none;
  }
}
@media (max-width: 889px) {
  .hide-in-889 {
    display: none;
  }
}
@media (max-width: 890px) {
  .hide-in-890 {
    display: none;
  }
}
@media (max-width: 891px) {
  .hide-in-891 {
    display: none;
  }
}
@media (max-width: 892px) {
  .hide-in-892 {
    display: none;
  }
}
@media (max-width: 893px) {
  .hide-in-893 {
    display: none;
  }
}
@media (max-width: 894px) {
  .hide-in-894 {
    display: none;
  }
}
@media (max-width: 895px) {
  .hide-in-895 {
    display: none;
  }
}
@media (max-width: 896px) {
  .hide-in-896 {
    display: none;
  }
}
@media (max-width: 897px) {
  .hide-in-897 {
    display: none;
  }
}
@media (max-width: 898px) {
  .hide-in-898 {
    display: none;
  }
}
@media (max-width: 899px) {
  .hide-in-899 {
    display: none;
  }
}
@media (max-width: 900px) {
  .hide-in-900 {
    display: none;
  }
}
@media (max-width: 901px) {
  .hide-in-901 {
    display: none;
  }
}
@media (max-width: 902px) {
  .hide-in-902 {
    display: none;
  }
}
@media (max-width: 903px) {
  .hide-in-903 {
    display: none;
  }
}
@media (max-width: 904px) {
  .hide-in-904 {
    display: none;
  }
}
@media (max-width: 905px) {
  .hide-in-905 {
    display: none;
  }
}
@media (max-width: 906px) {
  .hide-in-906 {
    display: none;
  }
}
@media (max-width: 907px) {
  .hide-in-907 {
    display: none;
  }
}
@media (max-width: 908px) {
  .hide-in-908 {
    display: none;
  }
}
@media (max-width: 909px) {
  .hide-in-909 {
    display: none;
  }
}
@media (max-width: 910px) {
  .hide-in-910 {
    display: none;
  }
}
@media (max-width: 911px) {
  .hide-in-911 {
    display: none;
  }
}
@media (max-width: 912px) {
  .hide-in-912 {
    display: none;
  }
}
@media (max-width: 913px) {
  .hide-in-913 {
    display: none;
  }
}
@media (max-width: 914px) {
  .hide-in-914 {
    display: none;
  }
}
@media (max-width: 915px) {
  .hide-in-915 {
    display: none;
  }
}
@media (max-width: 916px) {
  .hide-in-916 {
    display: none;
  }
}
@media (max-width: 917px) {
  .hide-in-917 {
    display: none;
  }
}
@media (max-width: 918px) {
  .hide-in-918 {
    display: none;
  }
}
@media (max-width: 919px) {
  .hide-in-919 {
    display: none;
  }
}
@media (max-width: 920px) {
  .hide-in-920 {
    display: none;
  }
}
@media (max-width: 921px) {
  .hide-in-921 {
    display: none;
  }
}
@media (max-width: 922px) {
  .hide-in-922 {
    display: none;
  }
}
@media (max-width: 923px) {
  .hide-in-923 {
    display: none;
  }
}
@media (max-width: 924px) {
  .hide-in-924 {
    display: none;
  }
}
@media (max-width: 925px) {
  .hide-in-925 {
    display: none;
  }
}
@media (max-width: 926px) {
  .hide-in-926 {
    display: none;
  }
}
@media (max-width: 927px) {
  .hide-in-927 {
    display: none;
  }
}
@media (max-width: 928px) {
  .hide-in-928 {
    display: none;
  }
}
@media (max-width: 929px) {
  .hide-in-929 {
    display: none;
  }
}
@media (max-width: 930px) {
  .hide-in-930 {
    display: none;
  }
}
@media (max-width: 931px) {
  .hide-in-931 {
    display: none;
  }
}
@media (max-width: 932px) {
  .hide-in-932 {
    display: none;
  }
}
@media (max-width: 933px) {
  .hide-in-933 {
    display: none;
  }
}
@media (max-width: 934px) {
  .hide-in-934 {
    display: none;
  }
}
@media (max-width: 935px) {
  .hide-in-935 {
    display: none;
  }
}
@media (max-width: 936px) {
  .hide-in-936 {
    display: none;
  }
}
@media (max-width: 937px) {
  .hide-in-937 {
    display: none;
  }
}
@media (max-width: 938px) {
  .hide-in-938 {
    display: none;
  }
}
@media (max-width: 939px) {
  .hide-in-939 {
    display: none;
  }
}
@media (max-width: 940px) {
  .hide-in-940 {
    display: none;
  }
}
@media (max-width: 941px) {
  .hide-in-941 {
    display: none;
  }
}
@media (max-width: 942px) {
  .hide-in-942 {
    display: none;
  }
}
@media (max-width: 943px) {
  .hide-in-943 {
    display: none;
  }
}
@media (max-width: 944px) {
  .hide-in-944 {
    display: none;
  }
}
@media (max-width: 945px) {
  .hide-in-945 {
    display: none;
  }
}
@media (max-width: 946px) {
  .hide-in-946 {
    display: none;
  }
}
@media (max-width: 947px) {
  .hide-in-947 {
    display: none;
  }
}
@media (max-width: 948px) {
  .hide-in-948 {
    display: none;
  }
}
@media (max-width: 949px) {
  .hide-in-949 {
    display: none;
  }
}
@media (max-width: 950px) {
  .hide-in-950 {
    display: none;
  }
}
@media (max-width: 951px) {
  .hide-in-951 {
    display: none;
  }
}
@media (max-width: 952px) {
  .hide-in-952 {
    display: none;
  }
}
@media (max-width: 953px) {
  .hide-in-953 {
    display: none;
  }
}
@media (max-width: 954px) {
  .hide-in-954 {
    display: none;
  }
}
@media (max-width: 955px) {
  .hide-in-955 {
    display: none;
  }
}
@media (max-width: 956px) {
  .hide-in-956 {
    display: none;
  }
}
@media (max-width: 957px) {
  .hide-in-957 {
    display: none;
  }
}
@media (max-width: 958px) {
  .hide-in-958 {
    display: none;
  }
}
@media (max-width: 959px) {
  .hide-in-959 {
    display: none;
  }
}
@media (max-width: 960px) {
  .hide-in-960 {
    display: none;
  }
}
@media (max-width: 961px) {
  .hide-in-961 {
    display: none;
  }
}
@media (max-width: 962px) {
  .hide-in-962 {
    display: none;
  }
}
@media (max-width: 963px) {
  .hide-in-963 {
    display: none;
  }
}
@media (max-width: 964px) {
  .hide-in-964 {
    display: none;
  }
}
@media (max-width: 965px) {
  .hide-in-965 {
    display: none;
  }
}
@media (max-width: 966px) {
  .hide-in-966 {
    display: none;
  }
}
@media (max-width: 967px) {
  .hide-in-967 {
    display: none;
  }
}
@media (max-width: 968px) {
  .hide-in-968 {
    display: none;
  }
}
@media (max-width: 969px) {
  .hide-in-969 {
    display: none;
  }
}
@media (max-width: 970px) {
  .hide-in-970 {
    display: none;
  }
}
@media (max-width: 971px) {
  .hide-in-971 {
    display: none;
  }
}
@media (max-width: 972px) {
  .hide-in-972 {
    display: none;
  }
}
@media (max-width: 973px) {
  .hide-in-973 {
    display: none;
  }
}
@media (max-width: 974px) {
  .hide-in-974 {
    display: none;
  }
}
@media (max-width: 975px) {
  .hide-in-975 {
    display: none;
  }
}
@media (max-width: 976px) {
  .hide-in-976 {
    display: none;
  }
}
@media (max-width: 977px) {
  .hide-in-977 {
    display: none;
  }
}
@media (max-width: 978px) {
  .hide-in-978 {
    display: none;
  }
}
@media (max-width: 979px) {
  .hide-in-979 {
    display: none;
  }
}
@media (max-width: 980px) {
  .hide-in-980 {
    display: none;
  }
}
@media (max-width: 981px) {
  .hide-in-981 {
    display: none;
  }
}
@media (max-width: 982px) {
  .hide-in-982 {
    display: none;
  }
}
@media (max-width: 983px) {
  .hide-in-983 {
    display: none;
  }
}
@media (max-width: 984px) {
  .hide-in-984 {
    display: none;
  }
}
@media (max-width: 985px) {
  .hide-in-985 {
    display: none;
  }
}
@media (max-width: 986px) {
  .hide-in-986 {
    display: none;
  }
}
@media (max-width: 987px) {
  .hide-in-987 {
    display: none;
  }
}
@media (max-width: 988px) {
  .hide-in-988 {
    display: none;
  }
}
@media (max-width: 989px) {
  .hide-in-989 {
    display: none;
  }
}
@media (max-width: 990px) {
  .hide-in-990 {
    display: none;
  }
}
@media (max-width: 991px) {
  .hide-in-991 {
    display: none;
  }
}
@media (max-width: 992px) {
  .hide-in-992 {
    display: none;
  }
}
@media (max-width: 993px) {
  .hide-in-993 {
    display: none;
  }
}
@media (max-width: 994px) {
  .hide-in-994 {
    display: none;
  }
}
@media (max-width: 995px) {
  .hide-in-995 {
    display: none;
  }
}
@media (max-width: 996px) {
  .hide-in-996 {
    display: none;
  }
}
@media (max-width: 997px) {
  .hide-in-997 {
    display: none;
  }
}
@media (max-width: 998px) {
  .hide-in-998 {
    display: none;
  }
}
@media (max-width: 999px) {
  .hide-in-999 {
    display: none;
  }
}
@media (max-width: 1000px) {
  .hide-in-1000 {
    display: none;
  }
}
@media (max-width: 1001px) {
  .hide-in-1001 {
    display: none;
  }
}
@media (max-width: 1002px) {
  .hide-in-1002 {
    display: none;
  }
}
@media (max-width: 1003px) {
  .hide-in-1003 {
    display: none;
  }
}
@media (max-width: 1004px) {
  .hide-in-1004 {
    display: none;
  }
}
@media (max-width: 1005px) {
  .hide-in-1005 {
    display: none;
  }
}
@media (max-width: 1006px) {
  .hide-in-1006 {
    display: none;
  }
}
@media (max-width: 1007px) {
  .hide-in-1007 {
    display: none;
  }
}
@media (max-width: 1008px) {
  .hide-in-1008 {
    display: none;
  }
}
@media (max-width: 1009px) {
  .hide-in-1009 {
    display: none;
  }
}
@media (max-width: 1010px) {
  .hide-in-1010 {
    display: none;
  }
}
@media (max-width: 1011px) {
  .hide-in-1011 {
    display: none;
  }
}
@media (max-width: 1012px) {
  .hide-in-1012 {
    display: none;
  }
}
@media (max-width: 1013px) {
  .hide-in-1013 {
    display: none;
  }
}
@media (max-width: 1014px) {
  .hide-in-1014 {
    display: none;
  }
}
@media (max-width: 1015px) {
  .hide-in-1015 {
    display: none;
  }
}
@media (max-width: 1016px) {
  .hide-in-1016 {
    display: none;
  }
}
@media (max-width: 1017px) {
  .hide-in-1017 {
    display: none;
  }
}
@media (max-width: 1018px) {
  .hide-in-1018 {
    display: none;
  }
}
@media (max-width: 1019px) {
  .hide-in-1019 {
    display: none;
  }
}
@media (max-width: 1020px) {
  .hide-in-1020 {
    display: none;
  }
}
@media (max-width: 1021px) {
  .hide-in-1021 {
    display: none;
  }
}
@media (max-width: 1022px) {
  .hide-in-1022 {
    display: none;
  }
}
@media (max-width: 1023px) {
  .hide-in-1023 {
    display: none;
  }
}
@media (max-width: 1024px) {
  .hide-in-1024 {
    display: none;
  }
}
@media (max-width: 1025px) {
  .hide-in-1025 {
    display: none;
  }
}
@media (max-width: 1026px) {
  .hide-in-1026 {
    display: none;
  }
}
@media (max-width: 1027px) {
  .hide-in-1027 {
    display: none;
  }
}
@media (max-width: 1028px) {
  .hide-in-1028 {
    display: none;
  }
}
@media (max-width: 1029px) {
  .hide-in-1029 {
    display: none;
  }
}
@media (max-width: 1030px) {
  .hide-in-1030 {
    display: none;
  }
}
@media (max-width: 1031px) {
  .hide-in-1031 {
    display: none;
  }
}
@media (max-width: 1032px) {
  .hide-in-1032 {
    display: none;
  }
}
@media (max-width: 1033px) {
  .hide-in-1033 {
    display: none;
  }
}
@media (max-width: 1034px) {
  .hide-in-1034 {
    display: none;
  }
}
@media (max-width: 1035px) {
  .hide-in-1035 {
    display: none;
  }
}
@media (max-width: 1036px) {
  .hide-in-1036 {
    display: none;
  }
}
@media (max-width: 1037px) {
  .hide-in-1037 {
    display: none;
  }
}
@media (max-width: 1038px) {
  .hide-in-1038 {
    display: none;
  }
}
@media (max-width: 1039px) {
  .hide-in-1039 {
    display: none;
  }
}
@media (max-width: 1040px) {
  .hide-in-1040 {
    display: none;
  }
}
@media (max-width: 1041px) {
  .hide-in-1041 {
    display: none;
  }
}
@media (max-width: 1042px) {
  .hide-in-1042 {
    display: none;
  }
}
@media (max-width: 1043px) {
  .hide-in-1043 {
    display: none;
  }
}
@media (max-width: 1044px) {
  .hide-in-1044 {
    display: none;
  }
}
@media (max-width: 1045px) {
  .hide-in-1045 {
    display: none;
  }
}
@media (max-width: 1046px) {
  .hide-in-1046 {
    display: none;
  }
}
@media (max-width: 1047px) {
  .hide-in-1047 {
    display: none;
  }
}
@media (max-width: 1048px) {
  .hide-in-1048 {
    display: none;
  }
}
@media (max-width: 1049px) {
  .hide-in-1049 {
    display: none;
  }
}
@media (max-width: 1050px) {
  .hide-in-1050 {
    display: none;
  }
}
@media (max-width: 1051px) {
  .hide-in-1051 {
    display: none;
  }
}
@media (max-width: 1052px) {
  .hide-in-1052 {
    display: none;
  }
}
@media (max-width: 1053px) {
  .hide-in-1053 {
    display: none;
  }
}
@media (max-width: 1054px) {
  .hide-in-1054 {
    display: none;
  }
}
@media (max-width: 1055px) {
  .hide-in-1055 {
    display: none;
  }
}
@media (max-width: 1056px) {
  .hide-in-1056 {
    display: none;
  }
}
@media (max-width: 1057px) {
  .hide-in-1057 {
    display: none;
  }
}
@media (max-width: 1058px) {
  .hide-in-1058 {
    display: none;
  }
}
@media (max-width: 1059px) {
  .hide-in-1059 {
    display: none;
  }
}
@media (max-width: 1060px) {
  .hide-in-1060 {
    display: none;
  }
}
@media (max-width: 1061px) {
  .hide-in-1061 {
    display: none;
  }
}
@media (max-width: 1062px) {
  .hide-in-1062 {
    display: none;
  }
}
@media (max-width: 1063px) {
  .hide-in-1063 {
    display: none;
  }
}
@media (max-width: 1064px) {
  .hide-in-1064 {
    display: none;
  }
}
@media (max-width: 1065px) {
  .hide-in-1065 {
    display: none;
  }
}
@media (max-width: 1066px) {
  .hide-in-1066 {
    display: none;
  }
}
@media (max-width: 1067px) {
  .hide-in-1067 {
    display: none;
  }
}
@media (max-width: 1068px) {
  .hide-in-1068 {
    display: none;
  }
}
@media (max-width: 1069px) {
  .hide-in-1069 {
    display: none;
  }
}
@media (max-width: 1070px) {
  .hide-in-1070 {
    display: none;
  }
}
@media (max-width: 1071px) {
  .hide-in-1071 {
    display: none;
  }
}
@media (max-width: 1072px) {
  .hide-in-1072 {
    display: none;
  }
}
@media (max-width: 1073px) {
  .hide-in-1073 {
    display: none;
  }
}
@media (max-width: 1074px) {
  .hide-in-1074 {
    display: none;
  }
}
@media (max-width: 1075px) {
  .hide-in-1075 {
    display: none;
  }
}
@media (max-width: 1076px) {
  .hide-in-1076 {
    display: none;
  }
}
@media (max-width: 1077px) {
  .hide-in-1077 {
    display: none;
  }
}
@media (max-width: 1078px) {
  .hide-in-1078 {
    display: none;
  }
}
@media (max-width: 1079px) {
  .hide-in-1079 {
    display: none;
  }
}
@media (max-width: 1080px) {
  .hide-in-1080 {
    display: none;
  }
}
@media (max-width: 1081px) {
  .hide-in-1081 {
    display: none;
  }
}
@media (max-width: 1082px) {
  .hide-in-1082 {
    display: none;
  }
}
@media (max-width: 1083px) {
  .hide-in-1083 {
    display: none;
  }
}
@media (max-width: 1084px) {
  .hide-in-1084 {
    display: none;
  }
}
@media (max-width: 1085px) {
  .hide-in-1085 {
    display: none;
  }
}
@media (max-width: 1086px) {
  .hide-in-1086 {
    display: none;
  }
}
@media (max-width: 1087px) {
  .hide-in-1087 {
    display: none;
  }
}
@media (max-width: 1088px) {
  .hide-in-1088 {
    display: none;
  }
}
@media (max-width: 1089px) {
  .hide-in-1089 {
    display: none;
  }
}
@media (max-width: 1090px) {
  .hide-in-1090 {
    display: none;
  }
}
@media (max-width: 1091px) {
  .hide-in-1091 {
    display: none;
  }
}
@media (max-width: 1092px) {
  .hide-in-1092 {
    display: none;
  }
}
@media (max-width: 1093px) {
  .hide-in-1093 {
    display: none;
  }
}
@media (max-width: 1094px) {
  .hide-in-1094 {
    display: none;
  }
}
@media (max-width: 1095px) {
  .hide-in-1095 {
    display: none;
  }
}
@media (max-width: 1096px) {
  .hide-in-1096 {
    display: none;
  }
}
@media (max-width: 1097px) {
  .hide-in-1097 {
    display: none;
  }
}
@media (max-width: 1098px) {
  .hide-in-1098 {
    display: none;
  }
}
@media (max-width: 1099px) {
  .hide-in-1099 {
    display: none;
  }
}
@media (max-width: 1100px) {
  .hide-in-1100 {
    display: none;
  }
}
@media (max-width: 1101px) {
  .hide-in-1101 {
    display: none;
  }
}
@media (max-width: 1102px) {
  .hide-in-1102 {
    display: none;
  }
}
@media (max-width: 1103px) {
  .hide-in-1103 {
    display: none;
  }
}
@media (max-width: 1104px) {
  .hide-in-1104 {
    display: none;
  }
}
@media (max-width: 1105px) {
  .hide-in-1105 {
    display: none;
  }
}
@media (max-width: 1106px) {
  .hide-in-1106 {
    display: none;
  }
}
@media (max-width: 1107px) {
  .hide-in-1107 {
    display: none;
  }
}
@media (max-width: 1108px) {
  .hide-in-1108 {
    display: none;
  }
}
@media (max-width: 1109px) {
  .hide-in-1109 {
    display: none;
  }
}
@media (max-width: 1110px) {
  .hide-in-1110 {
    display: none;
  }
}
@media (max-width: 1111px) {
  .hide-in-1111 {
    display: none;
  }
}
@media (max-width: 1112px) {
  .hide-in-1112 {
    display: none;
  }
}
@media (max-width: 1113px) {
  .hide-in-1113 {
    display: none;
  }
}
@media (max-width: 1114px) {
  .hide-in-1114 {
    display: none;
  }
}
@media (max-width: 1115px) {
  .hide-in-1115 {
    display: none;
  }
}
@media (max-width: 1116px) {
  .hide-in-1116 {
    display: none;
  }
}
@media (max-width: 1117px) {
  .hide-in-1117 {
    display: none;
  }
}
@media (max-width: 1118px) {
  .hide-in-1118 {
    display: none;
  }
}
@media (max-width: 1119px) {
  .hide-in-1119 {
    display: none;
  }
}
@media (max-width: 1120px) {
  .hide-in-1120 {
    display: none;
  }
}
@media (max-width: 1121px) {
  .hide-in-1121 {
    display: none;
  }
}
@media (max-width: 1122px) {
  .hide-in-1122 {
    display: none;
  }
}
@media (max-width: 1123px) {
  .hide-in-1123 {
    display: none;
  }
}
@media (max-width: 1124px) {
  .hide-in-1124 {
    display: none;
  }
}
@media (max-width: 1125px) {
  .hide-in-1125 {
    display: none;
  }
}
@media (max-width: 1126px) {
  .hide-in-1126 {
    display: none;
  }
}
@media (max-width: 1127px) {
  .hide-in-1127 {
    display: none;
  }
}
@media (max-width: 1128px) {
  .hide-in-1128 {
    display: none;
  }
}
@media (max-width: 1129px) {
  .hide-in-1129 {
    display: none;
  }
}
@media (max-width: 1130px) {
  .hide-in-1130 {
    display: none;
  }
}
@media (max-width: 1131px) {
  .hide-in-1131 {
    display: none;
  }
}
@media (max-width: 1132px) {
  .hide-in-1132 {
    display: none;
  }
}
@media (max-width: 1133px) {
  .hide-in-1133 {
    display: none;
  }
}
@media (max-width: 1134px) {
  .hide-in-1134 {
    display: none;
  }
}
@media (max-width: 1135px) {
  .hide-in-1135 {
    display: none;
  }
}
@media (max-width: 1136px) {
  .hide-in-1136 {
    display: none;
  }
}
@media (max-width: 1137px) {
  .hide-in-1137 {
    display: none;
  }
}
@media (max-width: 1138px) {
  .hide-in-1138 {
    display: none;
  }
}
@media (max-width: 1139px) {
  .hide-in-1139 {
    display: none;
  }
}
@media (max-width: 1140px) {
  .hide-in-1140 {
    display: none;
  }
}
@media (max-width: 1141px) {
  .hide-in-1141 {
    display: none;
  }
}
@media (max-width: 1142px) {
  .hide-in-1142 {
    display: none;
  }
}
@media (max-width: 1143px) {
  .hide-in-1143 {
    display: none;
  }
}
@media (max-width: 1144px) {
  .hide-in-1144 {
    display: none;
  }
}
@media (max-width: 1145px) {
  .hide-in-1145 {
    display: none;
  }
}
@media (max-width: 1146px) {
  .hide-in-1146 {
    display: none;
  }
}
@media (max-width: 1147px) {
  .hide-in-1147 {
    display: none;
  }
}
@media (max-width: 1148px) {
  .hide-in-1148 {
    display: none;
  }
}
@media (max-width: 1149px) {
  .hide-in-1149 {
    display: none;
  }
}
@media (max-width: 1150px) {
  .hide-in-1150 {
    display: none;
  }
}
@media (max-width: 1151px) {
  .hide-in-1151 {
    display: none;
  }
}
@media (max-width: 1152px) {
  .hide-in-1152 {
    display: none;
  }
}
@media (max-width: 1153px) {
  .hide-in-1153 {
    display: none;
  }
}
@media (max-width: 1154px) {
  .hide-in-1154 {
    display: none;
  }
}
@media (max-width: 1155px) {
  .hide-in-1155 {
    display: none;
  }
}
@media (max-width: 1156px) {
  .hide-in-1156 {
    display: none;
  }
}
@media (max-width: 1157px) {
  .hide-in-1157 {
    display: none;
  }
}
@media (max-width: 1158px) {
  .hide-in-1158 {
    display: none;
  }
}
@media (max-width: 1159px) {
  .hide-in-1159 {
    display: none;
  }
}
@media (max-width: 1160px) {
  .hide-in-1160 {
    display: none;
  }
}
@media (max-width: 1161px) {
  .hide-in-1161 {
    display: none;
  }
}
@media (max-width: 1162px) {
  .hide-in-1162 {
    display: none;
  }
}
@media (max-width: 1163px) {
  .hide-in-1163 {
    display: none;
  }
}
@media (max-width: 1164px) {
  .hide-in-1164 {
    display: none;
  }
}
@media (max-width: 1165px) {
  .hide-in-1165 {
    display: none;
  }
}
@media (max-width: 1166px) {
  .hide-in-1166 {
    display: none;
  }
}
@media (max-width: 1167px) {
  .hide-in-1167 {
    display: none;
  }
}
@media (max-width: 1168px) {
  .hide-in-1168 {
    display: none;
  }
}
@media (max-width: 1169px) {
  .hide-in-1169 {
    display: none;
  }
}
@media (max-width: 1170px) {
  .hide-in-1170 {
    display: none;
  }
}
@media (max-width: 1171px) {
  .hide-in-1171 {
    display: none;
  }
}
@media (max-width: 1172px) {
  .hide-in-1172 {
    display: none;
  }
}
@media (max-width: 1173px) {
  .hide-in-1173 {
    display: none;
  }
}
@media (max-width: 1174px) {
  .hide-in-1174 {
    display: none;
  }
}
@media (max-width: 1175px) {
  .hide-in-1175 {
    display: none;
  }
}
@media (max-width: 1176px) {
  .hide-in-1176 {
    display: none;
  }
}
@media (max-width: 1177px) {
  .hide-in-1177 {
    display: none;
  }
}
@media (max-width: 1178px) {
  .hide-in-1178 {
    display: none;
  }
}
@media (max-width: 1179px) {
  .hide-in-1179 {
    display: none;
  }
}
@media (max-width: 1180px) {
  .hide-in-1180 {
    display: none;
  }
}
@media (max-width: 1181px) {
  .hide-in-1181 {
    display: none;
  }
}
@media (max-width: 1182px) {
  .hide-in-1182 {
    display: none;
  }
}
@media (max-width: 1183px) {
  .hide-in-1183 {
    display: none;
  }
}
@media (max-width: 1184px) {
  .hide-in-1184 {
    display: none;
  }
}
@media (max-width: 1185px) {
  .hide-in-1185 {
    display: none;
  }
}
@media (max-width: 1186px) {
  .hide-in-1186 {
    display: none;
  }
}
@media (max-width: 1187px) {
  .hide-in-1187 {
    display: none;
  }
}
@media (max-width: 1188px) {
  .hide-in-1188 {
    display: none;
  }
}
@media (max-width: 1189px) {
  .hide-in-1189 {
    display: none;
  }
}
@media (max-width: 1190px) {
  .hide-in-1190 {
    display: none;
  }
}
@media (max-width: 1191px) {
  .hide-in-1191 {
    display: none;
  }
}
@media (max-width: 1192px) {
  .hide-in-1192 {
    display: none;
  }
}
@media (max-width: 1193px) {
  .hide-in-1193 {
    display: none;
  }
}
@media (max-width: 1194px) {
  .hide-in-1194 {
    display: none;
  }
}
@media (max-width: 1195px) {
  .hide-in-1195 {
    display: none;
  }
}
@media (max-width: 1196px) {
  .hide-in-1196 {
    display: none;
  }
}
@media (max-width: 1197px) {
  .hide-in-1197 {
    display: none;
  }
}
@media (max-width: 1198px) {
  .hide-in-1198 {
    display: none;
  }
}
@media (max-width: 1199px) {
  .hide-in-1199 {
    display: none;
  }
}
@media (max-width: 1200px) {
  .hide-in-1200 {
    display: none;
  }
}
@media (max-width: 1201px) {
  .hide-in-1201 {
    display: none;
  }
}
@media (max-width: 1202px) {
  .hide-in-1202 {
    display: none;
  }
}
@media (max-width: 1203px) {
  .hide-in-1203 {
    display: none;
  }
}
@media (max-width: 1204px) {
  .hide-in-1204 {
    display: none;
  }
}
@media (max-width: 1205px) {
  .hide-in-1205 {
    display: none;
  }
}
@media (max-width: 1206px) {
  .hide-in-1206 {
    display: none;
  }
}
@media (max-width: 1207px) {
  .hide-in-1207 {
    display: none;
  }
}
@media (max-width: 1208px) {
  .hide-in-1208 {
    display: none;
  }
}
@media (max-width: 1209px) {
  .hide-in-1209 {
    display: none;
  }
}
@media (max-width: 1210px) {
  .hide-in-1210 {
    display: none;
  }
}
@media (max-width: 1211px) {
  .hide-in-1211 {
    display: none;
  }
}
@media (max-width: 1212px) {
  .hide-in-1212 {
    display: none;
  }
}
@media (max-width: 1213px) {
  .hide-in-1213 {
    display: none;
  }
}
@media (max-width: 1214px) {
  .hide-in-1214 {
    display: none;
  }
}
@media (max-width: 1215px) {
  .hide-in-1215 {
    display: none;
  }
}
@media (max-width: 1216px) {
  .hide-in-1216 {
    display: none;
  }
}
@media (max-width: 1217px) {
  .hide-in-1217 {
    display: none;
  }
}
@media (max-width: 1218px) {
  .hide-in-1218 {
    display: none;
  }
}
@media (max-width: 1219px) {
  .hide-in-1219 {
    display: none;
  }
}
@media (max-width: 1220px) {
  .hide-in-1220 {
    display: none;
  }
}
@media (max-width: 1221px) {
  .hide-in-1221 {
    display: none;
  }
}
@media (max-width: 1222px) {
  .hide-in-1222 {
    display: none;
  }
}
@media (max-width: 1223px) {
  .hide-in-1223 {
    display: none;
  }
}
@media (max-width: 1224px) {
  .hide-in-1224 {
    display: none;
  }
}
@media (max-width: 1225px) {
  .hide-in-1225 {
    display: none;
  }
}
@media (max-width: 1226px) {
  .hide-in-1226 {
    display: none;
  }
}
@media (max-width: 1227px) {
  .hide-in-1227 {
    display: none;
  }
}
@media (max-width: 1228px) {
  .hide-in-1228 {
    display: none;
  }
}
@media (max-width: 1229px) {
  .hide-in-1229 {
    display: none;
  }
}
@media (max-width: 1230px) {
  .hide-in-1230 {
    display: none;
  }
}
@media (max-width: 1231px) {
  .hide-in-1231 {
    display: none;
  }
}
@media (max-width: 1232px) {
  .hide-in-1232 {
    display: none;
  }
}
@media (max-width: 1233px) {
  .hide-in-1233 {
    display: none;
  }
}
@media (max-width: 1234px) {
  .hide-in-1234 {
    display: none;
  }
}
@media (max-width: 1235px) {
  .hide-in-1235 {
    display: none;
  }
}
@media (max-width: 1236px) {
  .hide-in-1236 {
    display: none;
  }
}
@media (max-width: 1237px) {
  .hide-in-1237 {
    display: none;
  }
}
@media (max-width: 1238px) {
  .hide-in-1238 {
    display: none;
  }
}
@media (max-width: 1239px) {
  .hide-in-1239 {
    display: none;
  }
}
@media (max-width: 1240px) {
  .hide-in-1240 {
    display: none;
  }
}
@media (max-width: 1241px) {
  .hide-in-1241 {
    display: none;
  }
}
@media (max-width: 1242px) {
  .hide-in-1242 {
    display: none;
  }
}
@media (max-width: 1243px) {
  .hide-in-1243 {
    display: none;
  }
}
@media (max-width: 1244px) {
  .hide-in-1244 {
    display: none;
  }
}
@media (max-width: 1245px) {
  .hide-in-1245 {
    display: none;
  }
}
@media (max-width: 1246px) {
  .hide-in-1246 {
    display: none;
  }
}
@media (max-width: 1247px) {
  .hide-in-1247 {
    display: none;
  }
}
@media (max-width: 1248px) {
  .hide-in-1248 {
    display: none;
  }
}
@media (max-width: 1249px) {
  .hide-in-1249 {
    display: none;
  }
}
@media (max-width: 1250px) {
  .hide-in-1250 {
    display: none;
  }
}
@media (max-width: 1251px) {
  .hide-in-1251 {
    display: none;
  }
}
@media (max-width: 1252px) {
  .hide-in-1252 {
    display: none;
  }
}
@media (max-width: 1253px) {
  .hide-in-1253 {
    display: none;
  }
}
@media (max-width: 1254px) {
  .hide-in-1254 {
    display: none;
  }
}
@media (max-width: 1255px) {
  .hide-in-1255 {
    display: none;
  }
}
@media (max-width: 1256px) {
  .hide-in-1256 {
    display: none;
  }
}
@media (max-width: 1257px) {
  .hide-in-1257 {
    display: none;
  }
}
@media (max-width: 1258px) {
  .hide-in-1258 {
    display: none;
  }
}
@media (max-width: 1259px) {
  .hide-in-1259 {
    display: none;
  }
}
@media (max-width: 1260px) {
  .hide-in-1260 {
    display: none;
  }
}
@media (max-width: 1261px) {
  .hide-in-1261 {
    display: none;
  }
}
@media (max-width: 1262px) {
  .hide-in-1262 {
    display: none;
  }
}
@media (max-width: 1263px) {
  .hide-in-1263 {
    display: none;
  }
}
@media (max-width: 1264px) {
  .hide-in-1264 {
    display: none;
  }
}
@media (max-width: 1265px) {
  .hide-in-1265 {
    display: none;
  }
}
@media (max-width: 1266px) {
  .hide-in-1266 {
    display: none;
  }
}
@media (max-width: 1267px) {
  .hide-in-1267 {
    display: none;
  }
}
@media (max-width: 1268px) {
  .hide-in-1268 {
    display: none;
  }
}
@media (max-width: 1269px) {
  .hide-in-1269 {
    display: none;
  }
}
@media (max-width: 1270px) {
  .hide-in-1270 {
    display: none;
  }
}
@media (max-width: 1271px) {
  .hide-in-1271 {
    display: none;
  }
}
@media (max-width: 1272px) {
  .hide-in-1272 {
    display: none;
  }
}
@media (max-width: 1273px) {
  .hide-in-1273 {
    display: none;
  }
}
@media (max-width: 1274px) {
  .hide-in-1274 {
    display: none;
  }
}
@media (max-width: 1275px) {
  .hide-in-1275 {
    display: none;
  }
}
@media (max-width: 1276px) {
  .hide-in-1276 {
    display: none;
  }
}
@media (max-width: 1277px) {
  .hide-in-1277 {
    display: none;
  }
}
@media (max-width: 1278px) {
  .hide-in-1278 {
    display: none;
  }
}
@media (max-width: 1279px) {
  .hide-in-1279 {
    display: none;
  }
}
@media (max-width: 1280px) {
  .hide-in-1280 {
    display: none;
  }
}
@media (max-width: 1281px) {
  .hide-in-1281 {
    display: none;
  }
}
@media (max-width: 1282px) {
  .hide-in-1282 {
    display: none;
  }
}
@media (max-width: 1283px) {
  .hide-in-1283 {
    display: none;
  }
}
@media (max-width: 1284px) {
  .hide-in-1284 {
    display: none;
  }
}
@media (max-width: 1285px) {
  .hide-in-1285 {
    display: none;
  }
}
@media (max-width: 1286px) {
  .hide-in-1286 {
    display: none;
  }
}
@media (max-width: 1287px) {
  .hide-in-1287 {
    display: none;
  }
}
@media (max-width: 1288px) {
  .hide-in-1288 {
    display: none;
  }
}
@media (max-width: 1289px) {
  .hide-in-1289 {
    display: none;
  }
}
@media (max-width: 1290px) {
  .hide-in-1290 {
    display: none;
  }
}
@media (max-width: 1291px) {
  .hide-in-1291 {
    display: none;
  }
}
@media (max-width: 1292px) {
  .hide-in-1292 {
    display: none;
  }
}
@media (max-width: 1293px) {
  .hide-in-1293 {
    display: none;
  }
}
@media (max-width: 1294px) {
  .hide-in-1294 {
    display: none;
  }
}
@media (max-width: 1295px) {
  .hide-in-1295 {
    display: none;
  }
}
@media (max-width: 1296px) {
  .hide-in-1296 {
    display: none;
  }
}
@media (max-width: 1297px) {
  .hide-in-1297 {
    display: none;
  }
}
@media (max-width: 1298px) {
  .hide-in-1298 {
    display: none;
  }
}
@media (max-width: 1299px) {
  .hide-in-1299 {
    display: none;
  }
}
@media (max-width: 1300px) {
  .hide-in-1300 {
    display: none;
  }
}
@media (max-width: 1301px) {
  .hide-in-1301 {
    display: none;
  }
}
@media (max-width: 1302px) {
  .hide-in-1302 {
    display: none;
  }
}
@media (max-width: 1303px) {
  .hide-in-1303 {
    display: none;
  }
}
@media (max-width: 1304px) {
  .hide-in-1304 {
    display: none;
  }
}
@media (max-width: 1305px) {
  .hide-in-1305 {
    display: none;
  }
}
@media (max-width: 1306px) {
  .hide-in-1306 {
    display: none;
  }
}
@media (max-width: 1307px) {
  .hide-in-1307 {
    display: none;
  }
}
@media (max-width: 1308px) {
  .hide-in-1308 {
    display: none;
  }
}
@media (max-width: 1309px) {
  .hide-in-1309 {
    display: none;
  }
}
@media (max-width: 1310px) {
  .hide-in-1310 {
    display: none;
  }
}
@media (max-width: 1311px) {
  .hide-in-1311 {
    display: none;
  }
}
@media (max-width: 1312px) {
  .hide-in-1312 {
    display: none;
  }
}
@media (max-width: 1313px) {
  .hide-in-1313 {
    display: none;
  }
}
@media (max-width: 1314px) {
  .hide-in-1314 {
    display: none;
  }
}
@media (max-width: 1315px) {
  .hide-in-1315 {
    display: none;
  }
}
@media (max-width: 1316px) {
  .hide-in-1316 {
    display: none;
  }
}
@media (max-width: 1317px) {
  .hide-in-1317 {
    display: none;
  }
}
@media (max-width: 1318px) {
  .hide-in-1318 {
    display: none;
  }
}
@media (max-width: 1319px) {
  .hide-in-1319 {
    display: none;
  }
}
@media (max-width: 1320px) {
  .hide-in-1320 {
    display: none;
  }
}
@media (max-width: 1321px) {
  .hide-in-1321 {
    display: none;
  }
}
@media (max-width: 1322px) {
  .hide-in-1322 {
    display: none;
  }
}
@media (max-width: 1323px) {
  .hide-in-1323 {
    display: none;
  }
}
@media (max-width: 1324px) {
  .hide-in-1324 {
    display: none;
  }
}
@media (max-width: 1325px) {
  .hide-in-1325 {
    display: none;
  }
}
@media (max-width: 1326px) {
  .hide-in-1326 {
    display: none;
  }
}
@media (max-width: 1327px) {
  .hide-in-1327 {
    display: none;
  }
}
@media (max-width: 1328px) {
  .hide-in-1328 {
    display: none;
  }
}
@media (max-width: 1329px) {
  .hide-in-1329 {
    display: none;
  }
}
@media (max-width: 1330px) {
  .hide-in-1330 {
    display: none;
  }
}
@media (max-width: 1331px) {
  .hide-in-1331 {
    display: none;
  }
}
@media (max-width: 1332px) {
  .hide-in-1332 {
    display: none;
  }
}
@media (max-width: 1333px) {
  .hide-in-1333 {
    display: none;
  }
}
@media (max-width: 1334px) {
  .hide-in-1334 {
    display: none;
  }
}
@media (max-width: 1335px) {
  .hide-in-1335 {
    display: none;
  }
}
@media (max-width: 1336px) {
  .hide-in-1336 {
    display: none;
  }
}
@media (max-width: 1337px) {
  .hide-in-1337 {
    display: none;
  }
}
@media (max-width: 1338px) {
  .hide-in-1338 {
    display: none;
  }
}
@media (max-width: 1339px) {
  .hide-in-1339 {
    display: none;
  }
}
@media (max-width: 1340px) {
  .hide-in-1340 {
    display: none;
  }
}
@media (max-width: 1341px) {
  .hide-in-1341 {
    display: none;
  }
}
@media (max-width: 1342px) {
  .hide-in-1342 {
    display: none;
  }
}
@media (max-width: 1343px) {
  .hide-in-1343 {
    display: none;
  }
}
@media (max-width: 1344px) {
  .hide-in-1344 {
    display: none;
  }
}
@media (max-width: 1345px) {
  .hide-in-1345 {
    display: none;
  }
}
@media (max-width: 1346px) {
  .hide-in-1346 {
    display: none;
  }
}
@media (max-width: 1347px) {
  .hide-in-1347 {
    display: none;
  }
}
@media (max-width: 1348px) {
  .hide-in-1348 {
    display: none;
  }
}
@media (max-width: 1349px) {
  .hide-in-1349 {
    display: none;
  }
}
@media (max-width: 1350px) {
  .hide-in-1350 {
    display: none;
  }
}
@media (max-width: 1351px) {
  .hide-in-1351 {
    display: none;
  }
}
@media (max-width: 1352px) {
  .hide-in-1352 {
    display: none;
  }
}
@media (max-width: 1353px) {
  .hide-in-1353 {
    display: none;
  }
}
@media (max-width: 1354px) {
  .hide-in-1354 {
    display: none;
  }
}
@media (max-width: 1355px) {
  .hide-in-1355 {
    display: none;
  }
}
@media (max-width: 1356px) {
  .hide-in-1356 {
    display: none;
  }
}
@media (max-width: 1357px) {
  .hide-in-1357 {
    display: none;
  }
}
@media (max-width: 1358px) {
  .hide-in-1358 {
    display: none;
  }
}
@media (max-width: 1359px) {
  .hide-in-1359 {
    display: none;
  }
}
@media (max-width: 1360px) {
  .hide-in-1360 {
    display: none;
  }
}
@media (max-width: 1361px) {
  .hide-in-1361 {
    display: none;
  }
}
@media (max-width: 1362px) {
  .hide-in-1362 {
    display: none;
  }
}
@media (max-width: 1363px) {
  .hide-in-1363 {
    display: none;
  }
}
@media (max-width: 1364px) {
  .hide-in-1364 {
    display: none;
  }
}
@media (max-width: 1365px) {
  .hide-in-1365 {
    display: none;
  }
}
@media (max-width: 1366px) {
  .hide-in-1366 {
    display: none;
  }
}
@media (max-width: 1367px) {
  .hide-in-1367 {
    display: none;
  }
}
@media (max-width: 1368px) {
  .hide-in-1368 {
    display: none;
  }
}
@media (max-width: 1369px) {
  .hide-in-1369 {
    display: none;
  }
}
@media (max-width: 1370px) {
  .hide-in-1370 {
    display: none;
  }
}
@media (max-width: 1371px) {
  .hide-in-1371 {
    display: none;
  }
}
@media (max-width: 1372px) {
  .hide-in-1372 {
    display: none;
  }
}
@media (max-width: 1373px) {
  .hide-in-1373 {
    display: none;
  }
}
@media (max-width: 1374px) {
  .hide-in-1374 {
    display: none;
  }
}
@media (max-width: 1375px) {
  .hide-in-1375 {
    display: none;
  }
}
@media (max-width: 1376px) {
  .hide-in-1376 {
    display: none;
  }
}
@media (max-width: 1377px) {
  .hide-in-1377 {
    display: none;
  }
}
@media (max-width: 1378px) {
  .hide-in-1378 {
    display: none;
  }
}
@media (max-width: 1379px) {
  .hide-in-1379 {
    display: none;
  }
}
@media (max-width: 1380px) {
  .hide-in-1380 {
    display: none;
  }
}
@media (max-width: 1381px) {
  .hide-in-1381 {
    display: none;
  }
}
@media (max-width: 1382px) {
  .hide-in-1382 {
    display: none;
  }
}
@media (max-width: 1383px) {
  .hide-in-1383 {
    display: none;
  }
}
@media (max-width: 1384px) {
  .hide-in-1384 {
    display: none;
  }
}
@media (max-width: 1385px) {
  .hide-in-1385 {
    display: none;
  }
}
@media (max-width: 1386px) {
  .hide-in-1386 {
    display: none;
  }
}
@media (max-width: 1387px) {
  .hide-in-1387 {
    display: none;
  }
}
@media (max-width: 1388px) {
  .hide-in-1388 {
    display: none;
  }
}
@media (max-width: 1389px) {
  .hide-in-1389 {
    display: none;
  }
}
@media (max-width: 1390px) {
  .hide-in-1390 {
    display: none;
  }
}
@media (max-width: 1391px) {
  .hide-in-1391 {
    display: none;
  }
}
@media (max-width: 1392px) {
  .hide-in-1392 {
    display: none;
  }
}
@media (max-width: 1393px) {
  .hide-in-1393 {
    display: none;
  }
}
@media (max-width: 1394px) {
  .hide-in-1394 {
    display: none;
  }
}
@media (max-width: 1395px) {
  .hide-in-1395 {
    display: none;
  }
}
@media (max-width: 1396px) {
  .hide-in-1396 {
    display: none;
  }
}
@media (max-width: 1397px) {
  .hide-in-1397 {
    display: none;
  }
}
@media (max-width: 1398px) {
  .hide-in-1398 {
    display: none;
  }
}
@media (max-width: 1399px) {
  .hide-in-1399 {
    display: none;
  }
}
@media (max-width: 1400px) {
  .hide-in-1400 {
    display: none;
  }
}
@media (max-width: 1401px) {
  .hide-in-1401 {
    display: none;
  }
}
@media (max-width: 1402px) {
  .hide-in-1402 {
    display: none;
  }
}
@media (max-width: 1403px) {
  .hide-in-1403 {
    display: none;
  }
}
@media (max-width: 1404px) {
  .hide-in-1404 {
    display: none;
  }
}
@media (max-width: 1405px) {
  .hide-in-1405 {
    display: none;
  }
}
@media (max-width: 1406px) {
  .hide-in-1406 {
    display: none;
  }
}
@media (max-width: 1407px) {
  .hide-in-1407 {
    display: none;
  }
}
@media (max-width: 1408px) {
  .hide-in-1408 {
    display: none;
  }
}
@media (max-width: 1409px) {
  .hide-in-1409 {
    display: none;
  }
}
@media (max-width: 1410px) {
  .hide-in-1410 {
    display: none;
  }
}
@media (max-width: 1411px) {
  .hide-in-1411 {
    display: none;
  }
}
@media (max-width: 1412px) {
  .hide-in-1412 {
    display: none;
  }
}
@media (max-width: 1413px) {
  .hide-in-1413 {
    display: none;
  }
}
@media (max-width: 1414px) {
  .hide-in-1414 {
    display: none;
  }
}
@media (max-width: 1415px) {
  .hide-in-1415 {
    display: none;
  }
}
@media (max-width: 1416px) {
  .hide-in-1416 {
    display: none;
  }
}
@media (max-width: 1417px) {
  .hide-in-1417 {
    display: none;
  }
}
@media (max-width: 1418px) {
  .hide-in-1418 {
    display: none;
  }
}
@media (max-width: 1419px) {
  .hide-in-1419 {
    display: none;
  }
}
@media (max-width: 1420px) {
  .hide-in-1420 {
    display: none;
  }
}
@media (max-width: 1421px) {
  .hide-in-1421 {
    display: none;
  }
}
@media (max-width: 1422px) {
  .hide-in-1422 {
    display: none;
  }
}
@media (max-width: 1423px) {
  .hide-in-1423 {
    display: none;
  }
}
@media (max-width: 1424px) {
  .hide-in-1424 {
    display: none;
  }
}
@media (max-width: 1425px) {
  .hide-in-1425 {
    display: none;
  }
}
@media (max-width: 1426px) {
  .hide-in-1426 {
    display: none;
  }
}
@media (max-width: 1427px) {
  .hide-in-1427 {
    display: none;
  }
}
@media (max-width: 1428px) {
  .hide-in-1428 {
    display: none;
  }
}
@media (max-width: 1429px) {
  .hide-in-1429 {
    display: none;
  }
}
@media (max-width: 1430px) {
  .hide-in-1430 {
    display: none;
  }
}
@media (max-width: 1431px) {
  .hide-in-1431 {
    display: none;
  }
}
@media (max-width: 1432px) {
  .hide-in-1432 {
    display: none;
  }
}
@media (max-width: 1433px) {
  .hide-in-1433 {
    display: none;
  }
}
@media (max-width: 1434px) {
  .hide-in-1434 {
    display: none;
  }
}
@media (max-width: 1435px) {
  .hide-in-1435 {
    display: none;
  }
}
@media (max-width: 1436px) {
  .hide-in-1436 {
    display: none;
  }
}
@media (max-width: 1437px) {
  .hide-in-1437 {
    display: none;
  }
}
@media (max-width: 1438px) {
  .hide-in-1438 {
    display: none;
  }
}
@media (max-width: 1439px) {
  .hide-in-1439 {
    display: none;
  }
}
@media (max-width: 1440px) {
  .hide-in-1440 {
    display: none;
  }
}
@media (max-width: 1441px) {
  .hide-in-1441 {
    display: none;
  }
}
@media (max-width: 1442px) {
  .hide-in-1442 {
    display: none;
  }
}
@media (max-width: 1443px) {
  .hide-in-1443 {
    display: none;
  }
}
@media (max-width: 1444px) {
  .hide-in-1444 {
    display: none;
  }
}
@media (max-width: 1445px) {
  .hide-in-1445 {
    display: none;
  }
}
@media (max-width: 1446px) {
  .hide-in-1446 {
    display: none;
  }
}
@media (max-width: 1447px) {
  .hide-in-1447 {
    display: none;
  }
}
@media (max-width: 1448px) {
  .hide-in-1448 {
    display: none;
  }
}
@media (max-width: 1449px) {
  .hide-in-1449 {
    display: none;
  }
}
@media (max-width: 1450px) {
  .hide-in-1450 {
    display: none;
  }
}
@media (max-width: 1451px) {
  .hide-in-1451 {
    display: none;
  }
}
@media (max-width: 1452px) {
  .hide-in-1452 {
    display: none;
  }
}
@media (max-width: 1453px) {
  .hide-in-1453 {
    display: none;
  }
}
@media (max-width: 1454px) {
  .hide-in-1454 {
    display: none;
  }
}
@media (max-width: 1455px) {
  .hide-in-1455 {
    display: none;
  }
}
@media (max-width: 1456px) {
  .hide-in-1456 {
    display: none;
  }
}
@media (max-width: 1457px) {
  .hide-in-1457 {
    display: none;
  }
}
@media (max-width: 1458px) {
  .hide-in-1458 {
    display: none;
  }
}
@media (max-width: 1459px) {
  .hide-in-1459 {
    display: none;
  }
}
@media (max-width: 1460px) {
  .hide-in-1460 {
    display: none;
  }
}
@media (max-width: 1461px) {
  .hide-in-1461 {
    display: none;
  }
}
@media (max-width: 1462px) {
  .hide-in-1462 {
    display: none;
  }
}
@media (max-width: 1463px) {
  .hide-in-1463 {
    display: none;
  }
}
@media (max-width: 1464px) {
  .hide-in-1464 {
    display: none;
  }
}
@media (max-width: 1465px) {
  .hide-in-1465 {
    display: none;
  }
}
@media (max-width: 1466px) {
  .hide-in-1466 {
    display: none;
  }
}
@media (max-width: 1467px) {
  .hide-in-1467 {
    display: none;
  }
}
@media (max-width: 1468px) {
  .hide-in-1468 {
    display: none;
  }
}
@media (max-width: 1469px) {
  .hide-in-1469 {
    display: none;
  }
}
@media (max-width: 1470px) {
  .hide-in-1470 {
    display: none;
  }
}
@media (max-width: 1471px) {
  .hide-in-1471 {
    display: none;
  }
}
@media (max-width: 1472px) {
  .hide-in-1472 {
    display: none;
  }
}
@media (max-width: 1473px) {
  .hide-in-1473 {
    display: none;
  }
}
@media (max-width: 1474px) {
  .hide-in-1474 {
    display: none;
  }
}
@media (max-width: 1475px) {
  .hide-in-1475 {
    display: none;
  }
}
@media (max-width: 1476px) {
  .hide-in-1476 {
    display: none;
  }
}
@media (max-width: 1477px) {
  .hide-in-1477 {
    display: none;
  }
}
@media (max-width: 1478px) {
  .hide-in-1478 {
    display: none;
  }
}
@media (max-width: 1479px) {
  .hide-in-1479 {
    display: none;
  }
}
@media (max-width: 1480px) {
  .hide-in-1480 {
    display: none;
  }
}
@media (max-width: 1481px) {
  .hide-in-1481 {
    display: none;
  }
}
@media (max-width: 1482px) {
  .hide-in-1482 {
    display: none;
  }
}
@media (max-width: 1483px) {
  .hide-in-1483 {
    display: none;
  }
}
@media (max-width: 1484px) {
  .hide-in-1484 {
    display: none;
  }
}
@media (max-width: 1485px) {
  .hide-in-1485 {
    display: none;
  }
}
@media (max-width: 1486px) {
  .hide-in-1486 {
    display: none;
  }
}
@media (max-width: 1487px) {
  .hide-in-1487 {
    display: none;
  }
}
@media (max-width: 1488px) {
  .hide-in-1488 {
    display: none;
  }
}
@media (max-width: 1489px) {
  .hide-in-1489 {
    display: none;
  }
}
@media (max-width: 1490px) {
  .hide-in-1490 {
    display: none;
  }
}
@media (max-width: 1491px) {
  .hide-in-1491 {
    display: none;
  }
}
@media (max-width: 1492px) {
  .hide-in-1492 {
    display: none;
  }
}
@media (max-width: 1493px) {
  .hide-in-1493 {
    display: none;
  }
}
@media (max-width: 1494px) {
  .hide-in-1494 {
    display: none;
  }
}
@media (max-width: 1495px) {
  .hide-in-1495 {
    display: none;
  }
}
@media (max-width: 1496px) {
  .hide-in-1496 {
    display: none;
  }
}
@media (max-width: 1497px) {
  .hide-in-1497 {
    display: none;
  }
}
@media (max-width: 1498px) {
  .hide-in-1498 {
    display: none;
  }
}
@media (max-width: 1499px) {
  .hide-in-1499 {
    display: none;
  }
}
@media (max-width: 1500px) {
  .hide-in-1500 {
    display: none;
  }
}
@media (max-width: 1501px) {
  .hide-in-1501 {
    display: none;
  }
}
@media (max-width: 1502px) {
  .hide-in-1502 {
    display: none;
  }
}
@media (max-width: 1503px) {
  .hide-in-1503 {
    display: none;
  }
}
@media (max-width: 1504px) {
  .hide-in-1504 {
    display: none;
  }
}
@media (max-width: 1505px) {
  .hide-in-1505 {
    display: none;
  }
}
@media (max-width: 1506px) {
  .hide-in-1506 {
    display: none;
  }
}
@media (max-width: 1507px) {
  .hide-in-1507 {
    display: none;
  }
}
@media (max-width: 1508px) {
  .hide-in-1508 {
    display: none;
  }
}
@media (max-width: 1509px) {
  .hide-in-1509 {
    display: none;
  }
}
@media (max-width: 1510px) {
  .hide-in-1510 {
    display: none;
  }
}
@media (max-width: 1511px) {
  .hide-in-1511 {
    display: none;
  }
}
@media (max-width: 1512px) {
  .hide-in-1512 {
    display: none;
  }
}
@media (max-width: 1513px) {
  .hide-in-1513 {
    display: none;
  }
}
@media (max-width: 1514px) {
  .hide-in-1514 {
    display: none;
  }
}
@media (max-width: 1515px) {
  .hide-in-1515 {
    display: none;
  }
}
@media (max-width: 1516px) {
  .hide-in-1516 {
    display: none;
  }
}
@media (max-width: 1517px) {
  .hide-in-1517 {
    display: none;
  }
}
@media (max-width: 1518px) {
  .hide-in-1518 {
    display: none;
  }
}
@media (max-width: 1519px) {
  .hide-in-1519 {
    display: none;
  }
}
@media (max-width: 1520px) {
  .hide-in-1520 {
    display: none;
  }
}
@media (max-width: 1521px) {
  .hide-in-1521 {
    display: none;
  }
}
@media (max-width: 1522px) {
  .hide-in-1522 {
    display: none;
  }
}
@media (max-width: 1523px) {
  .hide-in-1523 {
    display: none;
  }
}
@media (max-width: 1524px) {
  .hide-in-1524 {
    display: none;
  }
}
@media (max-width: 1525px) {
  .hide-in-1525 {
    display: none;
  }
}
@media (max-width: 1526px) {
  .hide-in-1526 {
    display: none;
  }
}
@media (max-width: 1527px) {
  .hide-in-1527 {
    display: none;
  }
}
@media (max-width: 1528px) {
  .hide-in-1528 {
    display: none;
  }
}
@media (max-width: 1529px) {
  .hide-in-1529 {
    display: none;
  }
}
@media (max-width: 1530px) {
  .hide-in-1530 {
    display: none;
  }
}
@media (max-width: 1531px) {
  .hide-in-1531 {
    display: none;
  }
}
@media (max-width: 1532px) {
  .hide-in-1532 {
    display: none;
  }
}
@media (max-width: 1533px) {
  .hide-in-1533 {
    display: none;
  }
}
@media (max-width: 1534px) {
  .hide-in-1534 {
    display: none;
  }
}
@media (max-width: 1535px) {
  .hide-in-1535 {
    display: none;
  }
}
@media (max-width: 1536px) {
  .hide-in-1536 {
    display: none;
  }
}
@media (max-width: 1537px) {
  .hide-in-1537 {
    display: none;
  }
}
@media (max-width: 1538px) {
  .hide-in-1538 {
    display: none;
  }
}
@media (max-width: 1539px) {
  .hide-in-1539 {
    display: none;
  }
}
@media (max-width: 1540px) {
  .hide-in-1540 {
    display: none;
  }
}
@media (max-width: 1541px) {
  .hide-in-1541 {
    display: none;
  }
}
@media (max-width: 1542px) {
  .hide-in-1542 {
    display: none;
  }
}
@media (max-width: 1543px) {
  .hide-in-1543 {
    display: none;
  }
}
@media (max-width: 1544px) {
  .hide-in-1544 {
    display: none;
  }
}
@media (max-width: 1545px) {
  .hide-in-1545 {
    display: none;
  }
}
@media (max-width: 1546px) {
  .hide-in-1546 {
    display: none;
  }
}
@media (max-width: 1547px) {
  .hide-in-1547 {
    display: none;
  }
}
@media (max-width: 1548px) {
  .hide-in-1548 {
    display: none;
  }
}
@media (max-width: 1549px) {
  .hide-in-1549 {
    display: none;
  }
}
@media (max-width: 1550px) {
  .hide-in-1550 {
    display: none;
  }
}
@media (max-width: 1551px) {
  .hide-in-1551 {
    display: none;
  }
}
@media (max-width: 1552px) {
  .hide-in-1552 {
    display: none;
  }
}
@media (max-width: 1553px) {
  .hide-in-1553 {
    display: none;
  }
}
@media (max-width: 1554px) {
  .hide-in-1554 {
    display: none;
  }
}
@media (max-width: 1555px) {
  .hide-in-1555 {
    display: none;
  }
}
@media (max-width: 1556px) {
  .hide-in-1556 {
    display: none;
  }
}
@media (max-width: 1557px) {
  .hide-in-1557 {
    display: none;
  }
}
@media (max-width: 1558px) {
  .hide-in-1558 {
    display: none;
  }
}
@media (max-width: 1559px) {
  .hide-in-1559 {
    display: none;
  }
}
@media (max-width: 1560px) {
  .hide-in-1560 {
    display: none;
  }
}
@media (max-width: 1561px) {
  .hide-in-1561 {
    display: none;
  }
}
@media (max-width: 1562px) {
  .hide-in-1562 {
    display: none;
  }
}
@media (max-width: 1563px) {
  .hide-in-1563 {
    display: none;
  }
}
@media (max-width: 1564px) {
  .hide-in-1564 {
    display: none;
  }
}
@media (max-width: 1565px) {
  .hide-in-1565 {
    display: none;
  }
}
@media (max-width: 1566px) {
  .hide-in-1566 {
    display: none;
  }
}
@media (max-width: 1567px) {
  .hide-in-1567 {
    display: none;
  }
}
@media (max-width: 1568px) {
  .hide-in-1568 {
    display: none;
  }
}
@media (max-width: 1569px) {
  .hide-in-1569 {
    display: none;
  }
}
@media (max-width: 1570px) {
  .hide-in-1570 {
    display: none;
  }
}
@media (max-width: 1571px) {
  .hide-in-1571 {
    display: none;
  }
}
@media (max-width: 1572px) {
  .hide-in-1572 {
    display: none;
  }
}
@media (max-width: 1573px) {
  .hide-in-1573 {
    display: none;
  }
}
@media (max-width: 1574px) {
  .hide-in-1574 {
    display: none;
  }
}
@media (max-width: 1575px) {
  .hide-in-1575 {
    display: none;
  }
}
@media (max-width: 1576px) {
  .hide-in-1576 {
    display: none;
  }
}
@media (max-width: 1577px) {
  .hide-in-1577 {
    display: none;
  }
}
@media (max-width: 1578px) {
  .hide-in-1578 {
    display: none;
  }
}
@media (max-width: 1579px) {
  .hide-in-1579 {
    display: none;
  }
}
@media (max-width: 1580px) {
  .hide-in-1580 {
    display: none;
  }
}
@media (max-width: 1581px) {
  .hide-in-1581 {
    display: none;
  }
}
@media (max-width: 1582px) {
  .hide-in-1582 {
    display: none;
  }
}
@media (max-width: 1583px) {
  .hide-in-1583 {
    display: none;
  }
}
@media (max-width: 1584px) {
  .hide-in-1584 {
    display: none;
  }
}
@media (max-width: 1585px) {
  .hide-in-1585 {
    display: none;
  }
}
@media (max-width: 1586px) {
  .hide-in-1586 {
    display: none;
  }
}
@media (max-width: 1587px) {
  .hide-in-1587 {
    display: none;
  }
}
@media (max-width: 1588px) {
  .hide-in-1588 {
    display: none;
  }
}
@media (max-width: 1589px) {
  .hide-in-1589 {
    display: none;
  }
}
@media (max-width: 1590px) {
  .hide-in-1590 {
    display: none;
  }
}
@media (max-width: 1591px) {
  .hide-in-1591 {
    display: none;
  }
}
@media (max-width: 1592px) {
  .hide-in-1592 {
    display: none;
  }
}
@media (max-width: 1593px) {
  .hide-in-1593 {
    display: none;
  }
}
@media (max-width: 1594px) {
  .hide-in-1594 {
    display: none;
  }
}
@media (max-width: 1595px) {
  .hide-in-1595 {
    display: none;
  }
}
@media (max-width: 1596px) {
  .hide-in-1596 {
    display: none;
  }
}
@media (max-width: 1597px) {
  .hide-in-1597 {
    display: none;
  }
}
@media (max-width: 1598px) {
  .hide-in-1598 {
    display: none;
  }
}
@media (max-width: 1599px) {
  .hide-in-1599 {
    display: none;
  }
}
@media (max-width: 1600px) {
  .hide-in-1600 {
    display: none;
  }
}
@media (max-width: 1601px) {
  .hide-in-1601 {
    display: none;
  }
}
@media (max-width: 1602px) {
  .hide-in-1602 {
    display: none;
  }
}
@media (max-width: 1603px) {
  .hide-in-1603 {
    display: none;
  }
}
@media (max-width: 1604px) {
  .hide-in-1604 {
    display: none;
  }
}
@media (max-width: 1605px) {
  .hide-in-1605 {
    display: none;
  }
}
@media (max-width: 1606px) {
  .hide-in-1606 {
    display: none;
  }
}
@media (max-width: 1607px) {
  .hide-in-1607 {
    display: none;
  }
}
@media (max-width: 1608px) {
  .hide-in-1608 {
    display: none;
  }
}
@media (max-width: 1609px) {
  .hide-in-1609 {
    display: none;
  }
}
@media (max-width: 1610px) {
  .hide-in-1610 {
    display: none;
  }
}
@media (max-width: 1611px) {
  .hide-in-1611 {
    display: none;
  }
}
@media (max-width: 1612px) {
  .hide-in-1612 {
    display: none;
  }
}
@media (max-width: 1613px) {
  .hide-in-1613 {
    display: none;
  }
}
@media (max-width: 1614px) {
  .hide-in-1614 {
    display: none;
  }
}
@media (max-width: 1615px) {
  .hide-in-1615 {
    display: none;
  }
}
@media (max-width: 1616px) {
  .hide-in-1616 {
    display: none;
  }
}
@media (max-width: 1617px) {
  .hide-in-1617 {
    display: none;
  }
}
@media (max-width: 1618px) {
  .hide-in-1618 {
    display: none;
  }
}
@media (max-width: 1619px) {
  .hide-in-1619 {
    display: none;
  }
}
@media (max-width: 1620px) {
  .hide-in-1620 {
    display: none;
  }
}
@media (max-width: 1621px) {
  .hide-in-1621 {
    display: none;
  }
}
@media (max-width: 1622px) {
  .hide-in-1622 {
    display: none;
  }
}
@media (max-width: 1623px) {
  .hide-in-1623 {
    display: none;
  }
}
@media (max-width: 1624px) {
  .hide-in-1624 {
    display: none;
  }
}
@media (max-width: 1625px) {
  .hide-in-1625 {
    display: none;
  }
}
@media (max-width: 1626px) {
  .hide-in-1626 {
    display: none;
  }
}
@media (max-width: 1627px) {
  .hide-in-1627 {
    display: none;
  }
}
@media (max-width: 1628px) {
  .hide-in-1628 {
    display: none;
  }
}
@media (max-width: 1629px) {
  .hide-in-1629 {
    display: none;
  }
}
@media (max-width: 1630px) {
  .hide-in-1630 {
    display: none;
  }
}
@media (max-width: 1631px) {
  .hide-in-1631 {
    display: none;
  }
}
@media (max-width: 1632px) {
  .hide-in-1632 {
    display: none;
  }
}
@media (max-width: 1633px) {
  .hide-in-1633 {
    display: none;
  }
}
@media (max-width: 1634px) {
  .hide-in-1634 {
    display: none;
  }
}
@media (max-width: 1635px) {
  .hide-in-1635 {
    display: none;
  }
}
@media (max-width: 1636px) {
  .hide-in-1636 {
    display: none;
  }
}
@media (max-width: 1637px) {
  .hide-in-1637 {
    display: none;
  }
}
@media (max-width: 1638px) {
  .hide-in-1638 {
    display: none;
  }
}
@media (max-width: 1639px) {
  .hide-in-1639 {
    display: none;
  }
}
@media (max-width: 1640px) {
  .hide-in-1640 {
    display: none;
  }
}
@media (max-width: 1641px) {
  .hide-in-1641 {
    display: none;
  }
}
@media (max-width: 1642px) {
  .hide-in-1642 {
    display: none;
  }
}
@media (max-width: 1643px) {
  .hide-in-1643 {
    display: none;
  }
}
@media (max-width: 1644px) {
  .hide-in-1644 {
    display: none;
  }
}
@media (max-width: 1645px) {
  .hide-in-1645 {
    display: none;
  }
}
@media (max-width: 1646px) {
  .hide-in-1646 {
    display: none;
  }
}
@media (max-width: 1647px) {
  .hide-in-1647 {
    display: none;
  }
}
@media (max-width: 1648px) {
  .hide-in-1648 {
    display: none;
  }
}
@media (max-width: 1649px) {
  .hide-in-1649 {
    display: none;
  }
}
@media (max-width: 1650px) {
  .hide-in-1650 {
    display: none;
  }
}
@media (max-width: 1651px) {
  .hide-in-1651 {
    display: none;
  }
}
@media (max-width: 1652px) {
  .hide-in-1652 {
    display: none;
  }
}
@media (max-width: 1653px) {
  .hide-in-1653 {
    display: none;
  }
}
@media (max-width: 1654px) {
  .hide-in-1654 {
    display: none;
  }
}
@media (max-width: 1655px) {
  .hide-in-1655 {
    display: none;
  }
}
@media (max-width: 1656px) {
  .hide-in-1656 {
    display: none;
  }
}
@media (max-width: 1657px) {
  .hide-in-1657 {
    display: none;
  }
}
@media (max-width: 1658px) {
  .hide-in-1658 {
    display: none;
  }
}
@media (max-width: 1659px) {
  .hide-in-1659 {
    display: none;
  }
}
@media (max-width: 1660px) {
  .hide-in-1660 {
    display: none;
  }
}
@media (max-width: 1661px) {
  .hide-in-1661 {
    display: none;
  }
}
@media (max-width: 1662px) {
  .hide-in-1662 {
    display: none;
  }
}
@media (max-width: 1663px) {
  .hide-in-1663 {
    display: none;
  }
}
@media (max-width: 1664px) {
  .hide-in-1664 {
    display: none;
  }
}
@media (max-width: 1665px) {
  .hide-in-1665 {
    display: none;
  }
}
@media (max-width: 1666px) {
  .hide-in-1666 {
    display: none;
  }
}
@media (max-width: 1667px) {
  .hide-in-1667 {
    display: none;
  }
}
@media (max-width: 1668px) {
  .hide-in-1668 {
    display: none;
  }
}
@media (max-width: 1669px) {
  .hide-in-1669 {
    display: none;
  }
}
@media (max-width: 1670px) {
  .hide-in-1670 {
    display: none;
  }
}
@media (max-width: 1671px) {
  .hide-in-1671 {
    display: none;
  }
}
@media (max-width: 1672px) {
  .hide-in-1672 {
    display: none;
  }
}
@media (max-width: 1673px) {
  .hide-in-1673 {
    display: none;
  }
}
@media (max-width: 1674px) {
  .hide-in-1674 {
    display: none;
  }
}
@media (max-width: 1675px) {
  .hide-in-1675 {
    display: none;
  }
}
@media (max-width: 1676px) {
  .hide-in-1676 {
    display: none;
  }
}
@media (max-width: 1677px) {
  .hide-in-1677 {
    display: none;
  }
}
@media (max-width: 1678px) {
  .hide-in-1678 {
    display: none;
  }
}
@media (max-width: 1679px) {
  .hide-in-1679 {
    display: none;
  }
}
@media (max-width: 1680px) {
  .hide-in-1680 {
    display: none;
  }
}
@media (max-width: 1681px) {
  .hide-in-1681 {
    display: none;
  }
}
@media (max-width: 1682px) {
  .hide-in-1682 {
    display: none;
  }
}
@media (max-width: 1683px) {
  .hide-in-1683 {
    display: none;
  }
}
@media (max-width: 1684px) {
  .hide-in-1684 {
    display: none;
  }
}
@media (max-width: 1685px) {
  .hide-in-1685 {
    display: none;
  }
}
@media (max-width: 1686px) {
  .hide-in-1686 {
    display: none;
  }
}
@media (max-width: 1687px) {
  .hide-in-1687 {
    display: none;
  }
}
@media (max-width: 1688px) {
  .hide-in-1688 {
    display: none;
  }
}
@media (max-width: 1689px) {
  .hide-in-1689 {
    display: none;
  }
}
@media (max-width: 1690px) {
  .hide-in-1690 {
    display: none;
  }
}
@media (max-width: 1691px) {
  .hide-in-1691 {
    display: none;
  }
}
@media (max-width: 1692px) {
  .hide-in-1692 {
    display: none;
  }
}
@media (max-width: 1693px) {
  .hide-in-1693 {
    display: none;
  }
}
@media (max-width: 1694px) {
  .hide-in-1694 {
    display: none;
  }
}
@media (max-width: 1695px) {
  .hide-in-1695 {
    display: none;
  }
}
@media (max-width: 1696px) {
  .hide-in-1696 {
    display: none;
  }
}
@media (max-width: 1697px) {
  .hide-in-1697 {
    display: none;
  }
}
@media (max-width: 1698px) {
  .hide-in-1698 {
    display: none;
  }
}
@media (max-width: 1699px) {
  .hide-in-1699 {
    display: none;
  }
}
@media (max-width: 1700px) {
  .hide-in-1700 {
    display: none;
  }
}
@media (max-width: 1701px) {
  .hide-in-1701 {
    display: none;
  }
}
@media (max-width: 1702px) {
  .hide-in-1702 {
    display: none;
  }
}
@media (max-width: 1703px) {
  .hide-in-1703 {
    display: none;
  }
}
@media (max-width: 1704px) {
  .hide-in-1704 {
    display: none;
  }
}
@media (max-width: 1705px) {
  .hide-in-1705 {
    display: none;
  }
}
@media (max-width: 1706px) {
  .hide-in-1706 {
    display: none;
  }
}
@media (max-width: 1707px) {
  .hide-in-1707 {
    display: none;
  }
}
@media (max-width: 1708px) {
  .hide-in-1708 {
    display: none;
  }
}
@media (max-width: 1709px) {
  .hide-in-1709 {
    display: none;
  }
}
@media (max-width: 1710px) {
  .hide-in-1710 {
    display: none;
  }
}
@media (max-width: 1711px) {
  .hide-in-1711 {
    display: none;
  }
}
@media (max-width: 1712px) {
  .hide-in-1712 {
    display: none;
  }
}
@media (max-width: 1713px) {
  .hide-in-1713 {
    display: none;
  }
}
@media (max-width: 1714px) {
  .hide-in-1714 {
    display: none;
  }
}
@media (max-width: 1715px) {
  .hide-in-1715 {
    display: none;
  }
}
@media (max-width: 1716px) {
  .hide-in-1716 {
    display: none;
  }
}
@media (max-width: 1717px) {
  .hide-in-1717 {
    display: none;
  }
}
@media (max-width: 1718px) {
  .hide-in-1718 {
    display: none;
  }
}
@media (max-width: 1719px) {
  .hide-in-1719 {
    display: none;
  }
}
@media (max-width: 1720px) {
  .hide-in-1720 {
    display: none;
  }
}
@media (max-width: 1721px) {
  .hide-in-1721 {
    display: none;
  }
}
@media (max-width: 1722px) {
  .hide-in-1722 {
    display: none;
  }
}
@media (max-width: 1723px) {
  .hide-in-1723 {
    display: none;
  }
}
@media (max-width: 1724px) {
  .hide-in-1724 {
    display: none;
  }
}
@media (max-width: 1725px) {
  .hide-in-1725 {
    display: none;
  }
}
@media (max-width: 1726px) {
  .hide-in-1726 {
    display: none;
  }
}
@media (max-width: 1727px) {
  .hide-in-1727 {
    display: none;
  }
}
@media (max-width: 1728px) {
  .hide-in-1728 {
    display: none;
  }
}
@media (max-width: 1729px) {
  .hide-in-1729 {
    display: none;
  }
}
@media (max-width: 1730px) {
  .hide-in-1730 {
    display: none;
  }
}
@media (max-width: 1731px) {
  .hide-in-1731 {
    display: none;
  }
}
@media (max-width: 1732px) {
  .hide-in-1732 {
    display: none;
  }
}
@media (max-width: 1733px) {
  .hide-in-1733 {
    display: none;
  }
}
@media (max-width: 1734px) {
  .hide-in-1734 {
    display: none;
  }
}
@media (max-width: 1735px) {
  .hide-in-1735 {
    display: none;
  }
}
@media (max-width: 1736px) {
  .hide-in-1736 {
    display: none;
  }
}
@media (max-width: 1737px) {
  .hide-in-1737 {
    display: none;
  }
}
@media (max-width: 1738px) {
  .hide-in-1738 {
    display: none;
  }
}
@media (max-width: 1739px) {
  .hide-in-1739 {
    display: none;
  }
}
@media (max-width: 1740px) {
  .hide-in-1740 {
    display: none;
  }
}
@media (max-width: 1741px) {
  .hide-in-1741 {
    display: none;
  }
}
@media (max-width: 1742px) {
  .hide-in-1742 {
    display: none;
  }
}
@media (max-width: 1743px) {
  .hide-in-1743 {
    display: none;
  }
}
@media (max-width: 1744px) {
  .hide-in-1744 {
    display: none;
  }
}
@media (max-width: 1745px) {
  .hide-in-1745 {
    display: none;
  }
}
@media (max-width: 1746px) {
  .hide-in-1746 {
    display: none;
  }
}
@media (max-width: 1747px) {
  .hide-in-1747 {
    display: none;
  }
}
@media (max-width: 1748px) {
  .hide-in-1748 {
    display: none;
  }
}
@media (max-width: 1749px) {
  .hide-in-1749 {
    display: none;
  }
}
@media (max-width: 1750px) {
  .hide-in-1750 {
    display: none;
  }
}
@media (max-width: 1751px) {
  .hide-in-1751 {
    display: none;
  }
}
@media (max-width: 1752px) {
  .hide-in-1752 {
    display: none;
  }
}
@media (max-width: 1753px) {
  .hide-in-1753 {
    display: none;
  }
}
@media (max-width: 1754px) {
  .hide-in-1754 {
    display: none;
  }
}
@media (max-width: 1755px) {
  .hide-in-1755 {
    display: none;
  }
}
@media (max-width: 1756px) {
  .hide-in-1756 {
    display: none;
  }
}
@media (max-width: 1757px) {
  .hide-in-1757 {
    display: none;
  }
}
@media (max-width: 1758px) {
  .hide-in-1758 {
    display: none;
  }
}
@media (max-width: 1759px) {
  .hide-in-1759 {
    display: none;
  }
}
@media (max-width: 1760px) {
  .hide-in-1760 {
    display: none;
  }
}
@media (max-width: 1761px) {
  .hide-in-1761 {
    display: none;
  }
}
@media (max-width: 1762px) {
  .hide-in-1762 {
    display: none;
  }
}
@media (max-width: 1763px) {
  .hide-in-1763 {
    display: none;
  }
}
@media (max-width: 1764px) {
  .hide-in-1764 {
    display: none;
  }
}
@media (max-width: 1765px) {
  .hide-in-1765 {
    display: none;
  }
}
@media (max-width: 1766px) {
  .hide-in-1766 {
    display: none;
  }
}
@media (max-width: 1767px) {
  .hide-in-1767 {
    display: none;
  }
}
@media (max-width: 1768px) {
  .hide-in-1768 {
    display: none;
  }
}
@media (max-width: 1769px) {
  .hide-in-1769 {
    display: none;
  }
}
@media (max-width: 1770px) {
  .hide-in-1770 {
    display: none;
  }
}
@media (max-width: 1771px) {
  .hide-in-1771 {
    display: none;
  }
}
@media (max-width: 1772px) {
  .hide-in-1772 {
    display: none;
  }
}
@media (max-width: 1773px) {
  .hide-in-1773 {
    display: none;
  }
}
@media (max-width: 1774px) {
  .hide-in-1774 {
    display: none;
  }
}
@media (max-width: 1775px) {
  .hide-in-1775 {
    display: none;
  }
}
@media (max-width: 1776px) {
  .hide-in-1776 {
    display: none;
  }
}
@media (max-width: 1777px) {
  .hide-in-1777 {
    display: none;
  }
}
@media (max-width: 1778px) {
  .hide-in-1778 {
    display: none;
  }
}
@media (max-width: 1779px) {
  .hide-in-1779 {
    display: none;
  }
}
@media (max-width: 1780px) {
  .hide-in-1780 {
    display: none;
  }
}
@media (max-width: 1781px) {
  .hide-in-1781 {
    display: none;
  }
}
@media (max-width: 1782px) {
  .hide-in-1782 {
    display: none;
  }
}
@media (max-width: 1783px) {
  .hide-in-1783 {
    display: none;
  }
}
@media (max-width: 1784px) {
  .hide-in-1784 {
    display: none;
  }
}
@media (max-width: 1785px) {
  .hide-in-1785 {
    display: none;
  }
}
@media (max-width: 1786px) {
  .hide-in-1786 {
    display: none;
  }
}
@media (max-width: 1787px) {
  .hide-in-1787 {
    display: none;
  }
}
@media (max-width: 1788px) {
  .hide-in-1788 {
    display: none;
  }
}
@media (max-width: 1789px) {
  .hide-in-1789 {
    display: none;
  }
}
@media (max-width: 1790px) {
  .hide-in-1790 {
    display: none;
  }
}
@media (max-width: 1791px) {
  .hide-in-1791 {
    display: none;
  }
}
@media (max-width: 1792px) {
  .hide-in-1792 {
    display: none;
  }
}
@media (max-width: 1793px) {
  .hide-in-1793 {
    display: none;
  }
}
@media (max-width: 1794px) {
  .hide-in-1794 {
    display: none;
  }
}
@media (max-width: 1795px) {
  .hide-in-1795 {
    display: none;
  }
}
@media (max-width: 1796px) {
  .hide-in-1796 {
    display: none;
  }
}
@media (max-width: 1797px) {
  .hide-in-1797 {
    display: none;
  }
}
@media (max-width: 1798px) {
  .hide-in-1798 {
    display: none;
  }
}
@media (max-width: 1799px) {
  .hide-in-1799 {
    display: none;
  }
}
@media (max-width: 1800px) {
  .hide-in-1800 {
    display: none;
  }
}
@media (max-width: 1801px) {
  .hide-in-1801 {
    display: none;
  }
}
@media (max-width: 1802px) {
  .hide-in-1802 {
    display: none;
  }
}
@media (max-width: 1803px) {
  .hide-in-1803 {
    display: none;
  }
}
@media (max-width: 1804px) {
  .hide-in-1804 {
    display: none;
  }
}
@media (max-width: 1805px) {
  .hide-in-1805 {
    display: none;
  }
}
@media (max-width: 1806px) {
  .hide-in-1806 {
    display: none;
  }
}
@media (max-width: 1807px) {
  .hide-in-1807 {
    display: none;
  }
}
@media (max-width: 1808px) {
  .hide-in-1808 {
    display: none;
  }
}
@media (max-width: 1809px) {
  .hide-in-1809 {
    display: none;
  }
}
@media (max-width: 1810px) {
  .hide-in-1810 {
    display: none;
  }
}
@media (max-width: 1811px) {
  .hide-in-1811 {
    display: none;
  }
}
@media (max-width: 1812px) {
  .hide-in-1812 {
    display: none;
  }
}
@media (max-width: 1813px) {
  .hide-in-1813 {
    display: none;
  }
}
@media (max-width: 1814px) {
  .hide-in-1814 {
    display: none;
  }
}
@media (max-width: 1815px) {
  .hide-in-1815 {
    display: none;
  }
}
@media (max-width: 1816px) {
  .hide-in-1816 {
    display: none;
  }
}
@media (max-width: 1817px) {
  .hide-in-1817 {
    display: none;
  }
}
@media (max-width: 1818px) {
  .hide-in-1818 {
    display: none;
  }
}
@media (max-width: 1819px) {
  .hide-in-1819 {
    display: none;
  }
}
@media (max-width: 1820px) {
  .hide-in-1820 {
    display: none;
  }
}
@media (max-width: 1821px) {
  .hide-in-1821 {
    display: none;
  }
}
@media (max-width: 1822px) {
  .hide-in-1822 {
    display: none;
  }
}
@media (max-width: 1823px) {
  .hide-in-1823 {
    display: none;
  }
}
@media (max-width: 1824px) {
  .hide-in-1824 {
    display: none;
  }
}
@media (max-width: 1825px) {
  .hide-in-1825 {
    display: none;
  }
}
@media (max-width: 1826px) {
  .hide-in-1826 {
    display: none;
  }
}
@media (max-width: 1827px) {
  .hide-in-1827 {
    display: none;
  }
}
@media (max-width: 1828px) {
  .hide-in-1828 {
    display: none;
  }
}
@media (max-width: 1829px) {
  .hide-in-1829 {
    display: none;
  }
}
@media (max-width: 1830px) {
  .hide-in-1830 {
    display: none;
  }
}
@media (max-width: 1831px) {
  .hide-in-1831 {
    display: none;
  }
}
@media (max-width: 1832px) {
  .hide-in-1832 {
    display: none;
  }
}
@media (max-width: 1833px) {
  .hide-in-1833 {
    display: none;
  }
}
@media (max-width: 1834px) {
  .hide-in-1834 {
    display: none;
  }
}
@media (max-width: 1835px) {
  .hide-in-1835 {
    display: none;
  }
}
@media (max-width: 1836px) {
  .hide-in-1836 {
    display: none;
  }
}
@media (max-width: 1837px) {
  .hide-in-1837 {
    display: none;
  }
}
@media (max-width: 1838px) {
  .hide-in-1838 {
    display: none;
  }
}
@media (max-width: 1839px) {
  .hide-in-1839 {
    display: none;
  }
}
@media (max-width: 1840px) {
  .hide-in-1840 {
    display: none;
  }
}
@media (max-width: 1841px) {
  .hide-in-1841 {
    display: none;
  }
}
@media (max-width: 1842px) {
  .hide-in-1842 {
    display: none;
  }
}
@media (max-width: 1843px) {
  .hide-in-1843 {
    display: none;
  }
}
@media (max-width: 1844px) {
  .hide-in-1844 {
    display: none;
  }
}
@media (max-width: 1845px) {
  .hide-in-1845 {
    display: none;
  }
}
@media (max-width: 1846px) {
  .hide-in-1846 {
    display: none;
  }
}
@media (max-width: 1847px) {
  .hide-in-1847 {
    display: none;
  }
}
@media (max-width: 1848px) {
  .hide-in-1848 {
    display: none;
  }
}
@media (max-width: 1849px) {
  .hide-in-1849 {
    display: none;
  }
}
@media (max-width: 1850px) {
  .hide-in-1850 {
    display: none;
  }
}
@media (max-width: 1851px) {
  .hide-in-1851 {
    display: none;
  }
}
@media (max-width: 1852px) {
  .hide-in-1852 {
    display: none;
  }
}
@media (max-width: 1853px) {
  .hide-in-1853 {
    display: none;
  }
}
@media (max-width: 1854px) {
  .hide-in-1854 {
    display: none;
  }
}
@media (max-width: 1855px) {
  .hide-in-1855 {
    display: none;
  }
}
@media (max-width: 1856px) {
  .hide-in-1856 {
    display: none;
  }
}
@media (max-width: 1857px) {
  .hide-in-1857 {
    display: none;
  }
}
@media (max-width: 1858px) {
  .hide-in-1858 {
    display: none;
  }
}
@media (max-width: 1859px) {
  .hide-in-1859 {
    display: none;
  }
}
@media (max-width: 1860px) {
  .hide-in-1860 {
    display: none;
  }
}
@media (max-width: 1861px) {
  .hide-in-1861 {
    display: none;
  }
}
@media (max-width: 1862px) {
  .hide-in-1862 {
    display: none;
  }
}
@media (max-width: 1863px) {
  .hide-in-1863 {
    display: none;
  }
}
@media (max-width: 1864px) {
  .hide-in-1864 {
    display: none;
  }
}
@media (max-width: 1865px) {
  .hide-in-1865 {
    display: none;
  }
}
@media (max-width: 1866px) {
  .hide-in-1866 {
    display: none;
  }
}
@media (max-width: 1867px) {
  .hide-in-1867 {
    display: none;
  }
}
@media (max-width: 1868px) {
  .hide-in-1868 {
    display: none;
  }
}
@media (max-width: 1869px) {
  .hide-in-1869 {
    display: none;
  }
}
@media (max-width: 1870px) {
  .hide-in-1870 {
    display: none;
  }
}
@media (max-width: 1871px) {
  .hide-in-1871 {
    display: none;
  }
}
@media (max-width: 1872px) {
  .hide-in-1872 {
    display: none;
  }
}
@media (max-width: 1873px) {
  .hide-in-1873 {
    display: none;
  }
}
@media (max-width: 1874px) {
  .hide-in-1874 {
    display: none;
  }
}
@media (max-width: 1875px) {
  .hide-in-1875 {
    display: none;
  }
}
@media (max-width: 1876px) {
  .hide-in-1876 {
    display: none;
  }
}
@media (max-width: 1877px) {
  .hide-in-1877 {
    display: none;
  }
}
@media (max-width: 1878px) {
  .hide-in-1878 {
    display: none;
  }
}
@media (max-width: 1879px) {
  .hide-in-1879 {
    display: none;
  }
}
@media (max-width: 1880px) {
  .hide-in-1880 {
    display: none;
  }
}
@media (max-width: 1881px) {
  .hide-in-1881 {
    display: none;
  }
}
@media (max-width: 1882px) {
  .hide-in-1882 {
    display: none;
  }
}
@media (max-width: 1883px) {
  .hide-in-1883 {
    display: none;
  }
}
@media (max-width: 1884px) {
  .hide-in-1884 {
    display: none;
  }
}
@media (max-width: 1885px) {
  .hide-in-1885 {
    display: none;
  }
}
@media (max-width: 1886px) {
  .hide-in-1886 {
    display: none;
  }
}
@media (max-width: 1887px) {
  .hide-in-1887 {
    display: none;
  }
}
@media (max-width: 1888px) {
  .hide-in-1888 {
    display: none;
  }
}
@media (max-width: 1889px) {
  .hide-in-1889 {
    display: none;
  }
}
@media (max-width: 1890px) {
  .hide-in-1890 {
    display: none;
  }
}
@media (max-width: 1891px) {
  .hide-in-1891 {
    display: none;
  }
}
@media (max-width: 1892px) {
  .hide-in-1892 {
    display: none;
  }
}
@media (max-width: 1893px) {
  .hide-in-1893 {
    display: none;
  }
}
@media (max-width: 1894px) {
  .hide-in-1894 {
    display: none;
  }
}
@media (max-width: 1895px) {
  .hide-in-1895 {
    display: none;
  }
}
@media (max-width: 1896px) {
  .hide-in-1896 {
    display: none;
  }
}
@media (max-width: 1897px) {
  .hide-in-1897 {
    display: none;
  }
}
@media (max-width: 1898px) {
  .hide-in-1898 {
    display: none;
  }
}
@media (max-width: 1899px) {
  .hide-in-1899 {
    display: none;
  }
}
@media (max-width: 1900px) {
  .hide-in-1900 {
    display: none;
  }
}
@media (max-width: 1901px) {
  .hide-in-1901 {
    display: none;
  }
}
@media (max-width: 1902px) {
  .hide-in-1902 {
    display: none;
  }
}
@media (max-width: 1903px) {
  .hide-in-1903 {
    display: none;
  }
}
@media (max-width: 1904px) {
  .hide-in-1904 {
    display: none;
  }
}
@media (max-width: 1905px) {
  .hide-in-1905 {
    display: none;
  }
}
@media (max-width: 1906px) {
  .hide-in-1906 {
    display: none;
  }
}
@media (max-width: 1907px) {
  .hide-in-1907 {
    display: none;
  }
}
@media (max-width: 1908px) {
  .hide-in-1908 {
    display: none;
  }
}
@media (max-width: 1909px) {
  .hide-in-1909 {
    display: none;
  }
}
@media (max-width: 1910px) {
  .hide-in-1910 {
    display: none;
  }
}
@media (max-width: 1911px) {
  .hide-in-1911 {
    display: none;
  }
}
@media (max-width: 1912px) {
  .hide-in-1912 {
    display: none;
  }
}
@media (max-width: 1913px) {
  .hide-in-1913 {
    display: none;
  }
}
@media (max-width: 1914px) {
  .hide-in-1914 {
    display: none;
  }
}
@media (max-width: 1915px) {
  .hide-in-1915 {
    display: none;
  }
}
@media (max-width: 1916px) {
  .hide-in-1916 {
    display: none;
  }
}
@media (max-width: 1917px) {
  .hide-in-1917 {
    display: none;
  }
}
@media (max-width: 1918px) {
  .hide-in-1918 {
    display: none;
  }
}
@media (max-width: 1919px) {
  .hide-in-1919 {
    display: none;
  }
}
@media (max-width: 1920px) {
  .hide-in-1920 {
    display: none;
  }
}
@media (max-width: 1921px) {
  .hide-in-1921 {
    display: none;
  }
}
@media (max-width: 1922px) {
  .hide-in-1922 {
    display: none;
  }
}
@media (max-width: 1923px) {
  .hide-in-1923 {
    display: none;
  }
}
@media (max-width: 1924px) {
  .hide-in-1924 {
    display: none;
  }
}
@media (max-width: 1925px) {
  .hide-in-1925 {
    display: none;
  }
}
@media (max-width: 1926px) {
  .hide-in-1926 {
    display: none;
  }
}
@media (max-width: 1927px) {
  .hide-in-1927 {
    display: none;
  }
}
@media (max-width: 1928px) {
  .hide-in-1928 {
    display: none;
  }
}
@media (max-width: 1929px) {
  .hide-in-1929 {
    display: none;
  }
}
@media (max-width: 1930px) {
  .hide-in-1930 {
    display: none;
  }
}
@media (max-width: 1931px) {
  .hide-in-1931 {
    display: none;
  }
}
@media (max-width: 1932px) {
  .hide-in-1932 {
    display: none;
  }
}
@media (max-width: 1933px) {
  .hide-in-1933 {
    display: none;
  }
}
@media (max-width: 1934px) {
  .hide-in-1934 {
    display: none;
  }
}
@media (max-width: 1935px) {
  .hide-in-1935 {
    display: none;
  }
}
@media (max-width: 1936px) {
  .hide-in-1936 {
    display: none;
  }
}
@media (max-width: 1937px) {
  .hide-in-1937 {
    display: none;
  }
}
@media (max-width: 1938px) {
  .hide-in-1938 {
    display: none;
  }
}
@media (max-width: 1939px) {
  .hide-in-1939 {
    display: none;
  }
}
@media (max-width: 1940px) {
  .hide-in-1940 {
    display: none;
  }
}
@media (max-width: 1941px) {
  .hide-in-1941 {
    display: none;
  }
}
@media (max-width: 1942px) {
  .hide-in-1942 {
    display: none;
  }
}
@media (max-width: 1943px) {
  .hide-in-1943 {
    display: none;
  }
}
@media (max-width: 1944px) {
  .hide-in-1944 {
    display: none;
  }
}
@media (max-width: 1945px) {
  .hide-in-1945 {
    display: none;
  }
}
@media (max-width: 1946px) {
  .hide-in-1946 {
    display: none;
  }
}
@media (max-width: 1947px) {
  .hide-in-1947 {
    display: none;
  }
}
@media (max-width: 1948px) {
  .hide-in-1948 {
    display: none;
  }
}
@media (max-width: 1949px) {
  .hide-in-1949 {
    display: none;
  }
}
@media (max-width: 1950px) {
  .hide-in-1950 {
    display: none;
  }
}
@media (max-width: 1951px) {
  .hide-in-1951 {
    display: none;
  }
}
@media (max-width: 1952px) {
  .hide-in-1952 {
    display: none;
  }
}
@media (max-width: 1953px) {
  .hide-in-1953 {
    display: none;
  }
}
@media (max-width: 1954px) {
  .hide-in-1954 {
    display: none;
  }
}
@media (max-width: 1955px) {
  .hide-in-1955 {
    display: none;
  }
}
@media (max-width: 1956px) {
  .hide-in-1956 {
    display: none;
  }
}
@media (max-width: 1957px) {
  .hide-in-1957 {
    display: none;
  }
}
@media (max-width: 1958px) {
  .hide-in-1958 {
    display: none;
  }
}
@media (max-width: 1959px) {
  .hide-in-1959 {
    display: none;
  }
}
@media (max-width: 1960px) {
  .hide-in-1960 {
    display: none;
  }
}
@media (max-width: 1961px) {
  .hide-in-1961 {
    display: none;
  }
}
@media (max-width: 1962px) {
  .hide-in-1962 {
    display: none;
  }
}
@media (max-width: 1963px) {
  .hide-in-1963 {
    display: none;
  }
}
@media (max-width: 1964px) {
  .hide-in-1964 {
    display: none;
  }
}
@media (max-width: 1965px) {
  .hide-in-1965 {
    display: none;
  }
}
@media (max-width: 1966px) {
  .hide-in-1966 {
    display: none;
  }
}
@media (max-width: 1967px) {
  .hide-in-1967 {
    display: none;
  }
}
@media (max-width: 1968px) {
  .hide-in-1968 {
    display: none;
  }
}
@media (max-width: 1969px) {
  .hide-in-1969 {
    display: none;
  }
}
@media (max-width: 1970px) {
  .hide-in-1970 {
    display: none;
  }
}
@media (max-width: 1971px) {
  .hide-in-1971 {
    display: none;
  }
}
@media (max-width: 1972px) {
  .hide-in-1972 {
    display: none;
  }
}
@media (max-width: 1973px) {
  .hide-in-1973 {
    display: none;
  }
}
@media (max-width: 1974px) {
  .hide-in-1974 {
    display: none;
  }
}
@media (max-width: 1975px) {
  .hide-in-1975 {
    display: none;
  }
}
@media (max-width: 1976px) {
  .hide-in-1976 {
    display: none;
  }
}
@media (max-width: 1977px) {
  .hide-in-1977 {
    display: none;
  }
}
@media (max-width: 1978px) {
  .hide-in-1978 {
    display: none;
  }
}
@media (max-width: 1979px) {
  .hide-in-1979 {
    display: none;
  }
}
@media (max-width: 1980px) {
  .hide-in-1980 {
    display: none;
  }
}
.w0 {
  width: 0px;
}

.mw0 {
  max-width: 0px;
}

.h0 {
  height: 0px;
}

.mh0 {
  max-height: 0px;
}

.fs0 {
  font-size: 0px;
}

.lh0 {
  line-height: 0px;
}

.mt0 {
  margin-top: 0px;
}

.mb0 {
  margin-bottom: 0px;
}

.ml0 {
  margin-left: 0px;
}

.mr0 {
  margin-right: 0px;
}

.lh0 {
  line-height: 0px;
}

.w1 {
  width: 1px;
}

.mw1 {
  max-width: 1px;
}

.h1 {
  height: 1px;
}

.mh1 {
  max-height: 1px;
}

.fs1 {
  font-size: 1px;
}

.lh1 {
  line-height: 1px;
}

.mt1 {
  margin-top: 1px;
}

.mb1 {
  margin-bottom: 1px;
}

.ml1 {
  margin-left: 1px;
}

.mr1 {
  margin-right: 1px;
}

.lh1 {
  line-height: 1px;
}

.w2 {
  width: 2px;
}

.mw2 {
  max-width: 2px;
}

.h2 {
  height: 2px;
}

.mh2 {
  max-height: 2px;
}

.fs2 {
  font-size: 2px;
}

.lh2 {
  line-height: 2px;
}

.mt2 {
  margin-top: 2px;
}

.mb2 {
  margin-bottom: 2px;
}

.ml2 {
  margin-left: 2px;
}

.mr2 {
  margin-right: 2px;
}

.lh2 {
  line-height: 2px;
}

.w3 {
  width: 3px;
}

.mw3 {
  max-width: 3px;
}

.h3 {
  height: 3px;
}

.mh3 {
  max-height: 3px;
}

.fs3 {
  font-size: 3px;
}

.lh3 {
  line-height: 3px;
}

.mt3 {
  margin-top: 3px;
}

.mb3 {
  margin-bottom: 3px;
}

.ml3 {
  margin-left: 3px;
}

.mr3 {
  margin-right: 3px;
}

.lh3 {
  line-height: 3px;
}

.w4 {
  width: 4px;
}

.mw4 {
  max-width: 4px;
}

.h4 {
  height: 4px;
}

.mh4 {
  max-height: 4px;
}

.fs4 {
  font-size: 4px;
}

.lh4 {
  line-height: 4px;
}

.mt4 {
  margin-top: 4px;
}

.mb4 {
  margin-bottom: 4px;
}

.ml4 {
  margin-left: 4px;
}

.mr4 {
  margin-right: 4px;
}

.lh4 {
  line-height: 4px;
}

.w5 {
  width: 5px;
}

.mw5 {
  max-width: 5px;
}

.h5 {
  height: 5px;
}

.mh5 {
  max-height: 5px;
}

.fs5 {
  font-size: 5px;
}

.lh5 {
  line-height: 5px;
}

.mt5 {
  margin-top: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.lh5 {
  line-height: 5px;
}

.w6 {
  width: 6px;
}

.mw6 {
  max-width: 6px;
}

.h6 {
  height: 6px;
}

.mh6 {
  max-height: 6px;
}

.fs6 {
  font-size: 6px;
}

.lh6 {
  line-height: 6px;
}

.mt6 {
  margin-top: 6px;
}

.mb6 {
  margin-bottom: 6px;
}

.ml6 {
  margin-left: 6px;
}

.mr6 {
  margin-right: 6px;
}

.lh6 {
  line-height: 6px;
}

.w7 {
  width: 7px;
}

.mw7 {
  max-width: 7px;
}

.h7 {
  height: 7px;
}

.mh7 {
  max-height: 7px;
}

.fs7 {
  font-size: 7px;
}

.lh7 {
  line-height: 7px;
}

.mt7 {
  margin-top: 7px;
}

.mb7 {
  margin-bottom: 7px;
}

.ml7 {
  margin-left: 7px;
}

.mr7 {
  margin-right: 7px;
}

.lh7 {
  line-height: 7px;
}

.w8 {
  width: 8px;
}

.mw8 {
  max-width: 8px;
}

.h8 {
  height: 8px;
}

.mh8 {
  max-height: 8px;
}

.fs8 {
  font-size: 8px;
}

.lh8 {
  line-height: 8px;
}

.mt8 {
  margin-top: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.ml8 {
  margin-left: 8px;
}

.mr8 {
  margin-right: 8px;
}

.lh8 {
  line-height: 8px;
}

.w9 {
  width: 9px;
}

.mw9 {
  max-width: 9px;
}

.h9 {
  height: 9px;
}

.mh9 {
  max-height: 9px;
}

.fs9 {
  font-size: 9px;
}

.lh9 {
  line-height: 9px;
}

.mt9 {
  margin-top: 9px;
}

.mb9 {
  margin-bottom: 9px;
}

.ml9 {
  margin-left: 9px;
}

.mr9 {
  margin-right: 9px;
}

.lh9 {
  line-height: 9px;
}

.w10 {
  width: 10px;
}

.mw10 {
  max-width: 10px;
}

.h10 {
  height: 10px;
}

.mh10 {
  max-height: 10px;
}

.fs10 {
  font-size: 10px;
}

.lh10 {
  line-height: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mr10 {
  margin-right: 10px;
}

.lh10 {
  line-height: 10px;
}

.w11 {
  width: 11px;
}

.mw11 {
  max-width: 11px;
}

.h11 {
  height: 11px;
}

.mh11 {
  max-height: 11px;
}

.fs11 {
  font-size: 11px;
}

.lh11 {
  line-height: 11px;
}

.mt11 {
  margin-top: 11px;
}

.mb11 {
  margin-bottom: 11px;
}

.ml11 {
  margin-left: 11px;
}

.mr11 {
  margin-right: 11px;
}

.lh11 {
  line-height: 11px;
}

.w12 {
  width: 12px;
}

.mw12 {
  max-width: 12px;
}

.h12 {
  height: 12px;
}

.mh12 {
  max-height: 12px;
}

.fs12 {
  font-size: 12px;
}

.lh12 {
  line-height: 12px;
}

.mt12 {
  margin-top: 12px;
}

.mb12 {
  margin-bottom: 12px;
}

.ml12 {
  margin-left: 12px;
}

.mr12 {
  margin-right: 12px;
}

.lh12 {
  line-height: 12px;
}

.w13 {
  width: 13px;
}

.mw13 {
  max-width: 13px;
}

.h13 {
  height: 13px;
}

.mh13 {
  max-height: 13px;
}

.fs13 {
  font-size: 13px;
}

.lh13 {
  line-height: 13px;
}

.mt13 {
  margin-top: 13px;
}

.mb13 {
  margin-bottom: 13px;
}

.ml13 {
  margin-left: 13px;
}

.mr13 {
  margin-right: 13px;
}

.lh13 {
  line-height: 13px;
}

.w14 {
  width: 14px;
}

.mw14 {
  max-width: 14px;
}

.h14 {
  height: 14px;
}

.mh14 {
  max-height: 14px;
}

.fs14 {
  font-size: 14px;
}

.lh14 {
  line-height: 14px;
}

.mt14 {
  margin-top: 14px;
}

.mb14 {
  margin-bottom: 14px;
}

.ml14 {
  margin-left: 14px;
}

.mr14 {
  margin-right: 14px;
}

.lh14 {
  line-height: 14px;
}

.w15 {
  width: 15px;
}

.mw15 {
  max-width: 15px;
}

.h15 {
  height: 15px;
}

.mh15 {
  max-height: 15px;
}

.fs15 {
  font-size: 15px;
}

.lh15 {
  line-height: 15px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.ml15 {
  margin-left: 15px;
}

.mr15 {
  margin-right: 15px;
}

.lh15 {
  line-height: 15px;
}

.w16 {
  width: 16px;
}

.mw16 {
  max-width: 16px;
}

.h16 {
  height: 16px;
}

.mh16 {
  max-height: 16px;
}

.fs16 {
  font-size: 16px;
}

.lh16 {
  line-height: 16px;
}

.mt16 {
  margin-top: 16px;
}

.mb16 {
  margin-bottom: 16px;
}

.ml16 {
  margin-left: 16px;
}

.mr16 {
  margin-right: 16px;
}

.lh16 {
  line-height: 16px;
}

.w17 {
  width: 17px;
}

.mw17 {
  max-width: 17px;
}

.h17 {
  height: 17px;
}

.mh17 {
  max-height: 17px;
}

.fs17 {
  font-size: 17px;
}

.lh17 {
  line-height: 17px;
}

.mt17 {
  margin-top: 17px;
}

.mb17 {
  margin-bottom: 17px;
}

.ml17 {
  margin-left: 17px;
}

.mr17 {
  margin-right: 17px;
}

.lh17 {
  line-height: 17px;
}

.w18 {
  width: 18px;
}

.mw18 {
  max-width: 18px;
}

.h18 {
  height: 18px;
}

.mh18 {
  max-height: 18px;
}

.fs18 {
  font-size: 18px;
}

.lh18 {
  line-height: 18px;
}

.mt18 {
  margin-top: 18px;
}

.mb18 {
  margin-bottom: 18px;
}

.ml18 {
  margin-left: 18px;
}

.mr18 {
  margin-right: 18px;
}

.lh18 {
  line-height: 18px;
}

.w19 {
  width: 19px;
}

.mw19 {
  max-width: 19px;
}

.h19 {
  height: 19px;
}

.mh19 {
  max-height: 19px;
}

.fs19 {
  font-size: 19px;
}

.lh19 {
  line-height: 19px;
}

.mt19 {
  margin-top: 19px;
}

.mb19 {
  margin-bottom: 19px;
}

.ml19 {
  margin-left: 19px;
}

.mr19 {
  margin-right: 19px;
}

.lh19 {
  line-height: 19px;
}

.w20 {
  width: 20px;
}

.mw20 {
  max-width: 20px;
}

.h20 {
  height: 20px;
}

.mh20 {
  max-height: 20px;
}

.fs20 {
  font-size: 20px;
}

.lh20 {
  line-height: 20px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.ml20 {
  margin-left: 20px;
}

.mr20 {
  margin-right: 20px;
}

.lh20 {
  line-height: 20px;
}

.w21 {
  width: 21px;
}

.mw21 {
  max-width: 21px;
}

.h21 {
  height: 21px;
}

.mh21 {
  max-height: 21px;
}

.fs21 {
  font-size: 21px;
}

.lh21 {
  line-height: 21px;
}

.mt21 {
  margin-top: 21px;
}

.mb21 {
  margin-bottom: 21px;
}

.ml21 {
  margin-left: 21px;
}

.mr21 {
  margin-right: 21px;
}

.lh21 {
  line-height: 21px;
}

.w22 {
  width: 22px;
}

.mw22 {
  max-width: 22px;
}

.h22 {
  height: 22px;
}

.mh22 {
  max-height: 22px;
}

.fs22 {
  font-size: 22px;
}

.lh22 {
  line-height: 22px;
}

.mt22 {
  margin-top: 22px;
}

.mb22 {
  margin-bottom: 22px;
}

.ml22 {
  margin-left: 22px;
}

.mr22 {
  margin-right: 22px;
}

.lh22 {
  line-height: 22px;
}

.w23 {
  width: 23px;
}

.mw23 {
  max-width: 23px;
}

.h23 {
  height: 23px;
}

.mh23 {
  max-height: 23px;
}

.fs23 {
  font-size: 23px;
}

.lh23 {
  line-height: 23px;
}

.mt23 {
  margin-top: 23px;
}

.mb23 {
  margin-bottom: 23px;
}

.ml23 {
  margin-left: 23px;
}

.mr23 {
  margin-right: 23px;
}

.lh23 {
  line-height: 23px;
}

.w24 {
  width: 24px;
}

.mw24 {
  max-width: 24px;
}

.h24 {
  height: 24px;
}

.mh24 {
  max-height: 24px;
}

.fs24 {
  font-size: 24px;
}

.lh24 {
  line-height: 24px;
}

.mt24 {
  margin-top: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.ml24 {
  margin-left: 24px;
}

.mr24 {
  margin-right: 24px;
}

.lh24 {
  line-height: 24px;
}

.w25 {
  width: 25px;
}

.mw25 {
  max-width: 25px;
}

.h25 {
  height: 25px;
}

.mh25 {
  max-height: 25px;
}

.fs25 {
  font-size: 25px;
}

.lh25 {
  line-height: 25px;
}

.mt25 {
  margin-top: 25px;
}

.mb25 {
  margin-bottom: 25px;
}

.ml25 {
  margin-left: 25px;
}

.mr25 {
  margin-right: 25px;
}

.lh25 {
  line-height: 25px;
}

.w26 {
  width: 26px;
}

.mw26 {
  max-width: 26px;
}

.h26 {
  height: 26px;
}

.mh26 {
  max-height: 26px;
}

.fs26 {
  font-size: 26px;
}

.lh26 {
  line-height: 26px;
}

.mt26 {
  margin-top: 26px;
}

.mb26 {
  margin-bottom: 26px;
}

.ml26 {
  margin-left: 26px;
}

.mr26 {
  margin-right: 26px;
}

.lh26 {
  line-height: 26px;
}

.w27 {
  width: 27px;
}

.mw27 {
  max-width: 27px;
}

.h27 {
  height: 27px;
}

.mh27 {
  max-height: 27px;
}

.fs27 {
  font-size: 27px;
}

.lh27 {
  line-height: 27px;
}

.mt27 {
  margin-top: 27px;
}

.mb27 {
  margin-bottom: 27px;
}

.ml27 {
  margin-left: 27px;
}

.mr27 {
  margin-right: 27px;
}

.lh27 {
  line-height: 27px;
}

.w28 {
  width: 28px;
}

.mw28 {
  max-width: 28px;
}

.h28 {
  height: 28px;
}

.mh28 {
  max-height: 28px;
}

.fs28 {
  font-size: 28px;
}

.lh28 {
  line-height: 28px;
}

.mt28 {
  margin-top: 28px;
}

.mb28 {
  margin-bottom: 28px;
}

.ml28 {
  margin-left: 28px;
}

.mr28 {
  margin-right: 28px;
}

.lh28 {
  line-height: 28px;
}

.w29 {
  width: 29px;
}

.mw29 {
  max-width: 29px;
}

.h29 {
  height: 29px;
}

.mh29 {
  max-height: 29px;
}

.fs29 {
  font-size: 29px;
}

.lh29 {
  line-height: 29px;
}

.mt29 {
  margin-top: 29px;
}

.mb29 {
  margin-bottom: 29px;
}

.ml29 {
  margin-left: 29px;
}

.mr29 {
  margin-right: 29px;
}

.lh29 {
  line-height: 29px;
}

.w30 {
  width: 30px;
}

.mw30 {
  max-width: 30px;
}

.h30 {
  height: 30px;
}

.mh30 {
  max-height: 30px;
}

.fs30 {
  font-size: 30px;
}

.lh30 {
  line-height: 30px;
}

.mt30 {
  margin-top: 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.ml30 {
  margin-left: 30px;
}

.mr30 {
  margin-right: 30px;
}

.lh30 {
  line-height: 30px;
}

.w31 {
  width: 31px;
}

.mw31 {
  max-width: 31px;
}

.h31 {
  height: 31px;
}

.mh31 {
  max-height: 31px;
}

.fs31 {
  font-size: 31px;
}

.lh31 {
  line-height: 31px;
}

.mt31 {
  margin-top: 31px;
}

.mb31 {
  margin-bottom: 31px;
}

.ml31 {
  margin-left: 31px;
}

.mr31 {
  margin-right: 31px;
}

.lh31 {
  line-height: 31px;
}

.w32 {
  width: 32px;
}

.mw32 {
  max-width: 32px;
}

.h32 {
  height: 32px;
}

.mh32 {
  max-height: 32px;
}

.fs32 {
  font-size: 32px;
}

.lh32 {
  line-height: 32px;
}

.mt32 {
  margin-top: 32px;
}

.mb32 {
  margin-bottom: 32px;
}

.ml32 {
  margin-left: 32px;
}

.mr32 {
  margin-right: 32px;
}

.lh32 {
  line-height: 32px;
}

.w33 {
  width: 33px;
}

.mw33 {
  max-width: 33px;
}

.h33 {
  height: 33px;
}

.mh33 {
  max-height: 33px;
}

.fs33 {
  font-size: 33px;
}

.lh33 {
  line-height: 33px;
}

.mt33 {
  margin-top: 33px;
}

.mb33 {
  margin-bottom: 33px;
}

.ml33 {
  margin-left: 33px;
}

.mr33 {
  margin-right: 33px;
}

.lh33 {
  line-height: 33px;
}

.w34 {
  width: 34px;
}

.mw34 {
  max-width: 34px;
}

.h34 {
  height: 34px;
}

.mh34 {
  max-height: 34px;
}

.fs34 {
  font-size: 34px;
}

.lh34 {
  line-height: 34px;
}

.mt34 {
  margin-top: 34px;
}

.mb34 {
  margin-bottom: 34px;
}

.ml34 {
  margin-left: 34px;
}

.mr34 {
  margin-right: 34px;
}

.lh34 {
  line-height: 34px;
}

.w35 {
  width: 35px;
}

.mw35 {
  max-width: 35px;
}

.h35 {
  height: 35px;
}

.mh35 {
  max-height: 35px;
}

.fs35 {
  font-size: 35px;
}

.lh35 {
  line-height: 35px;
}

.mt35 {
  margin-top: 35px;
}

.mb35 {
  margin-bottom: 35px;
}

.ml35 {
  margin-left: 35px;
}

.mr35 {
  margin-right: 35px;
}

.lh35 {
  line-height: 35px;
}

.w36 {
  width: 36px;
}

.mw36 {
  max-width: 36px;
}

.h36 {
  height: 36px;
}

.mh36 {
  max-height: 36px;
}

.fs36 {
  font-size: 36px;
}

.lh36 {
  line-height: 36px;
}

.mt36 {
  margin-top: 36px;
}

.mb36 {
  margin-bottom: 36px;
}

.ml36 {
  margin-left: 36px;
}

.mr36 {
  margin-right: 36px;
}

.lh36 {
  line-height: 36px;
}

.w37 {
  width: 37px;
}

.mw37 {
  max-width: 37px;
}

.h37 {
  height: 37px;
}

.mh37 {
  max-height: 37px;
}

.fs37 {
  font-size: 37px;
}

.lh37 {
  line-height: 37px;
}

.mt37 {
  margin-top: 37px;
}

.mb37 {
  margin-bottom: 37px;
}

.ml37 {
  margin-left: 37px;
}

.mr37 {
  margin-right: 37px;
}

.lh37 {
  line-height: 37px;
}

.w38 {
  width: 38px;
}

.mw38 {
  max-width: 38px;
}

.h38 {
  height: 38px;
}

.mh38 {
  max-height: 38px;
}

.fs38 {
  font-size: 38px;
}

.lh38 {
  line-height: 38px;
}

.mt38 {
  margin-top: 38px;
}

.mb38 {
  margin-bottom: 38px;
}

.ml38 {
  margin-left: 38px;
}

.mr38 {
  margin-right: 38px;
}

.lh38 {
  line-height: 38px;
}

.w39 {
  width: 39px;
}

.mw39 {
  max-width: 39px;
}

.h39 {
  height: 39px;
}

.mh39 {
  max-height: 39px;
}

.fs39 {
  font-size: 39px;
}

.lh39 {
  line-height: 39px;
}

.mt39 {
  margin-top: 39px;
}

.mb39 {
  margin-bottom: 39px;
}

.ml39 {
  margin-left: 39px;
}

.mr39 {
  margin-right: 39px;
}

.lh39 {
  line-height: 39px;
}

.w40 {
  width: 40px;
}

.mw40 {
  max-width: 40px;
}

.h40 {
  height: 40px;
}

.mh40 {
  max-height: 40px;
}

.fs40 {
  font-size: 40px;
}

.lh40 {
  line-height: 40px;
}

.mt40 {
  margin-top: 40px;
}

.mb40 {
  margin-bottom: 40px;
}

.ml40 {
  margin-left: 40px;
}

.mr40 {
  margin-right: 40px;
}

.lh40 {
  line-height: 40px;
}

.w41 {
  width: 41px;
}

.mw41 {
  max-width: 41px;
}

.h41 {
  height: 41px;
}

.mh41 {
  max-height: 41px;
}

.fs41 {
  font-size: 41px;
}

.lh41 {
  line-height: 41px;
}

.mt41 {
  margin-top: 41px;
}

.mb41 {
  margin-bottom: 41px;
}

.ml41 {
  margin-left: 41px;
}

.mr41 {
  margin-right: 41px;
}

.lh41 {
  line-height: 41px;
}

.w42 {
  width: 42px;
}

.mw42 {
  max-width: 42px;
}

.h42 {
  height: 42px;
}

.mh42 {
  max-height: 42px;
}

.fs42 {
  font-size: 42px;
}

.lh42 {
  line-height: 42px;
}

.mt42 {
  margin-top: 42px;
}

.mb42 {
  margin-bottom: 42px;
}

.ml42 {
  margin-left: 42px;
}

.mr42 {
  margin-right: 42px;
}

.lh42 {
  line-height: 42px;
}

.w43 {
  width: 43px;
}

.mw43 {
  max-width: 43px;
}

.h43 {
  height: 43px;
}

.mh43 {
  max-height: 43px;
}

.fs43 {
  font-size: 43px;
}

.lh43 {
  line-height: 43px;
}

.mt43 {
  margin-top: 43px;
}

.mb43 {
  margin-bottom: 43px;
}

.ml43 {
  margin-left: 43px;
}

.mr43 {
  margin-right: 43px;
}

.lh43 {
  line-height: 43px;
}

.w44 {
  width: 44px;
}

.mw44 {
  max-width: 44px;
}

.h44 {
  height: 44px;
}

.mh44 {
  max-height: 44px;
}

.fs44 {
  font-size: 44px;
}

.lh44 {
  line-height: 44px;
}

.mt44 {
  margin-top: 44px;
}

.mb44 {
  margin-bottom: 44px;
}

.ml44 {
  margin-left: 44px;
}

.mr44 {
  margin-right: 44px;
}

.lh44 {
  line-height: 44px;
}

.w45 {
  width: 45px;
}

.mw45 {
  max-width: 45px;
}

.h45 {
  height: 45px;
}

.mh45 {
  max-height: 45px;
}

.fs45 {
  font-size: 45px;
}

.lh45 {
  line-height: 45px;
}

.mt45 {
  margin-top: 45px;
}

.mb45 {
  margin-bottom: 45px;
}

.ml45 {
  margin-left: 45px;
}

.mr45 {
  margin-right: 45px;
}

.lh45 {
  line-height: 45px;
}

.w46 {
  width: 46px;
}

.mw46 {
  max-width: 46px;
}

.h46 {
  height: 46px;
}

.mh46 {
  max-height: 46px;
}

.fs46 {
  font-size: 46px;
}

.lh46 {
  line-height: 46px;
}

.mt46 {
  margin-top: 46px;
}

.mb46 {
  margin-bottom: 46px;
}

.ml46 {
  margin-left: 46px;
}

.mr46 {
  margin-right: 46px;
}

.lh46 {
  line-height: 46px;
}

.w47 {
  width: 47px;
}

.mw47 {
  max-width: 47px;
}

.h47 {
  height: 47px;
}

.mh47 {
  max-height: 47px;
}

.fs47 {
  font-size: 47px;
}

.lh47 {
  line-height: 47px;
}

.mt47 {
  margin-top: 47px;
}

.mb47 {
  margin-bottom: 47px;
}

.ml47 {
  margin-left: 47px;
}

.mr47 {
  margin-right: 47px;
}

.lh47 {
  line-height: 47px;
}

.w48 {
  width: 48px;
}

.mw48 {
  max-width: 48px;
}

.h48 {
  height: 48px;
}

.mh48 {
  max-height: 48px;
}

.fs48 {
  font-size: 48px;
}

.lh48 {
  line-height: 48px;
}

.mt48 {
  margin-top: 48px;
}

.mb48 {
  margin-bottom: 48px;
}

.ml48 {
  margin-left: 48px;
}

.mr48 {
  margin-right: 48px;
}

.lh48 {
  line-height: 48px;
}

.w49 {
  width: 49px;
}

.mw49 {
  max-width: 49px;
}

.h49 {
  height: 49px;
}

.mh49 {
  max-height: 49px;
}

.fs49 {
  font-size: 49px;
}

.lh49 {
  line-height: 49px;
}

.mt49 {
  margin-top: 49px;
}

.mb49 {
  margin-bottom: 49px;
}

.ml49 {
  margin-left: 49px;
}

.mr49 {
  margin-right: 49px;
}

.lh49 {
  line-height: 49px;
}

.w50 {
  width: 50px;
}

.mw50 {
  max-width: 50px;
}

.h50 {
  height: 50px;
}

.mh50 {
  max-height: 50px;
}

.fs50 {
  font-size: 50px;
}

.lh50 {
  line-height: 50px;
}

.mt50 {
  margin-top: 50px;
}

.mb50 {
  margin-bottom: 50px;
}

.ml50 {
  margin-left: 50px;
}

.mr50 {
  margin-right: 50px;
}

.lh50 {
  line-height: 50px;
}

.w51 {
  width: 51px;
}

.mw51 {
  max-width: 51px;
}

.h51 {
  height: 51px;
}

.mh51 {
  max-height: 51px;
}

.fs51 {
  font-size: 51px;
}

.lh51 {
  line-height: 51px;
}

.mt51 {
  margin-top: 51px;
}

.mb51 {
  margin-bottom: 51px;
}

.ml51 {
  margin-left: 51px;
}

.mr51 {
  margin-right: 51px;
}

.lh51 {
  line-height: 51px;
}

.w52 {
  width: 52px;
}

.mw52 {
  max-width: 52px;
}

.h52 {
  height: 52px;
}

.mh52 {
  max-height: 52px;
}

.fs52 {
  font-size: 52px;
}

.lh52 {
  line-height: 52px;
}

.mt52 {
  margin-top: 52px;
}

.mb52 {
  margin-bottom: 52px;
}

.ml52 {
  margin-left: 52px;
}

.mr52 {
  margin-right: 52px;
}

.lh52 {
  line-height: 52px;
}

.w53 {
  width: 53px;
}

.mw53 {
  max-width: 53px;
}

.h53 {
  height: 53px;
}

.mh53 {
  max-height: 53px;
}

.fs53 {
  font-size: 53px;
}

.lh53 {
  line-height: 53px;
}

.mt53 {
  margin-top: 53px;
}

.mb53 {
  margin-bottom: 53px;
}

.ml53 {
  margin-left: 53px;
}

.mr53 {
  margin-right: 53px;
}

.lh53 {
  line-height: 53px;
}

.w54 {
  width: 54px;
}

.mw54 {
  max-width: 54px;
}

.h54 {
  height: 54px;
}

.mh54 {
  max-height: 54px;
}

.fs54 {
  font-size: 54px;
}

.lh54 {
  line-height: 54px;
}

.mt54 {
  margin-top: 54px;
}

.mb54 {
  margin-bottom: 54px;
}

.ml54 {
  margin-left: 54px;
}

.mr54 {
  margin-right: 54px;
}

.lh54 {
  line-height: 54px;
}

.w55 {
  width: 55px;
}

.mw55 {
  max-width: 55px;
}

.h55 {
  height: 55px;
}

.mh55 {
  max-height: 55px;
}

.fs55 {
  font-size: 55px;
}

.lh55 {
  line-height: 55px;
}

.mt55 {
  margin-top: 55px;
}

.mb55 {
  margin-bottom: 55px;
}

.ml55 {
  margin-left: 55px;
}

.mr55 {
  margin-right: 55px;
}

.lh55 {
  line-height: 55px;
}

.w56 {
  width: 56px;
}

.mw56 {
  max-width: 56px;
}

.h56 {
  height: 56px;
}

.mh56 {
  max-height: 56px;
}

.fs56 {
  font-size: 56px;
}

.lh56 {
  line-height: 56px;
}

.mt56 {
  margin-top: 56px;
}

.mb56 {
  margin-bottom: 56px;
}

.ml56 {
  margin-left: 56px;
}

.mr56 {
  margin-right: 56px;
}

.lh56 {
  line-height: 56px;
}

.w57 {
  width: 57px;
}

.mw57 {
  max-width: 57px;
}

.h57 {
  height: 57px;
}

.mh57 {
  max-height: 57px;
}

.fs57 {
  font-size: 57px;
}

.lh57 {
  line-height: 57px;
}

.mt57 {
  margin-top: 57px;
}

.mb57 {
  margin-bottom: 57px;
}

.ml57 {
  margin-left: 57px;
}

.mr57 {
  margin-right: 57px;
}

.lh57 {
  line-height: 57px;
}

.w58 {
  width: 58px;
}

.mw58 {
  max-width: 58px;
}

.h58 {
  height: 58px;
}

.mh58 {
  max-height: 58px;
}

.fs58 {
  font-size: 58px;
}

.lh58 {
  line-height: 58px;
}

.mt58 {
  margin-top: 58px;
}

.mb58 {
  margin-bottom: 58px;
}

.ml58 {
  margin-left: 58px;
}

.mr58 {
  margin-right: 58px;
}

.lh58 {
  line-height: 58px;
}

.w59 {
  width: 59px;
}

.mw59 {
  max-width: 59px;
}

.h59 {
  height: 59px;
}

.mh59 {
  max-height: 59px;
}

.fs59 {
  font-size: 59px;
}

.lh59 {
  line-height: 59px;
}

.mt59 {
  margin-top: 59px;
}

.mb59 {
  margin-bottom: 59px;
}

.ml59 {
  margin-left: 59px;
}

.mr59 {
  margin-right: 59px;
}

.lh59 {
  line-height: 59px;
}

.w60 {
  width: 60px;
}

.mw60 {
  max-width: 60px;
}

.h60 {
  height: 60px;
}

.mh60 {
  max-height: 60px;
}

.fs60 {
  font-size: 60px;
}

.lh60 {
  line-height: 60px;
}

.mt60 {
  margin-top: 60px;
}

.mb60 {
  margin-bottom: 60px;
}

.ml60 {
  margin-left: 60px;
}

.mr60 {
  margin-right: 60px;
}

.lh60 {
  line-height: 60px;
}

.w61 {
  width: 61px;
}

.mw61 {
  max-width: 61px;
}

.h61 {
  height: 61px;
}

.mh61 {
  max-height: 61px;
}

.fs61 {
  font-size: 61px;
}

.lh61 {
  line-height: 61px;
}

.mt61 {
  margin-top: 61px;
}

.mb61 {
  margin-bottom: 61px;
}

.ml61 {
  margin-left: 61px;
}

.mr61 {
  margin-right: 61px;
}

.lh61 {
  line-height: 61px;
}

.w62 {
  width: 62px;
}

.mw62 {
  max-width: 62px;
}

.h62 {
  height: 62px;
}

.mh62 {
  max-height: 62px;
}

.fs62 {
  font-size: 62px;
}

.lh62 {
  line-height: 62px;
}

.mt62 {
  margin-top: 62px;
}

.mb62 {
  margin-bottom: 62px;
}

.ml62 {
  margin-left: 62px;
}

.mr62 {
  margin-right: 62px;
}

.lh62 {
  line-height: 62px;
}

.w63 {
  width: 63px;
}

.mw63 {
  max-width: 63px;
}

.h63 {
  height: 63px;
}

.mh63 {
  max-height: 63px;
}

.fs63 {
  font-size: 63px;
}

.lh63 {
  line-height: 63px;
}

.mt63 {
  margin-top: 63px;
}

.mb63 {
  margin-bottom: 63px;
}

.ml63 {
  margin-left: 63px;
}

.mr63 {
  margin-right: 63px;
}

.lh63 {
  line-height: 63px;
}

.w64 {
  width: 64px;
}

.mw64 {
  max-width: 64px;
}

.h64 {
  height: 64px;
}

.mh64 {
  max-height: 64px;
}

.fs64 {
  font-size: 64px;
}

.lh64 {
  line-height: 64px;
}

.mt64 {
  margin-top: 64px;
}

.mb64 {
  margin-bottom: 64px;
}

.ml64 {
  margin-left: 64px;
}

.mr64 {
  margin-right: 64px;
}

.lh64 {
  line-height: 64px;
}

.w65 {
  width: 65px;
}

.mw65 {
  max-width: 65px;
}

.h65 {
  height: 65px;
}

.mh65 {
  max-height: 65px;
}

.fs65 {
  font-size: 65px;
}

.lh65 {
  line-height: 65px;
}

.mt65 {
  margin-top: 65px;
}

.mb65 {
  margin-bottom: 65px;
}

.ml65 {
  margin-left: 65px;
}

.mr65 {
  margin-right: 65px;
}

.lh65 {
  line-height: 65px;
}

.w66 {
  width: 66px;
}

.mw66 {
  max-width: 66px;
}

.h66 {
  height: 66px;
}

.mh66 {
  max-height: 66px;
}

.fs66 {
  font-size: 66px;
}

.lh66 {
  line-height: 66px;
}

.mt66 {
  margin-top: 66px;
}

.mb66 {
  margin-bottom: 66px;
}

.ml66 {
  margin-left: 66px;
}

.mr66 {
  margin-right: 66px;
}

.lh66 {
  line-height: 66px;
}

.w67 {
  width: 67px;
}

.mw67 {
  max-width: 67px;
}

.h67 {
  height: 67px;
}

.mh67 {
  max-height: 67px;
}

.fs67 {
  font-size: 67px;
}

.lh67 {
  line-height: 67px;
}

.mt67 {
  margin-top: 67px;
}

.mb67 {
  margin-bottom: 67px;
}

.ml67 {
  margin-left: 67px;
}

.mr67 {
  margin-right: 67px;
}

.lh67 {
  line-height: 67px;
}

.w68 {
  width: 68px;
}

.mw68 {
  max-width: 68px;
}

.h68 {
  height: 68px;
}

.mh68 {
  max-height: 68px;
}

.fs68 {
  font-size: 68px;
}

.lh68 {
  line-height: 68px;
}

.mt68 {
  margin-top: 68px;
}

.mb68 {
  margin-bottom: 68px;
}

.ml68 {
  margin-left: 68px;
}

.mr68 {
  margin-right: 68px;
}

.lh68 {
  line-height: 68px;
}

.w69 {
  width: 69px;
}

.mw69 {
  max-width: 69px;
}

.h69 {
  height: 69px;
}

.mh69 {
  max-height: 69px;
}

.fs69 {
  font-size: 69px;
}

.lh69 {
  line-height: 69px;
}

.mt69 {
  margin-top: 69px;
}

.mb69 {
  margin-bottom: 69px;
}

.ml69 {
  margin-left: 69px;
}

.mr69 {
  margin-right: 69px;
}

.lh69 {
  line-height: 69px;
}

.w70 {
  width: 70px;
}

.mw70 {
  max-width: 70px;
}

.h70 {
  height: 70px;
}

.mh70 {
  max-height: 70px;
}

.fs70 {
  font-size: 70px;
}

.lh70 {
  line-height: 70px;
}

.mt70 {
  margin-top: 70px;
}

.mb70 {
  margin-bottom: 70px;
}

.ml70 {
  margin-left: 70px;
}

.mr70 {
  margin-right: 70px;
}

.lh70 {
  line-height: 70px;
}

.w71 {
  width: 71px;
}

.mw71 {
  max-width: 71px;
}

.h71 {
  height: 71px;
}

.mh71 {
  max-height: 71px;
}

.fs71 {
  font-size: 71px;
}

.lh71 {
  line-height: 71px;
}

.mt71 {
  margin-top: 71px;
}

.mb71 {
  margin-bottom: 71px;
}

.ml71 {
  margin-left: 71px;
}

.mr71 {
  margin-right: 71px;
}

.lh71 {
  line-height: 71px;
}

.w72 {
  width: 72px;
}

.mw72 {
  max-width: 72px;
}

.h72 {
  height: 72px;
}

.mh72 {
  max-height: 72px;
}

.fs72 {
  font-size: 72px;
}

.lh72 {
  line-height: 72px;
}

.mt72 {
  margin-top: 72px;
}

.mb72 {
  margin-bottom: 72px;
}

.ml72 {
  margin-left: 72px;
}

.mr72 {
  margin-right: 72px;
}

.lh72 {
  line-height: 72px;
}

.w73 {
  width: 73px;
}

.mw73 {
  max-width: 73px;
}

.h73 {
  height: 73px;
}

.mh73 {
  max-height: 73px;
}

.fs73 {
  font-size: 73px;
}

.lh73 {
  line-height: 73px;
}

.mt73 {
  margin-top: 73px;
}

.mb73 {
  margin-bottom: 73px;
}

.ml73 {
  margin-left: 73px;
}

.mr73 {
  margin-right: 73px;
}

.lh73 {
  line-height: 73px;
}

.w74 {
  width: 74px;
}

.mw74 {
  max-width: 74px;
}

.h74 {
  height: 74px;
}

.mh74 {
  max-height: 74px;
}

.fs74 {
  font-size: 74px;
}

.lh74 {
  line-height: 74px;
}

.mt74 {
  margin-top: 74px;
}

.mb74 {
  margin-bottom: 74px;
}

.ml74 {
  margin-left: 74px;
}

.mr74 {
  margin-right: 74px;
}

.lh74 {
  line-height: 74px;
}

.w75 {
  width: 75px;
}

.mw75 {
  max-width: 75px;
}

.h75 {
  height: 75px;
}

.mh75 {
  max-height: 75px;
}

.fs75 {
  font-size: 75px;
}

.lh75 {
  line-height: 75px;
}

.mt75 {
  margin-top: 75px;
}

.mb75 {
  margin-bottom: 75px;
}

.ml75 {
  margin-left: 75px;
}

.mr75 {
  margin-right: 75px;
}

.lh75 {
  line-height: 75px;
}

.w76 {
  width: 76px;
}

.mw76 {
  max-width: 76px;
}

.h76 {
  height: 76px;
}

.mh76 {
  max-height: 76px;
}

.fs76 {
  font-size: 76px;
}

.lh76 {
  line-height: 76px;
}

.mt76 {
  margin-top: 76px;
}

.mb76 {
  margin-bottom: 76px;
}

.ml76 {
  margin-left: 76px;
}

.mr76 {
  margin-right: 76px;
}

.lh76 {
  line-height: 76px;
}

.w77 {
  width: 77px;
}

.mw77 {
  max-width: 77px;
}

.h77 {
  height: 77px;
}

.mh77 {
  max-height: 77px;
}

.fs77 {
  font-size: 77px;
}

.lh77 {
  line-height: 77px;
}

.mt77 {
  margin-top: 77px;
}

.mb77 {
  margin-bottom: 77px;
}

.ml77 {
  margin-left: 77px;
}

.mr77 {
  margin-right: 77px;
}

.lh77 {
  line-height: 77px;
}

.w78 {
  width: 78px;
}

.mw78 {
  max-width: 78px;
}

.h78 {
  height: 78px;
}

.mh78 {
  max-height: 78px;
}

.fs78 {
  font-size: 78px;
}

.lh78 {
  line-height: 78px;
}

.mt78 {
  margin-top: 78px;
}

.mb78 {
  margin-bottom: 78px;
}

.ml78 {
  margin-left: 78px;
}

.mr78 {
  margin-right: 78px;
}

.lh78 {
  line-height: 78px;
}

.w79 {
  width: 79px;
}

.mw79 {
  max-width: 79px;
}

.h79 {
  height: 79px;
}

.mh79 {
  max-height: 79px;
}

.fs79 {
  font-size: 79px;
}

.lh79 {
  line-height: 79px;
}

.mt79 {
  margin-top: 79px;
}

.mb79 {
  margin-bottom: 79px;
}

.ml79 {
  margin-left: 79px;
}

.mr79 {
  margin-right: 79px;
}

.lh79 {
  line-height: 79px;
}

.w80 {
  width: 80px;
}

.mw80 {
  max-width: 80px;
}

.h80 {
  height: 80px;
}

.mh80 {
  max-height: 80px;
}

.fs80 {
  font-size: 80px;
}

.lh80 {
  line-height: 80px;
}

.mt80 {
  margin-top: 80px;
}

.mb80 {
  margin-bottom: 80px;
}

.ml80 {
  margin-left: 80px;
}

.mr80 {
  margin-right: 80px;
}

.lh80 {
  line-height: 80px;
}

.w81 {
  width: 81px;
}

.mw81 {
  max-width: 81px;
}

.h81 {
  height: 81px;
}

.mh81 {
  max-height: 81px;
}

.fs81 {
  font-size: 81px;
}

.lh81 {
  line-height: 81px;
}

.mt81 {
  margin-top: 81px;
}

.mb81 {
  margin-bottom: 81px;
}

.ml81 {
  margin-left: 81px;
}

.mr81 {
  margin-right: 81px;
}

.lh81 {
  line-height: 81px;
}

.w82 {
  width: 82px;
}

.mw82 {
  max-width: 82px;
}

.h82 {
  height: 82px;
}

.mh82 {
  max-height: 82px;
}

.fs82 {
  font-size: 82px;
}

.lh82 {
  line-height: 82px;
}

.mt82 {
  margin-top: 82px;
}

.mb82 {
  margin-bottom: 82px;
}

.ml82 {
  margin-left: 82px;
}

.mr82 {
  margin-right: 82px;
}

.lh82 {
  line-height: 82px;
}

.w83 {
  width: 83px;
}

.mw83 {
  max-width: 83px;
}

.h83 {
  height: 83px;
}

.mh83 {
  max-height: 83px;
}

.fs83 {
  font-size: 83px;
}

.lh83 {
  line-height: 83px;
}

.mt83 {
  margin-top: 83px;
}

.mb83 {
  margin-bottom: 83px;
}

.ml83 {
  margin-left: 83px;
}

.mr83 {
  margin-right: 83px;
}

.lh83 {
  line-height: 83px;
}

.w84 {
  width: 84px;
}

.mw84 {
  max-width: 84px;
}

.h84 {
  height: 84px;
}

.mh84 {
  max-height: 84px;
}

.fs84 {
  font-size: 84px;
}

.lh84 {
  line-height: 84px;
}

.mt84 {
  margin-top: 84px;
}

.mb84 {
  margin-bottom: 84px;
}

.ml84 {
  margin-left: 84px;
}

.mr84 {
  margin-right: 84px;
}

.lh84 {
  line-height: 84px;
}

.w85 {
  width: 85px;
}

.mw85 {
  max-width: 85px;
}

.h85 {
  height: 85px;
}

.mh85 {
  max-height: 85px;
}

.fs85 {
  font-size: 85px;
}

.lh85 {
  line-height: 85px;
}

.mt85 {
  margin-top: 85px;
}

.mb85 {
  margin-bottom: 85px;
}

.ml85 {
  margin-left: 85px;
}

.mr85 {
  margin-right: 85px;
}

.lh85 {
  line-height: 85px;
}

.w86 {
  width: 86px;
}

.mw86 {
  max-width: 86px;
}

.h86 {
  height: 86px;
}

.mh86 {
  max-height: 86px;
}

.fs86 {
  font-size: 86px;
}

.lh86 {
  line-height: 86px;
}

.mt86 {
  margin-top: 86px;
}

.mb86 {
  margin-bottom: 86px;
}

.ml86 {
  margin-left: 86px;
}

.mr86 {
  margin-right: 86px;
}

.lh86 {
  line-height: 86px;
}

.w87 {
  width: 87px;
}

.mw87 {
  max-width: 87px;
}

.h87 {
  height: 87px;
}

.mh87 {
  max-height: 87px;
}

.fs87 {
  font-size: 87px;
}

.lh87 {
  line-height: 87px;
}

.mt87 {
  margin-top: 87px;
}

.mb87 {
  margin-bottom: 87px;
}

.ml87 {
  margin-left: 87px;
}

.mr87 {
  margin-right: 87px;
}

.lh87 {
  line-height: 87px;
}

.w88 {
  width: 88px;
}

.mw88 {
  max-width: 88px;
}

.h88 {
  height: 88px;
}

.mh88 {
  max-height: 88px;
}

.fs88 {
  font-size: 88px;
}

.lh88 {
  line-height: 88px;
}

.mt88 {
  margin-top: 88px;
}

.mb88 {
  margin-bottom: 88px;
}

.ml88 {
  margin-left: 88px;
}

.mr88 {
  margin-right: 88px;
}

.lh88 {
  line-height: 88px;
}

.w89 {
  width: 89px;
}

.mw89 {
  max-width: 89px;
}

.h89 {
  height: 89px;
}

.mh89 {
  max-height: 89px;
}

.fs89 {
  font-size: 89px;
}

.lh89 {
  line-height: 89px;
}

.mt89 {
  margin-top: 89px;
}

.mb89 {
  margin-bottom: 89px;
}

.ml89 {
  margin-left: 89px;
}

.mr89 {
  margin-right: 89px;
}

.lh89 {
  line-height: 89px;
}

.w90 {
  width: 90px;
}

.mw90 {
  max-width: 90px;
}

.h90 {
  height: 90px;
}

.mh90 {
  max-height: 90px;
}

.fs90 {
  font-size: 90px;
}

.lh90 {
  line-height: 90px;
}

.mt90 {
  margin-top: 90px;
}

.mb90 {
  margin-bottom: 90px;
}

.ml90 {
  margin-left: 90px;
}

.mr90 {
  margin-right: 90px;
}

.lh90 {
  line-height: 90px;
}

.w91 {
  width: 91px;
}

.mw91 {
  max-width: 91px;
}

.h91 {
  height: 91px;
}

.mh91 {
  max-height: 91px;
}

.fs91 {
  font-size: 91px;
}

.lh91 {
  line-height: 91px;
}

.mt91 {
  margin-top: 91px;
}

.mb91 {
  margin-bottom: 91px;
}

.ml91 {
  margin-left: 91px;
}

.mr91 {
  margin-right: 91px;
}

.lh91 {
  line-height: 91px;
}

.w92 {
  width: 92px;
}

.mw92 {
  max-width: 92px;
}

.h92 {
  height: 92px;
}

.mh92 {
  max-height: 92px;
}

.fs92 {
  font-size: 92px;
}

.lh92 {
  line-height: 92px;
}

.mt92 {
  margin-top: 92px;
}

.mb92 {
  margin-bottom: 92px;
}

.ml92 {
  margin-left: 92px;
}

.mr92 {
  margin-right: 92px;
}

.lh92 {
  line-height: 92px;
}

.w93 {
  width: 93px;
}

.mw93 {
  max-width: 93px;
}

.h93 {
  height: 93px;
}

.mh93 {
  max-height: 93px;
}

.fs93 {
  font-size: 93px;
}

.lh93 {
  line-height: 93px;
}

.mt93 {
  margin-top: 93px;
}

.mb93 {
  margin-bottom: 93px;
}

.ml93 {
  margin-left: 93px;
}

.mr93 {
  margin-right: 93px;
}

.lh93 {
  line-height: 93px;
}

.w94 {
  width: 94px;
}

.mw94 {
  max-width: 94px;
}

.h94 {
  height: 94px;
}

.mh94 {
  max-height: 94px;
}

.fs94 {
  font-size: 94px;
}

.lh94 {
  line-height: 94px;
}

.mt94 {
  margin-top: 94px;
}

.mb94 {
  margin-bottom: 94px;
}

.ml94 {
  margin-left: 94px;
}

.mr94 {
  margin-right: 94px;
}

.lh94 {
  line-height: 94px;
}

.w95 {
  width: 95px;
}

.mw95 {
  max-width: 95px;
}

.h95 {
  height: 95px;
}

.mh95 {
  max-height: 95px;
}

.fs95 {
  font-size: 95px;
}

.lh95 {
  line-height: 95px;
}

.mt95 {
  margin-top: 95px;
}

.mb95 {
  margin-bottom: 95px;
}

.ml95 {
  margin-left: 95px;
}

.mr95 {
  margin-right: 95px;
}

.lh95 {
  line-height: 95px;
}

.w96 {
  width: 96px;
}

.mw96 {
  max-width: 96px;
}

.h96 {
  height: 96px;
}

.mh96 {
  max-height: 96px;
}

.fs96 {
  font-size: 96px;
}

.lh96 {
  line-height: 96px;
}

.mt96 {
  margin-top: 96px;
}

.mb96 {
  margin-bottom: 96px;
}

.ml96 {
  margin-left: 96px;
}

.mr96 {
  margin-right: 96px;
}

.lh96 {
  line-height: 96px;
}

.mzic-audio-player-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.mzic-audio-player-container .buttons-container {
  display: inline-flex;
  width: 42px;
  align-items: center;
}
.mzic-audio-player-container .buttons-container mzic-svg {
  display: inline-flex;
  max-width: 42px;
  width: 42px;
  max-height: 42px;
  cursor: pointer;
}
.mzic-audio-player-container .buttons-container mzic-svg > svg {
  width: 100%;
  max-height: 100%;
}
.mzic-audio-player-container .wave-container {
  display: inline-flex;
  width: calc(100% - 42px);
  position: relative;
}

.mzic-tip-on-hover {
  position: relative;
}
.mzic-tip-on-hover:hover .mzic-tip {
  display: inline-flex;
}
.mzic-tip-on-hover .mzic-tip {
  display: none;
  transform: translate(100%, 0);
  position: absolute;
  flex-direction: column;
  padding: 8px;
  gap: 22px;
  border-radius: 8px;
  border: 1px solid var(--neutral-300);
  width: 200px;
  right: -28px;
  top: 0;
}
.mzic-tip-on-hover .mzic-tip::before {
  position: absolute;
  width: 32px;
  height: 100%;
  content: "";
  left: -28px;
  top: 0;
}
.mzic-tip-on-hover .mzic-tip p {
  color: var(--neutral-200);
}
.mzic-tip-on-hover .mzic-tip strong {
  color: var(--neutral-200);
}
.mzic-tip-on-hover .mzic-tip .item {
  color: var(--neutral-200);
  align-items: flex-start;
}
.mzic-tip-on-hover .mzic-tip .item.forbidden svg {
  margin-top: -3px;
  margin-right: 4px;
  width: 12px;
}
.mzic-tip-on-hover .mzic-tip .item.forbidden svg > path {
  fill: var(--red-500);
}
.mzic-tip-on-hover .mzic-tip .item mzic-svg {
  display: inline;
}

.full-page-icon {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(17, 16, 16, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-page-icon .icon {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 4px solid transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.full-page-icon .icon > svg {
  width: 26px;
  height: 26px;
}
.full-page-icon .icon.success {
  border-color: var(--green-500);
}
.full-page-icon .icon.success > svg > path {
  stroke: var(--green-500);
}

.rule-resume-card-component {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
}
.rule-resume-card-component .rule-card {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid var(--neutral-500);
  border-radius: 24px;
  padding: 12px 24px;
  background-color: transparent;
  width: 100%;
  color: var(--neutral-300);
}
.rule-resume-card-component .rule-card .card-title {
  margin-bottom: 8px;
}
.rule-resume-card-component .rule-card .content {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  color: var(--neutral-300);
}
.rule-resume-card-component .rule-card .content .strong {
  color: var(--neutral-100);
}

.sort-number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding-top: 3px;
  border-radius: 8px;
  background-color: var(--blue-500);
}

.split-details-resume-component {
  position: relative;
  display: inline-flex;
  width: 100%;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
}
.split-details-resume-component .image-container {
  position: relative;
  width: 200px;
}
.split-details-resume-component .image-container .image {
  position: relative;
  display: inline-flex;
  width: 173px;
  height: 173px;
  border-radius: 24px;
  overflow: hidden;
  background-color: var(--neutral-500);
  top: 0;
  left: 0;
  z-index: 3;
  justify-content: center;
  align-items: center;
}
.split-details-resume-component .image-container .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.split-details-resume-component .image-container .image + .image {
  position: absolute;
  background-color: var(--neutral-500-50);
  top: 11px;
  left: 12px;
  z-index: 2;
}
.split-details-resume-component .image-container .image + .image + .image {
  position: absolute;
  background-color: var(--neutral-600);
  top: 22px;
  left: 24px;
  z-index: 1;
}
.split-details-resume-component .text-container {
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 200px);
  align-items: flex-start;
  gap: 8px;
}
.split-details-resume-component .text-container .tag {
  display: inline-flex;
  padding: 2px 10px;
  border-radius: 4px;
  background-color: var(--neutral-100);
  color: var(--neutral-700);
  align-items: center;
  gap: 4px;
  cursor: pointer;
  margin-bottom: 8px;
}
.split-details-resume-component .text-container .tag span {
  margin-bottom: -2px;
}
.split-details-resume-component .text-container .title {
  display: inline-flex;
  gap: 7px;
  align-items: center;
}
.split-details-resume-component .text-container .title .icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background-color: var(--neutral-500);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.split-details-resume-component .text-container .resume {
  display: inline-flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 4px;
}
.split-details-resume-component .text-container .resume span {
  color: var(--neutral-300);
}
.split-details-resume-component .text-container .resume strong {
  color: var(--purple-500);
}
.split-details-resume-component .text-container .dates {
  display: inline-flex;
  gap: 20px;
  align-items: center;
}
.split-details-resume-component .text-container .dates > div {
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
}
.split-details-resume-component .text-container .dates > div span {
  color: var(--neutral-300);
}
.split-details-resume-component .text-container .dates > div strong {
  color: var(--neutral-100);
}

.split-details-payees-list-component {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}
.split-details-payees-list-component .split-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.split-details-payees-list-component .split-row.row-head {
  padding-left: 14px;
  padding-right: 6px;
}
.split-details-payees-list-component .split-row.row-head .split-column {
  color: var(--neutral-300);
  font-weight: var(--font-bold);
  font-size: 14px;
  line-height: 19px;
}
.split-details-payees-list-component .split-row:not(.row-head) {
  padding: 16px 6px 16px 14px;
  border: 1px solid #888;
  border-radius: 24px;
  border: 1px solid var(--neutral-500);
}
.split-details-payees-list-component .split-row:not(.row-head).flat {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.split-details-payees-list-component .split-column {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  width: 100%;
}
.split-details-payees-list-component .col-name {
  display: inline-flex;
  gap: 12px;
}
.split-details-payees-list-component .col-name .col-name-content {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.split-details-payees-list-component .col-name .col-name-content .name {
  color: var(--neutral-100);
  font-weight: var(--font-semi-bold);
  font-size: 14px;
  line-height: 21px;
}
.split-details-payees-list-component .col-name .col-name-content .email {
  font-size: 12px;
  line-height: 18px;
  color: var(--neutral-300);
}
.split-details-payees-list-component .col-name > .image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.split-details-payees-list-component .col-name > .image img {
  width: 100%;
  height: 100%;
}
.split-details-payees-list-component .col-tag {
  justify-content: center;
  max-width: 200px;
}
.split-details-payees-list-component .col-tag .tag {
  padding: 3px 10px 1px;
  background-color: var(--blue-500);
  color: var(--neutral-700);
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
}
.split-details-payees-list-component .col-percent {
  justify-content: center;
  color: var(--neutral-100);
  max-width: 100px;
}
.split-details-payees-list-component .col-icon {
  max-width: 32px;
}
.split-details-payees-list-component .col-money {
  justify-content: flex-start;
  max-width: 84px;
}
.split-details-payees-list-component .col-icon-msg {
  display: inline-flex;
  max-width: 200px;
  justify-content: center;
}
.split-details-payees-list-component .col-icon-msg > div {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding: 0 4px;
}
.split-details-payees-list-component .col-icon-msg > div:hover label {
  color: var(--neutral-200);
}
.split-details-payees-list-component .col-icon-msg > div label {
  color: var(--neutral-300);
  margin-bottom: -2px;
  cursor: pointer;
}
.split-details-payees-list-component .col-status {
  justify-content: center;
  max-width: 150px;
}
.split-details-payees-list-component .col-status .status {
  text-transform: uppercase;
  padding: 3px 10px 1px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  background-color: var(--neutral-600);
}
.split-details-payees-list-component .col-status .status.confirmed {
  color: var(--green-500);
}
.split-details-payees-list-component .col-status .status.action-required {
  background-color: var(--yellow-500);
  color: var(--neutral-600);
}
.split-details-payees-list-component .col-status .status.pending {
  color: var(--purple-500);
}
.split-details-payees-list-component .col-status .status.suspended {
  color: var(--red-500);
}
.split-details-payees-list-component .split-row:nth-child(1) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(5) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(10) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(15) .split-column.col-name .image {
  background: linear-gradient(45deg, #16b0e2 8.55%, #6e5af0 91.45%);
}
.split-details-payees-list-component .split-row:nth-child(2) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(6) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(11) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(16) .split-column.col-name .image {
  background: linear-gradient(45deg, #e21616 8.55%, #f0a25a 91.45%);
}
.split-details-payees-list-component .split-row:nth-child(3) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(7) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(12) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(17) .split-column.col-name .image {
  background: linear-gradient(45deg, #e21616 8.55%, #6e5af0 91.45%);
}
.split-details-payees-list-component .split-row:nth-child(4) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(8) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(13) .split-column.col-name .image,
.split-details-payees-list-component .split-row:nth-child(18) .split-column.col-name .image {
  background: linear-gradient(45deg, #00bf8f 8.55%, #002c22 91.45%);
}

.track-status-tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  border-radius: 8px;
  height: 24px;
  color: var(--neutral-700);
  gap: 6px;
}
.track-status-tag > span {
  padding-top: 2px;
}

.toggle-content-link-component {
  color: var(--purple-500);
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.toggle-content-link-component mzic-svg {
  margin-top: -2px;
}

.tracks-release-footer {
  display: inline-flex;
  background-color: var(--purple-500);
  padding: 10px 12px 66px 12px;
  margin-top: 64px;
  justify-content: center;
}
.tracks-release-footer > div {
  gap: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.tracks-release-save-progress {
  display: inline-flex;
  position: fixed;
  background-color: var(--purple-500);
  transform: translate(-50%, 0);
  gap: 32px;
  bottom: 40px;
  padding: 10px 16px;
  border-radius: 100px;
  justify-content: center;
  left: 50%;
}
.tracks-release-save-progress.hide {
  display: none;
}

.tracks-release-button.mz-button {
  color: var(--neutral-700);
}
.tracks-release-button.mz-button:hover {
  background-color: var(--neutral-700);
  color: #fff;
  fill: #fff;
}
.tracks-release-button.mz-button.save.mz-button {
  border-color: var(--neutral-700);
}

.tracks-release-input-section-title {
  padding-top: 56px;
  margin-top: 56px;
  margin-bottom: 32px;
  border-top: 1px solid var(--neutral-500);
}

.tracks-release-section-text {
  color: var(--neutral-200);
}

.tracks-release-fields-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.tracks-release-fields-container .sub-row {
  margin-top: -24px;
}

body {
  background-color: var(--id-main-black);
  margin: 0;
  padding: 0;
  font-family: "PP Mori", Arial, sans-serif;
  height: 100%;
}
body input[type=number]::-webkit-inner-spin-button,
body input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (prefers-color-scheme: dark) {
  body :root {
    color-scheme: only light;
  }
}
body a {
  text-decoration: none;
  cursor: pointer;
}
body input {
  caret-color: var(--id-main-purple);
}

.hide {
  display: none;
}

.no-margin {
  margin: 0;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  opacity: 0.9;
}

#globalServiceLoader {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999;
}
#globalServiceLoader .loader,
#globalServiceLoader .icon {
  display: none;
}
#globalServiceLoader.visible {
  display: block;
}
#globalServiceLoader.visible .loader {
  display: block;
}
#globalServiceLoader.success {
  display: block;
}
#globalServiceLoader.success .icon {
  display: flex;
  width: 80px;
  height: 80px;
  border: 3px solid var(--green-500);
  border-radius: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#globalServiceLoader .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid var(--neutral-400);
  border-top: 3px solid var(--purple-500);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  background: transparent;
  animation: spin 0.9s linear infinite;
}

.mzic-tooltip-container {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.side-floating-menu {
  position: fixed;
  top: 292px;
  left: 38px;
}
.side-floating-menu ul {
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin: 0;
  padding: 0;
}
.side-floating-menu ul li {
  position: relative;
  list-style: none;
  padding-left: 28px;
  color: var(--neutral-400);
}
.side-floating-menu ul li::after {
  transform: translate(0, -50%);
  position: absolute;
  left: 0;
  top: 50%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--neutral-400);
  content: "";
}
.side-floating-menu ul li.active {
  color: var(--purple-500);
}
.side-floating-menu ul li.active::after {
  background-color: var(--purple-500);
}

html,
body {
  min-height: 100%;
}

/**
 * `width` não é afetado por `padding` ou `border`.
 * já existe essa regra no _reboot.css do bootstrap, mas pretendemos remover o bootstrap
 */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.material-cdk-dialog-transparent {
  background: transparent;
}
.material-cdk-dialog-transparent .mat-mdc-dialog-container .mdc-dialog__surface {
  background: transparent;
}

.date-releases {
  position: relative;
  background-color: transparent !important;
}
.date-releases ::ng-deep .mat-mdc-button-persistent-ripple mdc-button__ripple {
  color: var(--id-main-purple);
}
.date-releases ::ng-deep .mdc-button__label {
  color: var(--id-main-purple) !important;
}
.date-releases .mat-calendar-table td {
  color: var(--id-main-purple) !important;
}
.date-releases .mat-calendar-table tr {
  color: var(--id-main-purple) !important;
}
.date-releases .mat-calendar-table th {
  color: var(--id-main-purple) !important;
}
.date-releases .mat-calendar-table-header td {
  color: var(--id-main-purple) !important;
}
.date-releases .mat-calendar-table-header tr {
  color: var(--id-main-purple) !important;
}
.date-releases .mat-calendar-table-header th {
  color: var(--id-main-purple) !important;
}
.date-releases .mat-focus-indicator {
  color: var(--id-main-purple) !important;
}
.date-releases .mat-calendar-body-label {
  display: none;
}
.date-releases .mat-calendar-body-today {
  width: 24px !important;
  height: 24px !important;
  background-color: transparent !important;
  color: var(--id-white) !important;
  box-shadow: none !important;
}
.date-releases .mat-calendar-body-selected {
  background-color: var(--id-white) !important;
  width: 24px !important;
  height: 24px !important;
  color: var(--id-main-purple) !important;
}

.mat-mdc-menu-panel {
  border-radius: 16px !important;
  margin-top: 10px !important;
  width: 211px !important;
}

.mzic-text-body-xxsm {
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-200);
}

.mzic-row {
  display: flex;
  width: 100%;
}

.mzic-gap-1 {
  gap: 0.25rem;
}

.mzic-gap-2 {
  gap: 0.5rem;
}

.mzic-gap-3 {
  gap: 0.75rem;
}

.mzic-gap-4 {
  gap: 1rem;
}

.mzic-gap-5 {
  gap: 1.25rem;
}

.mzic-gap-6 {
  gap: 1.5rem;
}

.mzic-gap-7 {
  gap: 1.75rem;
}

.mzic-gap-8 {
  gap: 2rem;
}

.mzic-gap-9 {
  gap: 2.25rem;
}

.mzic-gap-10 {
  gap: 2.5rem;
}

.mzic-page-full-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.mzic-link {
  font-size: 14px;
  line-height: 14px;
  font-weight: var(--font-bold);
  color: var(--purple-500);
}

.circle {
  border-radius: 50%;
}

.tracks-release-button.mz-button.save.mz-button:disabled, .tracks-release-button.mz-button.save.mz-button:disabled:hover {
  border-color: var(--purple-600) !important;
  color: var(--purple-600) !important;
  background-color: transparent !important;
}
.tracks-release-button.mz-button.save.mz-button:disabled > mzic-svg.neutral-700 > svg > path, .tracks-release-button.mz-button.save.mz-button:disabled:hover > mzic-svg.neutral-700 > svg > path {
  fill: var(--purple-600) !important;
}
.tracks-release-button.mz-button.save.mz-button:disabled > mzic-svg:not(.neutral-700) > svg > path, .tracks-release-button.mz-button.save.mz-button:disabled:hover > mzic-svg:not(.neutral-700) > svg > path {
  stroke: var(--purple-600) !important;
}

.pac-container {
  margin-top: 4px;
  border-radius: 24px;
  background: var(--neutral-500);
  border: none;
}

.pac-item {
  font-family: "PP Mori";
  font-size: 14px;
  padding: 8px 16px;
  border: none;
}
.pac-item:hover {
  background: var(--neutral-600-50);
}

.pac-item .pac-item-query {
  color: var(--neutral-100);
}

.pac-item .pac-item-query + span {
  color: var(--neutral-100);
}