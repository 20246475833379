$input-width: 60%;

.mzic-input-style {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--font-regular);
  padding: 12px 24px;
  color: var(--neutral-100);
  background-color: var(--neutral-500-50);
  border: 2px solid transparent;
  border-radius: 100px;
  outline: none;
  transition: 0.3s;
  -webkit-appearance: none;

  ::placeholder {
    color: var(--neutral-400);
  }

  &:hover {
    background-color: var(--neutral-500);
  }

  &.error,
  &.success {
    background-repeat: no-repeat;
    background-position: right 17px center;
  }

  &.error {
    border-color: var(--red-500);
    // background-image: url('/assets/icons/mzic-input-error.svg');
  }

  &.success {
    border-color: var(--green-500);
    // background-image: url('/assets/icons/mzic-input-success.svg');
  }
}

.mz-form-row {
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;

  + .mz-form-row {
    margin-top: 24px;
  }

  &.vertical + .mz-form-row {
    margin-top: 24px;
  }
}

.mz-label {
  display: inline-flex;
  flex-direction: column;
  padding-right: 36px;
  width: 40%;
  align-items: flex-start;
  justify-content: flex-start;

  &.center {
    justify-content: center;
  }

  &--full {
    width: 100%;
  }
}

.mz-input {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;

  &--full {
    width: 100%;
  }
}

.mz-input > .slot-start,
.mz-input > .slot-end {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 40px;
}

.mz-input > .slot-start {
  left: 24px;
  top: 50%;
  transform: translate(0, -50%);

  &.password {
    width: 20px;

    button {
      width: 20px;
      padding: 0;
      margin: 0;
      > svg {
        width: 20px;
        max-height: 20px;
      }
    }
  }
}

.mz-input input:is(.success, .error) + .slot-end {
  right: 54px;
}

.mz-input > .slot-end {
  right: 24px;
  top: 5px;

  &.password {
    width: 20px;

    button {
      width: 20px;
      padding: 0;
      margin: 0;
      > svg {
        width: 20px;
        max-height: 20px;
      }
    }
  }
}

.mz-input > .slot-start + input {
  padding-left: 52px !important;
}

.mz-input > .slot-start.slot-search {
  > mzic-svg {
    width: 18px;
  }

  & + input {
    padding-left: 52px;
  }
}

.mz-input > .slot-start.password + input {
  padding-left: 56px;
}

.mz-input > input.with-slot-end {
  padding-right: 92px;
}

.mz-input > input.with-slot-end.password {
  padding-right: 56px;
}

.mz-input > input:is(.success, .error).with-slot-end.password {
  padding-right: 87px;
}

.mz-label > label {
  @extend .text-body-md-semibold;

  .required {
    color: var(--red-500);
    font-size: 15px;
  }
}

.mz-form-row > .mz-label > p {
  @extend .text-body-xxsm;
  color: var(--neutral-300) !important;
}

.mz-input-container {
  position: relative;
  width: 100%;
}

.mz-input > :is(input, textarea, select),
.mz-input > .mz-input-container > :is(input, textarea, select) {
  @extend .mzic-input-style;
}

.mz-input--white > :is(input, textarea, select),
.mz-input--white > .mz-input-container > :is(input, textarea, select) {
  background-color: var(--white);
  color: var(--neutral-700);

  &::placeholder {
    color: var(--neutral-200);
  }

  &:hover {
    background-color: var(--white);
  }
}

.mz-form-row.auto .mz-input {
  width: auto;
}

.mz-form-row.auto .mz-input > select {
  padding-right: 62px;
}

.mz-input textarea {
  @extend .mzic-scrollbar;
  padding: 16px 24px;
  border-radius: 24px;
  min-height: 242px;
}

.mz-input select {
  background-image: url('/assets/icons/mzic-arrow-down-neutral-100.svg');
  background-repeat: no-repeat;
  background-position: right 28px center;
  background-size: 12px;
}

.mz-form-row .message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: var(--neutral-300);

  &:not(.error):not(.success) {
    background-image: url('/assets/icons/mzic-input-tooltip.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: left top;
    padding-left: 20px !important;
  }

  &.error {
    color: var(--red-500);
  }

  &.success {
    color: var(--green-500);
  }
}

.mz-form-row.for-radio .mz-input {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.full {
    div,
    div div {
      max-width: 100% !important;
    }
  }

  > div:first-child {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 38px;

    > div {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      max-width: 50%;
    }
  }
}

.mz-form-row.for-radio .mz-input label {
  font-size: 14px;
  line-height: 20px;
  padding-top: 2px;
  color: var(--neutral-100);
}

.mzic-radio-containers {
  display: inline-flex;
  align-items: center;
  gap: 34px;
}

.mzic-radio-container {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  label {
    font-size: 14px;
    margin-bottom: -3px;
  }
}

.mz-form-row.for-radio .mz-input input[type='radio'],
.mzic-radio {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid var(--neutral-300);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;

  &:disabled {
    cursor: not-allowed;

    + label {
      cursor: not-allowed;
    }
  }

  &.error {
    border: 2px solid var(--red-500);
  }

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: transparent;
    content: '';
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:checked::before {
    background-color: var(--purple-500);
  }

  &:checked:disabled::before {
    background-color: var(--neutral-400);
  }
}

////////////////////////////////////////////
/// Margins
/// ////////////
.mz-form-row.for-text .mz-input input + .mz-item-option,
.mz-form-row.for-text .mz-input .mz-item-option + .mz-item-option,
.mz-form-row.for-text .mz-input .mz-item-option + button,
.mz-form-row.for-button .mz-input .mz-item-option + button,
.mz-form-row.for-text .mz-input input + button,
.mz-form-row.for-textarea .mz-input textarea + button,
.mz-form-row.for-select .mz-input select + button {
  margin-top: 16px;
}

.mz-form-row.for-text .mz-input .message {
  padding-left: 10px;
}

.mz-form-row.for-image:not(.vertical) .mz-label {
  padding-top: 26px;
}

.mz-form-row.for-text:not(.vertical) .mz-label,
.mz-form-row.for-select:not(.vertical) .mz-label {
  padding-top: 16px;
}

.mz-form-row.for-select-label .mz-label {
  padding-top: 29px;
}

.mz-form-row.for-textarea .mz-label {
  padding-top: 20px;
}

.mz-form-row.for-toggle .mz-label {
  padding-top: 5px;
}

.mz-form-row.for-empty {
  flex-direction: column;
}

.mz-form-row.for-select-label .mz-input > label {
  font-size: 12px;
  line-height: 16px;
  color: var(--neutral-300);
  margin-bottom: 10px;
}

.mz-form-row.for-button {
  min-height: 50px;
  .mz-label {
    padding-top: 12px;
  }
}

.mz-item-option {
  position: relative;
  display: inline-flex;
  width: 100%;
  border: 1px solid var(--neutral-300);
  border-radius: 100px;
  padding: 6px 60px 6px 24px;
  min-height: 56px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  + .mz-item-option {
    margin-top: 16px;
  }

  > div {
    display: inline-flex;
    align-items: center;
    > img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;

      &.large {
        width: 34px;
        height: 34px;
      }
    }
  }

  > .mid {
    flex-direction: column;
    align-items: flex-start;

    strong {
      @extend .text-body-md;
      color: var(--neutral-100);
    }

    small {
      @extend .text-body-xxsm;
      color: var(--neutral-300);
    }
  }

  > .end {
    display: inline-flex;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0, -50%);
    gap: 8px;

    > .mz-button {
      width: 36px;
      height: 36px;

      &.delete:hover {
        background-color: var(--red-500);
      }

      > svg {
        width: 18px;
        max-height: 18px;
      }
    }
  }

  &.locked {
    background-color: var(--neutral-500-50);
    border-color: transparent;
    background-image: url('/assets/icons/mzic-lock.svg');
    background-repeat: no-repeat;
    background-position: right 18px center;
    background-size: 17px;

    > .end {
      right: 52px;
    }
  }
}

.mz-item-option.small {
  padding: 4px 24px;
  min-height: 44px;
}

.mz-item-option.secondary {
  background-color: var(--neutral-500-50);
  border-color: transparent;

  strong {
    color: var(--neutral-300);
  }
}

////////////////////////////////////////
/// inputs-2
///
.mz-form-row.inputs-2 {
  .mz-inputs-container {
    display: inline-flex;
    width: 60%;
    justify-content: space-between;

    .mz-input {
      width: calc(50% - 12px);
    }
  }
}

.mz-form-row .mz-input .input-tip {
  @extend .text-body-xsm;
  display: inline-block;
  color: var(--neutral-300);
  margin-top: 6px;
}

.mz-form-row.vertical {
  flex-direction: column;

  .mz-label {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .mz-input {
    width: 100%;

    + .mz-input {
      margin-top: 12px;
    }
  }
}

.mz-form-row.disabled {
  .mz-label label {
    color: var(--neutral-400) !important;
  }
  .mz-input {
    label {
      color: var(--neutral-400) !important;
    }
    :is(input, textarea, select),
    :is(input, textarea, select):hover {
      cursor: not-allowed;
      background-color: var(--neutral-500-50);
      color: var(--neutral-400);
      &::placeholder {
        color: var(--neutral-400);
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: var(--neutral-400);
      }
    }
  }
}

.mz-form-row.locked {
  :is(input, textarea, select) {
    background-image: url('/assets/icons/mzic-lock.svg');
    background-repeat: no-repeat;
    background-position: right 18px center;
    background-size: 17px;
    background-color: var(--neutral-500-50);
    border-color: transparent;
  }
}

.mz-form-row.for-select .mz-input mzic-select-basic {
  width: 100%;
}

.mz-form-row.for-image .mz-input {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;

  &:hover {
    .image-text .primary {
      text-decoration: underline;
    }
    .camera-icon {
      border-color: var(--purple-500);
    }
  }

  .camera-icon {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: var(--neutral-500-50);
    background-image: url('/assets/icons/mzic-camera.svg');
    background-repeat: no-repeat;
    background-size: 29px;
    background-position: center center;
    border: 2px solid transparent;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .image-text {
    display: inline-flex;
    max-width: calc(100% - 72px);
    padding-left: 16px;
    flex-direction: column;
    cursor: pointer;

    .primary {
      @extend .text-body-md-semibold;
      color: var(--purple-500);
    }

    .secondary {
      @extend .text-body-xsm;
      color: var(--neutral-300);
    }
  }
}

.mz-form-row.medium {
  .mz-input :is(input, textarea, select) {
    @extend .mzic-input-style;
    padding: 12px 16px 12px;
    font-size: 14px;
    line-height: 16px;
  }

  .mz-input select {
    padding-right: 44px;
    background-position: right 16px center;
  }
}

.mz-form-row.small {
  .mz-input > :is(input, textarea, select) {
    @extend .mzic-input-style;
    padding: 7px 16px 5px;
    font-size: 14px;
    line-height: 16px;
  }

  .mz-input select {
    padding-right: 44px;
    background-position: right 16px center;
  }
}

.mz-form-row .mz-input mzic-tooltip {
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translate(100%, -50%);
}

.mz-form-row.sub-row {
  margin-top: 0;

  .mz-label > label {
    color: var(--neutral-300);
    font-size: 14px;
    line-height: 20px;

    .required {
      font-size: 14px;
    }
  }
}

// inputs with icons
$icon-padding: 18px;

$icon-width-small: 15px;
$icon-width: 18px;
$icon-width-large: 28px;

$margin-gap-small: 4px;
$margin-gap: 8px;
$margin-gap-large: 12px;

.mz-form-row {
  &.icon-left-1-small .mz-input :is(input, textarea) {
    padding-left: calc(
      #{$icon-padding} + #{$icon-width-small} + #{$margin-gap-small} + #{$margin-gap-small}
    );
  }
  &.icon-right-1-small .mz-input :is(input, textarea) {
    padding-right: calc(
      #{$icon-padding} + #{$icon-width-small} + #{$margin-gap-small} + #{$margin-gap-small}
    );
  }
  &.icon-left-2-small .mz-input :is(input, textarea) {
    padding-left: calc(
      #{$icon-padding} + #{$icon-width-small} + #{$icon-width-small} + #{$margin-gap-small} +
        #{$margin-gap-small} + #{$margin-gap-small} + #{$margin-gap-small}
    );
  }
  &.icon-right-2-small .mz-input :is(input, textarea) {
    padding-right: calc(
      #{$icon-padding} + #{$icon-width-small} + #{$icon-width-small} + #{$margin-gap-small} +
        #{$margin-gap-small} + #{$margin-gap-small} + #{$margin-gap-small}
    );
  }

  &.icon-left-1 .mz-input :is(input, textarea) {
    padding-left: calc(#{$icon-padding} + #{$icon-width} + #{$margin-gap});
  }
  &.icon-right-1 .mz-input :is(input, textarea) {
    padding-right: calc(#{$icon-padding} + #{$icon-width} + #{$margin-gap});
  }
  &.icon-left-2 .mz-input :is(input, textarea) {
    padding-left: calc(
      #{$icon-padding} + #{$icon-width} + #{$icon-width} + #{$margin-gap} + #{$margin-gap} +
        #{$margin-gap}
    );
  }
  &.icon-right-2 .mz-input :is(input, textarea) {
    padding-right: calc(
      #{$icon-padding} + #{$icon-width} + #{$icon-width} + #{$margin-gap} + #{$margin-gap} +
        #{$margin-gap}
    );
  }

  &.icon-left-2-large .mz-input :is(input, textarea) {
    padding-left: calc(
      #{$icon-padding} + #{$icon-width-large} + #{$icon-width-large} + #{$margin-gap-large} +
        #{$margin-gap-large}
    );
  }
  &.icon-right-2-large .mz-input :is(input, textarea) {
    padding-right: calc(
      #{$icon-padding} + #{$icon-width-large} + #{$icon-width-large} + #{$margin-gap-large} +
        #{$margin-gap-large}
    );
  }

  &.icon-left-2-small .icon.left + .icon.left {
    left: $icon-padding + $icon-padding + $margin-gap-small + $margin-gap-small;
  }

  &.icon-right-2-small .icon.right + .icon.right {
    right: $icon-padding + $icon-padding + $margin-gap-small + $margin-gap-small;
  }

  &.icon-left-2 .icon.left + .icon.left {
    left: $icon-padding + $icon-padding + $margin-gap + $margin-gap;
  }

  &.icon-right-2 .icon.right + .icon.right {
    right: $icon-padding + $icon-padding + $margin-gap + $margin-gap;
  }

  &.icon-left-2-large .icon.left + .icon.left {
    left: $icon-padding + $icon-padding + $margin-gap-large + $margin-gap-large;
  }

  &.icon-right-2-large .icon.right + .icon.right {
    right: $icon-padding + $icon-padding + $margin-gap-large + $margin-gap-large;
  }

  &.icon-left-1-large .icon.left,
  &.icon-left-2-large .icon.left,
  &.icon-right-1-large .icon.right,
  &.icon-right-2-large .icon.right {
    width: $icon-width-large;
  }

  &.icon-left-1-small .icon.left,
  &.icon-left-2-small .icon.left,
  &.icon-right-1-small .icon.right,
  &.icon-right-2-small .icon.right {
    width: $icon-width-small;
  }

  &.icon-left-1-large .mz-input :is(input, textarea) {
    padding-left: calc(
      #{$icon-padding} + #{$icon-width-large} + #{$margin-gap-large}
    );
  }

  &.icon-right-1-large .mz-input :is(input, textarea) {
    padding-right: calc(
      #{$icon-padding} + #{$icon-width-large} + #{$margin-gap-large}
    );
  }

  &.for-select.icon-left-1-small .select .selected-item {
    padding-left: $icon-padding + $icon-width-small + $margin-gap-small +
      $margin-gap-small;
  }
  &.for-select.icon-left-1 .select .selected-item {
    padding-left: $icon-padding + $icon-width + $margin-gap;
  }
  &.for-select.icon-left-1-large .select .selected-item {
    padding-left: $icon-padding + $icon-width-large + $margin-gap-large;
  }

  .mz-input {
    .icon {
      position: absolute;
      width: $icon-width;
      max-height: 24px;
      top: 50%;
      transform: translate(0, -50%);
      display: inline-flex;
      justify-content: center;

      &.left {
        left: $icon-padding;
      }
      &.right {
        right: $icon-padding;
      }

      > mzic-svg {
        display: block;
      }

      > img {
        max-width: 100%;
        max-height: 100%;
      }

      > mzic-svg,
      > mzic-svg > svg,
      > mzic-svg > svg > path {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .input-prefix {
      @extend .text-body-xsm;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translate(0, -50%);
      color: var(--neutral-400);
    }
  }
}

.form-hint-success {
  @extend .text-body-xsm;
  color: var(--green-500);
}

.form-hint-error {
  @extend .text-body-xsm;
  color: var(--red-500);
}

.form-hint--with-space {
  margin-top: 6px;
  padding: 0 10px;
}

.mz-form-row.for-paragraph {
  p {
    @extend .text-body-xxsm;
  }
}
