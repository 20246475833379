@charset "UTF-8";
/*!
 *  chordium css variables v1 (https://mzic.io/)
 * Copyright2023 The mzic Authors (Waldir Feitoza de Azevedo Neto)
 * Licensed under MIT (add license)
 */
/*****************************************/
/* VARIABLE COLORS */
/***** Color helps express hierarchy, creating a brand"s look, adding meaning, and showing element status. **/
/**********************************/
/***** FEEDBACK COLORS **********/
/*************COLORS MAPS ****************/
/********* theme-color-variables ****************************/
.chordium-container,
.chordium-container-fluid,
.chordium-container-xxl,
.chordium-container-xl,
.chordium-container-lg,
.chordium-container-md,
.chordium-container-sm,
.chordium-container-xs {
  --chordium-gutter-x: 1.5rem;
  --chordium-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--chordium-gutter-x) * 0.5);
  padding-left: calc(var(--chordium-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.chordium-container {
  max-width: 0;
}

@media (min-width: 576px) {
  .chordium-container-sm, .chordium-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .chordium-container-md, .chordium-container-sm, .chordium-container {
    max-width: 720px;
  }
}
@media (min-width: 1280px) {
  .chordium-container-lg, .chordium-container-md, .chordium-container-sm, .chordium-container {
    max-width: 1180px;
  }
}
@media (min-width: 1366px) {
  .chordium-container-xl, .chordium-container-lg, .chordium-container-md, .chordium-container-sm, .chordium-container {
    max-width: 1300px;
  }
}
@media (min-width: 1800px) {
  .chordium-container-xxl, .chordium-container-xl, .chordium-container-lg, .chordium-container-md, .chordium-container-sm, .chordium-container {
    max-width: 1720px;
  }
}
:root {
  --chordium-breakpoint-xs: 0;
  --chordium-breakpoint-sm: 576px;
  --chordium-breakpoint-md: 768px;
  --chordium-breakpoint-lg: 1280px;
  --chordium-breakpoint-xl: 1366px;
  --chordium-breakpoint-xxl: 1800px;
}

.chordium-row {
  --chordium-gutter-x: 1.5rem;
  --chordium-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--chordium-gutter-y));
  margin-right: calc(-0.5 * var(--chordium-gutter-x));
  margin-left: calc(-0.5 * var(--chordium-gutter-x));
}
.chordium-row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--chordium-gutter-x) * 0.5);
  padding-left: calc(var(--chordium-gutter-x) * 0.5);
  margin-top: var(--chordium-gutter-y);
}

.chordium-col {
  flex: 1 0 0%;
}

.chordium-row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.chordium-row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.chordium-row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.chordium-row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.chordium-row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.chordium-row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.chordium-row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.chordium-col-auto {
  flex: 0 0 auto;
  width: auto;
}

.chordium-col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.chordium-col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.chordium-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.chordium-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.chordium-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.chordium-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.chordium-col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.chordium-col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.chordium-col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.chordium-col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.chordium-col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.chordium-col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.chordium-offset-1 {
  margin-left: 8.33333333%;
}

.chordium-offset-2 {
  margin-left: 16.66666667%;
}

.chordium-offset-3 {
  margin-left: 25%;
}

.chordium-offset-4 {
  margin-left: 33.33333333%;
}

.chordium-offset-5 {
  margin-left: 41.66666667%;
}

.chordium-offset-6 {
  margin-left: 50%;
}

.chordium-offset-7 {
  margin-left: 58.33333333%;
}

.chordium-offset-8 {
  margin-left: 66.66666667%;
}

.chordium-offset-9 {
  margin-left: 75%;
}

.chordium-offset-10 {
  margin-left: 83.33333333%;
}

.chordium-offset-11 {
  margin-left: 91.66666667%;
}

.chordium-g-1,
.chordium-gx-1 {
  --chordium-gutter-x: 2px;
}

.chordium-g-1,
.chordium-gy-1 {
  --chordium-gutter-y: 2px;
}

.chordium-g-2,
.chordium-gx-2 {
  --chordium-gutter-x: 4px;
}

.chordium-g-2,
.chordium-gy-2 {
  --chordium-gutter-y: 4px;
}

.chordium-g-3,
.chordium-gx-3 {
  --chordium-gutter-x: 6px;
}

.chordium-g-3,
.chordium-gy-3 {
  --chordium-gutter-y: 6px;
}

.chordium-g-4,
.chordium-gx-4 {
  --chordium-gutter-x: 8px;
}

.chordium-g-4,
.chordium-gy-4 {
  --chordium-gutter-y: 8px;
}

.chordium-g-5,
.chordium-gx-5 {
  --chordium-gutter-x: 10px;
}

.chordium-g-5,
.chordium-gy-5 {
  --chordium-gutter-y: 10px;
}

.chordium-g-6,
.chordium-gx-6 {
  --chordium-gutter-x: 12px;
}

.chordium-g-6,
.chordium-gy-6 {
  --chordium-gutter-y: 12px;
}

.chordium-g-7,
.chordium-gx-7 {
  --chordium-gutter-x: 14px;
}

.chordium-g-7,
.chordium-gy-7 {
  --chordium-gutter-y: 14px;
}

.chordium-g-8,
.chordium-gx-8 {
  --chordium-gutter-x: 16px;
}

.chordium-g-8,
.chordium-gy-8 {
  --chordium-gutter-y: 16px;
}

.chordium-g-9,
.chordium-gx-9 {
  --chordium-gutter-x: 18px;
}

.chordium-g-9,
.chordium-gy-9 {
  --chordium-gutter-y: 18px;
}

.chordium-g-10,
.chordium-gx-10 {
  --chordium-gutter-x: 20px;
}

.chordium-g-10,
.chordium-gy-10 {
  --chordium-gutter-y: 20px;
}

@media (min-width: 576px) {
  .chordium-col-sm {
    flex: 1 0 0%;
  }
  .chordium-row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .chordium-row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .chordium-col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .chordium-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .chordium-col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .chordium-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .chordium-col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .chordium-col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .chordium-col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-offset-sm-0 {
    margin-left: 0;
  }
  .chordium-offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .chordium-offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .chordium-offset-sm-3 {
    margin-left: 25%;
  }
  .chordium-offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .chordium-offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .chordium-offset-sm-6 {
    margin-left: 50%;
  }
  .chordium-offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .chordium-offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .chordium-offset-sm-9 {
    margin-left: 75%;
  }
  .chordium-offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .chordium-offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .chordium-g-sm-1,
  .chordium-gx-sm-1 {
    --chordium-gutter-x: 2px;
  }
  .chordium-g-sm-1,
  .chordium-gy-sm-1 {
    --chordium-gutter-y: 2px;
  }
  .chordium-g-sm-2,
  .chordium-gx-sm-2 {
    --chordium-gutter-x: 4px;
  }
  .chordium-g-sm-2,
  .chordium-gy-sm-2 {
    --chordium-gutter-y: 4px;
  }
  .chordium-g-sm-3,
  .chordium-gx-sm-3 {
    --chordium-gutter-x: 6px;
  }
  .chordium-g-sm-3,
  .chordium-gy-sm-3 {
    --chordium-gutter-y: 6px;
  }
  .chordium-g-sm-4,
  .chordium-gx-sm-4 {
    --chordium-gutter-x: 8px;
  }
  .chordium-g-sm-4,
  .chordium-gy-sm-4 {
    --chordium-gutter-y: 8px;
  }
  .chordium-g-sm-5,
  .chordium-gx-sm-5 {
    --chordium-gutter-x: 10px;
  }
  .chordium-g-sm-5,
  .chordium-gy-sm-5 {
    --chordium-gutter-y: 10px;
  }
  .chordium-g-sm-6,
  .chordium-gx-sm-6 {
    --chordium-gutter-x: 12px;
  }
  .chordium-g-sm-6,
  .chordium-gy-sm-6 {
    --chordium-gutter-y: 12px;
  }
  .chordium-g-sm-7,
  .chordium-gx-sm-7 {
    --chordium-gutter-x: 14px;
  }
  .chordium-g-sm-7,
  .chordium-gy-sm-7 {
    --chordium-gutter-y: 14px;
  }
  .chordium-g-sm-8,
  .chordium-gx-sm-8 {
    --chordium-gutter-x: 16px;
  }
  .chordium-g-sm-8,
  .chordium-gy-sm-8 {
    --chordium-gutter-y: 16px;
  }
  .chordium-g-sm-9,
  .chordium-gx-sm-9 {
    --chordium-gutter-x: 18px;
  }
  .chordium-g-sm-9,
  .chordium-gy-sm-9 {
    --chordium-gutter-y: 18px;
  }
  .chordium-g-sm-10,
  .chordium-gx-sm-10 {
    --chordium-gutter-x: 20px;
  }
  .chordium-g-sm-10,
  .chordium-gy-sm-10 {
    --chordium-gutter-y: 20px;
  }
}
@media (min-width: 768px) {
  .chordium-col-md {
    flex: 1 0 0%;
  }
  .chordium-row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .chordium-row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .chordium-col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .chordium-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .chordium-col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .chordium-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .chordium-col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .chordium-col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .chordium-col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-offset-md-0 {
    margin-left: 0;
  }
  .chordium-offset-md-1 {
    margin-left: 8.33333333%;
  }
  .chordium-offset-md-2 {
    margin-left: 16.66666667%;
  }
  .chordium-offset-md-3 {
    margin-left: 25%;
  }
  .chordium-offset-md-4 {
    margin-left: 33.33333333%;
  }
  .chordium-offset-md-5 {
    margin-left: 41.66666667%;
  }
  .chordium-offset-md-6 {
    margin-left: 50%;
  }
  .chordium-offset-md-7 {
    margin-left: 58.33333333%;
  }
  .chordium-offset-md-8 {
    margin-left: 66.66666667%;
  }
  .chordium-offset-md-9 {
    margin-left: 75%;
  }
  .chordium-offset-md-10 {
    margin-left: 83.33333333%;
  }
  .chordium-offset-md-11 {
    margin-left: 91.66666667%;
  }
  .chordium-g-md-1,
  .chordium-gx-md-1 {
    --chordium-gutter-x: 2px;
  }
  .chordium-g-md-1,
  .chordium-gy-md-1 {
    --chordium-gutter-y: 2px;
  }
  .chordium-g-md-2,
  .chordium-gx-md-2 {
    --chordium-gutter-x: 4px;
  }
  .chordium-g-md-2,
  .chordium-gy-md-2 {
    --chordium-gutter-y: 4px;
  }
  .chordium-g-md-3,
  .chordium-gx-md-3 {
    --chordium-gutter-x: 6px;
  }
  .chordium-g-md-3,
  .chordium-gy-md-3 {
    --chordium-gutter-y: 6px;
  }
  .chordium-g-md-4,
  .chordium-gx-md-4 {
    --chordium-gutter-x: 8px;
  }
  .chordium-g-md-4,
  .chordium-gy-md-4 {
    --chordium-gutter-y: 8px;
  }
  .chordium-g-md-5,
  .chordium-gx-md-5 {
    --chordium-gutter-x: 10px;
  }
  .chordium-g-md-5,
  .chordium-gy-md-5 {
    --chordium-gutter-y: 10px;
  }
  .chordium-g-md-6,
  .chordium-gx-md-6 {
    --chordium-gutter-x: 12px;
  }
  .chordium-g-md-6,
  .chordium-gy-md-6 {
    --chordium-gutter-y: 12px;
  }
  .chordium-g-md-7,
  .chordium-gx-md-7 {
    --chordium-gutter-x: 14px;
  }
  .chordium-g-md-7,
  .chordium-gy-md-7 {
    --chordium-gutter-y: 14px;
  }
  .chordium-g-md-8,
  .chordium-gx-md-8 {
    --chordium-gutter-x: 16px;
  }
  .chordium-g-md-8,
  .chordium-gy-md-8 {
    --chordium-gutter-y: 16px;
  }
  .chordium-g-md-9,
  .chordium-gx-md-9 {
    --chordium-gutter-x: 18px;
  }
  .chordium-g-md-9,
  .chordium-gy-md-9 {
    --chordium-gutter-y: 18px;
  }
  .chordium-g-md-10,
  .chordium-gx-md-10 {
    --chordium-gutter-x: 20px;
  }
  .chordium-g-md-10,
  .chordium-gy-md-10 {
    --chordium-gutter-y: 20px;
  }
}
@media (min-width: 1280px) {
  .chordium-col-lg {
    flex: 1 0 0%;
  }
  .chordium-row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .chordium-row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .chordium-col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .chordium-col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .chordium-col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .chordium-col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .chordium-col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .chordium-col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .chordium-col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-offset-lg-0 {
    margin-left: 0;
  }
  .chordium-offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .chordium-offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .chordium-offset-lg-3 {
    margin-left: 25%;
  }
  .chordium-offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .chordium-offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .chordium-offset-lg-6 {
    margin-left: 50%;
  }
  .chordium-offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .chordium-offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .chordium-offset-lg-9 {
    margin-left: 75%;
  }
  .chordium-offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .chordium-offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .chordium-g-lg-1,
  .chordium-gx-lg-1 {
    --chordium-gutter-x: 2px;
  }
  .chordium-g-lg-1,
  .chordium-gy-lg-1 {
    --chordium-gutter-y: 2px;
  }
  .chordium-g-lg-2,
  .chordium-gx-lg-2 {
    --chordium-gutter-x: 4px;
  }
  .chordium-g-lg-2,
  .chordium-gy-lg-2 {
    --chordium-gutter-y: 4px;
  }
  .chordium-g-lg-3,
  .chordium-gx-lg-3 {
    --chordium-gutter-x: 6px;
  }
  .chordium-g-lg-3,
  .chordium-gy-lg-3 {
    --chordium-gutter-y: 6px;
  }
  .chordium-g-lg-4,
  .chordium-gx-lg-4 {
    --chordium-gutter-x: 8px;
  }
  .chordium-g-lg-4,
  .chordium-gy-lg-4 {
    --chordium-gutter-y: 8px;
  }
  .chordium-g-lg-5,
  .chordium-gx-lg-5 {
    --chordium-gutter-x: 10px;
  }
  .chordium-g-lg-5,
  .chordium-gy-lg-5 {
    --chordium-gutter-y: 10px;
  }
  .chordium-g-lg-6,
  .chordium-gx-lg-6 {
    --chordium-gutter-x: 12px;
  }
  .chordium-g-lg-6,
  .chordium-gy-lg-6 {
    --chordium-gutter-y: 12px;
  }
  .chordium-g-lg-7,
  .chordium-gx-lg-7 {
    --chordium-gutter-x: 14px;
  }
  .chordium-g-lg-7,
  .chordium-gy-lg-7 {
    --chordium-gutter-y: 14px;
  }
  .chordium-g-lg-8,
  .chordium-gx-lg-8 {
    --chordium-gutter-x: 16px;
  }
  .chordium-g-lg-8,
  .chordium-gy-lg-8 {
    --chordium-gutter-y: 16px;
  }
  .chordium-g-lg-9,
  .chordium-gx-lg-9 {
    --chordium-gutter-x: 18px;
  }
  .chordium-g-lg-9,
  .chordium-gy-lg-9 {
    --chordium-gutter-y: 18px;
  }
  .chordium-g-lg-10,
  .chordium-gx-lg-10 {
    --chordium-gutter-x: 20px;
  }
  .chordium-g-lg-10,
  .chordium-gy-lg-10 {
    --chordium-gutter-y: 20px;
  }
}
@media (min-width: 1366px) {
  .chordium-col-xl {
    flex: 1 0 0%;
  }
  .chordium-row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .chordium-row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .chordium-col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .chordium-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .chordium-col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .chordium-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .chordium-col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .chordium-col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .chordium-col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-offset-xl-0 {
    margin-left: 0;
  }
  .chordium-offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .chordium-offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .chordium-offset-xl-3 {
    margin-left: 25%;
  }
  .chordium-offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .chordium-offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .chordium-offset-xl-6 {
    margin-left: 50%;
  }
  .chordium-offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .chordium-offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .chordium-offset-xl-9 {
    margin-left: 75%;
  }
  .chordium-offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .chordium-offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .chordium-g-xl-1,
  .chordium-gx-xl-1 {
    --chordium-gutter-x: 2px;
  }
  .chordium-g-xl-1,
  .chordium-gy-xl-1 {
    --chordium-gutter-y: 2px;
  }
  .chordium-g-xl-2,
  .chordium-gx-xl-2 {
    --chordium-gutter-x: 4px;
  }
  .chordium-g-xl-2,
  .chordium-gy-xl-2 {
    --chordium-gutter-y: 4px;
  }
  .chordium-g-xl-3,
  .chordium-gx-xl-3 {
    --chordium-gutter-x: 6px;
  }
  .chordium-g-xl-3,
  .chordium-gy-xl-3 {
    --chordium-gutter-y: 6px;
  }
  .chordium-g-xl-4,
  .chordium-gx-xl-4 {
    --chordium-gutter-x: 8px;
  }
  .chordium-g-xl-4,
  .chordium-gy-xl-4 {
    --chordium-gutter-y: 8px;
  }
  .chordium-g-xl-5,
  .chordium-gx-xl-5 {
    --chordium-gutter-x: 10px;
  }
  .chordium-g-xl-5,
  .chordium-gy-xl-5 {
    --chordium-gutter-y: 10px;
  }
  .chordium-g-xl-6,
  .chordium-gx-xl-6 {
    --chordium-gutter-x: 12px;
  }
  .chordium-g-xl-6,
  .chordium-gy-xl-6 {
    --chordium-gutter-y: 12px;
  }
  .chordium-g-xl-7,
  .chordium-gx-xl-7 {
    --chordium-gutter-x: 14px;
  }
  .chordium-g-xl-7,
  .chordium-gy-xl-7 {
    --chordium-gutter-y: 14px;
  }
  .chordium-g-xl-8,
  .chordium-gx-xl-8 {
    --chordium-gutter-x: 16px;
  }
  .chordium-g-xl-8,
  .chordium-gy-xl-8 {
    --chordium-gutter-y: 16px;
  }
  .chordium-g-xl-9,
  .chordium-gx-xl-9 {
    --chordium-gutter-x: 18px;
  }
  .chordium-g-xl-9,
  .chordium-gy-xl-9 {
    --chordium-gutter-y: 18px;
  }
  .chordium-g-xl-10,
  .chordium-gx-xl-10 {
    --chordium-gutter-x: 20px;
  }
  .chordium-g-xl-10,
  .chordium-gy-xl-10 {
    --chordium-gutter-y: 20px;
  }
}
@media (min-width: 1800px) {
  .chordium-col-xxl {
    flex: 1 0 0%;
  }
  .chordium-row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .chordium-row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .chordium-col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .chordium-col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .chordium-col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .chordium-col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .chordium-col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .chordium-col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .chordium-col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .chordium-col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .chordium-col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .chordium-col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .chordium-col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .chordium-col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .chordium-offset-xxl-0 {
    margin-left: 0;
  }
  .chordium-offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .chordium-offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .chordium-offset-xxl-3 {
    margin-left: 25%;
  }
  .chordium-offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .chordium-offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .chordium-offset-xxl-6 {
    margin-left: 50%;
  }
  .chordium-offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .chordium-offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .chordium-offset-xxl-9 {
    margin-left: 75%;
  }
  .chordium-offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .chordium-offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .chordium-g-xxl-1,
  .chordium-gx-xxl-1 {
    --chordium-gutter-x: 2px;
  }
  .chordium-g-xxl-1,
  .chordium-gy-xxl-1 {
    --chordium-gutter-y: 2px;
  }
  .chordium-g-xxl-2,
  .chordium-gx-xxl-2 {
    --chordium-gutter-x: 4px;
  }
  .chordium-g-xxl-2,
  .chordium-gy-xxl-2 {
    --chordium-gutter-y: 4px;
  }
  .chordium-g-xxl-3,
  .chordium-gx-xxl-3 {
    --chordium-gutter-x: 6px;
  }
  .chordium-g-xxl-3,
  .chordium-gy-xxl-3 {
    --chordium-gutter-y: 6px;
  }
  .chordium-g-xxl-4,
  .chordium-gx-xxl-4 {
    --chordium-gutter-x: 8px;
  }
  .chordium-g-xxl-4,
  .chordium-gy-xxl-4 {
    --chordium-gutter-y: 8px;
  }
  .chordium-g-xxl-5,
  .chordium-gx-xxl-5 {
    --chordium-gutter-x: 10px;
  }
  .chordium-g-xxl-5,
  .chordium-gy-xxl-5 {
    --chordium-gutter-y: 10px;
  }
  .chordium-g-xxl-6,
  .chordium-gx-xxl-6 {
    --chordium-gutter-x: 12px;
  }
  .chordium-g-xxl-6,
  .chordium-gy-xxl-6 {
    --chordium-gutter-y: 12px;
  }
  .chordium-g-xxl-7,
  .chordium-gx-xxl-7 {
    --chordium-gutter-x: 14px;
  }
  .chordium-g-xxl-7,
  .chordium-gy-xxl-7 {
    --chordium-gutter-y: 14px;
  }
  .chordium-g-xxl-8,
  .chordium-gx-xxl-8 {
    --chordium-gutter-x: 16px;
  }
  .chordium-g-xxl-8,
  .chordium-gy-xxl-8 {
    --chordium-gutter-y: 16px;
  }
  .chordium-g-xxl-9,
  .chordium-gx-xxl-9 {
    --chordium-gutter-x: 18px;
  }
  .chordium-g-xxl-9,
  .chordium-gy-xxl-9 {
    --chordium-gutter-y: 18px;
  }
  .chordium-g-xxl-10,
  .chordium-gx-xxl-10 {
    --chordium-gutter-x: 20px;
  }
  .chordium-g-xxl-10,
  .chordium-gy-xxl-10 {
    --chordium-gutter-y: 20px;
  }
}
.chordium-inline {
  display: inline !important;
}

.chordium-inline-block {
  display: inline-block !important;
}

.chordium-block {
  display: block !important;
}

.chordium-grid {
  display: grid !important;
}

.chordium-inline-grid {
  display: inline-grid !important;
}

.chordium-table {
  display: table !important;
}

.chordium-table-row {
  display: table-row !important;
}

.chordium-table-cell {
  display: table-cell !important;
}

.chordium-flex {
  display: flex !important;
}

.chordium-inline-flex {
  display: inline-flex !important;
}

.chordium-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.chordium-flex-row {
  flex-direction: row !important;
}

.chordium-flex-column {
  flex-direction: column !important;
}

.chordium-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.chordium-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.chordium-flex-grow-0 {
  flex-grow: 0 !important;
}

.chordium-flex-grow-1 {
  flex-grow: 1 !important;
}

.chordium-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.chordium-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.chordium-flex-wrap {
  flex-wrap: wrap !important;
}

.chordium-flex-nowrap {
  flex-wrap: nowrap !important;
}

.chordium-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.chordium-m-1 {
  margin: 2px !important;
}

.chordium-m-2 {
  margin: 4px !important;
}

.chordium-m-3 {
  margin: 6px !important;
}

.chordium-m-4 {
  margin: 8px !important;
}

.chordium-m-5 {
  margin: 10px !important;
}

.chordium-m-6 {
  margin: 12px !important;
}

.chordium-m-7 {
  margin: 14px !important;
}

.chordium-m-8 {
  margin: 16px !important;
}

.chordium-m-9 {
  margin: 18px !important;
}

.chordium-m-10 {
  margin: 20px !important;
}

.chordium-m-auto {
  margin: auto !important;
}

.chordium-mx-1 {
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.chordium-mx-2 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.chordium-mx-3 {
  margin-right: 6px !important;
  margin-left: 6px !important;
}

.chordium-mx-4 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.chordium-mx-5 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.chordium-mx-6 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.chordium-mx-7 {
  margin-right: 14px !important;
  margin-left: 14px !important;
}

.chordium-mx-8 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.chordium-mx-9 {
  margin-right: 18px !important;
  margin-left: 18px !important;
}

.chordium-mx-10 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.chordium-mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.chordium-my-1 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.chordium-my-2 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.chordium-my-3 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.chordium-my-4 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.chordium-my-5 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.chordium-my-6 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.chordium-my-7 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.chordium-my-8 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.chordium-my-9 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.chordium-my-10 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.chordium-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.chordium-mt-1 {
  margin-top: 2px !important;
}

.chordium-mt-2 {
  margin-top: 4px !important;
}

.chordium-mt-3 {
  margin-top: 6px !important;
}

.chordium-mt-4 {
  margin-top: 8px !important;
}

.chordium-mt-5 {
  margin-top: 10px !important;
}

.chordium-mt-6 {
  margin-top: 12px !important;
}

.chordium-mt-7 {
  margin-top: 14px !important;
}

.chordium-mt-8 {
  margin-top: 16px !important;
}

.chordium-mt-9 {
  margin-top: 18px !important;
}

.chordium-mt-10 {
  margin-top: 20px !important;
}

.chordium-mt-auto {
  margin-top: auto !important;
}

.chordium-me-1 {
  margin-right: 2px !important;
}

.chordium-me-2 {
  margin-right: 4px !important;
}

.chordium-me-3 {
  margin-right: 6px !important;
}

.chordium-me-4 {
  margin-right: 8px !important;
}

.chordium-me-5 {
  margin-right: 10px !important;
}

.chordium-me-6 {
  margin-right: 12px !important;
}

.chordium-me-7 {
  margin-right: 14px !important;
}

.chordium-me-8 {
  margin-right: 16px !important;
}

.chordium-me-9 {
  margin-right: 18px !important;
}

.chordium-me-10 {
  margin-right: 20px !important;
}

.chordium-me-auto {
  margin-right: auto !important;
}

.chordium-mb-1 {
  margin-bottom: 2px !important;
}

.chordium-mb-2 {
  margin-bottom: 4px !important;
}

.chordium-mb-3 {
  margin-bottom: 6px !important;
}

.chordium-mb-4 {
  margin-bottom: 8px !important;
}

.chordium-mb-5 {
  margin-bottom: 10px !important;
}

.chordium-mb-6 {
  margin-bottom: 12px !important;
}

.chordium-mb-7 {
  margin-bottom: 14px !important;
}

.chordium-mb-8 {
  margin-bottom: 16px !important;
}

.chordium-mb-9 {
  margin-bottom: 18px !important;
}

.chordium-mb-10 {
  margin-bottom: 20px !important;
}

.chordium-mb-auto {
  margin-bottom: auto !important;
}

.chordium-ms-1 {
  margin-left: 2px !important;
}

.chordium-ms-2 {
  margin-left: 4px !important;
}

.chordium-ms-3 {
  margin-left: 6px !important;
}

.chordium-ms-4 {
  margin-left: 8px !important;
}

.chordium-ms-5 {
  margin-left: 10px !important;
}

.chordium-ms-6 {
  margin-left: 12px !important;
}

.chordium-ms-7 {
  margin-left: 14px !important;
}

.chordium-ms-8 {
  margin-left: 16px !important;
}

.chordium-ms-9 {
  margin-left: 18px !important;
}

.chordium-ms-10 {
  margin-left: 20px !important;
}

.chordium-ms-auto {
  margin-left: auto !important;
}

.chordium-p-1 {
  padding: 2px !important;
}

.chordium-p-2 {
  padding: 4px !important;
}

.chordium-p-3 {
  padding: 6px !important;
}

.chordium-p-4 {
  padding: 8px !important;
}

.chordium-p-5 {
  padding: 10px !important;
}

.chordium-p-6 {
  padding: 12px !important;
}

.chordium-p-7 {
  padding: 14px !important;
}

.chordium-p-8 {
  padding: 16px !important;
}

.chordium-p-9 {
  padding: 18px !important;
}

.chordium-p-10 {
  padding: 20px !important;
}

.chordium-px-1 {
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.chordium-px-2 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.chordium-px-3 {
  padding-right: 6px !important;
  padding-left: 6px !important;
}

.chordium-px-4 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.chordium-px-5 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.chordium-px-6 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.chordium-px-7 {
  padding-right: 14px !important;
  padding-left: 14px !important;
}

.chordium-px-8 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.chordium-px-9 {
  padding-right: 18px !important;
  padding-left: 18px !important;
}

.chordium-px-10 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.chordium-py-1 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.chordium-py-2 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.chordium-py-3 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.chordium-py-4 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.chordium-py-5 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.chordium-py-6 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.chordium-py-7 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.chordium-py-8 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.chordium-py-9 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.chordium-py-10 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.chordium-pt-1 {
  padding-top: 2px !important;
}

.chordium-pt-2 {
  padding-top: 4px !important;
}

.chordium-pt-3 {
  padding-top: 6px !important;
}

.chordium-pt-4 {
  padding-top: 8px !important;
}

.chordium-pt-5 {
  padding-top: 10px !important;
}

.chordium-pt-6 {
  padding-top: 12px !important;
}

.chordium-pt-7 {
  padding-top: 14px !important;
}

.chordium-pt-8 {
  padding-top: 16px !important;
}

.chordium-pt-9 {
  padding-top: 18px !important;
}

.chordium-pt-10 {
  padding-top: 20px !important;
}

.chordium-pe-1 {
  padding-right: 2px !important;
}

.chordium-pe-2 {
  padding-right: 4px !important;
}

.chordium-pe-3 {
  padding-right: 6px !important;
}

.chordium-pe-4 {
  padding-right: 8px !important;
}

.chordium-pe-5 {
  padding-right: 10px !important;
}

.chordium-pe-6 {
  padding-right: 12px !important;
}

.chordium-pe-7 {
  padding-right: 14px !important;
}

.chordium-pe-8 {
  padding-right: 16px !important;
}

.chordium-pe-9 {
  padding-right: 18px !important;
}

.chordium-pe-10 {
  padding-right: 20px !important;
}

.chordium-pb-1 {
  padding-bottom: 2px !important;
}

.chordium-pb-2 {
  padding-bottom: 4px !important;
}

.chordium-pb-3 {
  padding-bottom: 6px !important;
}

.chordium-pb-4 {
  padding-bottom: 8px !important;
}

.chordium-pb-5 {
  padding-bottom: 10px !important;
}

.chordium-pb-6 {
  padding-bottom: 12px !important;
}

.chordium-pb-7 {
  padding-bottom: 14px !important;
}

.chordium-pb-8 {
  padding-bottom: 16px !important;
}

.chordium-pb-9 {
  padding-bottom: 18px !important;
}

.chordium-pb-10 {
  padding-bottom: 20px !important;
}

.chordium-ps-1 {
  padding-left: 2px !important;
}

.chordium-ps-2 {
  padding-left: 4px !important;
}

.chordium-ps-3 {
  padding-left: 6px !important;
}

.chordium-ps-4 {
  padding-left: 8px !important;
}

.chordium-ps-5 {
  padding-left: 10px !important;
}

.chordium-ps-6 {
  padding-left: 12px !important;
}

.chordium-ps-7 {
  padding-left: 14px !important;
}

.chordium-ps-8 {
  padding-left: 16px !important;
}

.chordium-ps-9 {
  padding-left: 18px !important;
}

.chordium-ps-10 {
  padding-left: 20px !important;
}

@media (min-width: 576px) {
  .chordium-sm-inline {
    display: inline !important;
  }
  .chordium-sm-inline-block {
    display: inline-block !important;
  }
  .chordium-sm-block {
    display: block !important;
  }
  .chordium-sm-grid {
    display: grid !important;
  }
  .chordium-sm-inline-grid {
    display: inline-grid !important;
  }
  .chordium-sm-table {
    display: table !important;
  }
  .chordium-sm-table-row {
    display: table-row !important;
  }
  .chordium-sm-table-cell {
    display: table-cell !important;
  }
  .chordium-sm-flex {
    display: flex !important;
  }
  .chordium-sm-inline-flex {
    display: inline-flex !important;
  }
  .chordium-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .chordium-flex-sm-row {
    flex-direction: row !important;
  }
  .chordium-flex-sm-column {
    flex-direction: column !important;
  }
  .chordium-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .chordium-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .chordium-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .chordium-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .chordium-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .chordium-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .chordium-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .chordium-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .chordium-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .chordium-m-sm-1 {
    margin: 2px !important;
  }
  .chordium-m-sm-2 {
    margin: 4px !important;
  }
  .chordium-m-sm-3 {
    margin: 6px !important;
  }
  .chordium-m-sm-4 {
    margin: 8px !important;
  }
  .chordium-m-sm-5 {
    margin: 10px !important;
  }
  .chordium-m-sm-6 {
    margin: 12px !important;
  }
  .chordium-m-sm-7 {
    margin: 14px !important;
  }
  .chordium-m-sm-8 {
    margin: 16px !important;
  }
  .chordium-m-sm-9 {
    margin: 18px !important;
  }
  .chordium-m-sm-10 {
    margin: 20px !important;
  }
  .chordium-m-sm-auto {
    margin: auto !important;
  }
  .chordium-mx-sm-1 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .chordium-mx-sm-2 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .chordium-mx-sm-3 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .chordium-mx-sm-4 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .chordium-mx-sm-5 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .chordium-mx-sm-6 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .chordium-mx-sm-7 {
    margin-right: 14px !important;
    margin-left: 14px !important;
  }
  .chordium-mx-sm-8 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .chordium-mx-sm-9 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .chordium-mx-sm-10 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .chordium-mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .chordium-my-sm-1 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .chordium-my-sm-2 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .chordium-my-sm-3 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .chordium-my-sm-4 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .chordium-my-sm-5 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .chordium-my-sm-6 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .chordium-my-sm-7 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .chordium-my-sm-8 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .chordium-my-sm-9 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .chordium-my-sm-10 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .chordium-my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .chordium-mt-sm-1 {
    margin-top: 2px !important;
  }
  .chordium-mt-sm-2 {
    margin-top: 4px !important;
  }
  .chordium-mt-sm-3 {
    margin-top: 6px !important;
  }
  .chordium-mt-sm-4 {
    margin-top: 8px !important;
  }
  .chordium-mt-sm-5 {
    margin-top: 10px !important;
  }
  .chordium-mt-sm-6 {
    margin-top: 12px !important;
  }
  .chordium-mt-sm-7 {
    margin-top: 14px !important;
  }
  .chordium-mt-sm-8 {
    margin-top: 16px !important;
  }
  .chordium-mt-sm-9 {
    margin-top: 18px !important;
  }
  .chordium-mt-sm-10 {
    margin-top: 20px !important;
  }
  .chordium-mt-sm-auto {
    margin-top: auto !important;
  }
  .chordium-me-sm-1 {
    margin-right: 2px !important;
  }
  .chordium-me-sm-2 {
    margin-right: 4px !important;
  }
  .chordium-me-sm-3 {
    margin-right: 6px !important;
  }
  .chordium-me-sm-4 {
    margin-right: 8px !important;
  }
  .chordium-me-sm-5 {
    margin-right: 10px !important;
  }
  .chordium-me-sm-6 {
    margin-right: 12px !important;
  }
  .chordium-me-sm-7 {
    margin-right: 14px !important;
  }
  .chordium-me-sm-8 {
    margin-right: 16px !important;
  }
  .chordium-me-sm-9 {
    margin-right: 18px !important;
  }
  .chordium-me-sm-10 {
    margin-right: 20px !important;
  }
  .chordium-me-sm-auto {
    margin-right: auto !important;
  }
  .chordium-mb-sm-1 {
    margin-bottom: 2px !important;
  }
  .chordium-mb-sm-2 {
    margin-bottom: 4px !important;
  }
  .chordium-mb-sm-3 {
    margin-bottom: 6px !important;
  }
  .chordium-mb-sm-4 {
    margin-bottom: 8px !important;
  }
  .chordium-mb-sm-5 {
    margin-bottom: 10px !important;
  }
  .chordium-mb-sm-6 {
    margin-bottom: 12px !important;
  }
  .chordium-mb-sm-7 {
    margin-bottom: 14px !important;
  }
  .chordium-mb-sm-8 {
    margin-bottom: 16px !important;
  }
  .chordium-mb-sm-9 {
    margin-bottom: 18px !important;
  }
  .chordium-mb-sm-10 {
    margin-bottom: 20px !important;
  }
  .chordium-mb-sm-auto {
    margin-bottom: auto !important;
  }
  .chordium-ms-sm-1 {
    margin-left: 2px !important;
  }
  .chordium-ms-sm-2 {
    margin-left: 4px !important;
  }
  .chordium-ms-sm-3 {
    margin-left: 6px !important;
  }
  .chordium-ms-sm-4 {
    margin-left: 8px !important;
  }
  .chordium-ms-sm-5 {
    margin-left: 10px !important;
  }
  .chordium-ms-sm-6 {
    margin-left: 12px !important;
  }
  .chordium-ms-sm-7 {
    margin-left: 14px !important;
  }
  .chordium-ms-sm-8 {
    margin-left: 16px !important;
  }
  .chordium-ms-sm-9 {
    margin-left: 18px !important;
  }
  .chordium-ms-sm-10 {
    margin-left: 20px !important;
  }
  .chordium-ms-sm-auto {
    margin-left: auto !important;
  }
  .chordium-p-sm-1 {
    padding: 2px !important;
  }
  .chordium-p-sm-2 {
    padding: 4px !important;
  }
  .chordium-p-sm-3 {
    padding: 6px !important;
  }
  .chordium-p-sm-4 {
    padding: 8px !important;
  }
  .chordium-p-sm-5 {
    padding: 10px !important;
  }
  .chordium-p-sm-6 {
    padding: 12px !important;
  }
  .chordium-p-sm-7 {
    padding: 14px !important;
  }
  .chordium-p-sm-8 {
    padding: 16px !important;
  }
  .chordium-p-sm-9 {
    padding: 18px !important;
  }
  .chordium-p-sm-10 {
    padding: 20px !important;
  }
  .chordium-px-sm-1 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .chordium-px-sm-2 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .chordium-px-sm-3 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .chordium-px-sm-4 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .chordium-px-sm-5 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .chordium-px-sm-6 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .chordium-px-sm-7 {
    padding-right: 14px !important;
    padding-left: 14px !important;
  }
  .chordium-px-sm-8 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .chordium-px-sm-9 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .chordium-px-sm-10 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .chordium-py-sm-1 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .chordium-py-sm-2 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .chordium-py-sm-3 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .chordium-py-sm-4 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .chordium-py-sm-5 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .chordium-py-sm-6 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .chordium-py-sm-7 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .chordium-py-sm-8 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .chordium-py-sm-9 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .chordium-py-sm-10 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .chordium-pt-sm-1 {
    padding-top: 2px !important;
  }
  .chordium-pt-sm-2 {
    padding-top: 4px !important;
  }
  .chordium-pt-sm-3 {
    padding-top: 6px !important;
  }
  .chordium-pt-sm-4 {
    padding-top: 8px !important;
  }
  .chordium-pt-sm-5 {
    padding-top: 10px !important;
  }
  .chordium-pt-sm-6 {
    padding-top: 12px !important;
  }
  .chordium-pt-sm-7 {
    padding-top: 14px !important;
  }
  .chordium-pt-sm-8 {
    padding-top: 16px !important;
  }
  .chordium-pt-sm-9 {
    padding-top: 18px !important;
  }
  .chordium-pt-sm-10 {
    padding-top: 20px !important;
  }
  .chordium-pe-sm-1 {
    padding-right: 2px !important;
  }
  .chordium-pe-sm-2 {
    padding-right: 4px !important;
  }
  .chordium-pe-sm-3 {
    padding-right: 6px !important;
  }
  .chordium-pe-sm-4 {
    padding-right: 8px !important;
  }
  .chordium-pe-sm-5 {
    padding-right: 10px !important;
  }
  .chordium-pe-sm-6 {
    padding-right: 12px !important;
  }
  .chordium-pe-sm-7 {
    padding-right: 14px !important;
  }
  .chordium-pe-sm-8 {
    padding-right: 16px !important;
  }
  .chordium-pe-sm-9 {
    padding-right: 18px !important;
  }
  .chordium-pe-sm-10 {
    padding-right: 20px !important;
  }
  .chordium-pb-sm-1 {
    padding-bottom: 2px !important;
  }
  .chordium-pb-sm-2 {
    padding-bottom: 4px !important;
  }
  .chordium-pb-sm-3 {
    padding-bottom: 6px !important;
  }
  .chordium-pb-sm-4 {
    padding-bottom: 8px !important;
  }
  .chordium-pb-sm-5 {
    padding-bottom: 10px !important;
  }
  .chordium-pb-sm-6 {
    padding-bottom: 12px !important;
  }
  .chordium-pb-sm-7 {
    padding-bottom: 14px !important;
  }
  .chordium-pb-sm-8 {
    padding-bottom: 16px !important;
  }
  .chordium-pb-sm-9 {
    padding-bottom: 18px !important;
  }
  .chordium-pb-sm-10 {
    padding-bottom: 20px !important;
  }
  .chordium-ps-sm-1 {
    padding-left: 2px !important;
  }
  .chordium-ps-sm-2 {
    padding-left: 4px !important;
  }
  .chordium-ps-sm-3 {
    padding-left: 6px !important;
  }
  .chordium-ps-sm-4 {
    padding-left: 8px !important;
  }
  .chordium-ps-sm-5 {
    padding-left: 10px !important;
  }
  .chordium-ps-sm-6 {
    padding-left: 12px !important;
  }
  .chordium-ps-sm-7 {
    padding-left: 14px !important;
  }
  .chordium-ps-sm-8 {
    padding-left: 16px !important;
  }
  .chordium-ps-sm-9 {
    padding-left: 18px !important;
  }
  .chordium-ps-sm-10 {
    padding-left: 20px !important;
  }
}
@media (min-width: 768px) {
  .chordium-md-inline {
    display: inline !important;
  }
  .chordium-md-inline-block {
    display: inline-block !important;
  }
  .chordium-md-block {
    display: block !important;
  }
  .chordium-md-grid {
    display: grid !important;
  }
  .chordium-md-inline-grid {
    display: inline-grid !important;
  }
  .chordium-md-table {
    display: table !important;
  }
  .chordium-md-table-row {
    display: table-row !important;
  }
  .chordium-md-table-cell {
    display: table-cell !important;
  }
  .chordium-md-flex {
    display: flex !important;
  }
  .chordium-md-inline-flex {
    display: inline-flex !important;
  }
  .chordium-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .chordium-flex-md-row {
    flex-direction: row !important;
  }
  .chordium-flex-md-column {
    flex-direction: column !important;
  }
  .chordium-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .chordium-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .chordium-flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .chordium-flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .chordium-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .chordium-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .chordium-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .chordium-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .chordium-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .chordium-m-md-1 {
    margin: 2px !important;
  }
  .chordium-m-md-2 {
    margin: 4px !important;
  }
  .chordium-m-md-3 {
    margin: 6px !important;
  }
  .chordium-m-md-4 {
    margin: 8px !important;
  }
  .chordium-m-md-5 {
    margin: 10px !important;
  }
  .chordium-m-md-6 {
    margin: 12px !important;
  }
  .chordium-m-md-7 {
    margin: 14px !important;
  }
  .chordium-m-md-8 {
    margin: 16px !important;
  }
  .chordium-m-md-9 {
    margin: 18px !important;
  }
  .chordium-m-md-10 {
    margin: 20px !important;
  }
  .chordium-m-md-auto {
    margin: auto !important;
  }
  .chordium-mx-md-1 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .chordium-mx-md-2 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .chordium-mx-md-3 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .chordium-mx-md-4 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .chordium-mx-md-5 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .chordium-mx-md-6 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .chordium-mx-md-7 {
    margin-right: 14px !important;
    margin-left: 14px !important;
  }
  .chordium-mx-md-8 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .chordium-mx-md-9 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .chordium-mx-md-10 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .chordium-mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .chordium-my-md-1 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .chordium-my-md-2 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .chordium-my-md-3 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .chordium-my-md-4 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .chordium-my-md-5 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .chordium-my-md-6 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .chordium-my-md-7 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .chordium-my-md-8 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .chordium-my-md-9 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .chordium-my-md-10 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .chordium-my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .chordium-mt-md-1 {
    margin-top: 2px !important;
  }
  .chordium-mt-md-2 {
    margin-top: 4px !important;
  }
  .chordium-mt-md-3 {
    margin-top: 6px !important;
  }
  .chordium-mt-md-4 {
    margin-top: 8px !important;
  }
  .chordium-mt-md-5 {
    margin-top: 10px !important;
  }
  .chordium-mt-md-6 {
    margin-top: 12px !important;
  }
  .chordium-mt-md-7 {
    margin-top: 14px !important;
  }
  .chordium-mt-md-8 {
    margin-top: 16px !important;
  }
  .chordium-mt-md-9 {
    margin-top: 18px !important;
  }
  .chordium-mt-md-10 {
    margin-top: 20px !important;
  }
  .chordium-mt-md-auto {
    margin-top: auto !important;
  }
  .chordium-me-md-1 {
    margin-right: 2px !important;
  }
  .chordium-me-md-2 {
    margin-right: 4px !important;
  }
  .chordium-me-md-3 {
    margin-right: 6px !important;
  }
  .chordium-me-md-4 {
    margin-right: 8px !important;
  }
  .chordium-me-md-5 {
    margin-right: 10px !important;
  }
  .chordium-me-md-6 {
    margin-right: 12px !important;
  }
  .chordium-me-md-7 {
    margin-right: 14px !important;
  }
  .chordium-me-md-8 {
    margin-right: 16px !important;
  }
  .chordium-me-md-9 {
    margin-right: 18px !important;
  }
  .chordium-me-md-10 {
    margin-right: 20px !important;
  }
  .chordium-me-md-auto {
    margin-right: auto !important;
  }
  .chordium-mb-md-1 {
    margin-bottom: 2px !important;
  }
  .chordium-mb-md-2 {
    margin-bottom: 4px !important;
  }
  .chordium-mb-md-3 {
    margin-bottom: 6px !important;
  }
  .chordium-mb-md-4 {
    margin-bottom: 8px !important;
  }
  .chordium-mb-md-5 {
    margin-bottom: 10px !important;
  }
  .chordium-mb-md-6 {
    margin-bottom: 12px !important;
  }
  .chordium-mb-md-7 {
    margin-bottom: 14px !important;
  }
  .chordium-mb-md-8 {
    margin-bottom: 16px !important;
  }
  .chordium-mb-md-9 {
    margin-bottom: 18px !important;
  }
  .chordium-mb-md-10 {
    margin-bottom: 20px !important;
  }
  .chordium-mb-md-auto {
    margin-bottom: auto !important;
  }
  .chordium-ms-md-1 {
    margin-left: 2px !important;
  }
  .chordium-ms-md-2 {
    margin-left: 4px !important;
  }
  .chordium-ms-md-3 {
    margin-left: 6px !important;
  }
  .chordium-ms-md-4 {
    margin-left: 8px !important;
  }
  .chordium-ms-md-5 {
    margin-left: 10px !important;
  }
  .chordium-ms-md-6 {
    margin-left: 12px !important;
  }
  .chordium-ms-md-7 {
    margin-left: 14px !important;
  }
  .chordium-ms-md-8 {
    margin-left: 16px !important;
  }
  .chordium-ms-md-9 {
    margin-left: 18px !important;
  }
  .chordium-ms-md-10 {
    margin-left: 20px !important;
  }
  .chordium-ms-md-auto {
    margin-left: auto !important;
  }
  .chordium-p-md-1 {
    padding: 2px !important;
  }
  .chordium-p-md-2 {
    padding: 4px !important;
  }
  .chordium-p-md-3 {
    padding: 6px !important;
  }
  .chordium-p-md-4 {
    padding: 8px !important;
  }
  .chordium-p-md-5 {
    padding: 10px !important;
  }
  .chordium-p-md-6 {
    padding: 12px !important;
  }
  .chordium-p-md-7 {
    padding: 14px !important;
  }
  .chordium-p-md-8 {
    padding: 16px !important;
  }
  .chordium-p-md-9 {
    padding: 18px !important;
  }
  .chordium-p-md-10 {
    padding: 20px !important;
  }
  .chordium-px-md-1 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .chordium-px-md-2 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .chordium-px-md-3 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .chordium-px-md-4 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .chordium-px-md-5 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .chordium-px-md-6 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .chordium-px-md-7 {
    padding-right: 14px !important;
    padding-left: 14px !important;
  }
  .chordium-px-md-8 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .chordium-px-md-9 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .chordium-px-md-10 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .chordium-py-md-1 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .chordium-py-md-2 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .chordium-py-md-3 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .chordium-py-md-4 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .chordium-py-md-5 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .chordium-py-md-6 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .chordium-py-md-7 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .chordium-py-md-8 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .chordium-py-md-9 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .chordium-py-md-10 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .chordium-pt-md-1 {
    padding-top: 2px !important;
  }
  .chordium-pt-md-2 {
    padding-top: 4px !important;
  }
  .chordium-pt-md-3 {
    padding-top: 6px !important;
  }
  .chordium-pt-md-4 {
    padding-top: 8px !important;
  }
  .chordium-pt-md-5 {
    padding-top: 10px !important;
  }
  .chordium-pt-md-6 {
    padding-top: 12px !important;
  }
  .chordium-pt-md-7 {
    padding-top: 14px !important;
  }
  .chordium-pt-md-8 {
    padding-top: 16px !important;
  }
  .chordium-pt-md-9 {
    padding-top: 18px !important;
  }
  .chordium-pt-md-10 {
    padding-top: 20px !important;
  }
  .chordium-pe-md-1 {
    padding-right: 2px !important;
  }
  .chordium-pe-md-2 {
    padding-right: 4px !important;
  }
  .chordium-pe-md-3 {
    padding-right: 6px !important;
  }
  .chordium-pe-md-4 {
    padding-right: 8px !important;
  }
  .chordium-pe-md-5 {
    padding-right: 10px !important;
  }
  .chordium-pe-md-6 {
    padding-right: 12px !important;
  }
  .chordium-pe-md-7 {
    padding-right: 14px !important;
  }
  .chordium-pe-md-8 {
    padding-right: 16px !important;
  }
  .chordium-pe-md-9 {
    padding-right: 18px !important;
  }
  .chordium-pe-md-10 {
    padding-right: 20px !important;
  }
  .chordium-pb-md-1 {
    padding-bottom: 2px !important;
  }
  .chordium-pb-md-2 {
    padding-bottom: 4px !important;
  }
  .chordium-pb-md-3 {
    padding-bottom: 6px !important;
  }
  .chordium-pb-md-4 {
    padding-bottom: 8px !important;
  }
  .chordium-pb-md-5 {
    padding-bottom: 10px !important;
  }
  .chordium-pb-md-6 {
    padding-bottom: 12px !important;
  }
  .chordium-pb-md-7 {
    padding-bottom: 14px !important;
  }
  .chordium-pb-md-8 {
    padding-bottom: 16px !important;
  }
  .chordium-pb-md-9 {
    padding-bottom: 18px !important;
  }
  .chordium-pb-md-10 {
    padding-bottom: 20px !important;
  }
  .chordium-ps-md-1 {
    padding-left: 2px !important;
  }
  .chordium-ps-md-2 {
    padding-left: 4px !important;
  }
  .chordium-ps-md-3 {
    padding-left: 6px !important;
  }
  .chordium-ps-md-4 {
    padding-left: 8px !important;
  }
  .chordium-ps-md-5 {
    padding-left: 10px !important;
  }
  .chordium-ps-md-6 {
    padding-left: 12px !important;
  }
  .chordium-ps-md-7 {
    padding-left: 14px !important;
  }
  .chordium-ps-md-8 {
    padding-left: 16px !important;
  }
  .chordium-ps-md-9 {
    padding-left: 18px !important;
  }
  .chordium-ps-md-10 {
    padding-left: 20px !important;
  }
}
@media (min-width: 1280px) {
  .chordium-lg-inline {
    display: inline !important;
  }
  .chordium-lg-inline-block {
    display: inline-block !important;
  }
  .chordium-lg-block {
    display: block !important;
  }
  .chordium-lg-grid {
    display: grid !important;
  }
  .chordium-lg-inline-grid {
    display: inline-grid !important;
  }
  .chordium-lg-table {
    display: table !important;
  }
  .chordium-lg-table-row {
    display: table-row !important;
  }
  .chordium-lg-table-cell {
    display: table-cell !important;
  }
  .chordium-lg-flex {
    display: flex !important;
  }
  .chordium-lg-inline-flex {
    display: inline-flex !important;
  }
  .chordium-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .chordium-flex-lg-row {
    flex-direction: row !important;
  }
  .chordium-flex-lg-column {
    flex-direction: column !important;
  }
  .chordium-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .chordium-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .chordium-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .chordium-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .chordium-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .chordium-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .chordium-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .chordium-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .chordium-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .chordium-m-lg-1 {
    margin: 2px !important;
  }
  .chordium-m-lg-2 {
    margin: 4px !important;
  }
  .chordium-m-lg-3 {
    margin: 6px !important;
  }
  .chordium-m-lg-4 {
    margin: 8px !important;
  }
  .chordium-m-lg-5 {
    margin: 10px !important;
  }
  .chordium-m-lg-6 {
    margin: 12px !important;
  }
  .chordium-m-lg-7 {
    margin: 14px !important;
  }
  .chordium-m-lg-8 {
    margin: 16px !important;
  }
  .chordium-m-lg-9 {
    margin: 18px !important;
  }
  .chordium-m-lg-10 {
    margin: 20px !important;
  }
  .chordium-m-lg-auto {
    margin: auto !important;
  }
  .chordium-mx-lg-1 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .chordium-mx-lg-2 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .chordium-mx-lg-3 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .chordium-mx-lg-4 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .chordium-mx-lg-5 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .chordium-mx-lg-6 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .chordium-mx-lg-7 {
    margin-right: 14px !important;
    margin-left: 14px !important;
  }
  .chordium-mx-lg-8 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .chordium-mx-lg-9 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .chordium-mx-lg-10 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .chordium-mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .chordium-my-lg-1 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .chordium-my-lg-2 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .chordium-my-lg-3 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .chordium-my-lg-4 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .chordium-my-lg-5 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .chordium-my-lg-6 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .chordium-my-lg-7 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .chordium-my-lg-8 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .chordium-my-lg-9 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .chordium-my-lg-10 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .chordium-my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .chordium-mt-lg-1 {
    margin-top: 2px !important;
  }
  .chordium-mt-lg-2 {
    margin-top: 4px !important;
  }
  .chordium-mt-lg-3 {
    margin-top: 6px !important;
  }
  .chordium-mt-lg-4 {
    margin-top: 8px !important;
  }
  .chordium-mt-lg-5 {
    margin-top: 10px !important;
  }
  .chordium-mt-lg-6 {
    margin-top: 12px !important;
  }
  .chordium-mt-lg-7 {
    margin-top: 14px !important;
  }
  .chordium-mt-lg-8 {
    margin-top: 16px !important;
  }
  .chordium-mt-lg-9 {
    margin-top: 18px !important;
  }
  .chordium-mt-lg-10 {
    margin-top: 20px !important;
  }
  .chordium-mt-lg-auto {
    margin-top: auto !important;
  }
  .chordium-me-lg-1 {
    margin-right: 2px !important;
  }
  .chordium-me-lg-2 {
    margin-right: 4px !important;
  }
  .chordium-me-lg-3 {
    margin-right: 6px !important;
  }
  .chordium-me-lg-4 {
    margin-right: 8px !important;
  }
  .chordium-me-lg-5 {
    margin-right: 10px !important;
  }
  .chordium-me-lg-6 {
    margin-right: 12px !important;
  }
  .chordium-me-lg-7 {
    margin-right: 14px !important;
  }
  .chordium-me-lg-8 {
    margin-right: 16px !important;
  }
  .chordium-me-lg-9 {
    margin-right: 18px !important;
  }
  .chordium-me-lg-10 {
    margin-right: 20px !important;
  }
  .chordium-me-lg-auto {
    margin-right: auto !important;
  }
  .chordium-mb-lg-1 {
    margin-bottom: 2px !important;
  }
  .chordium-mb-lg-2 {
    margin-bottom: 4px !important;
  }
  .chordium-mb-lg-3 {
    margin-bottom: 6px !important;
  }
  .chordium-mb-lg-4 {
    margin-bottom: 8px !important;
  }
  .chordium-mb-lg-5 {
    margin-bottom: 10px !important;
  }
  .chordium-mb-lg-6 {
    margin-bottom: 12px !important;
  }
  .chordium-mb-lg-7 {
    margin-bottom: 14px !important;
  }
  .chordium-mb-lg-8 {
    margin-bottom: 16px !important;
  }
  .chordium-mb-lg-9 {
    margin-bottom: 18px !important;
  }
  .chordium-mb-lg-10 {
    margin-bottom: 20px !important;
  }
  .chordium-mb-lg-auto {
    margin-bottom: auto !important;
  }
  .chordium-ms-lg-1 {
    margin-left: 2px !important;
  }
  .chordium-ms-lg-2 {
    margin-left: 4px !important;
  }
  .chordium-ms-lg-3 {
    margin-left: 6px !important;
  }
  .chordium-ms-lg-4 {
    margin-left: 8px !important;
  }
  .chordium-ms-lg-5 {
    margin-left: 10px !important;
  }
  .chordium-ms-lg-6 {
    margin-left: 12px !important;
  }
  .chordium-ms-lg-7 {
    margin-left: 14px !important;
  }
  .chordium-ms-lg-8 {
    margin-left: 16px !important;
  }
  .chordium-ms-lg-9 {
    margin-left: 18px !important;
  }
  .chordium-ms-lg-10 {
    margin-left: 20px !important;
  }
  .chordium-ms-lg-auto {
    margin-left: auto !important;
  }
  .chordium-p-lg-1 {
    padding: 2px !important;
  }
  .chordium-p-lg-2 {
    padding: 4px !important;
  }
  .chordium-p-lg-3 {
    padding: 6px !important;
  }
  .chordium-p-lg-4 {
    padding: 8px !important;
  }
  .chordium-p-lg-5 {
    padding: 10px !important;
  }
  .chordium-p-lg-6 {
    padding: 12px !important;
  }
  .chordium-p-lg-7 {
    padding: 14px !important;
  }
  .chordium-p-lg-8 {
    padding: 16px !important;
  }
  .chordium-p-lg-9 {
    padding: 18px !important;
  }
  .chordium-p-lg-10 {
    padding: 20px !important;
  }
  .chordium-px-lg-1 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .chordium-px-lg-2 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .chordium-px-lg-3 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .chordium-px-lg-4 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .chordium-px-lg-5 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .chordium-px-lg-6 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .chordium-px-lg-7 {
    padding-right: 14px !important;
    padding-left: 14px !important;
  }
  .chordium-px-lg-8 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .chordium-px-lg-9 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .chordium-px-lg-10 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .chordium-py-lg-1 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .chordium-py-lg-2 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .chordium-py-lg-3 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .chordium-py-lg-4 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .chordium-py-lg-5 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .chordium-py-lg-6 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .chordium-py-lg-7 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .chordium-py-lg-8 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .chordium-py-lg-9 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .chordium-py-lg-10 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .chordium-pt-lg-1 {
    padding-top: 2px !important;
  }
  .chordium-pt-lg-2 {
    padding-top: 4px !important;
  }
  .chordium-pt-lg-3 {
    padding-top: 6px !important;
  }
  .chordium-pt-lg-4 {
    padding-top: 8px !important;
  }
  .chordium-pt-lg-5 {
    padding-top: 10px !important;
  }
  .chordium-pt-lg-6 {
    padding-top: 12px !important;
  }
  .chordium-pt-lg-7 {
    padding-top: 14px !important;
  }
  .chordium-pt-lg-8 {
    padding-top: 16px !important;
  }
  .chordium-pt-lg-9 {
    padding-top: 18px !important;
  }
  .chordium-pt-lg-10 {
    padding-top: 20px !important;
  }
  .chordium-pe-lg-1 {
    padding-right: 2px !important;
  }
  .chordium-pe-lg-2 {
    padding-right: 4px !important;
  }
  .chordium-pe-lg-3 {
    padding-right: 6px !important;
  }
  .chordium-pe-lg-4 {
    padding-right: 8px !important;
  }
  .chordium-pe-lg-5 {
    padding-right: 10px !important;
  }
  .chordium-pe-lg-6 {
    padding-right: 12px !important;
  }
  .chordium-pe-lg-7 {
    padding-right: 14px !important;
  }
  .chordium-pe-lg-8 {
    padding-right: 16px !important;
  }
  .chordium-pe-lg-9 {
    padding-right: 18px !important;
  }
  .chordium-pe-lg-10 {
    padding-right: 20px !important;
  }
  .chordium-pb-lg-1 {
    padding-bottom: 2px !important;
  }
  .chordium-pb-lg-2 {
    padding-bottom: 4px !important;
  }
  .chordium-pb-lg-3 {
    padding-bottom: 6px !important;
  }
  .chordium-pb-lg-4 {
    padding-bottom: 8px !important;
  }
  .chordium-pb-lg-5 {
    padding-bottom: 10px !important;
  }
  .chordium-pb-lg-6 {
    padding-bottom: 12px !important;
  }
  .chordium-pb-lg-7 {
    padding-bottom: 14px !important;
  }
  .chordium-pb-lg-8 {
    padding-bottom: 16px !important;
  }
  .chordium-pb-lg-9 {
    padding-bottom: 18px !important;
  }
  .chordium-pb-lg-10 {
    padding-bottom: 20px !important;
  }
  .chordium-ps-lg-1 {
    padding-left: 2px !important;
  }
  .chordium-ps-lg-2 {
    padding-left: 4px !important;
  }
  .chordium-ps-lg-3 {
    padding-left: 6px !important;
  }
  .chordium-ps-lg-4 {
    padding-left: 8px !important;
  }
  .chordium-ps-lg-5 {
    padding-left: 10px !important;
  }
  .chordium-ps-lg-6 {
    padding-left: 12px !important;
  }
  .chordium-ps-lg-7 {
    padding-left: 14px !important;
  }
  .chordium-ps-lg-8 {
    padding-left: 16px !important;
  }
  .chordium-ps-lg-9 {
    padding-left: 18px !important;
  }
  .chordium-ps-lg-10 {
    padding-left: 20px !important;
  }
}
@media (min-width: 1366px) {
  .chordium-xl-inline {
    display: inline !important;
  }
  .chordium-xl-inline-block {
    display: inline-block !important;
  }
  .chordium-xl-block {
    display: block !important;
  }
  .chordium-xl-grid {
    display: grid !important;
  }
  .chordium-xl-inline-grid {
    display: inline-grid !important;
  }
  .chordium-xl-table {
    display: table !important;
  }
  .chordium-xl-table-row {
    display: table-row !important;
  }
  .chordium-xl-table-cell {
    display: table-cell !important;
  }
  .chordium-xl-flex {
    display: flex !important;
  }
  .chordium-xl-inline-flex {
    display: inline-flex !important;
  }
  .chordium-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .chordium-flex-xl-row {
    flex-direction: row !important;
  }
  .chordium-flex-xl-column {
    flex-direction: column !important;
  }
  .chordium-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .chordium-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .chordium-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .chordium-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .chordium-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .chordium-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .chordium-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .chordium-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .chordium-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .chordium-m-xl-1 {
    margin: 2px !important;
  }
  .chordium-m-xl-2 {
    margin: 4px !important;
  }
  .chordium-m-xl-3 {
    margin: 6px !important;
  }
  .chordium-m-xl-4 {
    margin: 8px !important;
  }
  .chordium-m-xl-5 {
    margin: 10px !important;
  }
  .chordium-m-xl-6 {
    margin: 12px !important;
  }
  .chordium-m-xl-7 {
    margin: 14px !important;
  }
  .chordium-m-xl-8 {
    margin: 16px !important;
  }
  .chordium-m-xl-9 {
    margin: 18px !important;
  }
  .chordium-m-xl-10 {
    margin: 20px !important;
  }
  .chordium-m-xl-auto {
    margin: auto !important;
  }
  .chordium-mx-xl-1 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .chordium-mx-xl-2 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .chordium-mx-xl-3 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .chordium-mx-xl-4 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .chordium-mx-xl-5 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .chordium-mx-xl-6 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .chordium-mx-xl-7 {
    margin-right: 14px !important;
    margin-left: 14px !important;
  }
  .chordium-mx-xl-8 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .chordium-mx-xl-9 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .chordium-mx-xl-10 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .chordium-mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .chordium-my-xl-1 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .chordium-my-xl-2 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .chordium-my-xl-3 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .chordium-my-xl-4 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .chordium-my-xl-5 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .chordium-my-xl-6 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .chordium-my-xl-7 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .chordium-my-xl-8 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .chordium-my-xl-9 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .chordium-my-xl-10 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .chordium-my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .chordium-mt-xl-1 {
    margin-top: 2px !important;
  }
  .chordium-mt-xl-2 {
    margin-top: 4px !important;
  }
  .chordium-mt-xl-3 {
    margin-top: 6px !important;
  }
  .chordium-mt-xl-4 {
    margin-top: 8px !important;
  }
  .chordium-mt-xl-5 {
    margin-top: 10px !important;
  }
  .chordium-mt-xl-6 {
    margin-top: 12px !important;
  }
  .chordium-mt-xl-7 {
    margin-top: 14px !important;
  }
  .chordium-mt-xl-8 {
    margin-top: 16px !important;
  }
  .chordium-mt-xl-9 {
    margin-top: 18px !important;
  }
  .chordium-mt-xl-10 {
    margin-top: 20px !important;
  }
  .chordium-mt-xl-auto {
    margin-top: auto !important;
  }
  .chordium-me-xl-1 {
    margin-right: 2px !important;
  }
  .chordium-me-xl-2 {
    margin-right: 4px !important;
  }
  .chordium-me-xl-3 {
    margin-right: 6px !important;
  }
  .chordium-me-xl-4 {
    margin-right: 8px !important;
  }
  .chordium-me-xl-5 {
    margin-right: 10px !important;
  }
  .chordium-me-xl-6 {
    margin-right: 12px !important;
  }
  .chordium-me-xl-7 {
    margin-right: 14px !important;
  }
  .chordium-me-xl-8 {
    margin-right: 16px !important;
  }
  .chordium-me-xl-9 {
    margin-right: 18px !important;
  }
  .chordium-me-xl-10 {
    margin-right: 20px !important;
  }
  .chordium-me-xl-auto {
    margin-right: auto !important;
  }
  .chordium-mb-xl-1 {
    margin-bottom: 2px !important;
  }
  .chordium-mb-xl-2 {
    margin-bottom: 4px !important;
  }
  .chordium-mb-xl-3 {
    margin-bottom: 6px !important;
  }
  .chordium-mb-xl-4 {
    margin-bottom: 8px !important;
  }
  .chordium-mb-xl-5 {
    margin-bottom: 10px !important;
  }
  .chordium-mb-xl-6 {
    margin-bottom: 12px !important;
  }
  .chordium-mb-xl-7 {
    margin-bottom: 14px !important;
  }
  .chordium-mb-xl-8 {
    margin-bottom: 16px !important;
  }
  .chordium-mb-xl-9 {
    margin-bottom: 18px !important;
  }
  .chordium-mb-xl-10 {
    margin-bottom: 20px !important;
  }
  .chordium-mb-xl-auto {
    margin-bottom: auto !important;
  }
  .chordium-ms-xl-1 {
    margin-left: 2px !important;
  }
  .chordium-ms-xl-2 {
    margin-left: 4px !important;
  }
  .chordium-ms-xl-3 {
    margin-left: 6px !important;
  }
  .chordium-ms-xl-4 {
    margin-left: 8px !important;
  }
  .chordium-ms-xl-5 {
    margin-left: 10px !important;
  }
  .chordium-ms-xl-6 {
    margin-left: 12px !important;
  }
  .chordium-ms-xl-7 {
    margin-left: 14px !important;
  }
  .chordium-ms-xl-8 {
    margin-left: 16px !important;
  }
  .chordium-ms-xl-9 {
    margin-left: 18px !important;
  }
  .chordium-ms-xl-10 {
    margin-left: 20px !important;
  }
  .chordium-ms-xl-auto {
    margin-left: auto !important;
  }
  .chordium-p-xl-1 {
    padding: 2px !important;
  }
  .chordium-p-xl-2 {
    padding: 4px !important;
  }
  .chordium-p-xl-3 {
    padding: 6px !important;
  }
  .chordium-p-xl-4 {
    padding: 8px !important;
  }
  .chordium-p-xl-5 {
    padding: 10px !important;
  }
  .chordium-p-xl-6 {
    padding: 12px !important;
  }
  .chordium-p-xl-7 {
    padding: 14px !important;
  }
  .chordium-p-xl-8 {
    padding: 16px !important;
  }
  .chordium-p-xl-9 {
    padding: 18px !important;
  }
  .chordium-p-xl-10 {
    padding: 20px !important;
  }
  .chordium-px-xl-1 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .chordium-px-xl-2 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .chordium-px-xl-3 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .chordium-px-xl-4 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .chordium-px-xl-5 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .chordium-px-xl-6 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .chordium-px-xl-7 {
    padding-right: 14px !important;
    padding-left: 14px !important;
  }
  .chordium-px-xl-8 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .chordium-px-xl-9 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .chordium-px-xl-10 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .chordium-py-xl-1 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .chordium-py-xl-2 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .chordium-py-xl-3 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .chordium-py-xl-4 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .chordium-py-xl-5 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .chordium-py-xl-6 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .chordium-py-xl-7 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .chordium-py-xl-8 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .chordium-py-xl-9 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .chordium-py-xl-10 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .chordium-pt-xl-1 {
    padding-top: 2px !important;
  }
  .chordium-pt-xl-2 {
    padding-top: 4px !important;
  }
  .chordium-pt-xl-3 {
    padding-top: 6px !important;
  }
  .chordium-pt-xl-4 {
    padding-top: 8px !important;
  }
  .chordium-pt-xl-5 {
    padding-top: 10px !important;
  }
  .chordium-pt-xl-6 {
    padding-top: 12px !important;
  }
  .chordium-pt-xl-7 {
    padding-top: 14px !important;
  }
  .chordium-pt-xl-8 {
    padding-top: 16px !important;
  }
  .chordium-pt-xl-9 {
    padding-top: 18px !important;
  }
  .chordium-pt-xl-10 {
    padding-top: 20px !important;
  }
  .chordium-pe-xl-1 {
    padding-right: 2px !important;
  }
  .chordium-pe-xl-2 {
    padding-right: 4px !important;
  }
  .chordium-pe-xl-3 {
    padding-right: 6px !important;
  }
  .chordium-pe-xl-4 {
    padding-right: 8px !important;
  }
  .chordium-pe-xl-5 {
    padding-right: 10px !important;
  }
  .chordium-pe-xl-6 {
    padding-right: 12px !important;
  }
  .chordium-pe-xl-7 {
    padding-right: 14px !important;
  }
  .chordium-pe-xl-8 {
    padding-right: 16px !important;
  }
  .chordium-pe-xl-9 {
    padding-right: 18px !important;
  }
  .chordium-pe-xl-10 {
    padding-right: 20px !important;
  }
  .chordium-pb-xl-1 {
    padding-bottom: 2px !important;
  }
  .chordium-pb-xl-2 {
    padding-bottom: 4px !important;
  }
  .chordium-pb-xl-3 {
    padding-bottom: 6px !important;
  }
  .chordium-pb-xl-4 {
    padding-bottom: 8px !important;
  }
  .chordium-pb-xl-5 {
    padding-bottom: 10px !important;
  }
  .chordium-pb-xl-6 {
    padding-bottom: 12px !important;
  }
  .chordium-pb-xl-7 {
    padding-bottom: 14px !important;
  }
  .chordium-pb-xl-8 {
    padding-bottom: 16px !important;
  }
  .chordium-pb-xl-9 {
    padding-bottom: 18px !important;
  }
  .chordium-pb-xl-10 {
    padding-bottom: 20px !important;
  }
  .chordium-ps-xl-1 {
    padding-left: 2px !important;
  }
  .chordium-ps-xl-2 {
    padding-left: 4px !important;
  }
  .chordium-ps-xl-3 {
    padding-left: 6px !important;
  }
  .chordium-ps-xl-4 {
    padding-left: 8px !important;
  }
  .chordium-ps-xl-5 {
    padding-left: 10px !important;
  }
  .chordium-ps-xl-6 {
    padding-left: 12px !important;
  }
  .chordium-ps-xl-7 {
    padding-left: 14px !important;
  }
  .chordium-ps-xl-8 {
    padding-left: 16px !important;
  }
  .chordium-ps-xl-9 {
    padding-left: 18px !important;
  }
  .chordium-ps-xl-10 {
    padding-left: 20px !important;
  }
}
@media (min-width: 1800px) {
  .chordium-xxl-inline {
    display: inline !important;
  }
  .chordium-xxl-inline-block {
    display: inline-block !important;
  }
  .chordium-xxl-block {
    display: block !important;
  }
  .chordium-xxl-grid {
    display: grid !important;
  }
  .chordium-xxl-inline-grid {
    display: inline-grid !important;
  }
  .chordium-xxl-table {
    display: table !important;
  }
  .chordium-xxl-table-row {
    display: table-row !important;
  }
  .chordium-xxl-table-cell {
    display: table-cell !important;
  }
  .chordium-xxl-flex {
    display: flex !important;
  }
  .chordium-xxl-inline-flex {
    display: inline-flex !important;
  }
  .chordium-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .chordium-flex-xxl-row {
    flex-direction: row !important;
  }
  .chordium-flex-xxl-column {
    flex-direction: column !important;
  }
  .chordium-flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .chordium-flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .chordium-flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .chordium-flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .chordium-flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .chordium-flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .chordium-flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .chordium-flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .chordium-flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .chordium-m-xxl-1 {
    margin: 2px !important;
  }
  .chordium-m-xxl-2 {
    margin: 4px !important;
  }
  .chordium-m-xxl-3 {
    margin: 6px !important;
  }
  .chordium-m-xxl-4 {
    margin: 8px !important;
  }
  .chordium-m-xxl-5 {
    margin: 10px !important;
  }
  .chordium-m-xxl-6 {
    margin: 12px !important;
  }
  .chordium-m-xxl-7 {
    margin: 14px !important;
  }
  .chordium-m-xxl-8 {
    margin: 16px !important;
  }
  .chordium-m-xxl-9 {
    margin: 18px !important;
  }
  .chordium-m-xxl-10 {
    margin: 20px !important;
  }
  .chordium-m-xxl-auto {
    margin: auto !important;
  }
  .chordium-mx-xxl-1 {
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .chordium-mx-xxl-2 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .chordium-mx-xxl-3 {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .chordium-mx-xxl-4 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .chordium-mx-xxl-5 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .chordium-mx-xxl-6 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }
  .chordium-mx-xxl-7 {
    margin-right: 14px !important;
    margin-left: 14px !important;
  }
  .chordium-mx-xxl-8 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
  .chordium-mx-xxl-9 {
    margin-right: 18px !important;
    margin-left: 18px !important;
  }
  .chordium-mx-xxl-10 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .chordium-mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .chordium-my-xxl-1 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .chordium-my-xxl-2 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .chordium-my-xxl-3 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .chordium-my-xxl-4 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .chordium-my-xxl-5 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .chordium-my-xxl-6 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .chordium-my-xxl-7 {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
  .chordium-my-xxl-8 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .chordium-my-xxl-9 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .chordium-my-xxl-10 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .chordium-my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .chordium-mt-xxl-1 {
    margin-top: 2px !important;
  }
  .chordium-mt-xxl-2 {
    margin-top: 4px !important;
  }
  .chordium-mt-xxl-3 {
    margin-top: 6px !important;
  }
  .chordium-mt-xxl-4 {
    margin-top: 8px !important;
  }
  .chordium-mt-xxl-5 {
    margin-top: 10px !important;
  }
  .chordium-mt-xxl-6 {
    margin-top: 12px !important;
  }
  .chordium-mt-xxl-7 {
    margin-top: 14px !important;
  }
  .chordium-mt-xxl-8 {
    margin-top: 16px !important;
  }
  .chordium-mt-xxl-9 {
    margin-top: 18px !important;
  }
  .chordium-mt-xxl-10 {
    margin-top: 20px !important;
  }
  .chordium-mt-xxl-auto {
    margin-top: auto !important;
  }
  .chordium-me-xxl-1 {
    margin-right: 2px !important;
  }
  .chordium-me-xxl-2 {
    margin-right: 4px !important;
  }
  .chordium-me-xxl-3 {
    margin-right: 6px !important;
  }
  .chordium-me-xxl-4 {
    margin-right: 8px !important;
  }
  .chordium-me-xxl-5 {
    margin-right: 10px !important;
  }
  .chordium-me-xxl-6 {
    margin-right: 12px !important;
  }
  .chordium-me-xxl-7 {
    margin-right: 14px !important;
  }
  .chordium-me-xxl-8 {
    margin-right: 16px !important;
  }
  .chordium-me-xxl-9 {
    margin-right: 18px !important;
  }
  .chordium-me-xxl-10 {
    margin-right: 20px !important;
  }
  .chordium-me-xxl-auto {
    margin-right: auto !important;
  }
  .chordium-mb-xxl-1 {
    margin-bottom: 2px !important;
  }
  .chordium-mb-xxl-2 {
    margin-bottom: 4px !important;
  }
  .chordium-mb-xxl-3 {
    margin-bottom: 6px !important;
  }
  .chordium-mb-xxl-4 {
    margin-bottom: 8px !important;
  }
  .chordium-mb-xxl-5 {
    margin-bottom: 10px !important;
  }
  .chordium-mb-xxl-6 {
    margin-bottom: 12px !important;
  }
  .chordium-mb-xxl-7 {
    margin-bottom: 14px !important;
  }
  .chordium-mb-xxl-8 {
    margin-bottom: 16px !important;
  }
  .chordium-mb-xxl-9 {
    margin-bottom: 18px !important;
  }
  .chordium-mb-xxl-10 {
    margin-bottom: 20px !important;
  }
  .chordium-mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .chordium-ms-xxl-1 {
    margin-left: 2px !important;
  }
  .chordium-ms-xxl-2 {
    margin-left: 4px !important;
  }
  .chordium-ms-xxl-3 {
    margin-left: 6px !important;
  }
  .chordium-ms-xxl-4 {
    margin-left: 8px !important;
  }
  .chordium-ms-xxl-5 {
    margin-left: 10px !important;
  }
  .chordium-ms-xxl-6 {
    margin-left: 12px !important;
  }
  .chordium-ms-xxl-7 {
    margin-left: 14px !important;
  }
  .chordium-ms-xxl-8 {
    margin-left: 16px !important;
  }
  .chordium-ms-xxl-9 {
    margin-left: 18px !important;
  }
  .chordium-ms-xxl-10 {
    margin-left: 20px !important;
  }
  .chordium-ms-xxl-auto {
    margin-left: auto !important;
  }
  .chordium-p-xxl-1 {
    padding: 2px !important;
  }
  .chordium-p-xxl-2 {
    padding: 4px !important;
  }
  .chordium-p-xxl-3 {
    padding: 6px !important;
  }
  .chordium-p-xxl-4 {
    padding: 8px !important;
  }
  .chordium-p-xxl-5 {
    padding: 10px !important;
  }
  .chordium-p-xxl-6 {
    padding: 12px !important;
  }
  .chordium-p-xxl-7 {
    padding: 14px !important;
  }
  .chordium-p-xxl-8 {
    padding: 16px !important;
  }
  .chordium-p-xxl-9 {
    padding: 18px !important;
  }
  .chordium-p-xxl-10 {
    padding: 20px !important;
  }
  .chordium-px-xxl-1 {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .chordium-px-xxl-2 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .chordium-px-xxl-3 {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .chordium-px-xxl-4 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .chordium-px-xxl-5 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .chordium-px-xxl-6 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .chordium-px-xxl-7 {
    padding-right: 14px !important;
    padding-left: 14px !important;
  }
  .chordium-px-xxl-8 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .chordium-px-xxl-9 {
    padding-right: 18px !important;
    padding-left: 18px !important;
  }
  .chordium-px-xxl-10 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .chordium-py-xxl-1 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .chordium-py-xxl-2 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .chordium-py-xxl-3 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .chordium-py-xxl-4 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .chordium-py-xxl-5 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .chordium-py-xxl-6 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .chordium-py-xxl-7 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }
  .chordium-py-xxl-8 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .chordium-py-xxl-9 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .chordium-py-xxl-10 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .chordium-pt-xxl-1 {
    padding-top: 2px !important;
  }
  .chordium-pt-xxl-2 {
    padding-top: 4px !important;
  }
  .chordium-pt-xxl-3 {
    padding-top: 6px !important;
  }
  .chordium-pt-xxl-4 {
    padding-top: 8px !important;
  }
  .chordium-pt-xxl-5 {
    padding-top: 10px !important;
  }
  .chordium-pt-xxl-6 {
    padding-top: 12px !important;
  }
  .chordium-pt-xxl-7 {
    padding-top: 14px !important;
  }
  .chordium-pt-xxl-8 {
    padding-top: 16px !important;
  }
  .chordium-pt-xxl-9 {
    padding-top: 18px !important;
  }
  .chordium-pt-xxl-10 {
    padding-top: 20px !important;
  }
  .chordium-pe-xxl-1 {
    padding-right: 2px !important;
  }
  .chordium-pe-xxl-2 {
    padding-right: 4px !important;
  }
  .chordium-pe-xxl-3 {
    padding-right: 6px !important;
  }
  .chordium-pe-xxl-4 {
    padding-right: 8px !important;
  }
  .chordium-pe-xxl-5 {
    padding-right: 10px !important;
  }
  .chordium-pe-xxl-6 {
    padding-right: 12px !important;
  }
  .chordium-pe-xxl-7 {
    padding-right: 14px !important;
  }
  .chordium-pe-xxl-8 {
    padding-right: 16px !important;
  }
  .chordium-pe-xxl-9 {
    padding-right: 18px !important;
  }
  .chordium-pe-xxl-10 {
    padding-right: 20px !important;
  }
  .chordium-pb-xxl-1 {
    padding-bottom: 2px !important;
  }
  .chordium-pb-xxl-2 {
    padding-bottom: 4px !important;
  }
  .chordium-pb-xxl-3 {
    padding-bottom: 6px !important;
  }
  .chordium-pb-xxl-4 {
    padding-bottom: 8px !important;
  }
  .chordium-pb-xxl-5 {
    padding-bottom: 10px !important;
  }
  .chordium-pb-xxl-6 {
    padding-bottom: 12px !important;
  }
  .chordium-pb-xxl-7 {
    padding-bottom: 14px !important;
  }
  .chordium-pb-xxl-8 {
    padding-bottom: 16px !important;
  }
  .chordium-pb-xxl-9 {
    padding-bottom: 18px !important;
  }
  .chordium-pb-xxl-10 {
    padding-bottom: 20px !important;
  }
  .chordium-ps-xxl-1 {
    padding-left: 2px !important;
  }
  .chordium-ps-xxl-2 {
    padding-left: 4px !important;
  }
  .chordium-ps-xxl-3 {
    padding-left: 6px !important;
  }
  .chordium-ps-xxl-4 {
    padding-left: 8px !important;
  }
  .chordium-ps-xxl-5 {
    padding-left: 10px !important;
  }
  .chordium-ps-xxl-6 {
    padding-left: 12px !important;
  }
  .chordium-ps-xxl-7 {
    padding-left: 14px !important;
  }
  .chordium-ps-xxl-8 {
    padding-left: 16px !important;
  }
  .chordium-ps-xxl-9 {
    padding-left: 18px !important;
  }
  .chordium-ps-xxl-10 {
    padding-left: 20px !important;
  }
}
@media print {
  .chordium-print-inline {
    display: inline !important;
  }
  .chordium-print-inline-block {
    display: inline-block !important;
  }
  .chordium-print-block {
    display: block !important;
  }
  .chordium-print-grid {
    display: grid !important;
  }
  .chordium-print-inline-grid {
    display: inline-grid !important;
  }
  .chordium-print-table {
    display: table !important;
  }
  .chordium-print-table-row {
    display: table-row !important;
  }
  .chordium-print-table-cell {
    display: table-cell !important;
  }
  .chordium-print-flex {
    display: flex !important;
  }
  .chordium-print-inline-flex {
    display: inline-flex !important;
  }
  .chordium-print-none {
    display: none !important;
  }
}
/* Regular Font */
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Regular-v1.0/WEB/PPMori-Regular.woff2") format("woff2"), url("./PP-Mori-Regular-v1.0/WEB/PPMori-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* semibold Font */
/* Medium Font */
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Medium/PPMori-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
/* Bold Font */
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Bold-v1.0//WEB/PPMori-Bold.woff2") format("woff2"), url("./PP-Mori-Bold-v1.0//WEB/PPMori-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
/* semibold Font */
@font-face {
  font-family: "PP Mori"; /* Nome da fonte */
  src: url("./PP-Mori-SemiBold/PPMori-SemiBold.otf") format("opentype"); /* URL do arquivo de fonte OTF */
  font-weight: 600; /* Peso da fonte (normal, bold, etc.) */
  font-style: normal; /* Estilo da fonte (normal, italic, etc.) */
}
/* Regular Font */
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Regular-v1.0/WEB/PPMori-Regular.woff2") format("woff2"), url("./PP-Mori-Regular-v1.0/WEB/PPMori-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* semibold Font */
/* Medium Font */
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Medium/PPMori-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
/* Bold Font */
@font-face {
  font-family: "PP Mori";
  src: url("./PP-Mori-Bold-v1.0//WEB/PPMori-Bold.woff2") format("woff2"), url("./PP-Mori-Bold-v1.0//WEB/PPMori-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
/* semibold Font */
@font-face {
  font-family: "PP Mori"; /* Nome da fonte */
  src: url("./PP-Mori-SemiBold/PPMori-SemiBold.otf") format("opentype"); /* URL do arquivo de fonte OTF */
  font-weight: 600; /* Peso da fonte (normal, bold, etc.) */
  font-style: normal; /* Estilo da fonte (normal, italic, etc.) */
}
/*!
 *  chordium css variables v1 (https://mzic.io/)
 * Copyright2023 The mzic Authors (Waldir Feitoza de Azevedo Neto)
 * Licensed under MIT (add license)
 */
/*****************************************/
/* VARIABLE COLORS */
/***** Color helps express hierarchy, creating a brand"s look, adding meaning, and showing element status. **/
/**********************************/
/***** FEEDBACK COLORS **********/
/*************COLORS MAPS ****************/
/********* theme-color-variables ****************************/
:root {
  --font-family: "PP Mori", Arial, sans-serif;
  --text-color-default: #ededed;
  --text-heading-xxxl: 64px/72px var(--font-family);
  --text-heading-xxl: 48px/56px var(--font-family);
  --text-heading-xl: 40px/48px var(--font-family);
  --text-heading-lg: 32px/40px var(--font-family);
  --text-heading-md: 24px/32px var(--font-family);
  --text-heading-sm: 20px/28px var(--font-family);
  --text-body-lg: 18px/26px var(--font-family);
  --text-body-md: 16px/24px var(--font-family);
  --text-body-xxsm: 14px/20px var(--font-family);
  --text-body-xsm: 12px/16px var(--font-family);
  --text-body-sm: 10px/14px var(--font-family);
  --breakpoint-text-xl: 1280px;
  --breakpoint-text-xxl: 1366px;
  --breakpoint-text-xxxl: 1920px;
}

/** aplica classes para titulos **/
.text-heading-xxxl, .text-heading-xxxl--alert, .text-heading-xxxl--info, .text-heading-xxxl--danger, .text-heading-xxxl--success, .text-heading-xxxl--black, .text-heading-xxxl--disabled, .text-heading-xxxl--secondary, .text-heading-xxxl--primary, .text-heading-xxxl--default {
  font: var(--text-heading-xxxl);
  color: var(--text-color-default);
}
.text-heading-xxxl--default {
  color: var(--text-color-default);
}
.text-heading-xxxl--primary {
  color: #9655ff;
}
.text-heading-xxxl--secondary {
  color: #878787;
}
.text-heading-xxxl--disabled {
  color: #545454;
}
.text-heading-xxxl--black {
  color: #111010;
}
.text-heading-xxxl--success {
  color: #09d793;
}
.text-heading-xxxl--danger {
  color: #ca2029;
}
.text-heading-xxxl--info {
  color: #40a9f9;
}
.text-heading-xxxl--alert {
  color: #ffc53f;
}

.text-heading-xxl, .text-heading-xxl--alert, .text-heading-xxl--info, .text-heading-xxl--danger, .text-heading-xxl--success, .text-heading-xxl--black, .text-heading-xxl--disabled, .text-heading-xxl--secondary, .text-heading-xxl--primary, .text-heading-xxl--default {
  font: var(--text-heading-xxl);
  color: var(--text-color-default);
}
.text-heading-xxl--default {
  color: var(--text-color-default);
}
.text-heading-xxl--primary {
  color: #9655ff;
}
.text-heading-xxl--secondary {
  color: #878787;
}
.text-heading-xxl--disabled {
  color: #545454;
}
.text-heading-xxl--black {
  color: #111010;
}
.text-heading-xxl--success {
  color: #09d793;
}
.text-heading-xxl--danger {
  color: #ca2029;
}
.text-heading-xxl--info {
  color: #40a9f9;
}
.text-heading-xxl--alert {
  color: #ffc53f;
}

.text-heading-xl, .text-heading-xl--alert, .text-heading-xl--info, .text-heading-xl--danger, .text-heading-xl--success, .text-heading-xl--black, .text-heading-xl--disabled, .text-heading-xl--secondary, .text-heading-xl--primary, .text-heading-xl--default {
  font: var(--text-heading-xl);
  color: var(--text-color-default);
}
.text-heading-xl--default {
  color: var(--text-color-default);
}
.text-heading-xl--primary {
  color: #9655ff;
}
.text-heading-xl--secondary {
  color: #878787;
}
.text-heading-xl--disabled {
  color: #545454;
}
.text-heading-xl--black {
  color: #111010;
}
.text-heading-xl--success {
  color: #09d793;
}
.text-heading-xl--danger {
  color: #ca2029;
}
.text-heading-xl--info {
  color: #40a9f9;
}
.text-heading-xl--alert {
  color: #ffc53f;
}

.text-heading-lg, .text-heading-lg--alert, .text-heading-lg--info, .text-heading-lg--danger, .text-heading-lg--success, .text-heading-lg--black, .text-heading-lg--disabled, .text-heading-lg--secondary, .text-heading-lg--primary, .text-heading-lg--default {
  font: var(--text-heading-lg);
  color: var(--text-color-default);
}
.text-heading-lg--default {
  color: var(--text-color-default);
}
.text-heading-lg--primary {
  color: #9655ff;
}
.text-heading-lg--secondary {
  color: #878787;
}
.text-heading-lg--disabled {
  color: #545454;
}
.text-heading-lg--black {
  color: #111010;
}
.text-heading-lg--success {
  color: #09d793;
}
.text-heading-lg--danger {
  color: #ca2029;
}
.text-heading-lg--info {
  color: #40a9f9;
}
.text-heading-lg--alert {
  color: #ffc53f;
}

.text-heading-md, .text-heading-md--alert, .text-heading-md--info, .text-heading-md--danger, .text-heading-md--success, .text-heading-md--black, .text-heading-md--disabled, .text-heading-md--secondary, .text-heading-md--primary, .text-heading-md--default {
  font: var(--text-heading-md);
  color: var(--text-color-default);
}
.text-heading-md--default {
  color: var(--text-color-default);
}
.text-heading-md--primary {
  color: #9655ff;
}
.text-heading-md--secondary {
  color: #878787;
}
.text-heading-md--disabled {
  color: #545454;
}
.text-heading-md--black {
  color: #111010;
}
.text-heading-md--success {
  color: #09d793;
}
.text-heading-md--danger {
  color: #ca2029;
}
.text-heading-md--info {
  color: #40a9f9;
}
.text-heading-md--alert {
  color: #ffc53f;
}

.text-heading-sm, .text-heading-sm--alert, .text-heading-sm--info, .text-heading-sm--danger, .text-heading-sm--success, .text-heading-sm--black, .text-heading-sm--disabled, .text-heading-sm--secondary, .text-heading-sm--primary, .text-heading-sm--default {
  font: var(--text-heading-sm);
  color: var(--text-color-default);
}
.text-heading-sm--default {
  color: var(--text-color-default);
}
.text-heading-sm--primary {
  color: #9655ff;
}
.text-heading-sm--secondary {
  color: #878787;
}
.text-heading-sm--disabled {
  color: #545454;
}
.text-heading-sm--black {
  color: #111010;
}
.text-heading-sm--success {
  color: #09d793;
}
.text-heading-sm--danger {
  color: #ca2029;
}
.text-heading-sm--info {
  color: #40a9f9;
}
.text-heading-sm--alert {
  color: #ffc53f;
}

/** aplica classes para body **/
.text-body-lg, .text-body-lg--alert, .text-body-lg--info, .text-body-lg--danger, .text-body-lg--success, .text-body-lg--black, .text-body-lg--disabled, .text-body-lg--secondary, .text-body-lg--primary, .text-body-lg--default {
  font: var(--text-body-lg);
  color: var(--text-color-default);
}
.text-body-lg--default {
  color: var(--text-color-default);
}
.text-body-lg--primary {
  color: #9655ff;
}
.text-body-lg--secondary {
  color: #878787;
}
.text-body-lg--disabled {
  color: #545454;
}
.text-body-lg--black {
  color: #111010;
}
.text-body-lg--success {
  color: #09d793;
}
.text-body-lg--danger {
  color: #ca2029;
}
.text-body-lg--info {
  color: #40a9f9;
}
.text-body-lg--alert {
  color: #ffc53f;
}

.text-body-md, .text-body-md--alert, .text-body-md--info, .text-body-md--danger, .text-body-md--success, .text-body-md--black, .text-body-md--disabled, .text-body-md--secondary, .text-body-md--primary, .text-body-md--default {
  font: var(--text-body-md);
  color: var(--text-color-default);
}
.text-body-md--default {
  color: var(--text-color-default);
}
.text-body-md--primary {
  color: #9655ff;
}
.text-body-md--secondary {
  color: #878787;
}
.text-body-md--disabled {
  color: #545454;
}
.text-body-md--black {
  color: #111010;
}
.text-body-md--success {
  color: #09d793;
}
.text-body-md--danger {
  color: #ca2029;
}
.text-body-md--info {
  color: #40a9f9;
}
.text-body-md--alert {
  color: #ffc53f;
}

.text-body-xxsm, .text-body-xxsm--alert, .text-body-xxsm--info, .text-body-xxsm--danger, .text-body-xxsm--success, .text-body-xxsm--black, .text-body-xxsm--disabled, .text-body-xxsm--secondary, .text-body-xxsm--primary, .text-body-xxsm--default {
  font: var(--text-body-xxsm);
  color: var(--text-color-default);
}
.text-body-xxsm--default {
  color: var(--text-color-default);
}
.text-body-xxsm--primary {
  color: #9655ff;
}
.text-body-xxsm--secondary {
  color: #878787;
}
.text-body-xxsm--disabled {
  color: #545454;
}
.text-body-xxsm--black {
  color: #111010;
}
.text-body-xxsm--success {
  color: #09d793;
}
.text-body-xxsm--danger {
  color: #ca2029;
}
.text-body-xxsm--info {
  color: #40a9f9;
}
.text-body-xxsm--alert {
  color: #ffc53f;
}

.text-body-xsm, .text-body-xsm--alert, .text-body-xsm--info, .text-body-xsm--danger, .text-body-xsm--success, .text-body-xsm--black, .text-body-xsm--disabled, .text-body-xsm--secondary, .text-body-xsm--primary, .text-body-xsm--default {
  font: var(--text-body-xsm);
  color: var(--text-color-default);
}
.text-body-xsm--default {
  color: var(--text-color-default);
}
.text-body-xsm--primary {
  color: #9655ff;
}
.text-body-xsm--secondary {
  color: #878787;
}
.text-body-xsm--disabled {
  color: #545454;
}
.text-body-xsm--black {
  color: #111010;
}
.text-body-xsm--success {
  color: #09d793;
}
.text-body-xsm--danger {
  color: #ca2029;
}
.text-body-xsm--info {
  color: #40a9f9;
}
.text-body-xsm--alert {
  color: #ffc53f;
}

.text-body-sm, .text-body-sm--alert, .text-body-sm--info, .text-body-sm--danger, .text-body-sm--success, .text-body-sm--black, .text-body-sm--disabled, .text-body-sm--secondary, .text-body-sm--primary, .text-body-sm--default {
  font: var(--text-body-sm);
  color: var(--text-color-default);
}
.text-body-sm--default {
  color: var(--text-color-default);
}
.text-body-sm--primary {
  color: #9655ff;
}
.text-body-sm--secondary {
  color: #878787;
}
.text-body-sm--disabled {
  color: #545454;
}
.text-body-sm--black {
  color: #111010;
}
.text-body-sm--success {
  color: #09d793;
}
.text-body-sm--danger {
  color: #ca2029;
}
.text-body-sm--info {
  color: #40a9f9;
}
.text-body-sm--alert {
  color: #ffc53f;
}

/** aplica resolucao de acordo com o tamanho da tela **/
/** Aplica as media queries **/
@media (min-width: 1280px) {
  .text-heading-xl, .text-heading-xl--default, .text-heading-xl--primary, .text-heading-xl--secondary, .text-heading-xl--disabled, .text-heading-xl--black, .text-heading-xl--success, .text-heading-xl--danger, .text-heading-xl--info, .text-heading-xl--alert {
    font: var(--text-heading-xl) !important;
  }
}
@media (min-width: 1366px) {
  .text-heading-xxl, .text-heading-xxl--default, .text-heading-xxl--primary, .text-heading-xxl--secondary, .text-heading-xxl--disabled, .text-heading-xxl--black, .text-heading-xxl--success, .text-heading-xxl--danger, .text-heading-xxl--info, .text-heading-xxl--alert {
    font: var(--text-heading-xxl) !important;
  }
}
@media (min-width: 1920px) {
  .text-heading-xxl, .text-heading-xxl--default, .text-heading-xxl--primary, .text-heading-xxl--secondary, .text-heading-xxl--disabled, .text-heading-xxl--black, .text-heading-xxl--success, .text-heading-xxl--danger, .text-heading-xxl--info, .text-heading-xxl--alert {
    font: var(--text-heading-xxxl) !important;
  }
}
/** aplicação de bold e semibold de acordo com a necessidade, isolando da classes de texts **/
.-semibold {
  font-weight: 600 !important;
}

.-bold {
  font-weight: bold !important;
}

.-uppercase {
  text-transform: uppercase;
}

/** aplicação de cores de acordo com a necessidade, isolando da classes de texts**/

/*# sourceMappingURL=chordium.css.map */
