.mzic-popover.modal-popover {
  border-radius: 16px;
  background-color: var(--neutral-600);
  padding: 16px 24px 24px 24px;
  width: 350px;
  gap: 0;
  max-height: 86vh;

  > div {
    padding: inherit;
    font-size: inherit;
    line-height: inherit;
    height: inherit;
    gap: inherit;
    align-items: inherit;
  }

  &.medium {
    width: 596px;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0;

    &.flex-end {
      justify-content: flex-end;
    }

    &.no-margin {
      margin: 0;
    }

    .title {
      @extend .text-body-md-semibold;
      display: inline-flex;
      padding-right: 27px;
      align-items: center;
      gap: 8px;
      margin-top: -2px !important;
    }

    .mz-button.close {
      margin-top: 4px;
      padding: 0;
      height: 16px;
      width: 16px;
    }
  }

  .main {
    @extend .mzic-scrollbar;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    width: 100%;
    max-height: 70vh;
    gap: 12px;

    @media screen and (max-height: 950px) {
      max-height: 64vh;
    }
    @media screen and (max-height: 640px) {
      max-height: 58vh;
    }

    &.center {
      align-items: center;

      p {
        justify-content: center;
        text-align: center;
      }
    }

    &.padding {
      padding: 24px 24px 4px 24px;
    }

    &.no-gap {
      gap: 0;
    }

    .title {
      @extend .text-body-md-semibold;
      font-size: 16px;
      color: var(--neutral-100);
      margin-bottom: -3px;
    }

    p {
      @extend .text-body-xxsm;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    padding: 0;
    width: 100%;

    &.flex-end {
      justify-content: flex-end;
    }

    button:not(.full) {
      width: auto;
    }

    &.padding {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 24px;
    }
  }
}
